import Api from "@/services/api";
import PersonalizerApi from "@/services/personalizer_api";
import {GET_ITEMS_DETAILS_BY_IDS} from "@/store/_actiontypes";
import { updateLoaderTo } from "./account";

const state = {};

const actions = {
    [GET_ITEMS_DETAILS_BY_IDS]({ commit }, {project_id, ids }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            PersonalizerApi.post(`items`, { ids },
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            ).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    }
};

const mutations = {};

export const items = {
    namespaced: true,
    state,
    actions,
    mutations,
};
