import axios from "axios";
import Api from "@/services/api";

export default {
  install(Vue) {
    Vue.prototype.$ipCheck = {
      // Function to get the client's IP address
      async getClientIP() {
        const response = await axios.get("https://api.ipify.org?format=json");
        return response.data.ip;
      },

      // Async function to check if client IP is within allowed ranges
      async ipRangeCheck(clientIP) {
        const { data } = await Api.get(
          "/allowlists",
          {
            headers: {
              'X-Client-IP': clientIP,
            }
          }
        );
        return data.allowed;
      }
    };
  }
};
