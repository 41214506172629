<template>
  <div>
    <Loader
      v-if="isLoading"
      class="mr-2 mt-6"
      loaderType="card-avatar, article, actions"
    />
    <v-container v-else fill-height fluid>
      <v-row no-gutters>
        <v-col cols="12" xs="12" md="7">
          <div
              v-if="isDeveloper() && selectedSolution.status"
              class="d-flex justify-space-between"
            >
                <h1>{{ selectedSolution.name }}</h1>
              <div class="mt-3">
                <template>
                  <v-tooltip top v-if="selectedSolution.status !== 'deleted'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="redirectEdit(selectedSolution)"
                      >
                        <v-icon
                          :color="
                            selectedSolution.segment && selectedSolution.segment.color
                          "
                          >mdi-pencil-circle-outline</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>{{ $vuetify.lang.t("$vuetify.tooltip.edit") }}</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    v-if="
                      selectedSolution.status !== 'published' &&
                      selectedSolution.status !== 'deleted'
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="publishSolution($event, selectedSolution)"
                      >
                        <v-icon
                          :color="
                            selectedSolution.segment && selectedSolution.segment.color
                          "
                        >
                          {{
                            selectedSolution.status &&
                            selectedSolution.status === "drafted"
                              ? "mdi-check-circle-outline"
                              : "mdi-file-outline"
                          }}
                        </v-icon></v-btn
                      >
                    </template>
                    <span>{{
                      publicationTooltip
                        ? publicationTooltip
                        : setTooltip(selectedSolution.status)
                    }}</span>
                  </v-tooltip>
                </template>
              </div>
            </div>
          <v-sheet v-else class="pb-3">
            <h1>{{ selectedSolution.name }}</h1>
          </v-sheet>
          <v-chip class="ma2" color="primary" label outlined pill>
            <v-icon left> mdi-account-outline </v-icon>
            {{
              selectedSolution.account ? selectedSolution.account.account_name : "None"
            }}
          </v-chip>
          <v-chip
            class="ma-2"
            v-bind:color="selectedSolution.segment && selectedSolution.segment.color"
            label
            outlined
            pill
          >
            {{ selectedSolution.segment && selectedSolution.segment.name }}
          </v-chip>
          <v-sheet class="pt-3">
            <h2>{{ $vuetify.lang.t("$vuetify.overview_label_txt") }}</h2>
          </v-sheet>
          <p v-if="selectedSolution && selectedSolution.overview">
            <show-rich-text-data
              :editorId="'selectedSolutionOverview'"
              :editorData="selectedSolution.overview"
            />
          </p>
          <v-sheet class="pt-3">
            <h2>{{ $vuetify.lang.t("$vuetify.description_label_txt") }}</h2>
          </v-sheet>
          <p v-if="selectedSolution && selectedSolution.description">
            <show-rich-text-data
              :editorId="'selectedSolutionDesc'"
              :editorData="selectedSolution.description"
            />
          </p>
          <v-sheet class="pt-3">
            <h2>{{ $vuetify.lang.t("$vuetify.use_case_label_txt") }}</h2>
          </v-sheet>
          <p v-if="selectedSolution && selectedSolution.use_case">
            <show-rich-text-data
              :editorId="'selectedSolutionUseCases'"
              :editorData="selectedSolution.use_case"
            />
          </p>
          <v-sheet class="pb-3">
            <h2>{{ $vuetify.lang.t("$vuetify.language_label_txt") }}</h2>
          </v-sheet>
          <v-chip
            v-for="element in selectedSolution.languages"
            :key="element"
            class="ma-2"
            color="success"
            label
            outlined
            pill
          >
            {{ element }}
          </v-chip>
        </v-col>
        <v-col cols="12" xs="12" md="5">
          <v-card class="elevation-0">
            <v-img
              class="rounded my-3"
              :src="
                selectedSolution.cover_image
                  ? selectedSolution.cover_image
                  : segmentImg(selectedSolution)
              "
              aspect-ratio="2"
            ></v-img>

            <v-sheet class="py-3">
              <h2>{{ $vuetify.lang.t("$vuetify.industry_label_txt") }}</h2>
            </v-sheet>
            <v-chip
              class="ma-2"
              v-bind:color="selectedSolution.segment && selectedSolution.segment.color"
              outlined
              label
              pill
              v-for="element in selectedSolution.categories"
              :key="element.id"
            >
              {{ element.name }}
            </v-chip>
            <!-- <h2 v-else class="mt-3">
              {{ $vuetify.lang.t("$vuetify.base_price_label_txt_2") }}
            </h2>
            <h3 class="primary--text ml-2">
              {{ formatNum(selectedSolution.base_price) }}
              {{ selectedSolution.currency ? selectedSolution.currency : "USD" }}
            </h3>
            <h2 class="mt-3">
              {{ $vuetify.lang.t("$vuetify.base_infra_price_label_txt") }}
            </h2>
            <h3 class="primary--text ml-2">
              {{
                selectedSolution.base_infra_price &&
                selectedSolution.base_infra_price.aws &&
                formatNum(selectedSolution.base_infra_price.aws)
              }}
              {{ selectedSolution.currency ? selectedSolution.currency : "USD" }}
            </h3>
            <strong class="ml-2">{{
              `(${selectedSolution.max_pv / 1000000} `+$vuetify.lang.t('$vuetify.million_requests_month_txt')+`)`
            }}</strong> -->

            <!-- <v-card-actions v-if="authorize([Role.ADMIN])" class="py-5">
              <v-btn
                class="text-capitalize font-weight-bold"
                color="primary"
                large
                outlined
                block
                :disabled="
                  selectedSolution.status && selectedSolution.status === 'published'
                    ? false
                    : true
                "
                @click="createProject()"
              >
                {{ $vuetify.lang.t("$vuetify.create_project_btn_txt") }}
                <v-icon right class="ml-2">add_circle_outline</v-icon>
              </v-btn>
            </v-card-actions> -->
            <!-- <v-card-actions v-if="authorize([Role.ADMIN])" class="py-5 pt-0">
              <v-btn
                class="text-capitalize font-weight-bold"
                color="primary"
                large
                block
                :disabled="
                  selectedSolution.status && selectedSolution.status === 'published'
                    ? false
                    : true
                "
                @click="createProject()"
              >
                {{ $vuetify.lang.t("$vuetify.create_trial_project_btn_txt") }}
                <v-icon right class="ml-2">add_circle_outline</v-icon>
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>
      <br />
      <v-row v-if="hasLinks()" no-gutters>
        <v-col>
          <h2>{{ $vuetify.lang.t("$vuetify.linked_solution_label_txt_3") }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          v-for="element in selectedSolution.links"
          :key="element.id"
        >
          <LinkSolutionCard
            v-if="element"
            :itemData="element"
            :color="selectedSolution.segment && selectedSolution.segment.color"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import { formatNum, authorize } from "@/helpers/helper";

import {
  LOAD_SOLUTION_BY_ID,
  LOAD_RELATED_SOLUTIONS_BY_TYPE,
  PUBLISH_SOLUTION_BY_ID,
  DRAFT_SOLUTION_BY_ID,
  ADD_ALERT,
} from "@/store/_actiontypes";

import { RAISED_BUTTON } from "@/components/common/button/_buttontypes";

import { Role } from "@/helpers/role";

import Loader from "@/components/common/loader/Loader";
import ShowRichTextData from "../components/SolutionRegister/ShowRichTextData";
import LinkSolutionCard from "@/components/Solutions/LinkSolutionCard";
import jwt_decode from "jwt-decode";

export default {
  props: {},
  components: {
    Loader,
    ShowRichTextData,
    LinkSolutionCard,
  },
  computed: {
    ...mapState({
      selectedSolution: (state) => state.solution.selectedSolution,
      relatedSolutions: (state) => state.solution.relatedSolutions,
      user: (state) => state.account.user,
    }),
    ...mapGetters("loader", ["loading"]),
  },
  mounted() {
    this.getSolution();
  },
  data() {
    return {
      Role,
      formatNum,
      authorize,
      isLoading: false,
      selectedSolutionType: "",
      RAISED_BUTTON: RAISED_BUTTON,
      publicationTooltip: "",
    };
  },
  methods: {
    ...mapActions("alert", [ADD_ALERT]),
    ...mapActions("solution", [
      LOAD_SOLUTION_BY_ID,
      LOAD_RELATED_SOLUTIONS_BY_TYPE,
      PUBLISH_SOLUTION_BY_ID,
      DRAFT_SOLUTION_BY_ID,
    ]),
    getSolution() {
      this.isLoading = true;
      this.LOAD_SOLUTION_BY_ID({
        solution_id: this.$route.params.solution_id,
      }).then(
        (response) => {
          this.isLoading = false;
          this.selectedSolutionType = this.selectedSolution.type;
          // this.getRelatedSolutionByTypeFunction();
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    getRelatedSolutionByTypeFunction() {
      this.LOAD_RELATED_SOLUTIONS_BY_TYPE({
        type: this.selectedSolutionType,
        solution_id: this.selectedSolution.id,
      }).then(
        (response) => {
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    createProject() {
      this.$router.push("/create-projects/" + this.$route.params.solution_id);
    },
    redirectDetails(itemData) {
      this.$router.replace({ params: { solution_id: itemData.id } });
    },
    hasLinks() {
      return this.selectedSolution.links && this.selectedSolution.links.length > 0
        ? true
        : false;
    },
    isDeveloper() {
      let parsedJWT = jwt_decode(this.user.access_token);
      return parsedJWT["scope"]?.includes("developer");
    },
    redirectEdit(selectedSolution) {
      this.$router.push(`/update-solutions/${selectedSolution.id}`);
    },
    publishSolution(event, selectedSolution) {
      event.preventDefault();
      event.stopPropagation();
      if (selectedSolution && selectedSolution.id && selectedSolution.status) {
        if (selectedSolution.status === "drafted") {
          this.PUBLISH_SOLUTION_BY_ID({
            solutionId: selectedSolution.id,
          }).then(
            (response) => {
              if (response) {
                this.$store.dispatch(
                  `alert/${ADD_ALERT}`,
                  {
                    message: this.$vuetify.lang.t("$vuetify.solution_submit_msg_txt"),
                    color: "success",
                  },
                  { root: true }
                );
                this.publicationTooltip = this.$vuetify.lang.t("$vuetify.tooltip.draft");
                this.getSolution();
              }
            },
            (error) => error
          );
        } else {
          this.DRAFT_SOLUTION_BY_ID({
            solutionId: selectedSolution.id,
          }).then(
            (response) => {
              if (response) {
                this.$store.dispatch(
                  `alert/${ADD_ALERT}`,
                  {
                    message: this.$vuetify.lang.t("$vuetify.solution_draft_msg_txt"),
                    color: "success",
                  },
                  { root: true }
                );
                this.publicationTooltip = this.$vuetify.lang.t(
                  "$vuetify.tooltip.submit_for_review"
                );
                this.getSolution();
              }
            },
            (error) => error
          );
        }
      }
    },
    setTooltip(status) {
      let tooltip = "";
      if (status && status === "drafted") {
        tooltip = this.$vuetify.lang.t("$vuetify.tooltip.submit_for_review");
      } else {
        tooltip = this.$vuetify.lang.t("$vuetify.tooltip.draft");
      }
      return tooltip;
    },
    segmentImg(data) {
      if (data && data.segment && data.segment.cover_image_url) {
        return data.segment.cover_image_url;
      }
    },
    contactSalesUrl() {
      return this.$vuetify.lang.t("$vuetify.contact_sales_url")
    }
  },
};
</script>

<style scoped>
.text-4xl {
  color: black;
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-2xl {
  color: black;
  font-size: 1.875rem;
  line-height: 1.5;
  letter-spacing: 0.025em;
}
.font-semibold {
  font-weight: 600;
}
.whitespace-pre-line {
  white-space: pre-line;
}
.text-base {
  color: black;
  font-size: 1rem;
  line-height: 1.5rem;
}
.font-normal {
  font-weight: 400;
}
.mb-14 {
  margin-bottom: 3.5rem;
}
p,
ul {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-card {
  margin: 10px;
}
.v-card__title {
  word-break: break-word;
  height: 100px;
  vertical-align: middle;
}
.v-card__subtitle,
.v-card__text {
  font-size: 1em;
}
.ml-auto {
  margin-left: auto;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
</style>
