<template>
  <v-menu class="mb-5" offset-y>
    <template v-slot:activator="{ on }" class="ml-4">
      <div v-on="on" class="d-flex align-center">
        <v-btn text class="px-4 py-5">
          <v-img :lazy-src="localeImg" max-width="20" :src="localeImg"></v-img>
          <v-icon class="white--text px-2">expand_more</v-icon>
        </v-btn>
      </div>
    </template>
    <v-list>
      <v-list-item
        v-for="item in localeMenuItems"
        :key="item.text"
        dense
        style="cursor: pointer"
      >
        <v-list-item-title @click="changeLocale(item.text)">{{
          item.text
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
import { CHANGE_LOCALE } from "@/store/_actiontypes";
import Global from "../../assets/images/globe-white.png";
import Japan from "../../assets/images/japan.png";

export default {
  mounted() {
    this.checkStorageForLocale();
  },
  data() {
    return {
      localeMenuItems: [{ text: "English" }, { text: "日本語" }],
      localeImg: Global,
    };
  },
  methods: {
    ...mapActions("alert", [CHANGE_LOCALE]),
    changeLocale(locale) {
      if (locale && locale === "English") {
        this.localeImg = Global;
        localStorage.setItem("language", "en-gb");
        this.$vuetify.lang.current = localStorage
          .getItem("language")
          ?.substring(0, localStorage.getItem("language")?.indexOf("-"));
        this.CHANGE_LOCALE(locale.toLowerCase());
      } else if (locale && locale === "日本語") {
        this.localeImg = Japan;
        localStorage.setItem("language", "ja-jp");
        this.$vuetify.lang.current = localStorage
          .getItem("language")
          ?.substring(0, localStorage.getItem("language")?.indexOf("-"));
        this.CHANGE_LOCALE(locale.toLowerCase());
      }
    },
    checkStorageForLocale() {
      if (
        localStorage.getItem("language") &&
        localStorage.getItem("language") === "ja-jp"
      ) {
        this.localeImg = Japan;
        this.$vuetify.lang.current = localStorage
          .getItem("language")
          ?.substring(0, localStorage.getItem("language")?.indexOf("-"));
        this.CHANGE_LOCALE("ja");
      } else if (
        localStorage.getItem("language") &&
        localStorage.getItem("language") === "en-gb"
      ) {
        this.localeImg = Global;
        this.$vuetify.lang.current = localStorage
          .getItem("language")
          ?.substring(0, localStorage.getItem("language")?.indexOf("-"));
        this.CHANGE_LOCALE("en");
      }
    },
  },
};
</script>
