import Api from "@/services/api";
import {
    LOAD_ALL_BANK_ACCOUNT,
    LOAD_BANK_ACCOUNT_BY_ID,
} from "@/store/_actiontypes";
import {
    SET_ALL_BANK_ACCOUNT,
    SET_BANK_ACCOUNT_BY_ID
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
    allBankAccounts: [],
    selectedBankAccount: {}
};

const actions = {
  [LOAD_ALL_BANK_ACCOUNT]({ commit }, {}) {
    updateLoaderTo(true);
    commit(SET_ALL_BANK_ACCOUNT, []);
    return new Promise((resolve, reject) => {
      Api.get(`bank-accounts`).then(
        (response) => {
          let allBankAccounts = response.data.accounts;
          commit(SET_ALL_BANK_ACCOUNT, allBankAccounts);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
    [LOAD_BANK_ACCOUNT_BY_ID]({ commit }, { id }) {
        updateLoaderTo(true)
        commit(SET_BANK_ACCOUNT_BY_ID, {})
        return new Promise((resolve, reject) => {
            Api.get(`bank-accounts/${id}`).then(
                response => {
                    let bank = response.data;
                    commit(SET_BANK_ACCOUNT_BY_ID, bank)
                    updateLoaderTo(false)
                    resolve(response)
                },
                error => {
                    updateLoaderTo(false)
                    reject(error)
                }
            )
        })
    }
};

const mutations = {
  [SET_ALL_BANK_ACCOUNT](state, allBankAccounts) {
    state.allBankAccounts = allBankAccounts;
  },
  [SET_BANK_ACCOUNT_BY_ID](state, selectedBankAccount) {
      state.selectedBankAccount = selectedBankAccount;
  }
};

export const bank_accounts = {
  namespaced: true,
  state,
  actions,
  mutations,
};
