<template>
    <v-datetime-picker
      v-model="inputVal"
      :label="label"
      :placeholder="placeholder"
      :hint="hint"
      :disabled="disabled"
      @input="onInput"
    >
    </v-datetime-picker>
</template>

<script>

import Vue from 'vue'
import DatetimePicker from 'vuetify-datetime-picker'

Vue.use(DatetimePicker)

export default {
  props: {
    value : {
      
    },
    label: {
      type: String,
      default: "Select Date Time"
    },
    hint: {
      type: String,
      default: "YYYY-MM-DD HH:MM"
    },
    type: {
      type: String,
      default: "date"
    },
    placeholder: {
      type: String,
      default: ""
    },
    rules: {
      type: Array,
      default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  data() {
    return {
      
    };
  },
  methods: {
    onInput() {
      this.$emit('onInput');
    },
  }
};
</script>

<style>
  .row {
    margin: 0px;
    margin-right: -12px;
    margin-left: -12px;
  }
</style>
