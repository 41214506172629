import {encodingForModel} from 'js-tiktoken';

export default {
  install(Vue) {
    Vue.prototype.$initTokenizer = async (model) => {
      try {
        return await encodingForModel(model);
      } catch (error) {
        console.error('Failed to initialize tokenizer:', error);
        throw error; // or handle it appropriately
      }
    };
  }
};