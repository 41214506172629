<template>
  <div>
    <v-app-bar app clipped-left dark color="primary">
      <v-row>
        <v-col cols="6" xs="6" md="6">
          <v-toolbar-title>
            <router-link
              to="/public/solutions"
              tag="span"
              style="cursor: pointer"
              class="headline"
            >
              <img src="@/assets/images/logo_white_horizontal.png" class="logo"  alt="Gigalogy"/>
            </router-link>
          </v-toolbar-title>
        </v-col>
        <v-col class="d-flex justify-end" cols="3" xs="3" md="3">
          <switch-language />
        </v-col>
        <v-col cols="3" xs="3" md="3" class="get-started-icon">
          <v-btn
            class="text-uppercase font-weight-bold"
            large
            outlined
            block
            @click="goToLogin()"
          >
            <span class="d-none d-sm-flex">{{
              $vuetify.lang.t("$vuetify.get_started_btn_txt")
            }}</span>
            <v-icon class="d-flex d-sm-none">account_circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-spacer></v-spacer>
    <div class="pa-4 mt-12">
      <v-container fill-height v-if="isLoading">
        <v-row>
          <v-container fluid>
            <v-row align="center">
              <v-col cols="12" md="12" lg="12">
                <v-skeleton-loader
                    class="mr-2 mt-6"
                    type="card-heading, actions"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-skeleton-loader
                    type="image, card-heading, actions"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-skeleton-loader
                    type="image, card-heading, actions"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-skeleton-loader
                    type="image, card-heading, actions"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-container>
      <v-container fill-height v-else>
        <v-row>
          <v-container fluid class="hidden-sm-and-down">
            <v-row align="center">
              <!-- Select Component -->
              <v-col
                class="border-right:none"
                cols="12"
                md="2"
                lg="2"
              >
                <v-select
                  v-model="solution_type"
                  outlined
                  :items="typeStates"
                  item-text="state"
                  item-value="value"
                  persistent-hint
                  @change="search"
                ></v-select>
              </v-col>
              <!-- Text Field Component -->
              <v-col class="pa-2" cols="12" md="10" lg="10">
                <v-text-field
                  v-model="searchText"
                  outlined
                  :label="$vuetify.lang.t('$vuetify.search_label_txt')"
                  prepend-inner-icon="search"
                  @keypress.enter="search"
                >
                  <template v-slot:append>
                    <v-btn
                      large
                      class="text-capitalize font-weight-bold mt-n3 mr-n2"
                      color="primary"
                      append-icon="search"
                      @click="search"
                    >
                      <span class="d-none d-sm-flex">{{
                        $vuetify.lang.t("$vuetify.search_btn_txt")
                      }}</span>
                      <v-icon class="d-flex d-sm-none">search</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <!-- Mobile responsiveness -->
          <template>
            <v-container fluid class="hidden-md-and-up">
              <v-row align="center">
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="searchText"
                    outlined
                    :label="$vuetify.lang.t('$vuetify.search_label_txt')"
                    prepend-inner-icon="search"
                    @keypress.enter="search"
                  >
                    <template v-slot:append>
                      <v-btn
                        large
                        class="text-capitalize font-weight-bold mt-n3 mr-n2"
                        color="primary"
                        append-icon="search"
                        @click="search"
                      >
                        <span class="d-none d-sm-flex">{{
                          $vuetify.lang.t("$vuetify.search_btn_txt")
                        }}</span>
                        <v-icon class="d-flex d-sm-none">search</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                
                <v-col
                  class="border-right:none"
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-model="solution_type"
                    outlined
                    :items="typeStates"
                    item-text="state"
                    item-value="value"
                    persistent-hint
                    @change="search"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-row> 

        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="4"
            xl="4"
            v-for="item in solutions"
            :key="item.id"
          >
            <SolutionCard :itemData="item" :isPublic="true" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { LOAD_PUBLIC_SOLUTIONS, LOAD_ALL_CATEGORY } from "@/store/_actiontypes";
import SolutionCard from "@/components/Solutions/SolutionCard";
import SwitchLanguage from "@/components/common/SwitchLanguage";
import {
  SEGMENTS
} from '@/global/_constants';
export default {
  components: {
    SolutionCard,
    SwitchLanguage,
  },
  computed: {
    ...mapState({
      publicSolutions: (state) => state.solution.publicSolutions,
      allCategory: (state) => state.category.allCategory,
    }),
  },
  mounted() {
    this.getPubSolutions();
    this.getCategories();
  },
  data() {
    return {
      isLoading: false,
      searchText: "",
      PROJECT_SEGMENTS: SEGMENTS,
      typeStates: [
        { state: "All", value: "" },
        { state: SEGMENTS.PERSONALIZER, value: SEGMENTS.PERSONALIZER },
        { state: SEGMENTS.CV, value: SEGMENTS.CV },
        { state: SEGMENTS.Maira, value: SEGMENTS.Maira}
      ],
      categoryStates: [{ state: "", value: "" }],
      solution_type: "",
      solution_category: "",
      solutions: [],
    };
  },
  watch: {
    publicSolutions: {
      handler: function () {
        this.solutions = this.publicSolutions;
      },
      deep: true,
    },
    allCategory: {
      handler: function () {
        this.allCategory.forEach((element) => {
          this.categoryStates.push({
            state: element.name,
            value: element.name,
          });
        });
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("solution", [LOAD_PUBLIC_SOLUTIONS]),
    ...mapActions("category", [LOAD_ALL_CATEGORY]),
    getPubSolutions() {
      this.isLoading = true;
      this.LOAD_PUBLIC_SOLUTIONS({
        searchText: this.searchText,
        type: this.solution_type,
        category: this.solution_category,
      }).then(
        (response) => {
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    getCategories() {
      this.LOAD_ALL_CATEGORY();
    },
    search() {
      this.getPubSolutions();
    },
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.v-app-bar {
  height: 64px !important;
}
.logo {
  max-height: 48px;
  vertical-align: middle;
}
@media screen and (max-width: 950px) {
  .get-started-icon {
    margin-top: 6px;
  }
}
</style>
