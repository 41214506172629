<template>
  <v-skeleton-loader :type="loaderType"> </v-skeleton-loader>
</template>

<script>
import { DATA_TABLE_LOADER } from "@/components/common/loader/_loadertypes";

export default {
  props: {
    loaderType: {
      type: String,
      default: DATA_TABLE_LOADER,
    },
  },
  data() {
    return {
      DATA_TABLE_LOADER: DATA_TABLE_LOADER,
    };
  },
};
</script>
