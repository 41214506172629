<template>
  <div class="mt-4">
    <template v-if="cancelBtn">
      <v-container fill-height fluid>
        <v-row>
          <v-col class="pa-0" xs="12" md="12">
            <v-card outlined class="mx-auto px-2">
              <v-row>
                <v-col xs="12" md="6">
                  <div class="px-2 primary--text font-weight-bold">
                    {{ $vuetify.lang.t("$vuetify.wallet.send_or_swap") }}
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-stepper v-model="e1" elevation="0">
                <v-stepper-header>
                  <template v-for="n in steps">
                    <v-stepper-step
                      :key="`${n}-step`"
                      :complete="e1 > n"
                      :step="n"
                    >
                      <!-- Step {{ n }} -->
                      <div v-if="n === 1">{{ $vuetify.lang.t("$vuetify.common.input") }}</div>
                      <div v-if="n === 2">{{ $vuetify.lang.t("$vuetify.common.review") }}</div>
                    </v-stepper-step>

                    <v-divider v-if="n !== steps" :key="n"></v-divider>
                  </template>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content
                    v-for="n in steps"
                    :key="`${n}-content`"
                    :step="n"
                  >
                    <div v-if="n === 1">
                      <ValidationObserver ref="firstStep">
                        <v-form ref="form1">
                          <v-row>
                            <v-col cols="12" xs="12" md="12">
                              <v-row>
                                <v-col cols="12">
                                  <v-alert type="info" outlined
                                    > {{ $vuetify.lang.t("$vuetify.wallet.send_swap_can_upto_msg") }}
                                    <Price 
                                      :amount="hasMinBalance() ? String(hasMinBalance()) : '0.00'"
                                      asset_code="XLM"
                                    />
                                  </v-alert
                                  >
                                </v-col>
                                <v-col cols="3">
                                  <Tooltip
                                    :label="$vuetify.lang.t('$vuetify.wallet.send_swap_action_type')"
                                    :message="$vuetify.lang.t('$vuetify.wallet.send_swap_action_tooltip')"
                                  />
                                </v-col>
                                <v-col cols="9">
                                  <div>
                                    <v-btn-toggle
                                      v-model="actionType"
                                      mandatory
                                      color="white"
                                      active-class="primary"
                                    >
                                      <v-btn :value="'send'" text> {{ $vuetify.lang.t("$vuetify.wallet.send_swap_send") }} </v-btn>
                                      <v-btn :value="'swap'" text> {{ $vuetify.lang.t("$vuetify.wallet.send_swap_swap") }} </v-btn>
                                    </v-btn-toggle>
                                  </div>
                                </v-col>
                              </v-row>
                              <template v-if="actionType === 'send'">
                                <v-row>
                                  <v-col cols="3">
                                    <Tooltip
                                      :label="$vuetify.lang.t('$vuetify.wallet.destination_public_key_label')"
                                      :message="$vuetify.lang.t('$vuetify.wallet.destination_public_key_msg')"
                                    />
                                  </v-col>
                                  <v-col cols="9">
                                    <ValidationProvider
                                      :name="$vuetify.lang.t('$vuetify.wallet.destination_account')"
                                      rules="required"
                                      v-slot="{ errors }"
                                    >
                                      <v-text-field
                                        v-model="sendTo"
                                        :placeholder="$vuetify.lang.t('$vuetify.wallet.destination_account_placeholder')"
                                        outlined
                                        :error-messages="errors"
                                      ></v-text-field>
                                    </ValidationProvider>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="3">
                                    <Tooltip
                                      :label="$vuetify.lang.t('$vuetify.wallet.destination_amount_label')"
                                      :message="$vuetify.lang.t('$vuetify.wallet.destination_amount_msg')"
                                    />
                                  </v-col>
                                  <v-col cols="9">
                                    <v-row no-gutters>
                                      <v-spacer></v-spacer>
                                      <v-col>
                                        <v-subheader
                                          :inset="false"
                                          class="d-block float-right"
                                        >
                                          {{ $vuetify.lang.t("$vuetify.wallet.available") }}:
                                          <v-progress-circular
                                            v-if="!acc_details"
                                            size="15"
                                            indeterminate
                                            color="primary"
                                          ></v-progress-circular>
                                          <span v-else>
                                            {{
                                              acc_details === "inactive"
                                                ? "0.00"
                                                : acc_details &&
                                                  getAssetBalanceData(
                                                    send_asset
                                                  ).length > 0
                                                ? getAssetBalanceData(
                                                    send_asset
                                                  )[0].balance
                                                : "0.00"
                                            }}
                                            {{ send_asset }}
                                          </span>
                                        </v-subheader>
                                      </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                      <v-col
                                        cols="5"
                                        sm="4"
                                        md="3"
                                        class="flex-grow-0 flex-shrink-0"
                                      >
                                        <ValidationProvider
                                          :name="$vuetify.lang.t('$vuetify.wallet.asset')"
                                          rules="required"
                                          v-slot="{ errors }"
                                        >
                                          <v-select
                                            class="mr-3"
                                            v-model="send_asset_code"
                                            :items="allAsset"
                                            :label="$vuetify.lang.t('$vuetify.wallet.asset')"
                                            outlined
                                            item-text="asset_code"
                                            return-object
                                            :error-messages="errors"
                                          >
                                            <template v-slot:selection="data">
                                              <v-chip
                                                :pill="true"
                                                color="white"
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                                @click="data.select"
                                              >
                                                <v-avatar
                                                  size="35"
                                                  left
                                                  :color="data.item.asset_icon ? 'lighten-2': 'primary lighten-2'"
                                                >
                                                  <v-img
                                                    v-if="data.item.asset_icon"
                                                    :src="data.item.asset_icon"
                                                  ></v-img>
                                                  <span v-else class="fix-asset-font">
                                                    {{ data.item.asset_code }}
                                                  </span>
                                                </v-avatar>
                                                {{ data.item.asset_code }}
                                              </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                              <template>
                                                <v-list-item-avatar :color="data.item.asset_icon ? 'lighten-2': 'primary lighten-2'">
                                                  <img v-if="data.item.asset_icon" :src="data.item.asset_icon" />
                                                  <span v-else class="fix-asset-font"> {{data.item.asset_code}} </span>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                  <v-list-item-title
                                                    v-html="data.item.asset_code"
                                                  ></v-list-item-title>
                                                </v-list-item-content>
                                              </template>
                                            </template>
                                          </v-select>
                                        </ValidationProvider>
                                      </v-col>
                                      <v-col
                                        cols="7"
                                        sm="8"
                                        md="9"
                                        class="flex-grow-0 flex-shrink-1"
                                      >
                                        <ValidationProvider
                                          :name="$vuetify.lang.t('$vuetify.wallet.amount')"
                                          rules="required|min_value:0|is_decimal_allowed"
                                          v-slot="{ errors }"
                                        >
                                          <v-text-field
                                            v-model="send_amount"
                                            :label="$vuetify.lang.t('$vuetify.wallet.amount')"
                                            type="number"
                                            min="0"
                                            outlined
                                            placeholder="0.0"
                                            :error-messages="errors"
                                          ></v-text-field>
                                        </ValidationProvider>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </template>
                              <template v-if="actionType === 'swap'">
                                <v-row>
                                  <v-col cols="3">
                                    <Tooltip
                                      :label="$vuetify.lang.t('$vuetify.wallet.swap')"
                                      :message="$vuetify.lang.t('$vuetify.wallet.swap_asset')"
                                    />
                                  </v-col>
                                  <v-col cols="9">
                                    <v-row no-gutters>
                                      <v-col>
                                        <v-subheader
                                          :inset="false"
                                          class="d-block float-right"
                                        >
                                          {{ $vuetify.lang.t("$vuetify.wallet.available") }}:
                                          <v-progress-circular
                                            v-if="!acc_details"
                                            size="15"
                                            indeterminate
                                            color="primary"
                                          ></v-progress-circular>
                                          <span v-else>
                                            {{
                                              acc_details === "inactive"
                                                ? "0.00"
                                                : acc_details &&
                                                  getAssetBalanceData(fromAsset)
                                                    .length > 0
                                                ? getAssetBalanceData(
                                                    fromAsset
                                                  )[0].balance
                                                : "0.00"
                                            }}
                                            {{ fromAsset }}
                                          </span>
                                        </v-subheader>
                                      </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                      <v-col
                                        cols="5"
                                        sm="4"
                                        md="3"
                                        class="flex-grow-0 flex-shrink-0"
                                      >
                                        <ValidationProvider
                                          :name="$vuetify.lang.t('$vuetify.wallet.from_asset')"
                                          rules="required"
                                          v-slot="{ errors }"
                                        >
                                          <v-select
                                            class="mr-3"
                                            :items="
                                              allAsset.filter(
                                                (item) => item.asset_code !== toAsset
                                              )
                                            "
                                            v-model="from_asset_code"
                                            :label="$vuetify.lang.t('$vuetify.wallet.from_asset')"
                                            item-text="asset_code"
                                            return-object
                                            outlined
                                            :error-messages="errors"
                                          >
                                            <template v-slot:selection="data">
                                              <v-chip
                                                :pill="true"
                                                color="white"
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                                @click="data.select"
                                              >
                                                <v-avatar 
                                                  size="35" 
                                                  left
                                                  :color="data.item.asset_icon ? 'lighten-2': 'primary lighten-2'"
                                                >
                                                  <v-img
                                                    v-if="data.item.asset_icon"
                                                    :src="data.item.asset_icon"
                                                  ></v-img>
                                                  <span v-else class="fix-asset-font">
                                                    {{ data.item.asset_code }}
                                                  </span>
                                                </v-avatar>
                                                {{ data.item.asset_code }}
                                              </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                              <template>
                                                <v-list-item-avatar :color="data.item.asset_icon ? 'lighten-2': 'primary lighten-2'">
                                                  <img v-if="data.item.asset_icon" :src="data.item.asset_icon" />
                                                  <span v-else class="fix-asset-font"> {{data.item.asset_code}} </span>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                  <v-list-item-title
                                                    v-html="data.item.asset_code"
                                                  ></v-list-item-title>
                                                </v-list-item-content>
                                              </template>
                                            </template>
                                          </v-select>
                                        </ValidationProvider>
                                      </v-col>
                                      <v-col
                                        cols="7"
                                        sm="8"
                                        md="9"
                                        class="flex-grow-0 flex-shrink-1"
                                      >
                                        <ValidationProvider
                                          :name="$vuetify.lang.t('$vuetify.wallet.from_amount')"
                                          rules="required|min_value:0|is_decimal_allowed"
                                          v-slot="{ errors }"
                                        >
                                          <v-text-field
                                            v-model="from_amount"
                                            :label="$vuetify.lang.t('$vuetify.wallet.from_amount')"
                                            placeholder="0.0"
                                            type="number"
                                            min="0"
                                            outlined
                                            :loading="check_loading"
                                            :error-messages="errors"
                                          ></v-text-field>
                                        </ValidationProvider>
                                      </v-col>
                                    </v-row>
                                    <v-row
                                      no-gutters
                                      align="center"
                                      class="mb-4 mt-n3"
                                    >
                                      <v-btn
                                        color="primary"
                                        fab
                                        small
                                        @click="swapValue()"
                                      >
                                        <v-icon>mdi-swap-vertical-bold</v-icon>
                                      </v-btn>
                                    </v-row>
                                    <v-row no-gutters>
                                      <v-spacer></v-spacer>
                                      <v-col>
                                        <v-subheader
                                          :inset="false"
                                          class="d-block float-right"
                                        >
                                          {{ $vuetify.lang.t("$vuetify.wallet.available") }}:
                                          <v-progress-circular
                                            v-if="!acc_details"
                                            size="15"
                                            indeterminate
                                            color="primary"
                                          ></v-progress-circular>
                                          <span v-else>
                                            {{
                                              acc_details === "inactive"
                                                ? "0.00"
                                                : acc_details &&
                                                  getAssetBalanceData(toAsset)
                                                    .length > 0
                                                ? getAssetBalanceData(
                                                    toAsset
                                                  )[0].balance
                                                : "0.00"
                                            }}
                                            {{ toAsset }}
                                          </span>
                                        </v-subheader>
                                      </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                      <v-col
                                        cols="5"
                                        sm="4"
                                        md="3"
                                        class="flex-grow-0 flex-shrink-0"
                                      >
                                        <ValidationProvider
                                          :name="$vuetify.lang.t('$vuetify.wallet.to_asset')"
                                          rules="required"
                                          v-slot="{ errors }"
                                        >
                                          <v-select
                                            class="mr-3"
                                            :items="
                                              allAsset.filter(
                                                (item) =>
                                                  item.asset_code !== fromAsset
                                              )
                                            "
                                            v-model="to_asset_code"
                                            :label="$vuetify.lang.t('$vuetify.wallet.to_asset')"
                                            item-text="asset_code"
                                            return-object
                                            outlined
                                            :error-messages="errors"
                                          >
                                            <template v-slot:selection="data">
                                              <v-chip
                                                :pill="true"
                                                color="white"
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                                @click="data.select"
                                              >
                                                <v-avatar 
                                                  size="35" 
                                                  left
                                                  :color="data.item.asset_icon ? 'lighten-2': 'primary lighten-2'"
                                                >
                                                  <v-img
                                                    v-if="data.item.asset_icon"
                                                    :src="data.item.asset_icon"
                                                  ></v-img>
                                                  <span v-else class="fix-asset-font">
                                                    {{ data.item.asset_code }}
                                                  </span>
                                                </v-avatar>
                                                {{ data.item.asset_code }}
                                              </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                              <template>
                                                <v-list-item-avatar :color="data.item.asset_icon ? 'lighten-2': 'primary lighten-2'">
                                                  <img v-if="data.item.asset_icon" :src="data.item.asset_icon" />
                                                  <span v-else class="fix-asset-font"> {{data.item.asset_code}} </span>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                  <v-list-item-title
                                                    v-html="data.item.asset_code"
                                                  ></v-list-item-title>
                                                </v-list-item-content>
                                              </template>
                                            </template>
                                          </v-select>
                                        </ValidationProvider>
                                      </v-col>
                                      <v-col
                                        cols="7"
                                        sm="8"
                                        md="9"
                                        class="flex-grow-0 flex-shrink-1"
                                      >
                                        <ValidationProvider
                                          :name="$vuetify.lang.t('$vuetify.wallet.to_amount_name')"
                                          rules="required"
                                          v-slot="{ errors }"
                                        >
                                          <v-text-field
                                            v-model="to_amount"
                                            :label="$vuetify.lang.t('$vuetify.wallet.to_amount_label')"
                                            placeholder="0.0"
                                            outlined
                                            :error-messages="errors"
                                            readonly
                                          >
                                          </v-text-field>
                                        </ValidationProvider>
                                      </v-col>
                                      <v-col cols="12">
                                        <v-card
                                          class="mx-auto"
                                          v-if="quote_details"
                                        >
                                          <v-card-text>
                                            <v-list v-if="!check_loading">
                                              <v-list-item>
                                                <v-list-item-content
                                                  class="font-weight-bold"
                                                  >{{ $vuetify.lang.t("$vuetify.wallet.next_update_will") }}:</v-list-item-content
                                                >
                                                <v-list-item-content>
                                                  <CountDown
                                                    :endDate="endDate"
                                                    @endTime="endTimeFunc()"
                                                  />
                                                </v-list-item-content>
                                              </v-list-item>
                                              <v-divider></v-divider>
                                            </v-list>
                                            <v-progress-linear
                                              v-if="check_loading"
                                              color="primary accent-4"
                                              indeterminate
                                              rounded
                                              height="6"
                                            ></v-progress-linear>
                                            <v-list>
                                              <v-list-item>
                                                <v-list-item-content
                                                  class="font-weight-bold"
                                                >
                                                  {{ $vuetify.lang.t("$vuetify.wallet.exchange_rate") }}:
                                                </v-list-item-content>
                                                <v-list-item-content
                                                  class="align-end"
                                                >
                                                  <div
                                                    class="d-flex align-center"
                                                  >
                                                    <div class="mr-1">
                                                      {{
                                                        valueSwap
                                                          ? `1 ${toAsset} ≈ ${
                                                              (from_amount /
                                                              to_amount).toFixed(7)
                                                            } ${fromAsset}`
                                                          : `1 ${fromAsset} ≈ ${
                                                              (to_amount /
                                                              from_amount).toFixed(7)
                                                            } ${toAsset}`
                                                      }}
                                                    </div>
                                                    <div>
                                                      <v-btn
                                                        icon
                                                        x-small
                                                        @click="
                                                          valueSwap = !valueSwap
                                                        "
                                                      >
                                                        <v-icon
                                                          >mdi-cached</v-icon
                                                        >
                                                      </v-btn>
                                                    </div>
                                                  </div>
                                                </v-list-item-content>
                                              </v-list-item>
                                              <v-divider></v-divider>
                                              <v-list-item>
                                                <v-list-item-content
                                                  class="font-weight-bold"
                                                >
                                                  <Tooltip
                                                    :label="$vuetify.lang.t('$vuetify.wallet.allowed_slippage_label')"
                                                    :message="$vuetify.lang.t('$vuetify.wallet.allowed_slippage_message')"
                                                  />
                                                </v-list-item-content>
                                                <v-list-item-content
                                                  class="align-end"
                                                >
                                                  1%
                                                </v-list-item-content>
                                              </v-list-item>
                                              <v-divider></v-divider>
                                              <v-list-item>
                                                <v-list-item-content
                                                  class="font-weight-bold"
                                                >
                                                  <Tooltip
                                                    :label="$vuetify.lang.t('$vuetify.wallet.minimum_received_label')"
                                                    :message="$vuetify.lang.t('$vuetify.wallet.minimum_received_message')"
                                                  />
                                                </v-list-item-content>
                                                <v-list-item-content
                                                  class="align-end"
                                                >
                                                  {{
                                                    quote_details &&
                                                    (to_amount - (1 / 100) * to_amount).toFixed(7)
                                                  }}
                                                  {{ toAsset }}
                                                </v-list-item-content>
                                              </v-list-item>
                                              <v-divider></v-divider>
                                              <v-list-item>
                                                <v-list-item-content
                                                  class="font-weight-bold"
                                                >
                                                  <Tooltip
                                                    :label="$vuetify.lang.t('$vuetify.wallet.path_label')"
                                                    :message="$vuetify.lang.t('$vuetify.wallet.path_message')"
                                                  />
                                                </v-list-item-content>
                                                <v-list-item-content
                                                  class="align-end"
                                                >
                                                  {{
                                                    quote_details &&
                                                    `${fromAsset} > ${toAsset}`
                                                  }}
                                                </v-list-item-content>
                                              </v-list-item>
                                            </v-list>
                                          </v-card-text>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-col>
                          </v-row>
                        </v-form>
                      </ValidationObserver>
                    </div>
                    <div v-if="n === 2">
                      <v-card class="elevation-0">
                        <v-card-title class="font-weight-bold">
                          {{ $vuetify.lang.t("$vuetify.common.review") }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-list>
                          <v-list-item>
                            <v-list-item-content class="font-weight-bold">
                              {{ $vuetify.lang.t("$vuetify.common.actions") }}:
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ actionType }}
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content class="font-weight-bold">
                              {{ $vuetify.lang.t("$vuetify.wallet.send_swap_account") }}:
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ item.account_name }}
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="actionType == 'send'">
                            <v-list-item-content class="font-weight-bold">
                              {{ $vuetify.lang.t("$vuetify.wallet.send_to") }}:
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ sendTo }}
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="actionType == 'send'">
                            <v-list-item-content class="font-weight-bold">
                              {{ $vuetify.lang.t("$vuetify.wallet.amount") }}:
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ send_amount }} {{ send_asset }}
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="actionType == 'swap'">
                            <v-list-item-content class="font-weight-bold">
                              {{ $vuetify.lang.t("$vuetify.wallet.from_asset") }}:
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ from_amount }} {{ fromAsset }}
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="actionType == 'swap'">
                            <v-list-item-content class="font-weight-bold">
                              {{ $vuetify.lang.t("$vuetify.wallet.to_asset") }}:
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ to_amount }} {{ toAsset }}
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="actionType == 'swap'">
                            <v-list-item-content class="font-weight-bold">
                              {{ $vuetify.lang.t("$vuetify.wallet.allowed_slippage_label") }}
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                              1%
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="actionType == 'swap'">
                            <v-list-item-content class="font-weight-bold">
                              {{ $vuetify.lang.t("$vuetify.wallet.minimum_received_label") }}
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{
                                quote_details &&
                                (to_amount - (1 / 100) * to_amount).toFixed(7)
                              }}
                              {{ toAsset }}
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </div>
                    <br />
                    <v-row align="center" justify="start">
                      <v-col cols="12">
                        <v-btn
                          v-if="n !== 1"
                          color="primary"
                          class="mr-4"
                          large
                          outlined
                          :loading="false"
                          @click="previousStep(n)"
                          >{{ $vuetify.lang.t("$vuetify.common.previous") }}</v-btn
                        >
                        <v-btn
                          v-if="n === 1"
                          color="error"
                          class="mr-2"
                          large
                          outlined
                          :loading="false"
                          @click="goBack()"
                          >{{ $vuetify.lang.t("$vuetify.common.cancel") }}</v-btn
                        >
                        <v-btn
                          v-if="n === 2"
                          color="primary"
                          class="mr-2"
                          large
                          :loading="false"
                          @click="proceedAction()"
                          >{{ $vuetify.lang.t("$vuetify.common.proceed") }}</v-btn
                        >
                        <v-btn
                          v-else
                          color="primary"
                          class="mr-2"
                          large
                          :disabled="isBalanceValid"
                          :loading="false"
                          @click="nextStep(n)"
                          >{{ $vuetify.lang.t("$vuetify.common.continue") }}</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="txnSuccessDialog" max-width="600" persistent>
        <v-card>
          <v-card-title class="font-weight-bold success--text">
            {{ $vuetify.lang.t("$vuetify.wallet.transaction_success") }} <v-spacer></v-spacer>
            <v-icon color="success">mdi-check-circle-outline</v-icon>
          </v-card-title>
          <v-row justify="center" v-if="!successData">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
          <v-card-text v-else>
            <v-row no-gutters class="my-2">
              <v-col cols="3">
                <div class="font-weight-bold">{{ $vuetify.lang.t("$vuetify.wallet.created_at") }}:</div>
              </v-col>
              <v-col cols="9">
                {{
                  successData
                    ? new Date(successData.created_at).toLocaleDateString(
                        "en-us",
                        {
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters class="my-2">
              <v-col cols="3">
                <div class="font-weight-bold">{{ $vuetify.lang.t("$vuetify.wallet.transaction_id") }}:</div>
              </v-col>
              <v-col cols="9">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      :href="successData && successData._links.transaction.href"
                      @click.stop
                      v-on="on"
                      class="text-decoration-none"
                    >
                      {{ successData ? successData.id : "N/A" }}
                    </a>
                  </template>
                  {{ $vuetify.lang.t("$vuetify.wallet.open_stellar_network_txt") }}
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mr-2" color="error" @click="closeSuccessDialog()"
              >{{ $vuetify.lang.t("$vuetify.common.close") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <PasscodeCheck
      v-model="passcode"
      @confirm="proceedClick"
      @cancel="cancel"
      :isCancelBtn="true"
      :isLoading="isLoading"
    />
    <!-- show send asset error dialog  -->
    <v-dialog v-model="sendAssetErrorDialog" persistent width="400">
      <v-card class="mx-auto pa-4 pt-0">
        <v-card-title class="font-weight-bold red--text">
          {{ $vuetify.lang.t("$vuetify.wallet.failed_to_send") }} <v-spacer></v-spacer>
          <v-icon color="red">mdi-cancel</v-icon>
        </v-card-title>
        <v-card-text class="text-center red--text subtitle-1">
          <v-icon color="red" size="20">warning</v-icon>
          {{ errMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red white--text"
            @click="hideSendAssetErrorDialog()"
          >
            {{ $vuetify.lang.t("$vuetify.OK_btn_txt") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import _ from 'lodash';

import {
  LOAD_ALL_WALLET_ACCOUNT,
  WALLET_SWAP_ASSET,
  WALLET_SEND_ASSET,
  GET_WALLET_SWAP_RATE,
  GET_WALLET_ACCOUNT_DETAILS,
  ACCESS_WALLET,
} from "@/store/_actiontypes";

import Modal from "@/components/common/modal/Modal";
import validations from "@/helpers/validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import {
  formatNum,
  timeToNextWalletToken,
  capitalizeFirstLetter,
} from "@/helpers/helper";

import { CURRENCY, NUMBER_FORMAT_LOCALE } from "@/global/_constants";
import Tooltip from "@/components/common/Tooltip";
import router from "@/router/index";
import CountDown from "@/components/CountDown";
import PasscodeCheck from "@/components/wallet/PasscodeCheck";
import Price from "@/components/wallet/Price";

export default {
  props: {
    item: {
      type: Object,
    },
    allAsset: {
      type: Array,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    Tooltip,
    CountDown,
    PasscodeCheck,
    Price,
  },
  computed: {
    ...mapState({
      allAccount: (state) => state.wallet.allAccount,
      total_amount: (state) => state.payment.total_amount,
    }),
    ...mapGetters("loader", ["loading"]),
  },
  mounted() {
    setTimeout(() => {
      this.from_asset_code = this.allAsset.filter((item) => item.asset_code === "XLM")[0]
      this.to_asset_code = this.allAsset.filter((item) => item.asset_code === "DZT")[0]
      this.send_asset_code = this.allAsset.filter((item) => item.asset_code === "XLM")[0]
    }, 1000)
  },
  data() {
    return {
      activePlan: "Amazon Web Services",
      formatNum,
      timeToNextWalletToken,
      capitalizeFirstLetter,
      createCategoryDialog: false,
      e1: 1,
      steps: 2,
      CURRENCY: CURRENCY,
      ...validations,
      search: "",
      invoiceIDList: [],
      token: null,
      confirmDialog: false,
      actionType: "send",
      showPasscode: false,
      passcodeDialog: false,
      sendAssetErrorDialog: false,
      errMessage: null,
      passcode: null,
      isLoading: false,
      allAction: [
        {
          id: "buy",
          name: "Buy",
        },
        {
          id: "send",
          name: "Send",
        },
        {
          id: "swap",
          name: "Swap",
        },
      ],
      selectedAcc: this.$route.params.id,
      selectedAccName: "",
      sendTo: null,
      send_amount: null,
      from_amount: null,
      to_amount: null,
      send_asset_code: null,
      send_asset: "XLM",
      fromAsset: "XLM",
      from_asset_code: null,
      toAsset: "DZT",
      to_asset_code: null,
      successData: null,
      txnSuccessDialog: false,
      timer: null,
      acc_details: null,
      quote_details: null,
      check_loading: false,
      endDate: null,
      valueSwap: false,
      exchange_rate: null,
      price_numerator: null,
      price_denominator: null,
      isBalanceValid: false,
      slippage: null,
      cancelBtn: true,
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(val, oldVal) {
        this.endDate = this.endDateFunc();
        this.get_wallet_acc_details();
      },
    },
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
    from_amount: _.debounce(function(val) {
      if (Number(val) > 0) {
        this.swapRate();
      }
      else{
        this.to_amount = "0.0";
        this.quote_details = null;
      }
      this.checkBalValid();
    }, 500),
    send_amount(val) {
      this.checkBalValid();
    },
    send_asset() {
      this.checkBalValid();
    },
    send_asset_code(val) {
      this.send_asset = val.asset_code
    },
    from_asset_code(val) {
      this.fromAsset = val.asset_code
    },
    to_asset_code(val) {
      this.toAsset = val.asset_code
    },
    fromAsset() {
      if (Number(this.from_amount) > 0) {
        this.swapRate();
      }
      this.checkBalValid();
    },
    toAsset() {
      if (Number(this.from_amount) > 0) {
        this.swapRate();
      }
      this.checkBalValid();
    },
  },
  methods: {
    ...mapActions("wallet", [
      LOAD_ALL_WALLET_ACCOUNT,
      WALLET_SWAP_ASSET,
      WALLET_SEND_ASSET,
      GET_WALLET_SWAP_RATE,
      GET_WALLET_ACCOUNT_DETAILS,
      ACCESS_WALLET,
    ]),
    loadAllAccount() {
      this.LOAD_ALL_WALLET_ACCOUNT({}).then(
        (res) => {},
        (error) => {}
      );
    },
    hideSendAssetErrorDialog() {
      this.errMessage = null;
      this.sendAssetErrorDialog = false;
    },
    swapValue() {
      let toAsset = this.to_asset_code;
      let fromAsset = this.from_asset_code;
      this.from_asset_code = toAsset;
      this.to_asset_code = fromAsset;
      if (Number(this.from_amount) > 0) {
        this.swapRate();
      }
      this.checkBalValid();
    },
    swapRate() {
      if (this.timeToNextWalletToken() < 1) {
        this.$emit("openPasscode");
      } else {
        this.swapAmount();
      }
    },
    swapAmount() {
      this.check_loading = true;
      let body_json = {
        from_asset_id: this.from_asset_code?.id,
        to_asset_id: this.to_asset_code?.id,
        amount: this.from_amount,
      };
      this.GET_WALLET_SWAP_RATE({
        body_json: body_json,
      })
        .then((res) => {
          let data = res.data.stellar_response;
          this.to_amount = data.destination_amount;
          this.quote_details = data;
          this.exchange_rate = data.swap_rate;
          this.check_loading = false;
          this.endDate = this.endDateFunc();
        })
        .catch((err) => {
          this.check_loading = false;
          this.endDate = this.endDateFunc();
        });
    },
    get_wallet_acc_details() {
      this.GET_WALLET_ACCOUNT_DETAILS({
        public_key: this.item?.public_key,
      })
        .then((res) => {
          this.acc_details = res.data.details;
        })
        .catch((err) => {
          this.acc_details = "inactive";
        });
    },
    getAssetBalanceData(asset) {
      return !this.acc_details || this.acc_details.status !== "created"
        ? this.acc_details?.balances.filter((item) => item.asset_code === asset)
        : 0;
    },
    hasMinBalance() {
      return (
        this.getAssetBalanceData("XLM")?.length > 0 &&
        Number(this.getAssetBalanceData("XLM")[0].balance) -
          Number(this.acc_details?.min_reserve?.total_reserve)
      );
    },
    endDateFunc() {
      let now = new Date();
      now.setSeconds(now.getSeconds() + 30);
      now = new Date(now);
      return now;
    },
    endTimeFunc() {
      if (this.from_amount.length > 0 && Number(this.from_amount) !== 0) {
        this.swapRate();
      }
    },
    checkBalValid() {
      if (this.actionType === "send") {
        this.getAssetBalanceData(this.send_asset)?.length > 0
          ? Number(this.send_amount) === 0 || Number(this.send_amount) >
              Number(this.getAssetBalanceData(this.send_asset)[0].balance) ||
            (this.send_asset === "XLM" &&
              Number(this.send_amount) > Number(this.hasMinBalance()))
            ? (this.isBalanceValid = true)
            : (this.isBalanceValid = false)
          : (this.isBalanceValid = true);
      } else {
        this.getAssetBalanceData(this.fromAsset)?.length > 0
          ? Number(this.from_amount) === 0 || Number(this.from_amount) >
              Number(this.getAssetBalanceData(this.fromAsset)[0].balance) ||
            (this.fromAsset === "XLM" &&
              Number(this.from_amount) > Number(this.hasMinBalance()))
            ? (this.isBalanceValid = true)
            : (this.isBalanceValid = false)
          : (this.isBalanceValid = true);
      }
    },
    get_account_name(item) {
      this.selectedAccName = item.account_name;
      return item.account_name;
    },
    proceedAction() {
      this.$store.commit("wallet/toggleModal", true);
    },
    proceedClick() {
      if (this.actionType === "swap") {
        this.swapAsset();
      } else {
        this.sendAsset();
      }
    },
    swapAsset() {
      this.isLoading = true;
      let body_json = {
        from_asset_id: this.from_asset_code?.id,
        to_asset_id: this.to_asset_code?.id,
        ...(this.price_numerator
          ? { price_numerator: this.price_numerator }
          : {}),
        ...(this.price_denominator
          ? { price_denominator: this.price_denominator }
          : {}),
        ...(this.exchange_rate ? { price: this.exchange_rate } : {}),
        ...(this.slippage ? { slippage: this.slippage } : {}),
        amount: this.from_amount,
        passcode: this.passcode,
      };
      if (!this.isBalanceValid) {
        this.WALLET_SWAP_ASSET({
          body_json: body_json,
          public_key: this.item?.public_key,
        }).then(
          (res) => {
            this.$store.commit("wallet/toggleModal", false);
            this.passcode = null;
            this.isLoading = false;
            this.txnSuccessDialog = true;
            this.successData = res.data.stellar_response;
          },
          (err) => {
            this.$store.commit("wallet/toggleModal", false);
            this.passcode = null;
            this.isLoading = false;
          }
        );
      }
    },
    sendAsset() {
      this.isLoading = true;
      let body_json = {
        destination_public_key: this.sendTo,
        asset_id: this.send_asset_code.id,
        amount: this.send_amount,
        passcode: this.passcode,
      };
      if (!this.isBalanceValid) {
        this.WALLET_SEND_ASSET({
          body_json: body_json,
          public_key: this.item?.public_key,
        }).then(
          (res) => {
            this.$store.commit("wallet/toggleModal", false);
            this.passcode = null;
            this.isLoading = false;
            this.txnSuccessDialog = true;
            this.successData = res.data.stellar_response;
          },
          (err) => {
            this.$store.commit("wallet/toggleModal", false);
            this.passcode = null;
            this.isLoading = false;
            this.sendAssetErrorDialog = true;
            this.errMessage = err.response.data?.message;
          }
        );
      }
    },
    goBack() {
      history.back();
    },
    nextStep(n) {
      if (n === 1) {
        this.$refs[`firstStep`][0].validate().then((success) => {
          if (!success) {
            return;
          }
          this.e1 = n + 1;
        });
      }
    },
    previousStep(n) {
      this.e1 = n - 1;
    },
    filterOnlyText(value, search, item) {
      return (
        search != null &&
        item.subscription.project.name
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
      );
    },
    closeSuccessDialog() {
      this.txnSuccessDialog = false;
      router.push("/wallet");
    },
    cancel() {
      this.$store.commit("wallet/toggleModal", false);
      this.passcode = null;
    },
  },
};
</script>

<style scoped>
.v-subheader {
  height: 20px;
}
.fix-asset-font {
  font-size: 10px; 
  color:white;
}
</style>