<template>
  <v-card class="mx-auto rounded" outlined exact tile>
    <v-img
      class="white--text align-end"
      :src="itemData.cover_image ? itemData.cover_image : segmentImg(itemData)"
      aspect-ratio="2"
    ></v-img>
    <template v-if="isDeveloper && itemData.status">
      <v-tooltip top v-if="itemData.status !== 'deleted'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            absolute
            v-bind:color="itemData.segment.color && itemData.segment.color"
            :class="
              itemData.status === 'published'
                ? 'white--text mt-7 rounded-circle'
                : 'white--text mr-13 mt-7 rounded-circle'
            "
            rounded
            fab
            small
            top
            right
            v-on="on"
            v-bind="attrs"
            @click="editSolution($event, itemData)"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $vuetify.lang.t("$vuetify.tooltip.edit") }}</span>
      </v-tooltip>
      <v-tooltip
        top
        v-if="itemData.status !== 'published' && itemData.status !== 'deleted'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            absolute
            v-bind:color="itemData.segment.color && itemData.segment.color"
            class="white--text mt-7 rounded-circle"
            rounded
            fab
            small
            top
            right
            v-on="on"
            v-bind="attrs"
            @click="publishSolution($event, itemData)"
          >
            <v-icon>{{
              itemData.status && itemData.status === "drafted"
                ? "mdi-check"
                : "mdi-file-outline"
            }}</v-icon>
          </v-btn>
        </template>
        <span>{{
          publicationTooltip ? publicationTooltip : setTooltip(itemData.status)
        }}</span>
      </v-tooltip>
    </template>
    <v-card-subtitle class="pb-0">
      {{ $vuetify.lang.t("$vuetify.solution.developed_by") }}
      {{ itemData.account.account_name }}
    </v-card-subtitle>
    <v-card-title>{{ itemData.name }}</v-card-title>
    <v-card-actions>
      <v-chip class="ma-2 py-3" v-bind:color="itemData.segment.color" label outlined pill>
        {{ itemData.segment.name }}
      </v-chip>
      <v-btn
        class="px-3 py-5 ml-auto mb-2 mr-2 outlined-default details-btn"
        outlined
        @click="redirectDetails(itemData)"
      >
        <span class="ml-1">
          {{ $vuetify.lang.t("$vuetify.solution.preview") }}
        </span>
        <v-icon class="ml-2">arrow_forward</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import {
  PUBLISH_SOLUTION_BY_ID,
  DRAFT_SOLUTION_BY_ID,
  ADD_ALERT,
  LOAD_ALL_SOLUTION_BY_DEV,
} from "@/store/_actiontypes";

export default {
  props: {
    itemData: {
      type: Object,
      default() {
        return {
          id: 0,
          name: "N/A",
          color_name: "help",
          overview: "N/A",
          category: [],
        };
      },
    },
    isDeveloper: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isPublic: {
      type: Boolean,
      default() {
        return false;
      },
    },
    setIsLoading: {
      type: Function,
    },
  },
  data: () => ({
    categoryShow: false,
    publicationTooltip: "",
  }),
  methods: {
    ...mapActions("solution", [
      PUBLISH_SOLUTION_BY_ID,
      DRAFT_SOLUTION_BY_ID,
      LOAD_ALL_SOLUTION_BY_DEV,
    ]),
    ...mapActions("alert", [ADD_ALERT]),
    redirectDetails(itemData) {
      if (itemData && itemData.id) {
        this.isPublic
          ? this.$router.push("/public/solutions/" + itemData.id)
          : this.$router.push("/solutions/" + itemData.id);
      }
    },
    editSolution(event, itemData) {
      event.preventDefault();
      event.stopPropagation();
      if (itemData && itemData.id) {
        this.$router.push("/update-solutions/" + itemData.id);
      }
    },
    publishSolution(event, itemData) {
      this.setIsLoading(true);
      event.preventDefault();
      event.stopPropagation();
      if (itemData && itemData.id && itemData.status) {
        if (itemData.status === "drafted") {
          this.PUBLISH_SOLUTION_BY_ID({
            solutionId: itemData.id,
          }).then(
            (response) => {
              if (response) {
                this.$store.dispatch(
                  `alert/${ADD_ALERT}`,
                  {
                    message: this.$vuetify.lang.t("$vuetify.solution_submit_msg_txt"),
                    color: "success",
                  },
                  { root: true }
                );
                this.publicationTooltip = this.$vuetify.lang.t("$vuetify.tooltip.draft");
                this.LOAD_ALL_SOLUTION_BY_DEV({ searchText: "", type: this.$route.query.type }).then(
                  (response) => {
                    if (response) {
                      this.setIsLoading(false);
                    }
                  },
                  (error) => {
                    this.setIsLoading(false);
                  }
                );
              }
            },
            (error) => error
          );
        } else {
          this.DRAFT_SOLUTION_BY_ID({
            solutionId: itemData.id,
          }).then(
            (response) => {
              if (response) {
                this.$store.dispatch(
                  `alert/${ADD_ALERT}`,
                  {
                    message: this.$vuetify.lang.t("$vuetify.solution_draft_msg_txt"),
                    color: "success",
                  },
                  { root: true }
                );
                this.publicationTooltip = this.$vuetify.lang.t(
                  "$vuetify.tooltip.submit_for_review"
                );
                this.LOAD_ALL_SOLUTION_BY_DEV({ searchText: "", type: this.$route.query.type }).then(
                  (response) => {
                    if (response) {
                      this.setIsLoading(false);
                    }
                  },
                  (error) => {
                    this.setIsLoading(false);
                  }
                );
              }
            },
            (error) => error
          );
        }
      }
    },
    setTooltip(status) {
      let tooltip = "";
      if (status && status === "drafted") {
        tooltip = this.$vuetify.lang.t("$vuetify.tooltip.submit_for_review");
      } else {
        tooltip = this.$vuetify.lang.t("$vuetify.tooltip.draft");
      }
      return tooltip;
    },
    segmentImg(data) {
      if (data && data.segment && data.segment.cover_image_url) {
        return data.segment.cover_image_url;
      }
    },
  },
};
</script>

<style scoped>
.overview-text {
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.card-style-none {
  box-shadow: none !important;
}
.text-xl {
  color: black;
  font-size: 1.25rem;
}
.text-lg,
.text-xl {
  line-height: 1.75rem;
}
.font-semibold {
  font-weight: 600;
}
tracking-wider {
  letter-spacing: 0.05em;
}
.capitalize {
  text-transform: capitalize;
}
.mt-1 {
  margin-top: 0.25rem;
}
.leading-5 {
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.overflow-ellipsis,
.truncate {
  text-overflow: ellipsis;
}
.overflow-hidden {
  overflow: hidden;
}
.ml-2 {
  margin-left: 0.5rem;
}
.m-2 {
  margin: 0.5rem;
}
.font-normal {
  font-weight: 100;
}
button.outlined-default {
  border: 1px solid rgba(0, 0, 0, 0.23);
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  font-weight: 700;
}
.ml-auto {
  margin-left: auto;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
button {
  font-size: 0.9375rem;
  border-radius: 0.25rem;
  border-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  letter-spacing: 0.025em;
  white-space: nowrap;
  transition-duration: 0.2s;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
.details-btn {
  text-transform: none;
}
button {
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
.v-card {
  margin: 10px;
}
.v-card__title {
  word-break: break-word;
  height: 100px;
  vertical-align: middle;
}
.v-card__subtitle,
.v-card__text {
  font-size: 1em;
}
</style>
