<template>
  <v-dialog v-model="open" :max-width="dialogWidth" persistent>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-4" v-if="!newDownloadRequest && fileGeneratedHistoryData.length===0">
        <v-form ref="fileGenerationForm">
          <v-row class="justify-start">
            <v-col cols="12" xs="12" md="4">
              <v-menu
                ref="fileDateRangeModal"
                v-model="fileDateRangeModal"
                :close-on-content-click="false"
                :return-value.sync="fileDateRange"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateRangeText"
                    :placeholder="
                      $vuetify.lang.t('$vuetify.daterange_placeholder_txt')
                    "
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                    :rules="[
                      (v) =>
                        (v.split(' ~ ').length === 2 &&
                          new Date(v.split(' ~ ')[0]) <= new Date(v.split(' ~ ')[1]))
                        || $vuetify.lang.t('$vuetify.logs_file_generation.date_range_validation')
                    ]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fileDateRange"
                  color="primary"
                  no-title
                  scrollable
                  range
                  :max="dateOfToday()"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="error" @click="fileDateRangeModal = false">
                    {{ $vuetify.lang.t("$vuetify.cancel_btn_txt") }}
                  </v-btn>
                  <v-btn text color="primary" @click="setDates">
                    {{ $vuetify.lang.t("$vuetify.OK_btn_txt") }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="py-4" v-else>
        <v-card-text v-if="fileGeneratedHistoryData.length > 0 && !newDownloadRequest" >
          <v-alert type="info" dense outlined class="mb-4">
              <strong>Notice:</strong> {{ $vuetify.lang.t("$vuetify.files_already_exist_txt") }}
              <span class="text--primary font-weight-bold">{{ dateRangeText }}</span>.
              <br>
              {{ $vuetify.lang.t("$vuetify.generate_conversation_history_instruction") }}
          </v-alert>
          <v-data-table 
              :headers="fileGeneratedHistoryHeaders" 
              :items="fileGeneratedHistoryData"
              :loading="fileGeneratedHistoryLoading" 
              :server-items-length="totalHits" 
              :items-per-page="itemsPerPage"
              :no-data-text="$vuetify.lang.t('$vuetify.no_data_available')"
              :options.sync="options"
              @update:options="getTasks"
              :footer-props="footerProps"
              disable-sort
              >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <template>
                      <td>{{ getRequestTime(item.name) }}</td>
                      <td>
                        {{ getDateRange(item.name) }}
                      </td>
                      <td>
                        <v-chip :color="item.status === 'success' ? 'green' : item.status === 'failed' ? 'red' : 'yellow'"
                          dark>
                          {{ item.status }}
                        </v-chip>
                      </td>
                      <td>
                        <a v-if="item.status == 'success' && getFileExpiration(getDownloadUrl(item.message))"
                          :href="getDownloadUrl(item.message)">Download</a>
                        <span v-else>N/A</span>
                      </td>
                      <td>
                        {{ item.status == "success" && getFileExpiration(getDownloadUrl(item.message)) ? getFileExpiration(getDownloadUrl(item.message)) : "Expired" }}
                      </td>
                    </template>

                  </tr>
                </tbody>
              </template>
          </v-data-table>
        </v-card-text>
          
        </v-card-text>
        
        <v-card-text v-if="newDownloadRequest" >
        {{$vuetify.lang.t('$vuetify.conversation_history_download_request_txt')}}
        {{$vuetify.lang.t('$vuetify.request_instruction')}}
        </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end" v-if="fileGeneratedHistoryData.length === 0 && !newDownloadRequest">
        <v-btn outlined color="error" @click="closeModal">{{
          $vuetify.lang.t("$vuetify.cancel_btn_txt")
        }}</v-btn>
        <v-btn
          color="primary"
          :loading="fileGenerationLoading"
          @click="getConversationHistory"
          >{{ $vuetify.lang.t("$vuetify.generate_btn_txt") }}</v-btn
        >
      </v-card-actions>
      <v-card-actions class="justify-end" v-else-if="newDownloadRequest">
        <v-btn outlined color="error" @click="closeResponse">{{
          $vuetify.lang.t("$vuetify.close_btn_txt")
        }}</v-btn>
      </v-card-actions>
      <v-card-actions class="justify-end" v-else>
        <v-btn outlined color="error" @click="closeResponse">{{
          $vuetify.lang.t("$vuetify.close_btn_txt")
        }}</v-btn>
        <v-btn
          color="primary"
          :loading="fileGenerationLoading"
          @click="downloadRequest"
          >
          {{$vuetify.lang.t("$vuetify.generate_new_btn_txt")}}
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { GENERATE_CONVERSATION_HISTORY, GET_TASKS_BY_PREFIX } from "@/store/_actiontypes";
import { dateOfToday } from "@/helpers/helper";

export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    history_type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dateOfToday,
      fileDateRange: [],
      fileDateRangeModal: false,
      fileGenerationLoading: false,
      response: false,
      fileGeneratedHistory: false,
      fileGeneratedHistoryData: [],
      totalHits: 0,
      loadingAvailableHistory: false,
      fileGeneratedHistoryLoading: false,
      options: { page: 1, itemsPerPage: 5 },
      newDownloadRequest: false,
      footerProps: {'items-per-page-options': [5, 15, 25]},
      itemsPerPage: 5,
      fileGeneratedHistoryHeaders: [
        {
          text: this.$vuetify.lang.t(
            "$vuetify.request_date"
          ),
          value: "date",
          align: 'start',
          sortable:  "false"
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.logs_file_generation.date_range"
          ),
          value: "date_range",
          sortable: "false"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.status_header_txt"),
          value: "status",
          sortable: "false"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.logs_file_generation.file_url"),
          value: "file_url",
          sortable: "false"
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.logs_file_generation.expiration"
          ),
          value: "expiration",
          sortable: "false"
        },
      ],
    };
  },

  computed: {
    ...mapState({
      account: (state) => state.account.account,
      selectedProject: (state) => state.project.selectedProject,
      dateRangeText: function () {
        return this.fileDateRange.join(" ~ ");
      },
    }),
    dialogWidth() {
      return window.innerWidth <= 768 ? '95%' : '57%';
    }
  },

  methods: {
    ...mapActions("project", [GENERATE_CONVERSATION_HISTORY]),
    ...mapActions("task", [GET_TASKS_BY_PREFIX]),

    setDates() {
      this.$refs.fileDateRangeModal.save(this.fileDateRange);
    },
    getTasks({ page, itemsPerPage }){
      if(!this.fileDateRange[0] && !this.fileDateRange[1])
          return
      this.currentPage = page;
      this.itemsPerPage = itemsPerPage;
      
      const start = (page - 1) * itemsPerPage;
      const size = itemsPerPage;
      this.getFileGeneratedHistory(start, size)
    },

    getConversationHistory(){
        this.getFileGeneratedHistory(0, 10)
        this.loadingAvailableHistory = true
    },
    async getFileGeneratedHistory(start, size) {
      if(this.fileGeneratedHistoryData.length == 0)
        if (!this.$refs.fileGenerationForm.validate()) return;
      this.fileGeneratedHistoryLoading = true;
      this.fileGenerationLoading = true;
      const task_prefix =  `conversation_history_download_${this.$route.params.project_id}_${this.fileDateRange[0]}_${this.fileDateRange[1]}`
      this.GET_TASKS_BY_PREFIX({
        project_id: this.selectedProject.id,
        task_prefix,
        task_status: "all_status",
        start: start,
        size: size,
      })
        .then(
          (response) => {
            
            this.fileGeneratedHistoryData = response.data?.detail?.response;
            if(this.fileGeneratedHistoryData.length > 0){
              this.fileGeneratedHistory = true
              this.totalHits = response.data?.detail?.total_tasks;
            } else {
              this.downloadRequest()

            }
          },
          (error) => {
            console.error(error);
          }
        )
        .finally(() => {
          this.fileGeneratedHistoryLoading = false;
          this.fileGenerationLoading = false;
        });
    },
    downloadRequest() {
      this.newDownloadRequest = true
      this.fileGenerationLoading = true;
      this.GENERATE_CONVERSATION_HISTORY({
        project_id: this.selectedProject.id,
        start_date: this.fileDateRange[0],
        end_date: this.fileDateRange[1],
      })
        .then(
          (response) => {
            this.response = response?.data.detail;
          },
          (error) => error
        )
        .finally(() => {
          this.fileGenerationLoading = false;
        });
    },

    closeResponse() {
      this.closeModal();
      this.response = false;
    },
    getRequestTime(task_name){
      const timestampStr = task_name?.split("_").pop();
      const timestamp = parseFloat(timestampStr);
      const date = new Date(timestamp * 1000);
      const formattedDate = date.toLocaleString('en-CA');
      return formattedDate
    },
    getDateRange(task_name){
      const parts = task_name.split("_");
        const startDate = parts.find(part => /^\d{4}-\d{2}-\d{2}$/.test(part));
        const endDate = parts.find((part, index) => 
          /^\d{4}-\d{2}-\d{2}$/.test(part) && part !== startDate
        );
        return `${startDate} ~ ${endDate}`
    },
    getDownloadUrl(message){
      const messageObj = JSON.parse(message)
      return messageObj?.url
    },
    getFileExpiration(url){
      const params = new URLSearchParams(url?.split("?")[1]);
      const expiresTimestamp = parseInt(params.get("Expires"), 10);
      const currentTimestamp = Math.floor(Date.now() / 1000); 
      
      if (expiresTimestamp < currentTimestamp) {
        return false; 
      }
      const expiresDate = new Date(expiresTimestamp * 1000);
      const formattedDate = expiresDate.toLocaleString('en-CA');
      return formattedDate;
    },
    isExpired(expiration) {
      if (expiration === "N/A") return false;
      else return new Date(expiration) < new Date();
    },

  },
};
</script>
