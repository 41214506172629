<template>
  <v-card class="mx-auto rounded" outlined>
    <v-img
      class="white--text align-end"
      :src="itemData.cover_image ? itemData.cover_image : segmentImg(itemData)"
      aspect-ratio="2"
    ></v-img>
    <v-btn
      style="bottom: 7em"
      absolute
      v-bind:color="color && color"
      class="white--text"
      fab
      small
      right
      @click="redirectDetails(itemData)"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
    <v-card-title>
      {{ itemData.name && itemData.name }}
    </v-card-title>
  </v-card>
</template>

<script>

export default {
  props: {
    itemData: {
      type: Object,
      default() {
        return {
          id: 0,
          name: "N/A",
        };
      },
    },
    color: {
        type: String,
    }
  },
  data: () => ({
  }),
  methods: {
    redirectDetails(itemData) {
        this.$router.replace({ params: { solution_id: itemData.id}})
    },
    segmentImg(data) {
      if (data &&
        data.segment &&
        data.segment.cover_image_url
      ) {
        return data.segment.cover_image_url;
      }
    },
  },
};
</script>
<style>
.v-card__title {
  word-break: break-word;
  height: 100px;
  vertical-align: middle;
}
</style>