<template>
  <div>
    <CatalogItems />
    <v-row >
      <v-col xs="6" md="6" sm="6">
        <div class="px-2 primary--text font-weight-bold">
          {{ $vuetify.lang.t("$vuetify.catalog.upload_catalog_items") }}
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-container>
      <v-file-input
        v-model="selectedFile"
        :label="$vuetify.lang.t('$vuetify.project.select_file')"
        accept=".csv"
        outlined
        clearable
      ></v-file-input>
      <div class="ml-8">
        <v-alert type="info" text border="left">
          {{ `${$vuetify.lang.t("$vuetify.project.dataset_update_info")}` }}
        </v-alert>
      </div>
      <v-row>
        <v-col cols="12" md="8"></v-col>
        <v-col cols="12" md="4" class="text-right">
          <v-btn v-if="!this.inProgress" color="primary" @click="handleFileParsing()">
            {{ `${$vuetify.lang.t("$vuetify.upload_btn_txt")}` }}
          </v-btn>
          <v-btn v-else color="primary" class="mr-4 secondary-disabled-button">
            {{ `${$vuetify.lang.t("$vuetify.project.uploading")}` }}
            <v-progress-circular indeterminate :size="15" :width="2" color="white"></v-progress-circular>
          </v-btn>
        </v-col>
      </v-row>
      <template v-if="updateResult !== '' || taskHistoryData !== null">
        <v-divider class="mt-8"></v-divider>
        <!-- <v-row>
            <v-col cols="12" md="2" class="pl-12"> </v-col>
        </v-row> -->
        <v-card class="elevation-0">
          <v-card-title class="text-h6 font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.project.dataset_update_log")}` }}
          </v-card-title>
          <v-divider></v-divider>
          <!-- <v-row class="mx-2 mb-3">
              <v-col cols="12" md="6">
              </v-col>
          </v-row>  -->
          <div>
            <template >
              <v-data-table
                :loading="isTrainHistoryLoading"
                :headers="trainHistoryHeaders"
                :items="taskHistoryData"
                :sort-desc="[true]"
                :server-items-length="totalNumberOfTrains"
                :footer-props="{ itemsPerPageOptions: itemsPerPageOptions }"
                :items-per-page.sync="defaultPagination.itemsPerPage"
                :page.sync="defaultPagination.page"
                @update:items-per-page="handleItemsPerPageChange"
                @update:page="handlePageChange"
              >
                <template v-slot:item.name="{ item }">
                  {{ item.name ? (item.name) : "N/A" }}
                </template>
                <template v-slot:item.started_at="{ item }">
                  {{ item.started_at ? formatDateTime(item.started_at) : "N/A" }}
                </template>
                <template v-slot:item.last_updated_at="{ item }">
                  {{ item.last_updated_at ? formatDateTime(item.last_updated_at) : "N/A" }}
                </template>
                <template v-slot:item.message="{ item }">
                  <div>
                    <!-- Check if the message exists, otherwise show "N/A" -->
                    {{ item.message ? truncateLog(item.message) : "N/A" }}

                    <!-- Show "Show more" only if the message is longer than 85 characters -->
                    <span
                      v-if="item.message && item.message.length > 85"
                      class="clickable"
                      @click="showErrorModal(item.message)"
                    >
                      Show more
                    </span>
                  </div>
                </template>

                <template v-slot:item.scheduled_by="{ item }">
                  {{ item.scheduled_by ? item.scheduled_by : "N/A" }}
                </template>
                <template v-slot:item.status="{ item }">
                  {{ item.status ? item.status : "N/A" }}
                </template>
              </v-data-table>
            </template>
          </div>
        </v-card>

        <v-dialog v-model="isModalVisible" max-width="600px">
          <v-card>
            <v-card-title class="headline">
              {{ $vuetify.lang.t("$vuetify.project.full_error_log") }}
            </v-card-title>
            <v-card-text>
              <pre class="wrapped-error">{{ fullError }}</pre>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="closeModal">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import CatalogItems from "@/components/Projects/CatalogItems.vue";

import {
    CREATE_MAPPER,
    UPDATE_MAPPER,
    CREATE_INDICES,
    CREATE_RANK,
    UPLOAD_RECOMMENDER_DATA,
    ITEMS_TRAIN,
    IMAGES_TRAIN,
    RANKS_TRAIN,
    GET_TASKS_STATUS,
    LOAD_TRAIN_TASKS,
} from "@/store/_actiontypes";
import { formatDateTime } from "@/helpers/helper";

export default {
  props: {},
  components: {
    CatalogItems,
  },
  data() {
    return {
      selectedFile: null,
      formatDateTime,
      taskHistoryData: [],
      trainLoader: false,
      updateLoader: false,
      isTrainHistoryLoading: false,
      totalNumberOfTrains: 0,
      trainHistoryHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.name_header_txt"),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.job.started_at"),
          align: "center",
          sortable: false,
          value: "started_at",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.job.finished_at"),
          align: "center",
          sortable: false,
          value: "last_updated_at",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.status_header_txt"),
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.project.message"),
          align: "center",
          sortable: false,
          value: "message",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.project.generated_by"),
          align: "center",
          sortable: false,
          value: "scheduled_by",
        },
      ],
      defaultTasks: [
        {
          name: "csv_upload",
          message: "",
          status: "not_started",
        },
        {
          name: "item_train",
          message: "",
          status: "not_started",
        },
        {
          name: "rank_train",
          message: "",
          status: "not_started",
        },
        {
          name: "image_train",
          message: "",
          status: "not_started",
        },
      ],
      polling: null,
      inProgress: false,
      updateResult: "",
      updateStatus: "",
      updateStartedAt: "",
      defaultPagination: {
        page: 1,
        itemsPerPage: 5,
        sortDesc: [true],
      },
      itemsPerPageOptions: [5, 10, 15, 50, 100],
      isModalVisible: false,
      fullError: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      selectedProject: (state) => state.project.selectedProject,
    }),
  },
  watch: {},
  mounted() {
    this.getTrainTasksFunction();
  },
  beforeCreate() {},
  created() {},
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    ...mapActions("project", [
      CREATE_MAPPER,
      UPDATE_MAPPER,
      CREATE_INDICES,
      CREATE_RANK,
      UPLOAD_RECOMMENDER_DATA,
      ITEMS_TRAIN,
      IMAGES_TRAIN,
      RANKS_TRAIN,
      GET_TASKS_STATUS,
      LOAD_TRAIN_TASKS,
    ]),
    async handleFileParsing() {
      if (!this.selectedFile) {
        return;
      }
      let project_id = this.selectedProject.id;
      const formData = new FormData();
      formData.append("file", this.selectedFile);

      this.inProgress = true;
      this.UPLOAD_RECOMMENDER_DATA({
        project_id: project_id,
        file: formData,
      }).then(
        async (response) => {
          this.inProgress = false;
          this.isLoading = false;
          this.getTrainTasksFunction();
        },
        (error) => {
          this.inProgress = false;
        }
      );
    },
    getTrainTasksFunction() {
      this.isTrainHistoryLoading = true;
      const start = (this.defaultPagination.page - 1) * this.defaultPagination.itemsPerPage;
      const size = this.defaultPagination.itemsPerPage;

      this.LOAD_TRAIN_TASKS({
        project_id: this.selectedProject.id,
        train_type: "item_save",
        status: "all_status",
        start,
        size,
      }).then(
        (response) => {
          this.taskHistoryData = response.data.detail.response;
          this.totalNumberOfTrains = response.data.detail.total_train;
        },
        (error) => {
          console.error("Task Error: ", error)
        }
      ).finally(() => {
        this.isTrainHistoryLoading = false;
      });
    },

    deepEqual(obj1, obj2) {
      if (obj1 === obj2) return true;

      if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false;

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;

      for (const key of keys1) {
        if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) return false;
      }

      return true;
    },
    handlePageChange(page) {
      this.defaultPagination.page = page;
      this.getTrainTasksFunction();
    },
    handleItemsPerPageChange(newItemsPerPage) {
      this.defaultPagination.itemsPerPage = newItemsPerPage;
      this.getTrainTasksFunction();
    },

    truncateLog(error) {
      const maxLength = 85;
      return error.length > maxLength ? error.substring(0, maxLength) + " ..." : error;
    },
    // Show modal with full error log
    showErrorModal(error) {
      this.fullError = error;
      this.isModalVisible = true;
    },
    // Close the modal
    closeModal() {
      this.isModalVisible = false;
      this.fullError = "";
    },
  },
};
</script>


<style scoped>
.secondary-disabled-button {
  cursor: not-allowed;
  pointer-events: none;
}

/* New Styles Added */
.gray-box {
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

.clickable {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  width: 80%;
  max-width: 600px;
}

.wrapped-error {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  cursor: pointer;
}
</style>

