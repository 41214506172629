<template>
  <div>
    <v-container fill-height fluid>
      <v-row>
        <v-col class="pa-0" xs="12" md="12">
          <v-card class="mx-auto px-2" outlined>
            <v-row>
              <v-col md="6">
                <div class="d-flex align-center">
                  <div class="primary--text font-weight-bold"
                 >
                  <v-btn text class="pa-0 ma-0" @click="redirectProjectPage">
                    <v-icon>
                      mdi-arrow-left
                    </v-icon>
                  </v-btn>
                   
                  </div>
                  <v-btn text class="primary--text font-weight-bold pa-0 ma-0"  @click="redirectProjectPage">
                    {{ $vuetify.lang.t("$vuetify.gpt_dataset.dataset_header_txt") }}
                  </v-btn>
                  <v-icon class="mx-1" color="primary">mdi-chevron-right</v-icon>
                  <v-btn text  class="primary--text font-weight-bold pa-0 ma-0">
                    {{ $vuetify.lang.t("$vuetify.gpt_dataset.dataset_document_header_txt") }}
                  </v-btn>
                </div>
              </v-col>
              <!-- Action Buttons -->
              <v-col md="6">
                <div class="d-flex float-right">
                  <v-btn
                    v-if="selectedDocuments.length > 0"
                    class="text-capitalize ml-2"
                    color="primary"
                    @click="download_alert_message =  $vuetify.lang.t('$vuetify.gpt_dataset.confirm_alert_msg_selected_download'); downloadDialog = true;"
                    dark
                  >
                    <v-icon left>mdi-download</v-icon>
                    <span class="hidden-sm-and-down">
                      {{ $vuetify.lang.t("$vuetify.download_btn_txt") }} ({{ selectedDocuments.length }})
                    </span>
                  </v-btn>
                  <v-btn
                    class="text-capitalize ml-2"
                    @click="handleCSVUpload()"
                    color="primary"
                    dark
                  >
                    <v-icon left>mdi-upload</v-icon>
                    <span class="hidden-sm-and-down">
                      {{ $vuetify.lang.t("$vuetify.upload_btn_txt") }}
                    </span>
                  </v-btn>
                  <v-btn
                    class="text-capitalize ml-2"
                    color="primary"
                    @click="download_alert_message = $vuetify.lang.t('$vuetify.gpt_dataset.confirm_alert_msg_all_download'); downloadAllDocumentsDialog = true;"
                    dark
                  >
                    <v-icon left>mdi-folder-download</v-icon>
                    <span class="hidden-sm-and-down">
                      {{ $vuetify.lang.t("$vuetify.project.dataset") }}
                    </span>
                  </v-btn>
                  <v-btn
                    class="text-capitalize ml-2"
                    color="primary"
                    @click="historyDialog = true;"
                    dark
                  >
                    <v-icon left>mdi-history</v-icon>
                    <span class="hidden-sm-and-down">
                      {{ $vuetify.lang.t("$vuetify.history") }}
                    </span>
                  </v-btn>
                  <v-btn
                    class="text-capitalize ml-2"
                    @click="createNewDocumentClick()"
                    color="primary"
                    dark
                  >
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    <span class="hidden-sm-and-down">
                      {{ $vuetify.lang.t("$vuetify.gpt_document.create_document") }}
                    </span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" xs="12" md="3">
                <v-text-field v-model="search_text" append-icon="mdi-magnify" 
                  :label="$vuetify.lang.t('$vuetify.search_btn_txt')" outlined dense single-line
                  hide-details @change="handleQueryOnDocuments"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" md="3">
                <v-select :items="document_status_states" item-text="state" item-value="value" v-model="selected_document_state" persistent-hint hide-details="auto"
                 outlined dense @change="handleQueryOnDocuments" :label="$vuetify.lang.t('$vuetify.gpt_dataset.active_status')"></v-select>
              </v-col>
              <v-col cols="12" xs="12" md="3">
                <v-select :items="training_status_states" item-text="state" item-value="value" v-model="selected_training_state" persistent-hint hide-details="auto"
                 outlined dense @change="handleQueryOnDocuments" :label="$vuetify.lang.t('$vuetify.gpt_dataset.train_status')"></v-select>
              </v-col>
              <v-col cols="12" xs="12" md="3" class="d-flex justify-end">
                <v-btn class="text-capitalize" color="error"
                  @click="delete_alert_message = selectedDocuments.length ? $vuetify.lang.t('$vuetify.gpt_dataset.confirm_alert_msg_txt_3') : $vuetify.lang.t('$vuetify.gpt_dataset.confirm_alert_msg_txt_2'); deleteDialog = true;"
                >
                  <v-icon>mdi-delete</v-icon>
                  <span>
                    {{ $vuetify.lang.t("$vuetify.tooltip.delete") }} ({{ selectedDocuments.length || "ALL" }})
                  </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-data-table
              :headers="headers"
              :items="gptDocuments"
              :search="search"
              hide-default-footer
              show-select
              v-model="selectedDocuments"
              :options.sync="options"
              :server-items-length="pageCount"
              :loading="isLoading"
              loading-text=""
              >
              <template v-slot:progress>
                <Loader class="mr-2 mt-6"  />
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.id.split("-")[0] }}</span>
                  </template>
                  <span>{{ item.id }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.data.name`]="{ item }">
                <div>
                  <v-btn text class="primary--text text--darken-3" @click="detailsModal(item)">{{ item.data.name
                    }}</v-btn>
                </div>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDateTime(item.created_at) }}
              </template>
              <template v-slot:[`item.updated_at`]="{ item }">
                {{ formatDateTime(item.updated_at) }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip color="primary" text-color="white" small class="text-capitalize">{{ item.status }}</v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <ButtonGroup class="d-flex justify-center" :groupType="ACTION_BUTTON_GROUP" :addButton="false"
                  :startButton="false" :stopButton="false" :disabledDeleteButton="disable_crud_operation"
                  :disabledEditButton="disable_crud_operation" @onDetailsClick="detailsModal(item)"
                  @onEditClick="updateItem(item)" @onDeleteClick="deleteItem(item)" />
              </template>
            </v-data-table>
            <v-row>
              <v-col class="py-0" cols="12" md="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="3"></v-col>
              <v-col cols="12" xs="12" sm="6" md="6">
                <v-pagination class="text-center" v-model="page" :length="pageCount"></v-pagination>
              </v-col>
              <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
                <v-select v-model="itemsPerPage" :items="rows" hide-details="auto" outlined dense></v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <template v-if="detailsDialog">
      <v-dialog v-model="detailsDialog" max-width="800px" persistent scrollable hide-overlay
        transition="dialog-bottom-transition" content-class="rounded-lg" elevation="24" rounded>
        <v-card class="rounded-lg" elevation="24" rounded v-if="!detailsLoader">
          <v-card-title>
            {{ $vuetify.lang.t("$vuetify.tooltip.document") }} {{ $vuetify.lang.t("$vuetify.details_header_txt") }}
          </v-card-title>
          <v-card-text>
            <div>{{ $vuetify.lang.t("$vuetify.id_header_txt") }}: {{ document_details.id }}</div>
            <div>{{ $vuetify.lang.t("$vuetify.dashboard.active") }} {{ $vuetify.lang.t("$vuetify.status_header_txt") }}:
              <span class="text-capitalize">{{ document_details.active_status }}</span>
            </div>
            <div>{{ $vuetify.lang.t("$vuetify.created_at_txt") }}: {{ formatDateTime(document_details.created_at) }}
            </div>
            <div>{{ $vuetify.lang.t("$vuetify.status_header_txt") }}: <span class="text-capitalize">{{
                    document_details.status }}</span></div>
            <v-container>
              <v-row class="pa-0 ma-0">
                <v-col class="py-1 my-0"
                  cols="6"><strong>{{ $vuetify.lang.t("$vuetify.gpt_document.document_key") }}</strong></v-col>
                <v-col class="py-1 my-0"
                  cols="6"><strong>{{ $vuetify.lang.t("$vuetify.gpt_document.document_value") }}</strong></v-col>
              </v-row>
              <v-row class="pa-0 ma-0" v-for="(value, key) in document_details.data" :key="key">
                <v-col class="py-1 my-0" cols="6">{{ key }}</v-col>
                <v-col class="py-1 my-0" cols="6">{{ value }}</v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn text color="error" @click="detailsDialog = false">
              {{ $vuetify.lang.t("$vuetify.close_btn_txt") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <Loader v-else />
      </v-dialog>
    </template>

    <template v-if="createNewDocumentDialog">
      <v-dialog v-model="createNewDocumentDialog" max-width="800px" persistent scrollable hide-overlay
        transition="dialog-bottom-transition" content-class="rounded-lg" elevation="24" rounded>
        <v-card class="rounded-lg" elevation="24" rounded>
          <v-card-title>
            {{
                    toBeUpdatedDocumentId !== '' ?
                      $vuetify.lang.t("$vuetify.gpt_document.update_document") :
                      $vuetify.lang.t("$vuetify.create_action_txt") + ' ' + $vuetify.lang.t("$vuetify.gpt_document.create_document")
                  }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" class="pa-0">
                <p class="text-center font-weight-bold pa-0 ma-0">
                  {{ $vuetify.lang.t("$vuetify.gpt_document.document_key") }}</p>
              </v-col>
              <v-col cols="12" md="8" class="pa-0">
                <p class="text-center font-weight-bold pa-0 ma-0">
                  {{ $vuetify.lang.t("$vuetify.gpt_document.document_value") }}</p>
              </v-col>
            </v-row>
            <hr class="my-2">
            <v-form ref="document_form">
              <v-row class="pa-0 ma-0" v-for="(newDocument, index) in newDocuments" :key="index">
                <v-col class="py-0 my-0" cols="12" md="4">
                  <v-text-field v-model="newDocument.key" outlined dense :rules="[
                    (v) => !newDocuments.some((doc, i) => doc.key === v && i !== index) || $vuetify.lang.t('$vuetify.common.duplicate_error_txt'),
                    (v) => !!v || $vuetify.lang.t('$vuetify.common.field_required_error_txt')
                  ]"></v-text-field>
                </v-col>
                <v-col class="py-0 my-0" cols="12" md="8">
                  <div class="d-flex">
                    <v-textarea v-model="newDocument.value" outlined dense rows="2" :rules="[
                    (v) => !!v || $vuetify.lang.t('$vuetify.common.field_required_error_txt')
                  ]"></v-textarea>
                    <v-btn icon @click="removeDocument(index)" v-if="newDocuments.length > 1">
                      <v-icon color="red">mdi-minus</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
            <v-row class="pa-0">
              <v-col cols="12" class="d-flex justify-end">
                <v-btn outlined @click="addMoreData" color="primary">
                  {{ $vuetify.lang.t("$vuetify.project.add_more") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex justify-end">
            <v-btn text color="error" @click="closeDocumentModel">
              {{ $vuetify.lang.t("$vuetify.close_btn_txt") }}
            </v-btn>

            <v-btn color="primary" @click="updateDocumentBtn" v-if="toBeUpdatedDocumentId !== ''">
              {{ $vuetify.lang.t("$vuetify.project.update") }}
            </v-btn>
            <v-btn color="primary" @click="createNewDocument" v-else>
              {{ $vuetify.lang.t("$vuetify.create_action_txt") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-if="uploadCSVDialog">
      <v-dialog v-model="uploadCSVDialog" max-width="800px" persistent scrollable hide-overlay
        transition="dialog-bottom-transition" content-class="rounded-lg" elevation="24" rounded>
        <v-card class="rounded-lg" elevation="24" rounded>
          <v-card-title class="ml-4">
            {{ $vuetify.lang.t("$vuetify.gpt_dataset.document_upload_header_txt") }}
          </v-card-title>
          <v-card-text >
            <v-col class="d-flex align-items-center">
            <v-icon class="text-info mr-2">mdi-information</v-icon>
            <span class="text-body-1">
              {{ $vuetify.lang.t("$vuetify.gpt_document.file_support_type") }}
            </span>
          </v-col>
            
          </v-card-text>
          <v-card-text>
            <v-form  ref="dataset_form">
              <v-col cols="12" md="12">
                <v-file-input
                  outlined
                  accept=".json, .csv, application/json, text/csv, application/pdf, .pdf"
                  @change="handleFileChange"
                  :label="$vuetify.lang.t('$vuetify.gpt_dataset.dataset_file')"
                  :rules="fileInputRules"
                >
                </v-file-input>
              </v-col>
            </v-form>
            <v-row class="pa-0">
              <v-col cols="12" class="d-flex justify-end pr-6">
                <v-btn :loading="uploadBtnLoader" outlined @click="uploadDataset" color="primary">
                  {{ $vuetify.lang.t("$vuetify.upload_btn_txt") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end pr-6">
            <v-btn text color="error" @click="uploadCSVDialog = false">
              {{ $vuetify.lang.t("$vuetify.close_btn_txt") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-if="is_task_running">
      <v-dialog v-model="is_task_running" max-width="400px" persistent transition="dialog-bottom-transition"
        content-class="rounded-lg" elevation="24" rounded>
        <v-card class="rounded-lg" elevation="24" rounded>
          <v-card-title>
            <div>
              <span class="headline text-capitalize">
                {{ uploadingCSV ? documentUploadHeader:  $vuetify.lang.t("$vuetify.gpt_document.delete_document_progress_header_txt") }}
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <v-list-item>

              <v-list-item-icon>
                <v-progress-circular indeterminate color="primary">
                </v-progress-circular>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ uploadingCSV ? uploadCSVMessage : $vuetify.lang.t("$vuetify.gpt_document.delete_document_progress_txt") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn text color="error" @click="is_task_running = false">
              {{ $vuetify.lang.t("$vuetify.close_btn_txt") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-if="task_error">
      <v-dialog v-model="task_error" max-width="600px" persistent transition="dialog-bottom-transition"
        content-class="rounded-lg" elevation="24" rounded>
        <v-card class="rounded-lg" elevation="24" rounded>
          <v-card-title>
            <div>
              <span class="headline text-capitalize">
                {{  documentUploadHeader }}
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{task_error_message}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn text color="error" @click="task_error = false">
              {{ $vuetify.lang.t("$vuetify.close_btn_txt") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>


    <DialogModal v-model="deleteDialog" :title="$vuetify.lang.t('$vuetify.confirm_alert_title_txt')"
      :message="delete_alert_message" :confirmButtonText="$vuetify.lang.t('$vuetify.confirm_btn_txt')"
      :cancelButtonText="$vuetify.lang.t('$vuetify.cancel_btn_txt')" @onConfirmation="deleteDocuments()"
      @onCancel="deleteDialog = false" />
    <DialogModal v-model="downloadDialog" :title="$vuetify.lang.t('$vuetify.confirm_alert_title_txt')"
    :message="download_alert_message" :confirmButtonText="$vuetify.lang.t('$vuetify.confirm_btn_txt')"
    :cancelButtonText="$vuetify.lang.t('$vuetify.cancel_btn_txt')" @onConfirmation="downloadDocuments()"
    @onCancel="downloadDialog = false" />
    <DialogModal v-model="downloadAllDocumentsDialog" :title="!allDocumentsDownloadRequestSubmitted ? $vuetify.lang.t('$vuetify.confirm_alert_title_txt') : $vuetify.lang.t('$vuetify.request_submitted')"
    :message="download_alert_message" :confirmButtonText="$vuetify.lang.t('$vuetify.confirm_btn_txt')"
    :cancelButtonText="allDocumentsDownloadRequestSubmitted ? $vuetify.lang.t('$vuetify.ok_btn_txt') : $vuetify.lang.t('$vuetify.cancel_btn_txt')" @onConfirmation="downloadAllDocuments()"
    @onCancel="downloadAllDocumentsDialog = false; allDocumentsDownloadRequestSubmitted = false; datasetDownloadRequestFailed = false"
    :confirmButton="!allDocumentsDownloadRequestSubmitted"
    :confirmBtnLoading="documentDownloadRequestSubmission"
    >
      <v-card-text v-if="allDocumentsDownloadRequestSubmitted && !datasetDownloadRequestFailed">
        {{$vuetify.lang.t('$vuetify.dataset_download_request_txt')}}
        {{$vuetify.lang.t('$vuetify.request_instruction')}}
      </v-card-text>
      <div v-else-if="datasetDownloadRequestFailed">
        {{datasetDownloadRequestFailMessage}}
      </div>
    </DialogModal>
    
    <DownloadDatasetHistoryModal v-model="historyDialog" :datasetId="this.$route.params.dataset_id" />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { ACTION_BUTTON_GROUP } from "@/components/common/buttongroup/_buttongrouptypes";
import validations from "@/helpers/validations";

import {
  CREATE_GPT_DOCUMENT,
  UPDATE_GPT_DOCUMENT,
  GET_GPT_DOCUMENTS,
  GET_GPT_DOCUMENT_BY_ID,
  TERMINATE_GPT_DOCUMENTS,
  GET_TASK_STATUS,
  UPDATE_GPT_DATASET_API,
  DATASET_DOWNLOAD_REQUEST,
  GET_TASKS_BY_PREFIX,
  UPDATE_GPT_DATASET_FILE
} from "@/store/_actiontypes";

import { TEXT } from "@/components/common/input/_inputTypes";

import { authorize } from "@/helpers/helper";
import {formatDateTime} from "@/helpers/formatter";
import { Role } from "@/helpers/role";

import ButtonGroup from "@/components/common/buttongroup/ButtonGroup";
import DialogModal from "@/components/common/DialogModal";
import DownloadDatasetHistoryModal from "./DownloadDatasetHistoryModal.vue";
import Loader from "@/components/common/loader/Loader";
import getEnv from "@/config/env";


export default {
  props: {
    solution_type: {
      type: String
    },
  },
  components: {
    ButtonGroup,
    DialogModal,
    Loader,
    DownloadDatasetHistoryModal
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      account: (state) => state.account.account
    }),
    ...mapGetters("loader", ["loading"]),
    fileInputRules() {
        let rules = [];
          rules.push(
            (value) =>
              !!value ||
              this.$vuetify.lang.t("$vuetify.gpt_dataset.dataset_file") +
                " is required"
          );

        return rules;
    },
  },
  watch: {
    page() {
      this.getAllDocument();
    },
    itemsPerPage() {
      if (this.page === 1) {
        this.getAllDocument();
      } else {
        this.page = 1;
      }
    },
    options: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.sortBy.length !== 0) {
          this.sortDesc = {
            [newVal.sortBy[0]]: newVal.sortDesc[0] ? "desc" : "asc",
          };
        }
        else {
          this.sortDesc = null;
        }
        this.getAllDocument();
      },
      deep: true,
    },
  },
  mounted() {
    this.disableCrudOperation();
    this.checkSectionId();
  },
  data() {
    return {
      gptDocuments: [],
      selectedDocuments: [],
      toBeUpdatedDocumentId: "",
      document_details: {},
      newDocuments: [{ key: "", value: "" }],
      formatDateTime,
      disable_crud_operation: false,
      isLoading: false,
      TEXT: TEXT,
      ACTION_BUTTON_GROUP: ACTION_BUTTON_GROUP,
      delete_alert_message: this.$vuetify.lang.t('$vuetify.gpt_dataset.confirm_alert_msg_txt_3'),
      createNewDocumentDialog: false,
      uploadCSVDialog: false,
      detailsDialog: false,
      documentDownloadRequestSubmission: false,
      detailsLoader: false,
      deleteDialog: false,
      downloadDialog: false,
      downloadAllDocumentsDialog: false,
      historyDialog: false,
      allDocumentsDownloadRequestSubmitted: false,
      taskId: "",
      download_alert_message: this.$vuetify.lang.t('$vuetify.gpt_dataset.confirm_alert_msg_all_download'),
      alertDialog: false,
      uploadingCSV: false,
      documentUploadHeader: this.$vuetify.lang.t('$vuetify.gpt_dataset.document_upload_header_txt'),
      uploadCSVMessage: this.$vuetify.lang.t('$vuetify.gpt_dataset.dataset_uploading_progress'),
      datasetFile: null,
      alertMessage: this.$vuetify.lang.t('$vuetify.unknown_err_msg_txt'),
      ...validations,
      downloadDatasetHistoryDialog: false,
      options:{},
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.id_header_txt"),
          align: "center",
          sortable: false,
          value: "id",
        },
        // {
        //   text: this.$vuetify.lang.t("$vuetify.name_header_txt"),
        //   align: "center",
        //   sortable: false,
        //   value: "data.name",
        // },
        {
          text: this.$vuetify.lang.t("$vuetify.created_at_txt"),
          align: "center",
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.updated_at_txt"),
          align: "center",
          sortable: true,
          value: "updated_at",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.status_header_txt"),
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.actions_header_txt"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      document_status_states: [
        { state: this.$vuetify.lang.t("$vuetify.gpt_dataset.active_status_menu.all"), value: "" },
        { state: this.$vuetify.lang.t("$vuetify.gpt_dataset.active_status_menu.active"), value: "active_status=active" },
        { state: this.$vuetify.lang.t("$vuetify.gpt_dataset.active_status_menu.archived"), value: "active_status=archived" }
      ],
      training_status_states: [
        { state: this.$vuetify.lang.t("$vuetify.gpt_dataset.train_status_menu.all"), value: "" },
        { state: this.$vuetify.lang.t("$vuetify.gpt_dataset.train_status_menu.text_trained"), value: "text_training_status=trained" }, 
        { state: this.$vuetify.lang.t("$vuetify.gpt_dataset.train_status_menu.image_trained"), value: "image_training_status=trained" },
        { state: this.$vuetify.lang.t("$vuetify.gpt_dataset.train_status_menu.both"), value: "text_training_status=trained&image_training_status=trained" }
      ],
      sortBy: 'created_at',
      datasetDownloadRequestFailed: false,
      datasetDownloadRequestFailMessage: "",
      sortDesc: null,
      uploadBtnLoader: false,
      selected_document_state: "",
      selected_training_state: "",
      search_text:"",
      is_task_running: false,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      rows: [5, 10, 20, 30, 40, 50],
      task_error: false,
      task_error_message: "",
    };
  },

  methods: {
    ...mapActions("document", [
      CREATE_GPT_DOCUMENT,
      UPDATE_GPT_DOCUMENT,
      GET_GPT_DOCUMENTS,
      GET_GPT_DOCUMENT_BY_ID,
      TERMINATE_GPT_DOCUMENTS,
      DATASET_DOWNLOAD_REQUEST
    ]),
    ...mapActions("dataset", [
      DATASET_DOWNLOAD_REQUEST
    ]),
    ...mapActions("project", [
      UPDATE_GPT_DATASET_FILE
    ]),
    ...mapActions("task", [GET_TASK_STATUS, GET_TASKS_BY_PREFIX]),
    checkSectionId() {
      if (this.$route.query.section_id) {
       let document_id = this.$route.query.section_id;
       this.GET_GPT_DOCUMENT_BY_ID({
        project_id: this.$route.params.project_id,
        dataset_id: this.$route.params.dataset_id,
        document_id: document_id,
      }).then(
        (response) => {
          let item = response.data?.detail;
          item.id = document_id;
          this.updateItem(item);
        },
        (error) => {
          this.alertMessage = error.message;
          this.alertDialog = true;
        }
      );
      }
    },
    handleQueryOnDocuments(){
      if(this.page === 1) {
        this.getAllDocument();
      }else{
        this.page = 1;
      }
    },
    
    async downloadAllDocuments(){
      this.documentDownloadRequestSubmission = true
      this.DATASET_DOWNLOAD_REQUEST({
        project_id: this.$route.params.project_id,
        dataset_id: this.$route.params.dataset_id,
      }).then(
        (response) => {
          this.download_alert_message = ``
          this.taskId = response.data.detail.task_id
        },
        (error) => {
          this.isLoading = false;
          this.datasetDownloadRequestFailed = true
          console.error("Error", error)
          this.datasetDownloadRequestFailMessage = error.response.data.detail.error
        }
      ).finally(()=> {
        this.download_alert_message = ``
        this.allDocumentsDownloadRequestSubmitted = true
        this.documentDownloadRequestSubmission = false
      })
    },
    downloadDocuments() {
        let allHeaders = new Set();
        this.selectedDocuments.forEach(doc => {
          Object.keys(doc.data).forEach(header => allHeaders.add(header))
        })
        allHeaders = Array.from(allHeaders)

        let csvRows = [];
        csvRows.push(allHeaders.join(","));

        this.selectedDocuments.forEach(doc => {
          let row = allHeaders.map(header => {
            let cell = doc.data[header] || "";
            return `"${String(cell).replace(/"/g, '""')}"`;
          });
          csvRows.push(row.join(","))
        })
        let csvContent = csvRows.join("\n")

        let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);
        
        link.setAttribute("href", url);
        link.setAttribute("download", "documents.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.downloadDialog = false
    },
    getAllDocument() {
      this.isLoading = true;
      this.gptDocuments = [];
      this.GET_GPT_DOCUMENTS({
        project_id: this.$route.params.project_id,
        dataset_id: this.$route.params.dataset_id,
        keyword: this.search_text,
        status_value: this.selected_document_state,
        train_status_value: this.selected_training_state,
        sort: this.sortDesc,
        start: (this.page - 1) * this.itemsPerPage,
        size: this.itemsPerPage,
      }).then(
        (response) => {
          this.isLoading = false;
          this.gptDocuments = response.data?.response.documents;
          let total_hits = response.data?.response.total_hits || 0;
          let returned_hits = response.data?.response.returned_hits || 0;
          this.pageCount = Math.ceil(total_hits / this.itemsPerPage) || 1;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    redirectProjectPage() {
      this.$router.push(`/projects/${this.$route.params.project_id}`)
    },
    createNewDocumentClick() {
      this.newDocuments = [{ key: "", value: "" }];
      this.toBeUpdatedDocumentId = "";
      this.createNewDocumentDialog = true;
    },
    handleCSVUpload() {
      this.uploadCSVDialog = true;
    },

    addMoreData() {
      if (this.$refs.document_form.validate() === false) {
        return;
      }
      this.newDocuments.push({ key: "", value: "" });
    },
    removeDocument(index) {
      this.newDocuments.splice(index, 1);
      this.$refs.document_form.validate();
    },
    createNewDocument() {
      if (this.$refs.document_form.validate() === false) {
        return;
      }
      let data = {};
      this.newDocuments.forEach((document) => {
        data[document.key] = document.value;
      });
      this.CREATE_GPT_DOCUMENT({
        project_id: this.$route.params.project_id,
        dataset_id: this.$route.params.dataset_id,
        documents: data,
      }).then(
        (response) => {
          if (response.status === 200) {
            this.createNewDocumentDialog = false;
            this.isLoading = true;
            setTimeout(async () => {
              await  this.getAllDocument();
              this.isLoading = false;
            }, 1000);
          }
          else {
            this.alertMessage = response.data?.message;
            this.alertDialog = true;
          }
        },
        (error) => {
          this.alertMessage = error.message;
          this.createNewDocumentDialog = false;
          this.alertDialog = true;
        }
      );
    },
    async updateDocumentBtn() {
      try {
        let data = {};
        this.newDocuments.forEach((document) => {
          data[document.key] = document.value;
        });

        const response = await this.UPDATE_GPT_DOCUMENT({
          project_id: this.$route.params.project_id,
          dataset_id: this.$route.params.dataset_id,
          document_id: this.toBeUpdatedDocumentId,
          data: data,
        });

        if (response.status === 200) {
          if (this.$route.query.section_id) {
            this.$router.push(`/projects/${this.$route.params.project_id}/datasets/${this.$route.params.dataset_id}/documents`);
          }
          setTimeout(async () => {
            this.isLoading = true;
            await this.getAllDocument();
            this.isLoading = false;
          }, 1000);
        } else {
          this.alertMessage = response.data?.message || 'An error occurred.';
          this.alertDialog = true;
        }
      } catch (error) {
        this.alertMessage = error.message || 'An error occurred.';
        this.alertDialog = true;
      } finally {
        this.createNewDocumentDialog = false;
      }
    },
    handleFileChange(file) {
      this.datasetFile = file;
    
    },
    startInterval(task_id) {
      this.intervalId = setInterval(() => {
        this.GET_TASK_STATUS({
          project_id: this.$route.params.project_id,
          task_id: task_id,
        }).then(
          (response) => {
            if (response.data.detail.response.status === "success") {
              this.stopInterval();
              this.getAllDocument();
            }
            if (response.data.detail.response.status === "failed"){
              this.stopInterval();
              this.task_error = true
              this.task_error_message = response.data.detail.response.message
            }
          },
          (error) => {
            this.stopInterval();
            clearInterval(this.intervalId);
            this.alertMessage = error.message;
            this.alertDialog = true;
          }
        );
      }, getEnv("VUE_APP_POLLING_INTERVAL"));
    },
    stopInterval() {
      this.is_task_running = false;
      this.selectedDocuments = [];
      clearInterval(this.intervalId);
    },
    deleteDocuments() {
      let documentIds = this.selectedDocuments.map((document) => document.id);
      this.TERMINATE_GPT_DOCUMENTS({
        project_id: this.$route.params.project_id,
        dataset_id: this.$route.params.dataset_id,
        documentIds
      }).then(
        (response) => {
          if (response.status === 202) {
            this.is_task_running = true;
            this.startInterval(response.data?.detail.task_id);
            this.deleteDialog = false;
          }
          else if (response.status === 200) {
            this.getAllDocument();
            this.deleteDialog = false;
          }
          else {
            this.alertMessage = response.data?.message;
            this.alertDialog = true;
          }

        },
        (error) => {
          this.alertMessage = error.message;
          this.deleteDialog = false;
          this.alertDialog = true;
        }
      );
    },
    uploadDataset(){
      this.uploadBtnLoader = true
      this.UPDATE_GPT_DATASET_FILE({
                                    project_id: this.$route.params.project_id,
                                    datasetId: this.$route.params.dataset_id,
                                    datasetFile: this.datasetFile,
                                  }).then(res => {
                                    this.uploadingCSV = true
                                    this.uploadCSVDialog = false;
                                    this.startInterval(res.data.detail?.task_id)
                                    this.is_task_running = true;
                                  }).catch(err => {
                                    console.log("err", err)
                                  }).finally(()=> {
                                    this.uploadBtnLoader = false
                                  })
    },
    detailsModal(item) {
      this.detailsDialog = true;
      this.document_details = item;
      // this.detailsLoader = true;      
    },
    updateItem(item) {
      this.createNewDocumentDialog = true;
      this.newDocuments = Object.keys(item.data).filter(key => key !== 'id').map(key => ({ key, value: item.data[key] }));
      this.toBeUpdatedDocumentId = item.id;

    },
    closeDocumentModel() {
      if(this.$route.query.section_id) {
        this.$router.push(`/projects/${this.$route.params.project_id}/datasets/${this.$route.params.dataset_id}/documents`)
      }
      this.createNewDocumentDialog = false;
    },
    deleteItem(item) {
      this.selectedDocuments = [item];
      this.deleteDialog = true;
    },
    disableCrudOperation() {
      if (authorize([Role.USER])) {
        this.disable_crud_operation = true;
      }
    }
  },
};
</script>

<style></style>