<template>
    <v-expansion-panels>
        <v-expansion-panel v-model="panel">
            <v-expansion-panel-header v-if="!isPreviouslyRankSettingsCreated()">
      <div class="text-h6 font-weight-bold ml-n2">{{ `${$vuetify.lang.t("$vuetify.project.create_rank_settings")}` }}</div>
    </v-expansion-panel-header>
    <v-expansion-panel-header v-else>
      <div class="text-h6 font-weight-bold ml-n2">{{ `${$vuetify.lang.t("$vuetify.project.update_rank_settings")}` }}</div>
    </v-expansion-panel-header>

            <v-expansion-panel-content>
                <v-form ref="form">
                    <v-row cols="12" md="6">
                        <v-col cols="2">
                            <span>Interval</span>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="interval"
                                :items="intervalOptions"
                                dense
                                hide-selected
                                outlined
                                small-chips
                                multiple
                                deletable-chips
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row cols="12" md="6">
                        <v-col cols="2">
                            <span>Split Size</span>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="number" v-model="splitSize" outlined dense :min="0" :max="1" :step="0.01" decimal @change="handleNonEmptyValue('splitSize')"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row cols="12" md="6">
                        <v-col cols="2">
                            <span>Epoch</span>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="number" v-model="epoch" outlined dense :min="1" decimal @change="handleNonEmptyValue('epoch')"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row cols="12" v-if="isPreviouslyRankSettingsCreated()">
                        <v-col>
                            <v-btn @click="updateRankSettings" color="primary" :loading="isLoading">{{ `${$vuetify.lang.t("$vuetify.project.update")}` }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row cols="12" v-else>
                        <v-col>
                            <v-btn @click="createRankSettings" color="primary" :loading="isLoading">{{ $vuetify.lang.t("$vuetify.create_action_txt") }}</v-btn>
                        </v-col>
                    </v-row>

                </v-form>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import {
    ADD_ALERT,
    CREATE_RANK,
    UPDATE_RANK_SETTINGS,

} from "@/store/_actiontypes";

export default {
    props: {
        rankSettings: {
            type: Object,
            default: false,
        },
    },
    components: {},
    data() {
        return {
            isLoading: false,
            selectedFile: null,
            defaultTasks: [
                {
                    name: "csv_upload",
                    message: "",
                    status: "not_started",
                },
                {
                    name: "item_train",
                    message: "",
                    status: "not_started",
                },
                {
                    name: "rank_train",
                    message: "",
                    status: "not_started",
                },
                {
                    name: "image_train",
                    message: "",
                    status: "not_started",
                },
            ],
            polling: null,
            inProgress: false,
            updateResult: "",
            updateStatus: "",
            interval: [],
            intervalOptions: ["weekly", "bi-weekly", "monthly", "quarterly", "yearly"],
            splitSize: 0.1,
            epoch: 200,
            panel: false,
        };
    },
    computed: {
        ...mapState({
            selectedProject: (state) => state.project.selectedProject,
            previouslySetupState: (state) => state.project.previouslySetupState
        }),
    },
    watch: {
        rankSettings: {
            handler(newVal) {
                if (newVal?.detail?.response){
                    this.interval = [...newVal.detail.response.interval];
                    this.splitSize = newVal.detail.response.split_size;
                    this.epoch = newVal.detail.response.epoch;
                }else {
                    this.interval = ["weekly", "bi-weekly", "monthly", "quarterly", "yearly"];
                    this.splitSize = 0.1;
                    this.epoch = 200;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    beforeMount(){
    },
    mounted() {
    },
    created() {
    },
    methods: {
        ...mapActions("project", [
            UPDATE_RANK_SETTINGS,
            CREATE_RANK,
        ]),
        ...mapActions("alert", [ADD_ALERT]),
        updateRankSettings() {
            this.isLoading = true;
            let newRankSettings = {
                rank_settings: {
                    interval: this.interval,
                    split_size: this.splitSize,
                    epoch: this.epoch,
                },
            }

            this.UPDATE_RANK_SETTINGS({
                project_id: this.selectedProject.id,
                settings: newRankSettings
            }).then(
                (response) => {
                    this.$store.dispatch(
                        `alert/${ADD_ALERT}`,
                        {
                            message: response.data?.detail?.response,
                            color: "success",
                        },
                        { root: true }
                    );
                    this.isLoading = false;
                },
                (error) => {
                    if (error.response.status === 422) {
                        this.$store.dispatch(
                            `alert/${ADD_ALERT}`,
                            {
                                message: error.response.data.detail[0].msg,
                                color: "error",
                            },
                            { root: true }
                        );
                    }
                    this.isLoading = false;
                }
            );
        },
        createRankSettings(){
            this.isLoading = true;
            let newRankSettings = {
                rank_settings: {
                    interval: this.interval,
                    split_size: this.splitSize,
                    epoch: this.epoch,
                },
            }
            
            this.CREATE_RANK({
                project_id: this.selectedProject.id,
                settings: newRankSettings
            }).then(
                (response) => {
                    this.$emit("updateComplete");
                    this.$store.dispatch(
                        `alert/${ADD_ALERT}`,
                        {
                            message: response.data?.detail?.response,
                            color: "success",
                        },
                        { root: true }
                    );
                    this.isLoading = false;
                },
                (error) => {
                    if (error.response.status === 422) {
                        this.$store.dispatch(
                            `alert/${ADD_ALERT}`,
                            {
                                message: error.response.data.detail[0].msg,
                                color: "error",
                            },
                            { root: true }
                        );
                    }
                    this.isLoading = false;
                }
            );
        },
        handleNonEmptyValue(fieldName) {
            if (this[fieldName] === "") {
                this[fieldName] = 0;
            }
        },
        isPreviouslyRankSettingsCreated() {
            return this.previouslySetupState.steps.some((step) => step.name === "rank_settings" && step.status === "success");
        },
    },
};
</script>

<style scoped>
.secondary-disabled-button {
    cursor: not-allowed;
    pointer-events: none;
}
</style>
