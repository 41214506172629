import PersonalizerApi from "@/services/personalizer_api";
import { LOAD_ALL_DATASETS, CREATE_DATASET, ADD_CONVERSATION_TO_DATASET, UPLOAD_DATASET, DATASET_DOWNLOAD_REQUEST} from "@/store/_actiontypes";
import { account, updateLoaderTo } from "./account";


const state = {
    datasets: []
}

const actions = {
    [LOAD_ALL_DATASETS] ({commit }, {project_id}) {

        return new Promise((resolve, reject)=> {
            PersonalizerApi.get(`gpt/datasets`,
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            )
            .then(res => {
                resolve(res);
            }, err => {
                reject(err);
            })
        })
    },
    [CREATE_DATASET] ({commit}, {project_id, datasetRequestBody}) {
        return new Promise((resolve, reject)=> {
            PersonalizerApi.post(`gpt/datasets`, datasetRequestBody,
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            ).then(res => {
                resolve(res);
            }, err => {
                reject(err)
            })
        })
    },
    async [DATASET_DOWNLOAD_REQUEST] ({commit}, {project_id, dataset_id}) {
        updateLoaderTo(true)
        try{
            const url = `files/datasets/${dataset_id}/generate`
            const payload = {}
            const config = {
                headers: {
                    "project-id": project_id
                }
            }
            return await PersonalizerApi.post(url, payload, config)
        }catch (error){
            throw error
        } finally {
            updateLoaderTo(false)
        }
    },
    [UPLOAD_DATASET] ({commit}, {project_id, dataset_id, dataset_file}) {
        const formData = new FormData()
        formData.append("dataset_file", dataset_file)
        return new Promise((resolve, reject)=> {
            PersonalizerApi.put(`gpt/datasets/${dataset_id}`, formData,
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            ).then(res => {
                resolve(res);
            }, err => {
                reject(err)
            })
        })
    },

    [ADD_CONVERSATION_TO_DATASET] ({commit}, {project_id, dataset_id, documents, is_background_task}) {
        
        return new Promise((resolve, reject)=> {
            PersonalizerApi.post(`gpt/datasets/${dataset_id}/documents`, {documents, is_background_task},
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            ).then(res => {
                resolve(res);
            }, err => {
                reject(err)
            })
        })
    },
}

const mutations = {
   
}


export const dataset = {
    namespaced: true,
    state, 
    actions, 
    mutations
}