import Api from "@/services/api";
import {
  LOAD_ALL_RESOURCEMETADATA,
  LOAD_RESOURCEMETADATA_BY_TYPE,
} from "@/store/_actiontypes";
import {
  SET_ALL_RESOURCEMETADATA,
  SET_ALL_RESOURCEMETADATA_BY_TYPE,
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
  allResourceMetadata: [],
  resourceMetadataByType: []
};

const actions = {
  [LOAD_ALL_RESOURCEMETADATA]({ commit }) {
    updateLoaderTo(true);
    commit(SET_ALL_RESOURCEMETADATA, []);
    return new Promise((resolve, reject) => {
      Api.get(`/v1/api/resources`).then(
        (response) => {
          let allResourceMetadata = response.data.resources;
          commit(SET_ALL_RESOURCEMETADATA, allResourceMetadata);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_RESOURCEMETADATA_BY_TYPE]({ commit }, { solutionType }) {
    updateLoaderTo(true);
    commit(SET_ALL_RESOURCEMETADATA_BY_TYPE, []);
    return new Promise((resolve, reject) => {
      Api.get(`/v1/api/resources/${solutionType}`).then(
        (response) => {
          let resourceMetadataByType = response.data;
          commit(SET_ALL_RESOURCEMETADATA_BY_TYPE, resourceMetadataByType);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  }
};

const mutations = {
  [SET_ALL_RESOURCEMETADATA](state, allResourceMetadata) {
    state.allResourceMetadata = allResourceMetadata;
  },
  [SET_ALL_RESOURCEMETADATA_BY_TYPE](state, resourceMetadataByType) {
    state.resourceMetadataByType = resourceMetadataByType;
  }
};

export const resource_metadata = {
  namespaced: true,
  state,
  actions,
  mutations,
};
