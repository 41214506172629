import Api from "@/services/api";
import {
    LOAD_ALL_SUBSCRIPTION,
    SAVE_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION_FEE,
} from "@/store/_actiontypes";
import {
    SET_ALL_SUBSCRIPTION
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
    allSubscription: [],
};

const actions = {
  [LOAD_ALL_SUBSCRIPTION]({ commit }) {
    updateLoaderTo(true);
    commit(SET_ALL_SUBSCRIPTION, []);
    return new Promise((resolve, reject) => {
      Api.get(`/v1/api/subscriptions`).then(
        (response) => {
          let allSubscription = response.data.subscriptions;
          commit(SET_ALL_SUBSCRIPTION, allSubscription);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
	[UPDATE_SUBSCRIPTION_FEE]({ commit }, {project_id, subscription_fee }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.put(`/v1/api/subscriptions/${project_id}`, {
        subscription_fee: subscription_fee,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
	[SAVE_SUBSCRIPTION]({ commit }, {organization_id, project_id, subscription_fee }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`/v1/api/subscriptions`, {
        organization_id: organization_id,
        project_id: project_id,
        subscription_fee: subscription_fee
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
};

const mutations = {
  [SET_ALL_SUBSCRIPTION](state, allSubscription) {
    state.allSubscription = allSubscription;
  }
};

export const subscription = {
  namespaced: true,
  state,
  actions,
  mutations,
};
