<template>
  <div>
    <v-container fill-height fluid>
      <v-row>
        <v-col class="pa-0" xs="12" md="12">
          <v-card outlined class="mx-auto px-2">
            <v-row>
              <v-col xs="12" md="6">
                <div class="px-2 primary--text font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.wallet.account_details") }}
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" xs="12">
                <v-row
                  class="pt-16"
                  style="height: 200px"
                  justify="center"
                  v-if="!accountDetails || detailsLoading"
                >
                  <div class="font-weight-bold text-h4 d-block">{{ $vuetify.lang.t("$vuetify.common.loading") }}</div>
                  <v-progress-circular
                    class="mt-16"
                    indeterminate
                    color="primary"
                    style="transform: translateX(-80px)"
                  ></v-progress-circular>
                </v-row>
                <template v-else>
                  <v-row class="pa-2">
                    <v-col cols="3">
                      <div class="font-weight-bold">{{ $vuetify.lang.t("$vuetify.wallet.balance") }}:</div>
                    </v-col>
                    <v-col cols="9">
                      <v-row no-gutters v-if="accountDetails === 'inactive'">
                        <v-col cols="6" md="3" class="d-flex flex-wrap">
                          <v-list-item-avatar>
                            <v-avatar left size="35px" color="lighten-2">
                              <span class="font-weight-bold">
                                <v-img
                                  max-height="35px"
                                  max-width="35px"
                                  :src="xlmIcon"
                                ></v-img>
                              </span>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              XLM
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-on="on"
                                    v-bind="attrs"
                                    small
                                    class="ml-2"
                                  >
                                    mdi-information
                                  </v-icon>
                                </template>
                                <div>
                                  <div>
                                    <span class="font-weight-bold"
                                      >{{ $vuetify.lang.t("$vuetify.wallet.buying_liabilities") }}:</span
                                    >
                                    0.00
                                  </div>
                                  <div>
                                    <span class="font-weight-bold"
                                      >{{ $vuetify.lang.t("$vuetify.wallet.selling_liabilities") }}:</span
                                    >
                                    0.00
                                  </div>
                                </div>
                              </v-tooltip>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <Price 
                                amount="0.00"
                              />
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-row no-gutters v-else>
                        <v-col
                          cols="6"
                          md="3"
                          v-for="(asset, index) in accountDetails.balances"
                          :key="index"
                          class="d-flex flex-wrap"
                        >
                          <v-list-item-avatar>
                            <v-avatar
                              left
                              size="35px"
                              :color="
                                allAsset.find(
                                  (a) => a.asset_code === asset.asset_code
                                ) &&
                                allAsset.filter(
                                  (a) => a.asset_code === asset.asset_code
                                ).length > 0 &&
                                allAsset.filter(
                                  (a) => a.asset_code === asset.asset_code
                                )[0].asset_icon
                                  ? 'lighten-2'
                                  : 'primary lighten-2'
                              "
                            >
                              <span class="font-weight-bold">
                                <v-img
                                  max-height="35px"
                                  max-width="35px"
                                  v-if="
                                    allAsset.find(
                                      (a) => a.asset_code === asset.asset_code
                                    ) &&
                                    allAsset.filter(
                                      (a) => a.asset_code === asset.asset_code
                                    ).length > 0 &&
                                    allAsset.filter(
                                      (a) => a.asset_code === asset.asset_code
                                    )[0].asset_icon
                                  "
                                  :src="allAsset.filter(a => a.asset_code === asset.asset_code)[0].asset_icon"
                                ></v-img>
                                <span v-else class="fix-asset-font">
                                  {{ asset.asset_code }}
                                </span>
                              </span>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ asset.asset_code }}
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-on="on"
                                    v-bind="attrs"
                                    small
                                    class="ml-2"
                                  >
                                    mdi-information
                                  </v-icon>
                                </template>
                                <div>
                                  <div>
                                    <span class="font-weight-bold"
                                      >{{ $vuetify.lang.t("$vuetify.wallet.buying_liabilities") }}:</span
                                    >
                                    {{
                                      asset.buying_liabilities &&
                                      Number.parseFloat(
                                        asset.buying_liabilities
                                      ).toFixed(2)
                                    }}
                                  </div>
                                  <div>
                                    <span class="font-weight-bold"
                                      >{{ $vuetify.lang.t("$vuetify.wallet.selling_liabilities") }}:</span
                                    >
                                    {{
                                      asset.selling_liabilities &&
                                      Number.parseFloat(
                                        asset.selling_liabilities
                                      ).toFixed(2)
                                    }}
                                  </div>
                                </div>
                              </v-tooltip>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <Price 
                                :amount="asset.balance"
                              />
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="pa-2">
                    <v-col cols="3">
                      <div class="font-weight-bold">{{ $vuetify.lang.t("$vuetify.wallet.reserve") }}:</div>
                    </v-col>
                    <v-col cols="9">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-list>
                            <v-list-item class="item-height">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-list-item-content
                                    v-bind="attrs"
                                    v-on="on"
                                    class="font-weight-bold py-0"
                                    >{{ $vuetify.lang.t("$vuetify.wallet.base_network_reserve") }}:
                                    <v-icon
                                      class="mx-2"
                                      style="transform: translateY(-15px)"
                                      x-small
                                    >
                                      mdi-information
                                    </v-icon></v-list-item-content
                                  >
                                </template>
                                <span>{{ $vuetify.lang.t("$vuetify.wallet.base_network_reserve_tooltip") }}</span>
                              </v-tooltip>
                              <v-list-item-content class="align-end py-0">{{
                                accountDetails && accountDetails.min_reserve
                                  ? accountDetails.min_reserve.base_net_reserve
                                  : "0.00"
                              }}</v-list-item-content>
                            </v-list-item>
                            <v-list-item class="item-height">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-list-item-content
                                    v-bind="attrs"
                                    v-on="on"
                                    class="font-weight-bold py-0"
                                    >Doozie {{ $vuetify.lang.t("$vuetify.wallet.reserve") }}:
                                    <v-icon
                                      class="mr-2"
                                      style="transform: translateY(-15px)"
                                      x-small
                                    >
                                      mdi-information
                                    </v-icon></v-list-item-content
                                  >
                                </template>
                                <span>{{ $vuetify.lang.t("$vuetify.wallet.doozie_reserve_tooltip") }}</span>
                              </v-tooltip>
                              <v-list-item-content class="align-end py-0">{{
                                accountDetails && accountDetails.min_reserve
                                  ? accountDetails.min_reserve.doozie_reserve
                                  : "0.00"
                              }}</v-list-item-content>
                            </v-list-item>
                            <v-list-item class="item-height">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-list-item-content
                                    v-bind="attrs"
                                    v-on="on"
                                    class="font-weight-bold py-0"
                                    >{{ $vuetify.lang.t("$vuetify.wallet.per_entry_reserve") }}:
                                    <v-icon
                                      class="mr-2"
                                      style="transform: translateY(-15px)"
                                      x-small
                                    >
                                      mdi-information
                                    </v-icon></v-list-item-content
                                  >
                                </template>
                                <span>P{{ $vuetify.lang.t("$vuetify.wallet.per_entry_reserve_tooltip") }}</span>
                              </v-tooltip>
                              <v-list-item-content class="align-end py-0">{{
                                accountDetails && accountDetails.min_reserve
                                  ? accountDetails.min_reserve.per_entry_reserve
                                  : "0.00"
                              }}</v-list-item-content>
                            </v-list-item>
                            <v-list-item class="item-height">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-list-item-content
                                    v-bind="attrs"
                                    v-on="on"
                                    class="font-weight-bold py-0"
                                    >{{ $vuetify.lang.t("$vuetify.wallet.entry_count") }}:
                                    <v-icon
                                      class="mr-2"
                                      style="transform: translateY(-15px)"
                                      x-small
                                    >
                                      mdi-information
                                    </v-icon></v-list-item-content
                                  >
                                </template>
                                <span>{{ $vuetify.lang.t("$vuetify.wallet.entry_count_tooltip") }}</span>
                              </v-tooltip>
                              <v-list-item-content class="align-end py-0">{{
                                accountDetails && accountDetails.min_reserve
                                  ? accountDetails.min_reserve.entry_count
                                  : "0.00"
                              }}</v-list-item-content>
                            </v-list-item>
                            <v-list-item class="item-height">
                              <v-list-item-content class="font-weight-bold py-0"
                                >{{ $vuetify.lang.t("$vuetify.wallet.calculation") }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end py-0">{{
                                accountDetails && accountDetails.min_reserve
                                  ? accountDetails.min_reserve.calculation
                                  : "0.00"
                              }}</v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item class="item-height">
                              <v-list-item-content class="font-weight-bold py-0"
                                >{{ $vuetify.lang.t("$vuetify.wallet.total_reserve") }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end py-0">{{
                                accountDetails && accountDetails.min_reserve
                                  ? accountDetails.min_reserve.total_reserve
                                  : "0.00"
                              }}</v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="pa-2">
                    <v-col cols="3">
                      <div class="font-weight-bold">{{ $vuetify.lang.t("$vuetify.wallet.wallet_account") }}:</div>
                    </v-col>
                    <v-col cols="9">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-list>
                            <v-list-item class="item-height">
                              <v-list-item-content class="font-weight-bold py-0"
                                >{{ $vuetify.lang.t("$vuetify.common.name") }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end py-0">
                                {{ item.account_name }}
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="item-height">
                              <v-list-item-content class="font-weight-bold py-0"
                                >{{ $vuetify.lang.t("$vuetify.wallet.wallet_account_status") }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end py-0">
                                <div>
                                  <v-chip
                                    class="text-capitalize"
                                    :color="
                                      item.status === 'active'
                                        ? 'primary'
                                        : 'error'
                                    "
                                    small
                                    outlined
                                  >
                                    {{ item.status }}
                                  </v-chip>
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="item-height">
                              <v-list-item-content class="font-weight-bold py-0"
                                >{{ $vuetify.lang.t("$vuetify.wallet.public_key") }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end py-0">
                                <div>
                                  {{ passChar(item.public_key) }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-on="on"
                                        icon
                                        @click="copyKeyOnClick(item.public_key)"
                                        ><v-icon
                                          >mdi-content-copy</v-icon
                                        ></v-btn
                                      >
                                    </template>
                                    {{ $vuetify.lang.t("$vuetify.wallet.wallet_click_for_copy") }}
                                  </v-tooltip>
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="item-height">
                              <v-list-item-content class="font-weight-bold py-0"
                                >{{ $vuetify.lang.t("$vuetify.wallet.secret_key") }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end py-0">
                                <div>
                                  S*****************
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-on="on"
                                        icon
                                        @click="showSecretModal()"
                                        ><v-icon
                                          >mdi-eye</v-icon
                                        ></v-btn
                                      >
                                    </template>
                                    {{ $vuetify.lang.t("$vuetify.wallet.click_to_show_secret") }}
                                  </v-tooltip>
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="item-height">
                              <v-list-item-content class="font-weight-bold py-0"
                                >{{ $vuetify.lang.t("$vuetify.common.actions") }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end my-2">
                                <div class="my-2">
                                  <v-btn
                                    color="primary"
                                    @click="editAccountAction()"
                                  >
                                    <v-icon small> mdi-pencil </v-icon> {{ $vuetify.lang.t("$vuetify.wallet.edit_account") }} </v-btn
                                  >
                                </div>
                                <div>
                                  <v-btn
                                    color="error"
                                    @click="deleteAccountAction()"
                                  >
                                    <v-icon small> mdi-delete </v-icon> {{ $vuetify.lang.t("$vuetify.wallet.delete_account") }}</v-btn
                                  >
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                        <v-spacer></v-spacer>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- account edit modal  -->
    <v-dialog v-model="editAccountModal" max-width="500" persistent>
      <v-card>
        <v-card-title class="font-weight-bold"> {{ $vuetify.lang.t("$vuetify.wallet.edit_account") }} </v-card-title>
        <ValidationObserver ref="firstStep">
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <ValidationProvider
                  :name="$vuetify.lang.t('$vuetify.wallet.account_name')"
                  rules="required|is_numeric"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="account_name"
                    :label="$vuetify.lang.t('$vuetify.wallet.account_name')"
                    outlined
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </ValidationObserver>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="cancelClick()">{{ $vuetify.lang.t("$vuetify.common.cancel") }}</v-btn>
          <v-btn color="primary" @click="continueEdit()">{{ $vuetify.lang.t("$vuetify.common.continue") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PasscodeCheck
      v-model="passcode"
      @confirm="confirm"
      @cancel="cancel"
      :isCancelBtn="isCancelBtn"
      :isLoading="loading"
      :message="message"
    />
    <v-snackbar v-model="snackbar" :timeout="timeout">{{ $vuetify.lang.t("$vuetify.common.copied") }}</v-snackbar>
  </div>
</template>

<script>
import {
  GET_WALLET_ACCOUNT_DETAILS,
  REMOVE_WALLET_ACCOUNT,
  EDIT_WALLET_ACCOUNT,
  LOAD_ALL_WALLET_ACCOUNT,
} from "@/store/_actiontypes";
import Loader from "@/components/common/loader/Loader";
import { mapActions, mapState } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import PasscodeCheck from "@/components/wallet/PasscodeCheck";
import { timeToNextWalletToken } from "@/helpers/helper";
import Price from "@/components/wallet/Price";

export default {
  props: {
    item: {
      type: Object,
    },
    allAsset: {
      type: Array,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    PasscodeCheck,
    Price,
  },
  computed: {},
  data() {
    return {
      timeToNextWalletToken,
      detailsLoading: false,
      showLiabilities: [],
      xlmIcon:
        "https://img.icons8.com/external-black-fill-lafs/144/external-Stellar-cryptocurrency-black-fill-lafs.png",
      dztIcon: "https://doozie.io/img/favicon.png",
      accountDetails: null,
      editAccountModal: false,
      account_name: null,
      showPasscode: false,
      isCancelBtn: false,
      passcode: null,
      actionType: null,
      loading: false,
      message: null,
      snackbar: false,
      timeout: 2000,
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.get_wallet_acc_details();
          this.account_name = this.item.account_name;
        }
      },
    },
  },
  methods: {
    ...mapActions("wallet", [
      GET_WALLET_ACCOUNT_DETAILS,
      REMOVE_WALLET_ACCOUNT,
      LOAD_ALL_WALLET_ACCOUNT,
      EDIT_WALLET_ACCOUNT,
    ]),
    get_wallet_acc_details() {
      this.detailsLoading = true;
      this.GET_WALLET_ACCOUNT_DETAILS({
        public_key: this.item?.public_key,
      })
        .then((res) => {
          this.accountDetails = res.data.details;
          this.detailsLoading = false;
        })
        .catch((err) => {
          this.accountDetails = "inactive";
          this.detailsLoading = false;
        });
    },
    updateWalletAccount() {
      if (this.account_name && this.item.id) {
          this.editWalletAccount();
      }
    },
    editWalletAccount() {
      this.loading = true;
      this.EDIT_WALLET_ACCOUNT({
        public_key: this.item?.public_key,
        account_name: this.account_name,
        passcode: this.passcode,
      }).then(
        (response) => {
          if (response) {
            this.$emit("loadEditData", this.account_name);
            this.$store.commit("wallet/toggleModal", false);
            this.passcode = null;
            this.isCancelBtn = false;
            this.loading = false;
            this.editAccountModal = false;
          }
        },
        (error) => {
          this.loading = false;
          this.$store.commit("wallet/toggleModal", false);
          this.passcode = null;
        }
      );
    },
    confirmRemoveWalletAccount() {
      this.loading = true;
      this.REMOVE_WALLET_ACCOUNT({
        public_key: this.item?.public_key,
        passcode: this.passcode,
      }).then(
        (response) => {
          if (response) {
            this.$emit("loadAllAcc");
            this.$store.commit("wallet/toggleModal", false);
            this.passcode = null;
            this.isCancelBtn = false;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.$store.commit("wallet/toggleModal", false);
          this.passcode = null;
        }
      );
    },
    deleteAccountAction() {
      this.$store.commit("wallet/toggleModal", true);
      this.isCancelBtn = true;
      this.actionType = "delete";
      this.message = this.$vuetify.lang.t('$vuetify.wallet.wallet_account_delete_confirmation_msg');
    },
    editAccountAction() {
      this.editAccountModal = !this.editAccountModal;
    },
    cancelClick() {
      this.editAccountModal = false;
      this.actionType = null;
      this.account_name = this.item?.account_name;
    },
    confirm() {
      if (this.actionType === "delete") {
        this.confirmRemoveWalletAccount();
      } else if (this.actionType === "edit") {
        this.updateWalletAccount();
      }
    },
    continueEdit() {
      this.$refs.firstStep.validate().then((success) => {
        if (!success) {
          return;
        }
        this.$store.commit("wallet/toggleModal", true);
        this.isCancelBtn = true;
        this.actionType = "edit";
        this.message = null;
      });
    },
    copyKeyOnClick(key) {
      navigator.clipboard.writeText(key);
      this.snackbar = true;
    },
    showSecretModal() {
      this.$emit("showSecret");
    },
    cancel() {
      this.$store.commit("wallet/toggleModal", false);
      this.passcode = null;
    },
    passChar(str) {
      return `${str.substring(0, 5)}**********${str.substring(
        str.length - 6,
        str.length
      )}`;
    },
  },
};
</script>
<style scoped>
.fix-asset-font {
  font-size: 10px; 
  color:white;
}
</style>