<template>
  <v-main>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col align-self="center" sm="8" md="6" lg="4" xl="4">
          <v-card outlined class="elevation-2 mx-auto pa-4">
            <Logo />
            <v-card-title class="font-weight-bold">
              {{
                $vuetify.lang
                  .t("$vuetify.login_msg_txt")
                  .concat(" ", "Gigalogy AI Platform")
              }}
            </v-card-title>
            <v-card-text>
              <v-form ref="loginForm" @submit.prevent="handleLoginSubmitClick()">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.email_label_txt')"
                  type="email"
                  v-model="loginForm.email"
                  placeholder="adam@gigalogy.com"
                  prepend-inner-icon="email"
                  outlined
                  :rules="[
                    required($vuetify.lang.t('$vuetify.email_label_txt')),
                    email($vuetify.lang.t('$vuetify.email_label_txt')),
                  ]"
                  autocomplete="off"
                  @focus="handlePass"
                ></v-text-field>
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.password_label_txt')"
                  v-model="loginForm.password"
                  :placeholder="$vuetify.lang.t('$vuetify.password_label_txt')"
                  autocomplete="off"
                  prepend-inner-icon="lock"
                  outlined
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : passType ? 'text' : 'password'"
                  :rules="[required($vuetify.lang.t('$vuetify.password_label_txt'))]"
                  @click:append="showPassword = !showPassword"
                  @focus="handlePass"
                ></v-text-field>
                <v-btn color="primary" type="submit" large block :loading="loading"
                  >{{ $vuetify.lang.t("$vuetify.login_btn_txt") }}</v-btn
                >
              </v-form>
              <div class="font-weight-medium pt-5">
                <router-link to="/forgot-password" class="text-decoration-none">
                  {{ $vuetify.lang.t("$vuetify.forgot_password_msg_txt") }}
                </router-link>
              </div>
              <v-divider class="my-5"></v-divider>
              <div class="font-weight-medium pb-5">
                {{ $vuetify.lang.t("$vuetify.registration_msg_txt") }}
              </div>
              <v-btn
                color="primary"
                @click="handleRegisterClick()"
                large
                block
                outlined
                >{{ $vuetify.lang.t("$vuetify.registration_btn_txt") }}</v-btn
              >
            </v-card-text>
          </v-card>
          <div class="text-center font-weight-medium pt-4">
            <router-link to="/" class="text-decoration-none">
              {{ $vuetify.lang.t("$vuetify.back_to_home_txt") }}
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import getEnv from "@/config/env";
import { LOGIN, LOAD_ORGANIZATION, ADD_ALERT } from "@/store/_actiontypes";
import { LOGIN_SUCCESS } from "@/store/_mutationtypes";
import validations from "@/helpers/validations";
import router from "@/router/index";
import { getCurrentUser } from "@/helpers/helper";
import Logo from "@/components/common/logo";
import jwt_decode from "jwt-decode";

export default {
  components: {
    Logo,
  },
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
      showPassword: false,
      passType: true,
      ...validations,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loader.loading,
      login_error: (state) => state.account.login_error,
      user: (state) => state.account.user,
      organization: (state) => state.account.organization,
    }),
  },
  created() {
    //reset theme
    this.$vuetify.theme.dark = 0;
  },
  mounted(){
    window.addEventListener('visibilitychange', this.checkUserExists);
    window.addEventListener('focusin', this.checkUserExists);
  },
  destroyed() {
    window.removeEventListener('visibilitychange', this.checkUserExists);
    window.removeEventListener('focusin', this.checkUserExists);
  },
  methods: {
    ...mapActions("account", [LOGIN, LOAD_ORGANIZATION]),
    async handleLoginSubmitClick() {
      if (getCurrentUser()) {
        await this.$store.dispatch(
          `alert/${ADD_ALERT}`,
          {message: this.$vuetify.lang.t("$vuetify.already_logged_in_error_msg"), color: "error"},
          {root: true}
        );
        this.loginUserDirectly();
      }
      if (!this.$refs.loginForm.validate()) return;

      const clientIP = await this.$ipCheck.getClientIP();
      const isAllowed = await this.$ipCheck.ipRangeCheck(clientIP);

      if (getEnv("VUE_APP_CAPTCHA_ENABLED") && !isAllowed) {
        this.$recaptcha.execute("login")
          .then((recaptcha_token) => {
            this.handleLoginSubmit(recaptcha_token, clientIP);
          }).catch((error) => {
            console.error("reCaptcha error:", error);
          });
      } else {
        this.handleLoginSubmit(null, clientIP);
      }
    },
    handleLoginSubmit(recaptcha_token, clientIP) {
      const { email, password } = this.loginForm;
      if (email && password) {
        this.LOGIN({ email, password, recaptcha_token, clientIP }).then(
          (response) => {
            if (this.user) {
              this.LOAD_ORGANIZATION()
                .then((res) => {
                  let parsedJWTJson = jwt_decode(this.user.access_token);
                  let user_id = parsedJWTJson["sub"];
                  let user_hash = parsedJWTJson["iuh"];
                  router.push("/dashboard").catch(() => {});
                })
                .catch(() => {});
            }
          },
          (error) => error
        );
      }
    },
    handleRegisterClick() {
      router.push("/register").then(
        (response) => {},
        (error) => error
      );
    },
    handlePass(event) {
      this.passType = false;
    },
    checkUserExists(e) {
      if(!document.hidden && getCurrentUser()) {
        this.loginUserDirectly();
      }
    },
    loginUserDirectly(){
      this.$store.commit(`account/${LOGIN_SUCCESS}`, getCurrentUser());
      this.$router.push('/dashboard');
    },
  },
};
</script>
