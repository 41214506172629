<template>
    <v-dialog
        v-model="dialog"
        :width="width"
    >
        <v-card>
            <v-card-title
                class="headline primary white--text"
                primary-title
            >
                {{title}}
            </v-card-title>
            <v-layout
                class="px-5 py-5"
            >
                <v-row
                    v-if="modalType===CONFIRMATION_MODAL"
                    class="mx-2 my-2"
                >
                    <span>{{message}}</span>
                </v-row>
                <v-row
                    v-if="modalType===MULTI_SELECTION_MODAL"
                >
                    <v-col cols="12" md="2" v-for="item in this.headers" :key="item.value">
                        <v-checkbox v-model="selected" :label="item.text" :value="item.value" :disabled="item.text=='セグメント名'||item.text=='広告名'"></v-checkbox>
                    </v-col>
                </v-row>
            </v-layout>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <Button
                    v-if="cancelButton"
                    :buttonType="TEXT_BUTTON"
                    :buttonText="cancelButtonText"
                    color="primary"
                    @onClick="onCancel()"
                />
                <Button
                    :buttonType="RAISED_BUTTON"
                    :buttonText="confirmButtonText"
                    color="primary"
                    @onClick="onConfirmation()"
                />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {
  CONFIRMATION_MODAL,
  MULTI_SELECTION_MODAL
} from '@/components/common/modal/_modaltypes';

import {
    TEXT_BUTTON,
    RAISED_BUTTON
} from '@/components/common/button/_buttontypes';

import Button from "@/components/common/button/Button";

export default {
    props: {
        value : {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "Confirmation"
        },
        message: {
            type: String,
            default: "Are you sure?"
        },
        confirmButtonText: {
            type: String,
            default: "Confirm"
        },
        cancelButtonText: {
            type: String,
            default: "Cancel"
        },
        cancelButton : {
            type: Boolean,
            default: true
        },
        width: {
            type: Number,
            default: 500
        },
        modalType: {
            type: String,
            default: CONFIRMATION_MODAL
        },
        headers: {
            type: Array,
            default(){
                return [];
            }
        },
        selectedArray : {
            type: Array,
            default(){
                return [];
            }
        },
    },
    components: {
        Button
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        selected: {
            get() {
                return this.selectedArray;
            },
            set(newValue) {
                this.$emit('updateSelected', newValue);
            }
        }
    },
    data () {
        return {
            CONFIRMATION_MODAL: CONFIRMATION_MODAL,
            MULTI_SELECTION_MODAL: MULTI_SELECTION_MODAL,
            TEXT_BUTTON: TEXT_BUTTON,
            RAISED_BUTTON: RAISED_BUTTON
        }
    },
    methods: {
        onConfirmation() {
            this.$emit('onConfirmation');
        },
        onCancel() {
            this.$emit('onCancel');
        }
    }
};
</script>