<template>
  <div class="d-flex justify-center align-center">
    <img src="@/assets/images/logo_horizontal.png" class="logo" />
    <!-- <div class="font-weight-bold display-1 primary--text">Gigalogy</div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.logo {
  max-height: 55px;
  vertical-align: middle;
}
</style>