<template>
  <div>
    <v-container fill-height fluid>
      <v-row>
        <v-col class="pa-0" xs="12" md="12">
          <v-card outlined class="mx-auto px-2">
            <v-row>
              <v-col cols="6" md="6">
                <div class="px-2 primary--text font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.wallet.dzt_rewards") }}
                </div>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12">
                <v-tabs v-model="activeTab" show-arrows>
                    <v-tab
                      v-for="tab in tabs"
                      :key="tab.id"
                      :to="tab.route"
                      exact
                    >
                      <v-icon v-if="tab.icon" size="20" class="me-3">
                        {{ tab.icon }}
                      </v-icon>
                      <v-img class="mr-2" v-else :src="tab.img"></v-img>
                      <span>{{ tab.title }}</span>
                    </v-tab>
                  </v-tabs>
              </v-col>
            </v-row>
            <router-view :item="item" :allAssets="allAssets" @openPasscode="openPasscode"></router-view>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import {
  LOAD_ALL_WALLET_ASSET,
} from "@/store/_actiontypes";
export default {
  props: {
    item: {
      type: Object,
    },
  },
  components: {},
  computed: {
    ...mapState({
      allAssets: (state) => state.wallet.allAsset,
    }),
  },
  mounted() {
    if(this.$route.path === "/wallet/rewards"){
      this.$router.push("/wallet/rewards/pending")
    }
    this.allAssets?.length < 1 && this.getAllAssets()
  },
  data() {
    return {
      activeTab: "/wallet/rewards/pending",
      tabs: [
        { 
          id: 0, 
          title: this.$vuetify.lang.t('$vuetify.wallet.rewards_pending'), 
          icon: "pending", 
          route: "/wallet/rewards/pending" },
        {
          id: 1,
          title: this.$vuetify.lang.t('$vuetify.wallet.rewards_claimed'),
          icon: "mdi-bank-check",
          route: "/wallet/rewards/claimed",
        },
      ],
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(val, oldVal) {},
    },
  },
  methods: {
    ...mapActions("wallet", [
      LOAD_ALL_WALLET_ASSET,
    ]),
    openPasscode() {
      this.$emit("openPasscode");
    }, 
    getAllAssets() {
      this.LOAD_ALL_WALLET_ASSET({}).then(
        (res) => {},
        (error) => {}
      );
    }
  },
};
</script>

<style>
.table-bar {
  margin: 5px;
}
.total-cost {
  margin-right: 100px !important;
}
</style>