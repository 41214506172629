var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading || _vm.isDevSolLoading)?_c('Loader',{staticClass:"mr-2 mt-6",attrs:{"loaderType":"card-avatar, article, actions"}}):_c('div',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","xs":"8","md":"6"}},[_c('div',{staticClass:"d-flex justify-center justify-md-start align-center"},[_c('div',{staticClass:"font-weight-medium mr-2"},[_vm._v(" "+_vm._s(`${_vm.$vuetify.lang.t("$vuetify.filter_label_txt")}:`)+" ")]),_c('div',[_c('v-select',{attrs:{"items":_vm.states,"item-text":"state","item-value":"value","persistent-hint":"","hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.solution_status),callback:function ($$v) {_vm.solution_status=$$v},expression:"solution_status"}})],1)])])],1),(
        _vm.solution_status === ''
          ? _vm.allSolutionByDev.length > 0
          : _vm.allSolutionByDev.filter((item) => item.status === _vm.solution_status).length >
            0
      )?_c('v-row',_vm._l((_vm.solution_status === ''
          ? _vm.allSolutionByDev
          : _vm.allSolutionByDev.filter((item) => item.status === _vm.solution_status)),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('SolutionCard',{attrs:{"itemData":item,"isDeveloper":true,"setIsLoading":_vm.toggleIsLoading}})],1)}),1):_c('v-row',{staticClass:"justify-center align-center mt-16"},[_c('h1',[_vm._v(_vm._s(`${_vm.$vuetify.lang.t("$vuetify.no_result_msg_txt")} :(`))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }