import Api from "@/services/api";
import {
  LOAD_ALL_CATEGORY,
  SAVE_CATEGORY,
} from "@/store/_actiontypes";
import {
  SET_ALL_CATEGORY
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
  allCategory: [],
};

const actions = {
  [LOAD_ALL_CATEGORY]({ commit }) {
    updateLoaderTo(true);
    commit(SET_ALL_CATEGORY, []);
    return new Promise((resolve, reject) => {
      Api.get(`categories`).then(
        (response) => {
          // Check if response.data.categories exists and is an Array
          if(response.data && Array.isArray(response.data.categories)) {
            let allCategory = response.data.categories;
            commit(SET_ALL_CATEGORY, allCategory);
          } else {
            throw new Error('Unexpected response format');
          }
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      ).catch((error) => {
        updateLoaderTo(false);
        console.error('Failed to get categories from API', error);
      });
    });
  },
	[SAVE_CATEGORY]({ commit }, {code, name, description}) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`categories`, {
        code: code,
        name: name,
        description: description
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
};

const mutations = {
  [SET_ALL_CATEGORY](state, allCategory) {
    state.allCategory = allCategory;
  }
};

export const category = {
  namespaced: true,
  state,
  actions,
  mutations,
};
