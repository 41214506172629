const reCaptchaPlugin =  {
  install(Vue, options) {
    const recaptchaSiteKey = options.siteKey;
    const scriptId = "recaptcha-script";

    // Load reCaptcha script only once
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.setAttribute(
        "src",
        `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaSiteKey}`
      );
      script.setAttribute("async", true);
      script.setAttribute("defer", true);
      script.setAttribute("id", scriptId);
      document.head.appendChild(script);
      // console.log("captcha script loaded")
    }

    // Add function to Vue prototype so all components can access it
    Vue.prototype.$recaptcha = {
      load() {
        return new Promise((resolve, reject) => {
          if (window.grecaptcha && window.grecaptcha.enterprise) {
            resolve(window.grecaptcha);
          } else {
            const script = document.getElementById(scriptId);
            script.onload = () => {
              if (window.grecaptcha && window.grecaptcha.enterprise) {
                resolve(window.grecaptcha);
              } else {
                reject(new Error("reCAPTCHA failed to load"));
              }
            };
            script.onerror = () => reject(new Error("Failed to load reCAPTCHA script"));
          }
        });
      },
      execute(action) {
        return this.load().then((grecaptcha) =>
          grecaptcha.enterprise.execute(recaptchaSiteKey, {
            action
          })
        ).catch((error) => {
          throw new Error(`reCAPTCHA error: ${error.message}`);
        });
      },
    };
  },
};

export { reCaptchaPlugin };