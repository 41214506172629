<template>
    <ActionButtonGroup
        v-if="groupType===ACTION_BUTTON_GROUP"
        :detailsButton="detailsButton"
        :editButton="editButton"
        :documentButton="documentButton"
        :deleteButton="deleteButton"
        :addButton="addButton"
        :stopButton="stopButton"
        :startButton="startButton"
        :downloadButton="downloadButton"
        :trainButton="trainButton"
        :disabledDetailsButton="disabledDetailsButton"
        :disabledEditButton="disabledEditButton"
        :disabledDeleteButton="disabledDeleteButton"
        :disabledAddButton="disabledAddButton"
        :disabledStartButton="disabledStartButton"
        :disabledStopButton="disabledStopButton"
        :disabledDownloadButton="disabledDownloadButton"
        @onDetailsClick="onDetailsClick()"
        @onEditClick="onEditClick()"
        @onAddClick="onAddClick()"
        @onDocumentClick="onDocumentClick()"
        @onDeleteClick="onDeleteClick()"
        @onStopClick="onStopClick()"
        @onStartClick="onStartClick()"
        @onDownloadClick="onDownloadClick()"
        @onTrainClick="onTrainClick()"
    />
</template>

<script>

import {
    ACTION_BUTTON_GROUP
} from '@/components/common/buttongroup/_buttongrouptypes';

import ActionButtonGroup from "@/components/common/buttongroup/ActionButtonGroup";

export default {
    props: {
        groupType: {
            type: String,
            default: ACTION_BUTTON_GROUP
        },
        detailsButton: {
            type: Boolean,
            default: true
        },
        editButton: {
            type: Boolean,
            default: true
        },
        documentButton: {
            type: Boolean,
            default: false
        },
        deleteButton: {
            type: Boolean,
            default: true
        },
        addButton: {
            type: Boolean,
            default: true
        },
        stopButton: {
            type: Boolean,
            default: true
        },
        startButton: {
            type: Boolean,
            default: true
        },
        downloadButton: {
            type: Boolean,
            default: false
        },
        trainButton:{
            type: Boolean,
            default: false
        },
        disabledDetailsButton: {
            type: Boolean,
            default: false
        },
        disabledEditButton: {
            type: Boolean,
            default: false
        },
        disabledDeleteButton: {
            type: Boolean,
            default: false
        },
        disabledAddButton: {
            type: Boolean,
            default: false
        },
        disabledStartButton: {
            type: Boolean,
            default: false
        },
        disabledStopButton: {
            type: Boolean,
            default: false
        },
        disabledDownloadButton: {
            type: Boolean,
            default: false
        },
        disableTrainButton:{
            type: Boolean,
            default: false
        },
    },
    components: {
        ActionButtonGroup,
    },
    computed: {
        
    },
    data() {
        return {
            ACTION_BUTTON_GROUP: ACTION_BUTTON_GROUP,
        };
    },
    methods: {
        onDetailsClick() {
            this.$emit('onDetailsClick');
        },
        onEditClick() {
            this.$emit('onEditClick');
        },
        onDocumentClick() {
            this.$emit('onDocumentClick');
        },
        onDeleteClick() {
            this.$emit('onDeleteClick');
        },
        onAddClick() {
            this.$emit('onAddClick');
        },
        onStopClick() {
            this.$emit('onStopClick');
        },
        onStartClick() {
            this.$emit('onStartClick');
        },
        onDownloadClick() {
            this.$emit('onDownloadClick');
        },
        onTrainClick(){
            this.$emit('onTrainClick');
        }
    }
};
</script>
