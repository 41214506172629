import PersonalizerApi from "@/services/personalizer_api";
import {
    RECOMMENDER_INSIGHTS, RECOMMENDER_UPDATE_FEEDBACK,
} from '@/store/_actiontypes'
import {
    RECOMMENDER_SET_INSIGHTS,
} from '@/store/_mutationtypes'

import { updateLoaderTo } from "./account";

const state = {
    recommender_insights: {},
}

const actions = {
  [RECOMMENDER_INSIGHTS]({ commit }, { project_id, start_date, end_date }) {
    updateLoaderTo(true);
    commit(RECOMMENDER_SET_INSIGHTS, {});
    return new Promise((resolve, reject) => {
      PersonalizerApi.get(
        `insights?${start_date ? `start_date=${start_date}`: ''}${end_date ? `&end_date=${end_date}` : ''}`,
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(response => {
        if (response.data.detail.response && response.data.detail.response === "No data found"){
          commit(RECOMMENDER_SET_INSIGHTS, {});
        } else if (response.data.detail.response){
          const data = response.data.detail.response;
          commit(RECOMMENDER_SET_INSIGHTS, data);
        }
        updateLoaderTo(false);
        resolve(response);
      }, error => {
        updateLoaderTo(false);
        reject(error);
      })
    })
  },
  [RECOMMENDER_UPDATE_FEEDBACK]({ commit }, { project_id, feedback }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      PersonalizerApi.put(
        `gpt/conversations`,
        {
          updates: [
            {
              conversation_id: feedback.conversation_id,
              message: feedback.message,
              type: feedback.type
            }
          ]
        },
        {
          headers: {
            "project-id": project_id,
          },
        }
      ).then(response => {
        updateLoaderTo(false);
        resolve(response);
      }).catch(error => {
        updateLoaderTo(false);
        reject(error);
      });
    });
  },
}
const mutations = {
    [RECOMMENDER_SET_INSIGHTS](state, data) {
        state.recommender_insights = data;
    },
}
export const insight = {
  namespaced: true,
  state,
  actions,
  mutations
};
