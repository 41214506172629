import Api from "@/services/api";
import { LOAD_ALL_CONTENT, LOAD_SINGLE_CONTENT, SAVE_CONTENT } from "@/store/_actiontypes";
import { SET_ALL_CONTENT, SET_CONTENT } from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
  allContent: [],
  content: "",
};

const actions = {
  [LOAD_ALL_CONTENT]({ commit }, {content_type, identity_type, identity_side}) {
    updateLoaderTo(true);
    commit(SET_ALL_CONTENT, []);
    return new Promise((resolve, reject) => {
      Api.get(`contents/lists?content_type=${content_type}&identity_type=${identity_type}&identity_side=${identity_side}`).then(
        (response) => {
          let allContent = response.data.use_cases;
          commit(SET_ALL_CONTENT, allContent);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_SINGLE_CONTENT]({ commit }, { content_key }) {
    updateLoaderTo(true);
    commit(SET_CONTENT, "");
    return new Promise((resolve, reject) => {
      Api.get(`contents/single?content_key=${content_key}`).then(
        (response) => {
          let content = response.data.url;
          commit(SET_CONTENT, content);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [SAVE_CONTENT]({ commit }, {
    formData ,
    onUploadProgress,
    content_type,
    identity_type = null,
    identity_side = null
  }) {
    updateLoaderTo(true);
    let file = new FormData();
    file.append("file", formData)
    file.append("content_type", content_type)
    file.append("identity_type", identity_type)
    file.append("identity_side", identity_side)
    return new Promise((resolve, reject) => {
      Api.post(`contents`, file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
};

const mutations = {
  [SET_ALL_CONTENT](state, allContent) {
    state.allContent = allContent;
  },
  [SET_CONTENT](state, content) {
    state.content = content;
  }
};

export const upload = {
  namespaced: true,
  state,
  actions,
  mutations,
};
