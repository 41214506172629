<template>
  <div>
    <v-card class="elevation-0">
      <v-card-title class="font-weight-bold">{{
        `${$vuetify.lang.t("$vuetify.details_header_txt")}`
      }}</v-card-title>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.solution_name_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            <p class="pb-4">
              {{ solutions.name }} 
              <span class="pa-1 primary white--text rounded">English</span>
            </p>
            <p class="">
              {{ solutions.name_ja }} 
              <span class="pa-1 primary white--text rounded">日本語</span>
            </p>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.solution_header_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            <v-card flat>
              <v-chip
                class="text-capitalize"
                v-bind:color="solutions.type.color && solutions.type.color"
                label
                outlined
                pill
              >
                {{ solutions.type.name ? solutions.type.name : "N/A" }}
              </v-chip>
            </v-card>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-sheet class="pt-3">
              <p class="text-subtitle-1 font-weight-bold">
                {{ `${$vuetify.lang.t("$vuetify.solution_overview_label_txt")}:` }}
              </p>
            </v-sheet>
            <fieldset v-if="solutions && solutions.overview" class="rounded">
              <legend class="primary white--text rounded py-1 px-2 ml-2">English</legend>
              <show-rich-text-data
                :editorId="'reviewOverview'"
                :editorData="solutions.overview"
              />
            </fieldset>
            <fieldset class="rounded mt-2" v-if="solutions && solutions.overview_ja && solutions.overview_ja != {}">
              <legend class="primary white--text rounded py-1 px-2 ml-2">日本語</legend>
              <show-rich-text-data
                :editorId="'reviewOverviewJa'"
                :editorData="solutions.overview_ja"
              />
            </fieldset>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-sheet class="pt-3">
              <p class="text-subtitle-1 font-weight-bold">
                {{
                  `${$vuetify.lang.t("$vuetify.solution_description_placeholder_txt")}:`
                }}
              </p>
            </v-sheet>
            <fieldset v-if="solutions && solutions.description" class="rounded">
              <legend class="primary white--text rounded py-1 px-2 ml-2">English</legend>
              <show-rich-text-data
                :editorId="'reviewDescription'"
                :editorData="solutions.description"
              />
            </fieldset>
            <fieldset class="rounded mt-2" v-if="solutions && solutions.description_ja">
              <legend class="primary white--text rounded py-1 px-2 ml-2">日本語</legend>
              <show-rich-text-data
                :editorId="'reviewDescriptionJa'"
                :editorData="solutions.description_ja"
              />
            </fieldset>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold align-self-start">
            {{ `${$vuetify.lang.t("$vuetify.industry_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            <div class="d-flex flex-wrap">
              <div
                v-for="(element, index) in solutions.industry"
                :key="index"
                class="pa-1"
              >
                {{ element.name }},
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-sheet class="pt-3">
              <p class="text-subtitle-1 font-weight-bold">
                {{ `${$vuetify.lang.t("$vuetify.use_case_placeholder_txt")}:` }}
              </p>
            </v-sheet>
            <fieldset v-if="solutions && solutions.use_cases" class="rounded">
              <legend class="primary white--text rounded py-1 px-2 ml-2">English</legend>
              <show-rich-text-data
                :editorId="'reviewUseCases'"
                :editorData="solutions.use_cases"
              />
            </fieldset>
            <fieldset class="rounded mt-2" v-if="solutions && solutions.use_cases_ja">
              <legend class="primary white--text rounded py-1 px-2 ml-2">日本語</legend>
              <show-rich-text-data
                :editorId="'reviewUseCasesJa'"
                :editorData="solutions.use_cases_ja"
              />
            </fieldset>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold align-self-start">
            {{ `${$vuetify.lang.t("$vuetify.linked_solution_label_txt_2")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            <div
              v-for="(item, index) in solutions.linked_solution"
              :key="index"
              class="py-2 d-flex align-center"
            >
              <v-icon
                v-bind:color="solutions.type.color && solutions.type.color"
                size="21"
                class="mr-2 align-self-start"
              >
                task_alt
              </v-icon>
              <div>{{ item.name }}</div>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold align-self-start">
            {{ `${$vuetify.lang.t("$vuetify.language_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            <div class="d-flex flex-wrap">
              <div
                v-for="(element, index) in solutions.language"
                :key="index"
                class="pa-1"
              >
                {{ element.name }},
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-card class="elevation-0">
      <v-card-title class="font-weight-bold">
        {{ `${$vuetify.lang.t("$vuetify.pricing_step_txt")}` }}
      </v-card-title>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.cpu_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ solutions.min_cpu ? formatNum(solutions.min_cpu) : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.memory_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ solutions.min_memory ? formatNum(solutions.min_memory) : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.gpu_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ solutions.min_gpu ? formatNum(solutions.min_gpu) : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.benchmark_request_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ solutions.page_view ? formatNum(solutions.page_view) : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.benchmark_rps_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ solutions.rps ? formatNum(solutions.rps) : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.base_price_label_txt_1")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ solutions.price ? formatNum(solutions.price) : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.additional_price_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{
              solutions.additional_price ? formatNum(solutions.additional_price) : "N/A"
            }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-card class="elevation-0">
      <v-card-title class="font-weight-bold">
        {{ `${$vuetify.lang.t("$vuetify.metadata_step_txt")}` }}
      </v-card-title>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content class="font-weight-bold align-self-start">
            {{ `${$vuetify.lang.t("$vuetify.cover_img_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            <div>
              <v-img :src="solutionImg" width="150" height="100"> </v-img>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.version_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ solutions.version ? solutions.version : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.source_code_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ solutions.source_url ? solutions.source_url : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.sandbox_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ solutions.sanbdbox_url ? solutions.sanbdbox_url : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.api_docs_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ solutions.document_url ? solutions.document_url : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.tutorial_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ solutions.tutorial_location ? solutions.tutorial_location : "N/A" }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { formatNum } from "@/helpers/helper";
import ShowRichTextData from "./ShowRichTextData";

export default {
  components: {
    ShowRichTextData,
  },
  props: {
    solutions: {
      type: Object,
      default() {
        return {};
      },
    },
    solutionImg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formatNum,
    };
  },
  methods: {},
};
</script>

<style scoped>
fieldset {
  border: 1px solid gray;
}
</style>