<template>
  <div>
    <Loader
      v-if="isLoading || isDevSolLoading"
      class="mr-2 mt-6"
      loaderType="card-avatar, article, actions"
    />
    <div v-else>
      <v-row class="align-center">
        <v-col cols="12" xs="8" md="6">
          <div class="d-flex justify-center justify-md-start align-center">
            <div class="font-weight-medium mr-2">
              {{ `${$vuetify.lang.t("$vuetify.filter_label_txt")}:` }}
            </div>
            <div>
              <v-select
                v-model="solution_status"
                :items="states"
                item-text="state"
                item-value="value"
                persistent-hint
                hide-details="auto"
                outlined
                dense
              ></v-select>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="
          solution_status === ''
            ? allSolutionByDev.length > 0
            : allSolutionByDev.filter((item) => item.status === solution_status).length >
              0
        "
      >
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          v-for="item in solution_status === ''
            ? allSolutionByDev
            : allSolutionByDev.filter((item) => item.status === solution_status)"
          :key="item.id"
        >
          <SolutionCard
            :itemData="item"
            :isDeveloper="true"
            :setIsLoading="toggleIsLoading"
          />
        </v-col>
      </v-row>
      <v-row v-else class="justify-center align-center mt-16">
        <h1>{{ `${$vuetify.lang.t("$vuetify.no_result_msg_txt")} :(` }}</h1>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/common/loader/Loader";
import SolutionCard from "@/components/Solutions/SolutionCard";

export default {
  props: {
    isDevSolLoading: {
      type: Boolean,
    },
  },
  components: {
    Loader,
    SolutionCard,
  },
  computed: {
    ...mapState({
      allSolutionByDev: (state) => state.solution.allSolutionByDev,
    }),
  },
  data() {
    return {
      isLoading: false,
      states: [
        { state: "All", value: "" },
        { state: "Pending", value: "pending" },
        { state: "Drafted", value: "drafted" },
        { state: "Rejected", value: "rejected" },
        { state: "Published", value: "published" },
      ],
      solution_status: "",
    };
  },
  methods: {
    toggleIsLoading(status) {
      this.isLoading = status;
    },
  },
};
</script>
