import Api from "@/services/api";
import {
  LOAD_ALL_SEGMENT,
  SAVE_SEGMENT,
} from "@/store/_actiontypes";
import {
  SET_ALL_SEGMENT
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
  allSegments: [],
};

const actions = {
  [LOAD_ALL_SEGMENT]({ commit }) {
    updateLoaderTo(true);
    commit(SET_ALL_SEGMENT, []);
    return new Promise((resolve, reject) => {
      Api.get(`segments`).then(
        (response) => {
          let allSegments = response.data.segments;
          commit(SET_ALL_SEGMENT, allSegments);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
	[SAVE_SEGMENT]({ commit }, { name, color, description }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`segments`, {
        name: name,
        color: color,
        description: description,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
};

const mutations = {
  [SET_ALL_SEGMENT](state, allSegments) {
    state.allSegments = allSegments;
  }
};

export const segment = {
  namespaced: true,
  state,
  actions,
  mutations,
};