<template>
  <div>
    <v-row>
      <v-col cols="12" xs="12" md="3">
        <v-select
          v-model="asset_code_id"
          :items="allWalletAssets"
          item-text="asset_code"
          item-value="id"
          placeholder="select asset"
          persistent-hint
          hide-details="auto"
          outlined
          dense
        ></v-select>
      </v-col>
    </v-row>
    <Loader v-if="isLoading" class="mr-2 mt-6" />
    <v-data-table
      v-else
      :headers="headers"
      :items="allRecievedClaimableBalance"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:[`item.created_at`]="{ item }">
        {{
          new Date(item.created_at).toLocaleDateString("en-us", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-chip color="primary" outlined small>{{ item && "Claimed" }}</v-chip>
      </template>
      <template v-slot:[`item.account`]="{ item }">
        {{ passChar(item.account) }}
      </template>
      <template v-slot:[`item.asset`]="{ item }">
        <v-chip
          :pill="true"
          color="white"
        >
          <v-avatar
            left
            size="30"
            :color="
              item.asset === 'native' || allAssets.find((a) => a.asset_code === item.asset.split(':')[0])
                ? 'lighten-2'
                : 'primary lighten-2'
            "
          >
            <v-img
              v-if="
                item.asset === 'native' || allAssets.find((a) => a.asset_code === item.asset.split(':')[0])
              "
              :src="item.asset === 'native' 
              ? allAssets.filter(a => a.asset_code === 'XLM')[0].asset_icon
              : allAssets.filter(a => a.asset_code === item.asset.split(':')[0])[0].asset_icon"
            ></v-img>
            <span v-else class="fix-asset-font">
              {{ item.asset.split(":")[0] }}
            </span>
          </v-avatar>
          {{ item && item.asset === "native" ? "XLM" : item.asset.split(":")[0] }}
        </v-chip>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <Price 
          color="primary"
          prefix="+"
          :amount="item && item.amount"
        />
      </template>
    </v-data-table>
    <v-row>
      <v-col class="py-0" cols="12" md="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" xs="12" sm="3" md="3"></v-col>
      <v-col cols="12" xs="12" sm="6" md="6" class="d-flex justify-center">
        <v-btn
          @click="loadmoreClick()"
          color="primary"
          :loading="loadmore"
          :disabled="disableLoadmore"
        >
          {{ $vuetify.lang.t("$vuetify.common.load_more") }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import Loader from "@/components/common/loader/Loader";
import {
  WALLET_CLAIM_CLAIMABLE_BALANCE,
  ACCESS_WALLET,
  GET_WALLET_CLAIMABLE_BALANCE_RECIEVED_HISTORY,
} from "@/store/_actiontypes";
import { SET_ALL_WALLET_RECIEVED_CLAIMABLE_BALANCE } from "@/store/_mutationtypes";
import {
  timeToNextWalletToken,
} from "@/helpers/helper";
import Price from "@/components/wallet/Price";

export default {
  props: {
    item: {
      type: Object,
    },
    allAssets: {
      type: Array,
    },
  },
  components: {
    Loader,
    Price,
  },
  computed: {
    ...mapState({
      allRecievedClaimableBalance: (state) =>
        state.wallet.allRecievedClaimableBalance,
    }),
  },

  data() {
    return {
      isLoading: false,
      timeToNextWalletToken,
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.common.date'),
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.common.status'),
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.address'),
          align: "start",
          sortable: true,
          value: "account",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.asset'),
          align: "start",
          sortable: false,
          value: "asset",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.amount'),
          align: "start",
          sortable: true,
          value: "amount",
        },
      ],
      items: [],
      page: 1,
      search: "",
      pageCount: 0,
      itemsPerPage: 10,
      rows: [5, 10, 20],
      xlmIcon:
        "https://img.icons8.com/external-black-fill-lafs/144/external-Stellar-cryptocurrency-black-fill-lafs.png",
      dztIcon: "https://doozie.io/img/favicon.png",
      loadmore: false,
      disableLoadmore: false,
      cursor: null,
      asset_code_id: "",
      allWalletAssets: [
        {
          "asset_code": "All",
          "id": "",
        },
        ...this.allAssets
      ]
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(val, oldVal) {
        val.status === "created"
          ? this.SET_ALL_WALLET_RECIEVED_CLAIMABLE_BALANCE([])
          : this.getAllRecievedClaimableBalance()
      },
    },
    asset_code_id() {
      this.getAllRecievedClaimableBalance();
    }
  },
  methods: {
    ...mapActions("wallet", [
      WALLET_CLAIM_CLAIMABLE_BALANCE,
      ACCESS_WALLET,
      GET_WALLET_CLAIMABLE_BALANCE_RECIEVED_HISTORY,
    ]),
    ...mapMutations("wallet", [SET_ALL_WALLET_RECIEVED_CLAIMABLE_BALANCE]),
    getAllRecievedClaimableBalance() {
      this.isLoading = true;
      this.GET_WALLET_CLAIMABLE_BALANCE_RECIEVED_HISTORY({
        public_key: this.item ? this.item.public_key : "",
        asset_code_id: this.asset_code_id ? this.asset_code_id : "",
        cursor: "",
      })
        .then((res) => {
          this.isLoading = false;
          let data = res?.data?.stellar_response;
          this.cursor = res.data?.next_cursor;
          this.disableLoadmore = !!data.length < 1;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    loadmoreClick() {
      if (this.timeToNextWalletToken() < 1) {
        this.$emit("openPasscode");
      } else {
        this.loadmore = true;
        this.GET_WALLET_CLAIMABLE_BALANCE_RECIEVED_HISTORY({
          public_key: this.item ? this.item.public_key : "",
          asset_code_id: this.asset_code_id ? this.asset_code_id : "",
          cursor: this.cursor ? this.cursor : "",
          commit_data: false,
        })
          .then((res) => {
            let prevData = this.allRecievedClaimableBalance;
            let data = res?.data?.stellar_response;
            let combineData = [...prevData, ...data];
            this.SET_ALL_WALLET_RECIEVED_CLAIMABLE_BALANCE(combineData);
            this.cursor = res?.data?.next_cursor;
            this.loadmore = false;
            this.disableLoadmore = !!data.length < 1;
          })
          .catch((err) => {
            this.loadmore = false;
          });
      }
    },
    passChar(str) {
      return `${str.substring(0, 5)}**********${str.substring(
        str.length - 6,
        str.length
      )}`;
    },
  },
};
</script>
<style scoped>
.fix-asset-font {
  font-size: 10px; 
  color:white;
}
</style>