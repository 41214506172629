<template>
  <v-progress-linear
    :active="loading"
    :height="4"
    :indeterminate="true"
    fixed
    color="primary lighten-2"
    :class="{'singlebar': $vuetify.breakpoint.smAndDown, 'doublebar': $vuetify.breakpoint.mdAndUp}"
  ></v-progress-linear>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      loading: state => state.loader.loading
    })
  }
};
</script>
<style scoped>
.singlebar {
  top: 50px;
}

.doublebar {
  top: 50px;
}
</style>