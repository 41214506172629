<template>
    <div>
        <RaisedButton
            v-if="buttonType==RAISED_BUTTON"
            :buttonText="buttonText"
            :color="color"
            :size="size"
            :disabled="disabled"
            :loading="loading"
            @onClick="onClick()"
        />
        <OutlinedButton
            v-if="buttonType==OUTLINED_BUTTON"
            :buttonText="buttonText"
            :color="color"
            :size="size"
            :disabled="disabled"
            :loading="loading"
            @onClick="onClick()"
        />
        <TextButton
            v-if="buttonType==TEXT_BUTTON"
            :buttonText="buttonText"
            :color="color"
            :size="size"
            :disabled="disabled"
            :loading="loading"
            @onClick="onClick()"
        />
        <FloatingActionButton
            v-if="buttonType==FLOATING_ACTION_BUTTON"
            :tooltipText="buttonText"
            :iconName="iconName"
            :color="color"
            :size="size"
            :loading="loading"
            :disabled="disabled"
            @onClick="onClick()"
        />
        <IconButton
            v-if="buttonType==ICON_BUTTON"
            :tooltipText="buttonText"
            :iconName="iconName"
            :color="color"
            :size="size"
            :loading="loading"
            :disabled="disabled"
            @onClick="onClick()"
        />
        <!-- <CSVDownload
            v-if="buttonType==CSV_DOWNLOAD_BUTTON"
            :tooltipText="buttonText"
            :iconName="iconName"
            :color="color"
            :size="size"
            :fileName="fileName"
            :seriesData="seriesData"
            @onClick="onClick()"
        /> -->
    </div>
</template>

<script>

import {
    RAISED_BUTTON,
    OUTLINED_BUTTON,
    TEXT_BUTTON,
    FLOATING_ACTION_BUTTON,
    ICON_BUTTON,
    CSV_DOWNLOAD_BUTTON
} from '@/components/common/button/_buttontypes';

import RaisedButton from "@/components/common/button/RaisedButton";
import OutlinedButton from "@/components/common/button/OutlinedButton";
import TextButton from "@/components/common/button/TextButton";
import FloatingActionButton from "@/components/common/button/FloatingActionButton";
import IconButton from "@/components/common/button/IconButton";
// import CSVDownload from "@/components/common/button/CSVDownload";

export default {
    props: {
        buttonType: {
            type: String,
            default: RAISED_BUTTON
        },
        buttonText: {
            type: String,
            default: "Click"
        },
        iconName: {
            type: String,
            default: "mdi-television"
        },
        color: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: "medium"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        fileName: {
            type: String,
            default: "file.csv"
        },
        seriesData: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    components: {
        RaisedButton,
        OutlinedButton,
        TextButton,
        FloatingActionButton,
        IconButton,
        // CSVDownload
    },
    computed: {
        
    },
    data() {
        return {
            RAISED_BUTTON : RAISED_BUTTON,
            OUTLINED_BUTTON: OUTLINED_BUTTON,
            TEXT_BUTTON: TEXT_BUTTON,
            FLOATING_ACTION_BUTTON: FLOATING_ACTION_BUTTON,
            ICON_BUTTON: ICON_BUTTON,
            CSV_DOWNLOAD_BUTTON: CSV_DOWNLOAD_BUTTON
        };
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        }
    }
};
</script>
