<template>
  <div>
    <Navbar :isProfile="$route.path === '/profile-settings' || $route.path === '/account-settings'">
      <v-container
        pt-2
        :class="{'singleAppbar': $vuetify.breakpoint.smAndDown, 'doubleAppbar': $vuetify.breakpoint.mdAndUp}"
      >
        <v-main class="pt-1 pl-1">
          <v-breadcrumbs :items="crumbs">
            <template v-slot:divider>
              <v-icon>mdi-forward</v-icon>
            </template>
          </v-breadcrumbs>
          <router-view></router-view>
        </v-main>
        <MainFooter />
      </v-container>
    </Navbar>
  </div>
</template>

<script>
import Navbar from "@/components/TheNavbar";
import MainFooter from "@/components/layout/MainFooter.vue";
export default {
  components: {
    Navbar,
    MainFooter,
  },
  computed: {
    crumbs: function() {
      let pathArray = this.$route.path.split("/")
      pathArray.shift()
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        let routeelements = pathArray.slice(0, idx);
        breadcrumbArray.push({
            path: path,
            href: routeelements.length === 0 ? "/" : "/"+ routeelements.join("/") + "/" + path,
            text: idx===0 ? path.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : path,
            disabled: true,
          });
        return breadcrumbArray;
      }, [])
      return breadcrumbs;
    }
  },
};
</script>
<style>
/* scroll bar style */
::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #757575;
}

.form-label label[for] {
  height: 20px;
  font-size: 10pt;
}

.tableHeader {
  height: 20px;
  font-size: 16px !important;
}

.singleAppbar {
  margin-top: 48px;
}

.doubleAppbar {
  margin-top: 10px;
}

.v-select__slot {
  font-size: smaller;
}

.v-text-field__slot {
  font-size: smaller;
}

.v-breadcrumbs {
  padding-top: 58px !important;
  padding-left: 1px !important;
  padding-bottom: 2px !important;
}
@media screen and (max-width: 950px) {
  .v-breadcrumbs {
    padding-top: 10px !important;
  }
}
</style>
