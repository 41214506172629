<template>
    <div 
        :id="editorId" 
        class="read-only text-editor"
    >
        {{ initiateEditor(editorId, editorData) }}
    </div>
</template>

<script>
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Quote from '@editorjs/quote';
import CodeTool from '@editorjs/code';
import RawTool from '@editorjs/raw';
import Underline from '@editorjs/underline';
import Marker from '@editorjs/marker';
import Table from '@editorjs/table';
import SimpleLink from './SimpleLink';
import SimpleImage from './SimpleImage';
import Delimiter from '@editorjs/delimiter';

export default {
    props: {
        editorId: {
            type: String
        },
        editorData: {
            type: [String, Object],
            default() {
                return {};
            }
        },
    },
    data() {
        return {};
    },
    methods: {
        initiateEditor(holderId, data) {

            let readOnlyEditor = null;

            let jsonData = data;

            if (typeof jsonData === "string") {
                jsonData = this.parseJson(jsonData);
            }

            if (holderId && jsonData) {

                setTimeout(function() {

                const editor = document.querySelector(`#${holderId}`);

                if (editor){
                    editor.innerHTML = "";
                }

                readOnlyEditor = new EditorJS({ 
                    /** 
                     * Id of Element that should contain the Editor 
                     */ 
                    holder: holderId,
                    /** 
                     * Available Tools list. 
                     * Pass Tool's class or Settings object for each Tool you want to use 
                     */ 
                    tools: { 
                        header: {
                            class: Header,
                            inlineToolbar : true
                        },
                        list: {
                            class: List,
                            inlineToolbar : true
                        },
                        quote: {
                            class: Quote,
                            inlineToolbar : true
                        },
                        code: {
                            class: CodeTool,
                            inlineToolbar : true
                        },
                        raw: {
                            class: RawTool,
                            inlineToolbar: true
                        },
                        underline: {
                            class: Underline,
                            inlineToolbar : true
                        },
                        marker: {
                            class: Marker,
                            inlineToolbar : true
                        },
                        table: {
                            class: Table,
                            inlineToolbar : true
                        },
                        linkTool: {
                            class: SimpleLink,
                            inlineToolbar : true,
                        },
                        image: {
                            class: SimpleImage,
                            inlineToolbar: true,
                        },
                        delimiter: {
                            class: Delimiter,
                            inlineToolbar: true
                        },
                    },
                    data: jsonData,
                    readOnly: true,
                });
                }, 500);
            }
        },
        parseJson(jsonString) {
            const parsedJson = {};
            if (jsonString) {
                Object.assign(parsedJson, JSON.parse(jsonString));
            }
            return parsedJson;
        },
    },
}
</script>
<style>
.read-only {
    border: none !important;
}
.read-only .codex-editor__redactor {
    padding-bottom: 8px !important;
}
.read-only .ce-block__content, .read-only .ce-toolbar__content {
  color: rgba(0,0,0,0.87);
}
</style>