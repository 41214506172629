<template>
  <div>
    <v-card>
      <v-row justify-space-between>
        <v-col cols="12" xs="12" md="12">
          <Details/>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>


<script>
import Details from "@/components/Member/Details";

export default {
  components: {
    Details
  }
}
</script>

