import Api from "@/services/api";
import {
    ADD_ALERT,
    LOAD_ALL_INVOICE,
    LOAD_INVOICE_BY_ID,
    PAY_INVOICE_BY_ID
} from "@/store/_actiontypes";
import {
    SET_ALL_INVOICE,
    SET_INVOICE_BY_ID
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
    allInvoice: {},
    selectedInvoice: {}
};

const actions = {
  [LOAD_ALL_INVOICE]({ commit }, {start_date, end_date, payment_status, per_page, page}) {
    updateLoaderTo(true);
    commit(SET_ALL_INVOICE, {});
    return new Promise((resolve, reject) => {
      Api.get(`invoices?${start_date ? `start_date=${start_date}`: ''}${end_date ? `&end_date=${end_date}` : ''}${page ? `&page=${page}` : ''}&per_page=${per_page ? per_page : 100}${payment_status ? `&status=${payment_status}` : ''}`).then(
        (response) => {
          let allInvoice = response.data;
          commit(SET_ALL_INVOICE, allInvoice);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_INVOICE_BY_ID]({ commit }, { invoice_id }) {
      updateLoaderTo(true)
      commit(SET_INVOICE_BY_ID, {})
      return new Promise((resolve, reject) => {
          Api.get(`invoices/${invoice_id}`).then(
              response => {
                  let invoice = response.data;
                  commit(SET_INVOICE_BY_ID, invoice)
                  updateLoaderTo(false)
                  resolve(response)
              },
              error => {
                  updateLoaderTo(false)
                  reject(error)
              }
          )
      })
  },
  [PAY_INVOICE_BY_ID]({ commit, dispatch }, { invoice_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`invoices/${invoice_id}/pay`, {
        
      }).then(
        (response) => {
          let jsonResponse = response.data;
          dispatch(
            `alert/${ADD_ALERT}`,
            { message: jsonResponse.message, color: "success" },
            { root: true }
          );
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  }
};

const mutations = {
  [SET_ALL_INVOICE](state, allInvoice) {
    state.allInvoice = allInvoice;
  },
  [SET_INVOICE_BY_ID](state, selectedInvoice) {
      state.selectedInvoice = selectedInvoice;
  }
};

export const invoice = {
  namespaced: true,
  state,
  actions,
  mutations,
};
