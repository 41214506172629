import Api from "@/services/api";
import {
  LOAD_ALL_SOLUTION,
  LOAD_ALL_SOLUTION_BY_TYPE,
  LOAD_SOLUTION_BY_ID,
  LOAD_RELATED_SOLUTIONS_BY_TYPE,
  SAVE_SOLUTION,
  UPDATE_SOLUTION_BY_ID,
  LOAD_ALL_SOLUTION_BY_DEV,
  PUBLISH_SOLUTION_BY_ID,
  DRAFT_SOLUTION_BY_ID,
  LOAD_PUBLIC_SOLUTIONS,
  LOAD_SOLUTION_DETAILS,
  SOLUTION_HAS_PROJECT,
  RESET_SOLUTION_HAS_PROJECT,
  RESET_SELECTED_SOLUTION,
} from "@/store/_actiontypes";
import {
  SET_ALL_SOLUTION,
  SET_ALL_SOLUTION_BY_TYPE,
  SET_SOLUTION_BY_ID,
  SET_RELATED_SOLUTIONS_BY_TYPE,
  SET_ALL_SOLUTION_BY_DEV,
  SET_PUBLIC_SOLUTIONS,
  SET_SOLUTION_DETAILS,
  SET_SOLUTION_HAS_PROJECT,
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
  allSolution: [],
  allSolutionByType: [],
  selectedSolution: {},
  relatedSolutions: [],
  allSolutionByDev: [],
  publicSolutions: [],
  solutionDetails: {},
  solutionHasProject: false,
};

const actions = {
  [LOAD_ALL_SOLUTION]({ commit }, { searchText, type }) {
    updateLoaderTo(true);
    commit(SET_ALL_SOLUTION, []);
    return new Promise((resolve, reject) => {
      Api.get(`solutions?type=${type}`).then(
        (response) => {
          let allSolution = response.data.solutions;
          allSolution.sort(function(a, b){
            return a.solution_order - b.solution_order;
          });
          commit(SET_ALL_SOLUTION, allSolution);
          if (searchText) {
            allSolution = allSolution.filter(
              (el) =>
                el.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            );
            commit(SET_ALL_SOLUTION, allSolution);
          }
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_ALL_SOLUTION_BY_TYPE]({ commit }, { type }) {
    updateLoaderTo(true);
    commit(SET_ALL_SOLUTION_BY_TYPE, []);
    return new Promise((resolve, reject) => {
      Api.get(`solutions?type=${type}`).then(
        (response) => {
          let allSolution = response.data.solutions;
          commit(SET_ALL_SOLUTION_BY_TYPE, allSolution);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_RELATED_SOLUTIONS_BY_TYPE]({ commit }, { type, solution_id }) {
    updateLoaderTo(true);
    commit(SET_RELATED_SOLUTIONS_BY_TYPE, []);
    return new Promise((resolve, reject) => {
      Api.get(`solutions?type=${type}`).then(
        (response) => {
          let relatedSolutions = [];
          response.data.solutions.forEach((element) => {
            if (element.id !== solution_id) {
              relatedSolutions.push(element);
            }
          });
          commit(SET_RELATED_SOLUTIONS_BY_TYPE, relatedSolutions);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_PUBLIC_SOLUTIONS]({ commit }, { searchText = '', type = '', category = '' }) {
    updateLoaderTo(true);
    commit(SET_PUBLIC_SOLUTIONS, []);
    return new Promise((resolve, reject) => {
      let url = 'public/solutions';
      const typeQuery = type ? `type=${type}` : '';
      const categoryQuery = category ? `category=${category}` : '';

      // Determine if we have any query parameters
      if (typeQuery || categoryQuery) {
        url += '?';
      }

      // If we have both type and category
      if (typeQuery && categoryQuery) {
        url += `${typeQuery}&${categoryQuery}`;
      } else {
        // If we only have type or category
        url += `${typeQuery}${categoryQuery}`;
      }
      Api.get(url).then(
        (response) => {
          let pubSolutions = response.data.solutions;
          commit(SET_PUBLIC_SOLUTIONS, pubSolutions);
          if (searchText) {
            pubSolutions = pubSolutions.filter(
              (element) =>
              element.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            );
            commit(SET_PUBLIC_SOLUTIONS, pubSolutions);
          }
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_SOLUTION_BY_ID]({ commit }, { solution_id, localize=true }) {
    updateLoaderTo(true);
    commit(SET_SOLUTION_BY_ID, {});
    return new Promise((resolve, reject) => {
      Api.get(`solutions/${solution_id}?localize=${localize}`).then(
        (response) => {
          let solution = response.data;
          commit(SET_SOLUTION_BY_ID, solution);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_SOLUTION_DETAILS]({ commit }, { solution_id }) {
    updateLoaderTo(true);
    commit(SET_SOLUTION_DETAILS, {});
    return new Promise((resolve, reject) => {
      Api.get(`public/solutions/${solution_id}`).then(
        (response) => {
          let solDetails = response.data;
          commit(SET_SOLUTION_DETAILS, solDetails);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [SAVE_SOLUTION]({ commit }, {
    name,
    name_ja,
    description,
    description_ja,
    overview,
    overview_ja,
    languages,
    cover_image,
    source_version,
    source_location,
    sandbox_location,
    documentation_location,
    tutorial_location,
    max_pv,
    max_rps,
    base_price,
    additional_price,
    min_cpu,
    min_memory,
    min_gpu,
    links,
    use_case,
    use_case_ja,
    status,
    segment_id,
    category_ids
  }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`solutions`, {
        name,
        name_ja,
        description,
        description_ja,
        overview,
        overview_ja,
        languages,
        cover_image,
        source_version,
        source_location,
        sandbox_location,
        documentation_location,
        tutorial_location,
        max_pv,
        max_rps,
        base_price,
        additional_price,
        min_cpu,
        min_memory,
        min_gpu,
        links,
        use_case,
        use_case_ja,
        status,
        segment_id,
        category_ids,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_ALL_SOLUTION_BY_DEV]({ commit }, { searchText, type }) {
    updateLoaderTo(true);
    commit(SET_ALL_SOLUTION_BY_DEV, []);
    return new Promise((resolve, reject) => {
      Api.get(`solutions/developers?type=${type}`).then((response) => {
        let devSolution = response.data.solutions;
        devSolution.sort(function(a, b){
          return a.solution_order - b.solution_order;
        });
        commit(SET_ALL_SOLUTION_BY_DEV, devSolution);
        if (searchText) {
          devSolution = devSolution.filter(
            (el) =>
              el.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
          commit(SET_ALL_SOLUTION_BY_DEV, devSolution);
        }
        updateLoaderTo(false);
        resolve(response);
      }, (error) => {
        updateLoaderTo(false);
        reject(error)
      });
    });
  },
  [PUBLISH_SOLUTION_BY_ID]({ commit }, { solutionId }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`solutions/${solutionId}/publishes`)
      .then((response) => {
        updateLoaderTo(false);
        resolve(response);
      }, (error) => {
        updateLoaderTo(false);
        reject(error);
      });
    })
  },
  [DRAFT_SOLUTION_BY_ID]({ commit }, { solutionId }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`solutions/${solutionId}/drafts`)
      .then((response) => {
        updateLoaderTo(false);
        resolve(response);
      }, (error) => {
        updateLoaderTo(false);
        reject(error);
      });
    })
  },
  [SOLUTION_HAS_PROJECT]({ commit }, { solutionId }) {
    updateLoaderTo(true);
    commit(SET_SOLUTION_HAS_PROJECT, false);
    return new Promise((resolve, reject) => {
      Api.get(`solutions/${solutionId}/project`)
      .then((response) => {
        commit(SET_SOLUTION_HAS_PROJECT, response?.data?.message)
        updateLoaderTo(false);
        resolve(response);
      },
      (error) => {
        updateLoaderTo(false);
        reject(error);
      });
    })
  },
  [RESET_SOLUTION_HAS_PROJECT]({ commit }) {
    commit(SET_SOLUTION_HAS_PROJECT, false);
  },
  [RESET_SELECTED_SOLUTION]({ commit }) {
    commit(SET_SOLUTION_BY_ID, {});
  },
  [UPDATE_SOLUTION_BY_ID]({ commit }, {
    solutionId,
    name,
    name_ja,
    description,
    description_ja,
    overview,
    overview_ja,
    languages,
    cover_image,
    source_version,
    source_location,
    sandbox_location,
    documentation_location,
    tutorial_location,
    max_pv,
    max_rps,
    base_price,
    additional_price,
    min_cpu,
    min_memory,
    min_gpu,
    links,
    use_case,
    use_case_ja,
    status,
    segment_id,
    category_ids
  }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.put(`solutions/${solutionId}`, {
        name,
        name_ja,
        description,
        description_ja,
        overview,
        overview_ja,
        languages,
        cover_image,
        source_version,
        source_location,
        sandbox_location,
        documentation_location,
        tutorial_location,
        max_pv,
        max_rps,
        base_price,
        additional_price,
        min_cpu,
        min_memory,
        min_gpu,
        links,
        use_case,
        use_case_ja,
        status,
        segment_id,
        category_ids,
      })
      .then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      )
    });
  },
};

const mutations = {
  [SET_ALL_SOLUTION](state, allSolution) {
    state.allSolution = allSolution;
  },
  [SET_ALL_SOLUTION_BY_TYPE](state, allSolutionByType) {
    state.allSolutionByType = allSolutionByType;
  },
  [SET_RELATED_SOLUTIONS_BY_TYPE](state, relatedSolutions) {
    state.relatedSolutions = relatedSolutions;
  },
  [SET_SOLUTION_BY_ID](state, selectedSolution) {
    state.selectedSolution = selectedSolution;
  },
  [SET_ALL_SOLUTION_BY_DEV](state, solutionByDev) {
    state.allSolutionByDev = solutionByDev;
  },
  [SET_PUBLIC_SOLUTIONS](state, pubSolutions) {
    state.publicSolutions = pubSolutions;
  },
  [SET_SOLUTION_DETAILS](state, solDetails) {
    state.solutionDetails = solDetails;
  },
  [SET_SOLUTION_HAS_PROJECT](state, solutionHasProject) {
    state.solutionHasProject = solutionHasProject;
  }
};

export const solution = {
  namespaced: true,
  state,
  actions,
  mutations,
};
