import Api from "@/services/api";
import {
  LOAD_REGION_BY_CLOUD_PROVIDER,
  LOAD_REGION_BY_ID

} from "@/store/_actiontypes";
import {
    SET_ALL_REGION,
    SET_REGION_BY_ID
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
    allRegion: [],
    selectedRegion: {}
};

const actions = {
  [LOAD_REGION_BY_CLOUD_PROVIDER]({ commit }, {cloudProvider}) {
    updateLoaderTo(true);
    commit(SET_ALL_REGION, []);
    return new Promise((resolve, reject) => {
      Api.get(`/regions?cloud_provider=${cloudProvider}`).then(
        (response) => {
          let allRegion = response.data.regions;
          commit(SET_ALL_REGION, allRegion);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
    [LOAD_REGION_BY_ID]({ commit }, { regionId }) {
        updateLoaderTo(true)
        commit(SET_REGION_BY_ID, {})
        return new Promise((resolve, reject) => {
            Api.get(`/regions/${regionId}`).then(
                response => {
                    let region = response.data;
                    commit(SET_REGION_BY_ID, region)
                    updateLoaderTo(false)
                    resolve(response)
                },
                error => {
                    updateLoaderTo(false)
                    reject(error)
                }
            )
        })
    }
};

const mutations = {
  [SET_ALL_REGION](state, allRegion) {
    state.allRegion = allRegion;
  },
  [SET_REGION_BY_ID](state, selectedRegion) {
      state.selectedRegion = selectedRegion;
  }
};

export const region = {
  namespaced: true,
  state,
  actions,
  mutations,
};
