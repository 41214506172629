<template>
  <v-dialog v-model="open" :max-width="dialogWidth" persistent>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-2">
        <div>
          <v-data-table 
            :headers="fileGeneratedHistoryHeaders" 
            :items="fileGeneratedHistory"
            :loading="fileGeneratedHistoryLoading" 
            :server-items-length="totalHits" 
            :items-per-page="itemsPerPage"
            :no-data-text="$vuetify.lang.t('$vuetify.no_data_available')"
            :options.sync="options"
            @update:options="getTasks"
            :footer-props="footerProps"
            disable-sort
            >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td>{{ getRequestTime(item.name) }}</td>
                    <td>
                      {{ getDateRange(item.name) }}
                    </td>
                    <td>
                      <v-chip :color="item.status === 'success' ? 'green' : item.status === 'failed' ? 'red' : 'grey'"
                        dark>
                        {{ item.status }}
                      </v-chip>
                    </td>
                    <td>
                      <a v-if="item.status === 'success' && getFileExpiration(getDownloadUrl(item.message))"
                        :href="getDownloadUrl(item.message)">Download</a>
                      <span v-else>N/A</span>
                    </td>
                    <td>
                      {{ item.status === "success" && getFileExpiration(getDownloadUrl(item.message)) ? getFileExpiration(getDownloadUrl(item.message)) : item.status === "pending" ? "N/A" :  "Expired" }}
                    </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn outlined color="error" @click="closeModal">{{
          $vuetify.lang.t("$vuetify.close_btn_txt")
          }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { GET_TASKS_BY_PREFIX } from "@/store/_actiontypes";
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    history_type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      fileGeneratedHistoryLoading: false,
      fileGeneratedHistory: [],
      totalHits: 0,
      itemsPerPage: 5,
      currentPage: 1,
      options: { page: 1, itemsPerPage: 5 },
      footerProps: {'items-per-page-options': [5, 15, 25]},
      fileGeneratedHistoryHeaders: [
        {
          text: this.$vuetify.lang.t(
            "$vuetify.request_date"
          ),
          value: "date",
          align: 'start',
          sortable: 'false'

        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.logs_file_generation.date_range"
          ),
          value: "date_range",
          sortable: "false"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.status_header_txt"),
          value: "status",
          sortable: "false"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.logs_file_generation.file_url"),
          value: "file_url",
          sortable: "false"
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.logs_file_generation.expiration"
          ),
          value: "expiration",
          sortable: "false"
        },
      ],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.account.user,
      selectedProject: (state) => state.project.selectedProject,
      dateRangeText: function () {
        return this.fileDateRange.join(" ~ ");
      },
    }),
    dialogWidth() {
      return window.innerWidth <= 768 ? '95%' : '57%';
    }
  },

  watch: {
    open(newVal) {
      if (newVal === true) {
        this.getFileGeneratedHistory(0, 4);
      }
    },
    pagination: {
      handler() {
        this.getTasks(this.pagination); 
      },
      deep: true
    }
  },

  methods: {
    ...mapActions("task", [GET_TASKS_BY_PREFIX]),
    getTasks({ page, itemsPerPage }){
      this.currentPage = page;
      this.itemsPerPage = itemsPerPage;
      
      const start = (page - 1) * itemsPerPage;
      const size = itemsPerPage;
      this.getFileGeneratedHistory(start, size)
    },
    getFileGeneratedHistory(start, size) {
      this.fileGeneratedHistoryLoading = true;
      const task_prefix =  `conversation_history_download_${this.selectedProject.id}`
      this.GET_TASKS_BY_PREFIX({
        project_id: this.selectedProject.id,
        task_prefix,
        task_status: "all_status",
        start: start,
        size: size,
      })
        .then(
          (response) => {
            this.fileGeneratedHistory = response.data?.detail?.response;
            this.totalHits = response.data?.detail?.total_tasks;
          },
          (error) => {
            console.error(error);
          }
        )
        .finally(() => {
          this.fileGeneratedHistoryLoading = false;
        });
    },

    getRequestTime(task_name){
      const timestampStr = task_name?.split("_").pop();
      const timestamp = parseFloat(timestampStr);
      const date = new Date(timestamp * 1000);
      const formattedDate = date.toLocaleString('en-CA');
      return formattedDate
    },
    getDateRange(task_name){
      const parts = task_name.split("_");
        const startDate = parts.find(part => /^\d{4}-\d{2}-\d{2}$/.test(part));
        const endDate = parts.find((part, index) => 
          /^\d{4}-\d{2}-\d{2}$/.test(part) && part !== startDate
        );
        return `${startDate} ~ ${endDate}`
    },
    getDownloadUrl(message){
      const messageObj = JSON.parse(message)
      return messageObj?.url
    },
    formatDate(date) {
      if (date === "N/A") return "N/A";
      else return new Date(date).toLocaleString("en-CA").split(",")[0];
    },
    getFileExpiration(url){
      const params = new URLSearchParams(url?.split("?")[1]);
      const expiresTimestamp = parseInt(params.get("Expires"), 10);
      const currentTimestamp = Math.floor(Date.now() / 1000); 
      
      if (expiresTimestamp < currentTimestamp) {
        return false; 
      }
      const expiresDate = new Date(expiresTimestamp * 1000);
      const formattedDate = expiresDate.toLocaleString('en-CA');
      return formattedDate;
    },
    isExpired(expiration) {
      if (expiration === "N/A") return false;
      else return new Date(expiration) < new Date();
    },
  },
};
</script>
