<template>
  <div>
    <v-container fill-height fluid>
      <v-row>
        <v-col class="pa-0" xs="12" md="12">
          <v-card outlined class="mx-auto px-2">
            <v-row>
              <v-col xs="12" md="6">
                <div class="px-2 primary--text font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.job.header") }}
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <!-- Date Filtering  -->
              <v-col cols="12" xs="12" md="4">
                <v-menu
                  ref="menuDates"
                  v-model="menuDates"
                  :close-on-content-click="false"
                  :return-value.sync="dates"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      :placeholder="$vuetify.lang.t('$vuetify.daterange_placeholder_txt')"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates"
                    color="primary"
                    no-title
                    scrollable
                    :min="dates[0]"
                    :max="currentDate()"
                    range
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="menuDates = false">
                      {{ $vuetify.lang.t("$vuetify.cancel_btn_txt") }}
                    </v-btn>
                    <v-btn text color="secondary" @click="dates = []">
                      {{ $vuetify.lang.t("$vuetify.clear_btn_txt") }}
                    </v-btn>
                    <v-btn text color="primary" @click="setDates">
                      {{ $vuetify.lang.t("$vuetify.OK_btn_txt") }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" xs="12" md="3">
                <v-select
                  v-model="job_status"
                  :items="states"
                  item-text="state"
                  item-value="value"
                  persistent-hint
                  hide-details="auto"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- integrate after get job data  -->
            <Loader v-if="isLoading" class="mr-2 mt-6" />
            <!-- job here  -->
            <v-data-table
              v-else
              v-model="selected"
              :headers="headers"
              :items="allJob.items"
              :sort-by="['created_at']"
              :sort-desc="[true]"
              :custom-filter="filterOnlyText"
              :search="search"
              hide-default-footer
            >
              <template v-slot:[`item.id`]="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{item.id.split('-')[0]}}</span>
                  </template>
                  <span>{{item.id}}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <span>{{item.name}}</span>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <div v-if="item.created_at !== null">
                  {{ formatDateTime(item.created_at) }}
                </div>
              </template>
              <template v-slot:[`item.updated_at`]="{ item }">
                <div v-if="item.updated_at !== null">
                  {{ formatDateTime(item.updated_at) }}
                </div>
              </template>
              <template v-slot:[`item.exception_details`]="{ item }">
                <div class="font-weight-bold subtitle-1 primary--text">
                  {{item.exception_details}}
                </div>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  class="text-capitalize"
                  outlined
                  small
                  :color="`${item.status === 'success'? 'success' : 'red' }`"
                >
                 {{ item.status }}
                </v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-row>
                  <v-tooltip top v-if="item.status==='failed'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="reRunJobClick(item)"
                      >
                        <v-icon small>mdi-restart</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $vuetify.lang.t("$vuetify.job.re_run") }}
                    </span>
                  </v-tooltip>
                </v-row>
              </template>
            </v-data-table>
            <div v-if="!allJob.total"></div>
            <v-row v-else>
              <v-col class="py-0" cols="12" md="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="3"></v-col>
              <v-col cols="12" xs="12" sm="6" md="6">
                <v-pagination
                  class="text-center"
                  v-model="page"
                  :length="Math.ceil(allJob.total / selectRow)"
                  @input="handlePageChange"
                ></v-pagination>
              </v-col>
              <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
                <v-select
                  v-model="selectRow"
                  :items="rows"
                  hide-details="auto"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-form ref="reRunForm" @submit.prevent="submitReRunJob" lazy-validation>
      <v-dialog v-model="reRunDialog" width="500">
        <v-card>
          <v-card-title class="headline primary white--text" primary-title>
            {{ $vuetify.lang.t("$vuetify.job.re_run_job") }}
          </v-card-title>
          <v-layout class="px-5 py-5">
            <v-row>
              <v-col cols="12" md="12">
                <v-menu
                  ref="reRunDate"
                  v-model="reRunDate"
                  :close-on-content-click="false"
                  :return-value.sync="reRunForm.job_date"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="reRunForm.job_date"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="reRunForm.job_date"
                    type="month"
                    no-title
                    scrollable
                    color="primary"
                    :max="currentDate()"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="red"
                      @click="reRunDate = false"
                    >
                      {{ $vuetify.lang.t("$vuetify.cancel_btn_txt") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.reRunDate.save(reRunForm.job_date)"
                    >
                      {{ $vuetify.lang.t("$vuetify.OK_btn_txt") }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-layout>
          <v-divider></v-divider>
          <v-card-actions>
            <Button
              :buttonType="TEXT_BUTTON"
              :buttonText="$vuetify.lang.t('$vuetify.cancel_btn_txt')"
              color="red"
              @onClick="cancelReRunJob"
            />
            <Button
              :buttonType="RAISED_BUTTON"
              :loading="isSubmitJob"
              :buttonText="$vuetify.lang.t('$vuetify.job.run')"
              color="primary"
              @onClick="submitReRunJob"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { LOAD_ALL_JOB, RERUN_JOB_BY_ID } from "@/store/_actiontypes";
import {
  TEXT,
  DATETIME_PICKER,
} from "@/components/common/input/_inputTypes";
import {
  RAISED_BUTTON,
  TEXT_BUTTON,
} from "@/components/common/button/_buttontypes";
import Button from "@/components/common/button/Button";
import validations from "@/helpers/validations";
import Loader from "@/components/common/loader/Loader";
import { dateTimeToStr } from "@/helpers/helper";


export default {
  props: {
  },
  components: {
    Loader,
    Button
  },
  computed: {
    ...mapState({
      allJob: (state) => state.job.allJob,
      dateRangeText: function() {
        return this.dates.join(" ~ ")
      }
    }),
    ...mapGetters("loader", ["loading"]),
  },
  mounted() {
    this.getAllJob();
  },
  data() {
    return {
      dateTimeToStr,
      TEXT: TEXT,
      RAISED_BUTTON: RAISED_BUTTON,
      TEXT_BUTTON: TEXT_BUTTON,
      DATETIME_PICKER: DATETIME_PICKER,
      isLoading: false,
      reRunDialog: false,
      isSubmitJob: false,
      reRunForm: {
        job_date: ""
      },
      job_id: "",
      search: "",
      start_date: "",
      end_date: "",
      menuDates: false,
      reRunDate: false,
      job_status: "",
      states: [
        {state: "All", value: ""},
        {state: "Pending", value: "pending"},
        {state: "Success", value: "success"},
        {state: "Cancelled", value: "cancelled"},
        {state: "Failed", value: "failed"},
      ],
      dates: [],
      selected: [],
      page: 1,
      selectRow: 10,
      rows: [ 5, 10, 20 ],
      ...validations,
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.id_header_txt"),
          value: "id",
          align: "start",
          sortable: true
        },
        {
          text: this.$vuetify.lang.t("$vuetify.name_header_txt"),
          value: "name",
          align: "start",
          sortable: true
        },
        {
          text: this.$vuetify.lang.t("$vuetify.job.started_at"),
          value: "created_at",
          align: "start",
          sortable: true },
        {
          text: this.$vuetify.lang.t("$vuetify.job.finished_at"),
          value: "updated_at",
          align: "start",
          sortable: true
        },
        {
          text: this.$vuetify.lang.t("$vuetify.job.exception"),
          value: "exception_details",
          align: "start",
          sortable: true
        },
        {
          text: this.$vuetify.lang.t("$vuetify.status_header_txt"),
          value: "status",
          align: "start",
          sortable: true
        },
        {
          text: this.$vuetify.lang.t("$vuetify.actions_header_txt"),
          value: "action",
          align: "start"
        },
      ],
    };
  },
  watch: {
    job_status(){
      if(this.job_status){
        this.page = 1;
      }
      this.getAllJob()
    },
    selectRow(){
      this.page = 1;
      this.getAllJob()
    },
    dates(){
      this.start_date = this.dates[0]
      this.end_date = this.dates[1]
    }
  },
  methods: {
    ...mapActions("job", [LOAD_ALL_JOB, RERUN_JOB_BY_ID]),
    getAllJob(){
      this.isLoading = true;
      if(this.dates.length === 1){
        this.dates[1] = this.currentDate();
        this.end_date = this.currentDate();
      }
      this.LOAD_ALL_JOB({
        start_date: this.start_date ? this.start_date : "",
        end_date: this.end_date ? this.end_date : "",
        job_status: this.job_status,
        per_page: this.selectRow,
        page: this.page,
      }).then((response) => {
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
    },
    handlePageChange(value){
      this.page = value
      this.getAllJob()
    },
    filterOnlyText (value, search, item){
      return search != null &&
        item.subscription.project.name.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
    },
    setDates(){
      this.page = 1;
      this.$refs.menuDates.save(this.dates)
      this.getAllJob()
    },
    formatDateTime(datetime){
      return new Date(datetime).toLocaleDateString(
        "en-us", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      );
    },
    currentDate(){
      return this.dateTimeToStr(new Date(), "yyyy-MM-dd");
    },
    reRunJobClick(item){
      this.reRunDialog = true;
      this.job_id = item.id;
      this.job_name = item.name;
    },
    cancelReRunJob(){
      this.isSubmitJob = false;
      this.reRunDialog = false;
      this.reRunForm.job_date = "";
    },
    submitReRunJob(){
      this.isSubmitJob = true;

      this.RERUN_JOB_BY_ID({
        job_id: this.job_id,
        name: this.job_name,
        year_month: this.reRunForm.job_date ? this.reRunForm.job_date : ""
      }).then((response) => {
        this.isSubmitJob = false;
        this.reRunDialog = false;
        this.reRunForm.job_date = "";
        this.getAllJob();
      }, (error) => {
        this.isSubmitJob = false;
        this.reRunDialog = false;
      });

    },
  },
};
</script>

<style>
.table-bar {
  margin: 5px;
}
</style>
