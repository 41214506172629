<template>
  <v-dialog v-model="dialog" :max-width="dialogWidth" :persistent="true">
    <v-card class="px-5 py-5">
      <div class="d-flex justify-space-between align-center">
        <v-card-title class="title-padding">
          {{$vuetify.lang.t("$vuetify.dataset_download_history")}}
        </v-card-title>
        
      </div>


      <v-divider></v-divider>

      <v-data-table 
        :headers="fileGeneratedHistoryHeaders" 
        :items="items" 
        :items-per-page="5" 
        class="elevation-1"
        :server-items-length="totalItems"
        :options.sync="options"
        @update:options="getTasks"
        :footer-props="footerProps"
        :loading="datasetHistoryLoading"
        disable-sort
      >
      <template #item="{ item }">
        <tr>
          <td>{{ getRequestTime(item.name) }}</td>
          <td>
            <v-chip :color="item.status === 'success' ? 'green' : item.status==='pending' ? 'grey' : 'red'" dark>
              {{ item.status }}
            </v-chip>
          </td>
          <td>
            <a v-if="item.status === 'success' && getFileExpiration(getDownloadUrl(item.message))"  :href="getDownloadUrl(item.message)">Download</a>
            <span v-else >N/A</span>
          </td>
          <td>
            {{ item.status === "success" && getFileExpiration(getDownloadUrl(item.message)) ? getFileExpiration(getDownloadUrl(item.message)) : item.status === "pending" ? "N/A" :  "Expired"}}
          </td>
        </tr>
      </template>

      </v-data-table>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn outlined color="error" @click="onCancel">
          {{$vuetify.lang.t("$vuetify.close_btn_txt")}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import {
  GET_TASKS_BY_PREFIX
} from "@/store/_actiontypes";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datasetId: {
      type: String,
      default: "Confirmation",
    },
    persistent: {
      type: Boolean,
      default: true,
    },

  },
  components: {
  },
  computed: {
    dialogWidth() {
      return window.innerWidth <= 768 ? '95%' : '57%';
    },
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      open: false,
      isLoading: false,
      totalItems: 0, 
      itemsPerPage: 5,
      currentPage: 1,
      options: { page: 1, itemsPerPage: 5 },
      footerProps: {'items-per-page-options': [5, 15, 25]},
      datasetHistoryLoading: false,
      fileGeneratedHistoryHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.request_date"),
          value: "date",
          align: 'start',
        },
        {
          text: this.$vuetify.lang.t("$vuetify.common.status"),
          value: "status",
          align: 'start',
        },
        {
          text: this.$vuetify.lang.t("$vuetify.file_url"),
          value: "file_url",
          align: 'start',
        },
        {
          text: this.$vuetify.lang.t("$vuetify.expiration"),
          value: "expiration",
          align: 'start',
        },
      ],
      items: [
      ]
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal === true) {
        this.getTasksByPrefix(0, 4);
      }
    },
    pagination: {
      handler() {
        this.getTasks(this.pagination); 
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("task", [GET_TASKS_BY_PREFIX]),

    getTasks({ page, itemsPerPage }){
      this.currentPage = page;
      this.itemsPerPage = itemsPerPage;
      
      const start = (page - 1) * itemsPerPage;
      const size = itemsPerPage;
      this.getTasksByPrefix(start, size)
    },
    async getTasksByPrefix(start, size){
      this.datasetHistoryLoading = true
      let task_prefix = `dataset_documents_download_${this.$route.params.dataset_id}`
      this.GET_TASKS_BY_PREFIX({
        project_id: this.$route.params.project_id,
        task_prefix,
        start,
        size
      }).then(
        (response) => {
          this.items = response.data?.detail?.response
          this.totalItems = response.data?.detail?.total_tasks
        },
        (error) => {
          console.error("error", error)
        }
      ).finally(()=> {
        this.datasetHistoryLoading = false
      })
    },
    onCancel() {
      this.dialog = false
    },
    getDownloadUrl(message){
      const messageObj = JSON.parse(message)
      return messageObj?.url
    },
    getRequestTime(task_name){
      const timestampStr = task_name?.split("_").pop();
      const timestamp = parseFloat(timestampStr);
      const date = new Date(timestamp * 1000);
      const formattedDate = date.toLocaleString();
      return formattedDate
    },
    getFileExpiration(url){
      const params = new URLSearchParams(url?.split("?")[1]);
      const expiresTimestamp = parseInt(params.get("Expires"), 10);
      const currentTimestamp = Math.floor(Date.now() / 1000); 
      
      if (expiresTimestamp < currentTimestamp) {
        return false; 
      }
      const expiresDate = new Date(expiresTimestamp * 1000);
      const formattedDate = expiresDate.toLocaleString();
      return formattedDate;
    }
  },
};
</script>
<style scoped>
.title-padding{
  padding-left: 0 !important;
}
</style>