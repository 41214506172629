<template>
  <v-footer app absolute>
    <v-col class="text-center">
      <span>Gigalogy &copy; {{ new Date().getFullYear() }} <span v-if="isTargetUser">事業再構築</span></span>
    </v-col>
  </v-footer>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "MainFooter",
  computed: {
    ...mapGetters("account", ["userProfile"]),
    isTargetUser() {
      return this.userProfile.email === "gaip@gigalogy.com";
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>