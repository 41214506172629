import axios from "axios";
import store from "@/store";

import { ADD_ALERT, TOGGLE_LOADING } from "@/store/_actiontypes";
import { CLEAR_ERROR } from "@/store/_mutationtypes";

import getEnv from "@/config/env";
import { getCurrentUser } from "@/helpers/helper";
import {startTokenRefreshService} from "@/services/tokenRefreshService";

let PersonalizerApi = axios.create({
  withCredentials: false,
  baseURL: getEnv("VUE_APP_PERSONALIZER_API"),
});


PersonalizerApi.interceptors.request.use(
  async (request) => {
    const user = getCurrentUser();

    request.headers["X-Client-Id"] = getEnv("VUE_APP_CLIENT_ID")
    request.headers["X-Client-Secret"] = getEnv("VUE_APP_CLIENT_SECRET")

    if (user && user.access_token) {
      request.headers["Authorization"] = `Bearer ${user.access_token}`;
    }

    if (localStorage.getItem('language')) {
      request.headers["Accept-Language"] = localStorage.getItem('language');
    } else {
      request.headers["Accept-Language"] = 'en-gb';
    }

    await updateLoaderTo(true);
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

PersonalizerApi.interceptors.response.use(
  async (response) => {
    await updateLoaderTo(false);
    store.commit(`account/${CLEAR_ERROR}`);
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        await startTokenRefreshService();
      } catch (refreshError) {
        // Log error if token refresh fails
        console.error("Token refresh failed:", refreshError);
      }
    } else {
      const errorMessage = generateErrorMessage(error);
      if(errorMessage){
        await store.dispatch(
          `alert/${ADD_ALERT}`,
          {message: errorMessage, color: "error"},
          {root: true}
        );
      }
    }
    return Promise.reject(error);
  }
);

const generateErrorMessage = (error) => {
  let errormessage = error.response && error.response.data.message
    ? error.response.data.message
    : error.message;

  if (error.response && error.response.status === 422) {
    if (error.response.data.errors) {
      errormessage = error.response.data.errors
        .map(value => value.toString())
        .join(' ');
    }
  }

  errormessage = [
    "Request aborted",
    "The token has expired.",
    "The token has been revoked.",
    "Destination Account either does not exist or is not activated yet.",
    "宛先アカウントが存在しないか、まだアクティブ化されていません。"
  ].includes(errormessage) ? "" : errormessage;

  return errormessage;
};

let updateLoaderTo = (loading) => {
  return store.dispatch(
    `loader/${TOGGLE_LOADING}`,
    { loading: loading },
    { root: true }
  );
};

export default PersonalizerApi;
