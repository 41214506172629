import {
  startTokenRefreshService,
  stopTokenRefreshService
} from "@/services/tokenRefreshService";

const TokenRefreshPlugin = {
  install(Vue) {
    Vue.mixin({
      created() {
        // Start the token refresh service when the root component is created
        if (this.$root === this) {
          (async () => {
            try {
              await startTokenRefreshService();
              console.log("Token refresh service started successfully.");
            } catch (error) {
              console.error("Failed to start token refresh service:", error);
            }
          })();
        }
      },
      beforeDestroy() {
        // Stop the token refresh service when the root component is destroyed
        if (this.$root === this) {
          stopTokenRefreshService();
        }
      }
    });
  }
};

export default TokenRefreshPlugin;
