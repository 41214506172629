import Vue from "vue";
import Router from "vue-router";
import { Role } from "@/helpers/role";
import { authorize, getCurrentUser } from "@/helpers/helper";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import HomePage from "@/views/HomePage.vue";
import Dashboard from "@/views/Dashboard.vue";
import Solutions from "@/views/Solutions.vue";
import PublicSolutions from "@/views/PublicSolutions";
import SolutionDetails from "@/views/SolutionDetails.vue";
import PublicSolutionDetails from "@/views/PublicSolutionDetails";
import Projects from "@/views/Project.vue";
import ProjectCreate from "@/views/ProjectCreate.vue";
import ProjectDetails from "@/views/ProjectDetails.vue";
import DatasetCreate from "@/views/DatasetCreate.vue";
import DatasetUpdate from "@/views/DatasetUpdate.vue";
import GptDocumentsView from "@/views/GptDocumentsView.vue";
import Settings from "@/views/Settings.vue";
import MyProfile from "@/views/MyProfile.vue";
import PageNotFound from "@/views/PageNotFound.vue";
// import Invoices from "@/views/Invoices.vue";
import RegisterSolution from "@/views/RegisterSolution";
import VerifyEmail from "@/views/VerifyEmail.vue";
import CompleteRegister from "@/views/CompleteRegister.vue";
// import InvoiceDetails from "@/views/InvoiceDetails.vue";
import RegistrationSuccess from "@/views/RegistrationSuccess.vue";
import Wallet from "@/views/Wallet.vue";
import AddAccount from "@/views/AddAccount.vue";
// import Payment from "@/views/Payment.vue";
import Jobs from "@/views/Jobs.vue";
import Members from "@/views/Members.vue";
import MemberDetails from "@/views/MemberDetails.vue";
import jwt_decode from "jwt-decode";
// wallet
import WalletDetails from "@/components/wallet/Details";
import WalletSendSwap from "@/components/wallet/SendSwap";
import WalletBuy from "@/components/wallet/Buy";
import TransactionHistory from "@/components/wallet/TransactionHistory";
import WalletRewards from "@/components/wallet/Rewards";
import WalletRewardsPending from "@/components/wallet/Rewards-pending";
import WalletRewardsClaimed from "@/components/wallet/Rewards-claimed";
import getEnv from "@/config/env";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: HomePage,
      children: [
        //HomePage's <router-view>
        {
          path: "/setup-accounts",
          component: CompleteRegister,
          meta: { auth: [], title: 'Account - Setup | Gigalogy' }
        },
        {
          path: "/register-solutions",
          component: RegisterSolution,
          meta: { auth: [Role.DEVELOPER], title: 'Solution - Register | Gigalogy' }
        },
        // {
        //   path: "/invoices",
        //   component: Invoices,
        //   meta: { auth: [Role.ADMIN, Role.USER], title: 'Invoice | Gigalogy' }
        // },
        // {
        //   path: "/invoices/:invoice_id",
        //   component: InvoiceDetails,
        //   meta: { auth: [Role.ADMIN, Role.USER], title: 'Invoice - Details | Gigalogy' }
        // },
        {
          path: "/dashboard",
          component: Dashboard,
          name: 'dashboard',
          meta: { auth: [], title: 'Dashboard | Gigalogy' }
        },
        {
          path: "/solutions",
          component: Solutions,
          name: 'solutions',
          props: (route) => ({
            role: route.query.role,
          }),
          meta: { auth: [], title: 'Solution | Gigalogy' }
        },
        {
          path: "/solutions/:solution_id",
          component: SolutionDetails,
          name: 'solution_details',
          meta: { auth: [], title: 'Solution - Details | Gigalogy' }
        },
        {
          path: "/update-solutions/:solution_id",
          component: RegisterSolution,
          meta: { auth: [Role.DEVELOPER], title: 'Solution - Update | Gigalogy' }
        },
        {
          path: "/projects",
          component: Projects,
          meta: { auth: [Role.ADMIN, Role.USER], title: 'Project | Gigalogy' }
        },
        {
          path: "/projects/:project_id",
          component: ProjectDetails,
          meta: { auth: [Role.ADMIN, Role.USER], title: 'Project - Details | Gigalogy' }
        },
        {
          path: "/create-projects/:solution_id",
          component: ProjectCreate,
          meta: { auth: [Role.ADMIN], title: 'Project - Create | Gigalogy' }
        },
        {
          path: "/create-projects",
          component: ProjectCreate,
          meta: { auth: [Role.ADMIN], title: 'Project - Create | Gigalogy' }
        },
        {
          path: "/update-projects/:project_id",
          component: ProjectCreate,
          meta: { auth: [Role.ADMIN], title: 'Project - Update | Gigalogy' }
        },
        {
          path: "/account-settings",
          component: Settings,
          meta: { auth: [], title: 'Settings - Account | Gigalogy' }
        },
        {
          path: "/profile-settings",
          component: MyProfile,
          meta: { auth: [], title: 'Settings - Profile | Gigalogy' }
        },
        {
          path: "projects/:project_id/create-dataset",
          component: DatasetCreate,
          meta: { auth: [Role.ADMIN], title: 'Project - Create Dataset | Gigalogy' }
        },
        {
          path: "projects/:project_id/update-dataset/:dataset_id",
          component: DatasetUpdate,
          meta: { auth: [Role.ADMIN], title: 'Project - Update Dataset | Gigalogy' }
        },
        {
          path: "projects/:project_id/datasets/:dataset_id/documents",
          component: GptDocumentsView,
          meta: { auth: [Role.ADMIN], title: 'Project - Update Dataset | Gigalogy' }
        },
        {
          path: "/",
          component: Dashboard,
          meta: { auth: [], title: 'Solution | Gigalogy' }
        },
        // {
        //   path: "/invoices/:invoice_id/pay",
        //   component: Payment,
        //   meta: { auth: [Role.ADMIN, Role.USER], title: 'Payment | Gigalogy' }
        // },
        {
          path: "/jobs",
          component: Jobs,
          meta: { auth: [Role.SUPPORT, Role.SUPER], title: 'Jobs | Gigalogy' }
        },
        {
          path: "/members",
          component: Members,
          meta: { auth: [Role.ADMIN, Role.USER], title: 'Members | Gigalogy' }
        },
        {
          path: "/members/:member_id",
          component: MemberDetails,
          meta: { auth: [Role.ADMIN, Role.USER], title: 'Member - Details | Gigalogy' }
        },
        {
          path: "/wallet",
          component: Wallet,
          meta: { auth: [Role.ADMIN, Role.USER], title: 'Wallet | Gigalogy' },
          props: true,
          children: [
            {
              path: "details",
              component: WalletDetails,
              meta: { auth: [Role.ADMIN, Role.USER], title: 'Wallet | Gigalogy' },
            },
            {
              path: "send-swap",
              component: WalletSendSwap,
              meta: { auth: [Role.ADMIN, Role.USER], title: 'Wallet | Gigalogy' },
            },
            {
              path: "buy",
              component: WalletBuy,
              meta: { auth: [Role.ADMIN, Role.USER], title: 'Wallet | Gigalogy' },
            },
            {
              path: "transactions",
              component: TransactionHistory,
              meta: { auth: [Role.ADMIN, Role.USER], title: 'Wallet | Gigalogy' },
            },
            {
              path: "rewards",
              component: WalletRewards,
              meta: { auth: [Role.ADMIN, Role.USER], title: 'Wallet | Gigalogy' },
              props: true,
              children: [
                {
                  path: "pending",
                  component: WalletRewardsPending,
                  meta: { auth: [Role.ADMIN, Role.USER], title: 'Wallet | Gigalogy' },
                },
                {
                  path: "claimed",
                  component: WalletRewardsClaimed,
                  meta: { auth: [Role.ADMIN, Role.USER], title: 'Wallet | Gigalogy' },
                }
              ]
            },
          ]
        },
        {
          path: "/add-account",
          component: AddAccount,
          meta: { auth: [Role.ADMIN, Role.USER], title: 'Wallet | Gigalogy' }
        },
      ],
      meta: { auth: [] }
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: { title: 'Login | Gigalogy' }
    },
    {
      path: "/register",
      component: Register,
      meta: { title: 'Account - Register | Gigalogy' }
    },
    {
      path: "/register-success/:email",
      component: RegistrationSuccess,
      meta: { title: 'Account - Register Successful | Gigalogy' }
    },
    {
      path: "/forgot-password",
      component: ForgotPassword,
      meta: { title: 'Forgot Password | Gigalogy' }
    },
    {
      path: "/reset-password/:token",
      component: ResetPassword,
      meta: { title: 'Reset Password | Gigalogy' }
    },
    {
      path: "/verify/:token/:verify_method",
      component: VerifyEmail,
      meta: { title: 'Account - Verify | Gigalogy' }
    },
    {
      path: "/public/solutions",
      component: PublicSolutions,
      meta: { title: 'Public Solutions | Gigalogy' }
    },
    {
      path: "/public/solutions/:solution_id",
      component: PublicSolutionDetails,
      meta: { title: 'Public Solution - Details | Gigalogy' }
    },
    // otherwise redirect to home
    {
      path: "*",
      component: PageNotFound,
      meta: { title: 'Page Not Found | Gigalogy' }
    },
    {
      path: "/404",
      component: PageNotFound,
      meta: { title: 'Page Not Found | Gigalogy' }
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

function addDynamicPageTitle(to, from, next) {
  if (typeof to !== 'object' || !Array.isArray(to.matched) || typeof from !== 'object' || !Array.isArray(from.matched)) {
    throw new TypeError("'to' and 'from' must be objects with 'matched' property of type Array");
  }

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  const result = typeof next === 'function' ? next() : Promise.resolve();
  return result.then(() => applyMetaTags(nearestWithMeta));
}

function applyMetaTags(nearestWithMeta) {
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if (!nearestWithMeta) {
    return;
  }

  const createdTags = nearestWithMeta.meta.metaTags
    .filter(tagDef => !document.querySelector(`meta[name="${tagDef.name}"]`))
    .map(tagDef => createMetaTag(tagDef));

  createdTags.forEach(tag => document.head.appendChild(tag));
}

function createMetaTag(tagDef) {
  const tag = document.createElement('meta');
  Object.keys(tagDef).forEach(key => {
    if (tagDef[key] !== undefined) {
      tag.setAttribute(key, tagDef[key]);
    }
  });

  tag.setAttribute('data-vue-router-controlled', '');
  return tag;
}

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { auth } = to.meta;
  const currentUser = getCurrentUser();

  // if already logged in redirect to homepage
  if(currentUser && to.path === '/login'){
    return next({ path: '/' });
  }

  if(currentUser && to.path === '/public/solutions'){
    return next({ path: '/' });
  }

  if (getEnv("VUE_APP_WALLET_ENABLED") === "false" && to.path.startsWith("/wallet")){
    return next({path: "/"});
  }

  if(!currentUser && (to.name === 'solutions' || to.name === 'solution_details')){
    return next({ path: to.path.replace('solutions', 'public/solutions') });
  }

  if (auth) {
    if (!currentUser) {
      // not logged in so redirect to landing page for guest user with the return url
      return next({ path: '/public/solutions' });
    }
    // checking user status
    const parsedJWTJson = jwt_decode(currentUser.access_token);
    //@TODO user identity status check will be integrated here soon
    if (parsedJWTJson["ust"] === "created" || parsedJWTJson["ast"] === "created") {
      // checking to avoid loop
      if (to.path === '/setup-accounts') return next();
      next({path: '/setup-accounts'});
    }
    else {
      next();
    }
    // check if route is restricted by role
    if(!authorize(auth)){
      // role not authorised so redirect to not found page
      return next({ path: '/404' });
    }
  }

  next();
});

router.afterEach((to, from, next) => {
  // Function for adding dynamic page title
  addDynamicPageTitle(to, from, next);
})

export default router;
