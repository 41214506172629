const localUser = localStorage.getItem("wallet_account");
import { deleteCurrentWalletUser } from "@/helpers/helper";
import store from "@/store";
import Api from "@/services/api";
import router from "@/router/index";
import {
  CHECK_WALLET_PASSCODE,
  REGISTER_WALLET_PASSCODE,
  ACCESS_WALLET,
  WALLET_REVOKE,
  CHECK_BALANCE,
  ADD_ALERT,
  ADD_WALLET_ACCOUNT,
  LOAD_ALL_WALLET_ACCOUNT,
  WALLET_BUY_ASSET,
  BUY_AUTHORIZE,
  LOAD_ALL_WALLET_TRANSACTION,
  GET_WALLET_ACCOUNT_DETAILS,
  WALLET_CREATE_ACCOUNT_FROM_SOURCE,
  WALLET_CREATE_ACCOUNT_WITHOUT_SOURCE,
  WALLET_CHECK_QUOTE,
  WALLET_SWAP_ASSET,
  WALLET_SEND_ASSET,
  GET_WALLET_TRANSACTION_DETAILS,
  GET_WALLET_SWAP_RATE,
  EDIT_WALLET_ACCOUNT,
  REMOVE_WALLET_ACCOUNT,
  GET_WALLET_TRANSACTION,
  GET_WALLET_CLAIMABLE_BALANCE,
  WALLET_CLAIM_CLAIMABLE_BALANCE,
  GET_WALLET_CLAIMABLE_BALANCE_RECIEVED_HISTORY,
  WALLET_SECRET_KEY_SAVED_CONSENT,
  LOAD_WALLET_BUY_HISTORY_BY_ACCOUNT,
  LOAD_WALLET_REWARD_PERCENTAGES,
  LOAD_WALLET_APPLICABLE_REWARD_INFO,
  WALLET_SHOW_SECRET_KEY,
  LOAD_ALL_WALLET_ASSET,
  UPDATE_WALLET_WYRE_ORDER,
} from "@/store/_actiontypes";
import {
  SET_ALL_WALLET_ACCOUNT,
  SET_ALL_WALLET_TRANSACTION,
  SET_WALLET_ACCOUNT_DETAILS,
  SET_ALL_WALLET_CLAIMABLE_BALANCE,
  SET_ALL_WALLET_RECIEVED_CLAIMABLE_BALANCE,
  SET_WALLET_STELLAR_TRANSACTION,
  SET_WALLET_SELECTED_ACC,
  SET_WALLET_BUY_HISTORY_BY_ACCOUNT,
  SET_ALL_WALLET_ASSET,
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";


const state = {
  showModal: false,
  allAccount: [],
  balance: {},
  allTransaction: [],
  accountDetails: null,
  stellarTransaction: [],
  allClaimableBalance: [],
  allRecievedClaimableBalance: [],
  allBuyHistoryByAccount: {},
  allAsset: [],
  selected_acc: localUser === "undefined" || localUser === "null" ? null : JSON.parse(localUser)
};

const actions = {
  [CHECK_WALLET_PASSCODE]({ dispatch, commit }, {  }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get("/wallet/passcode/check").then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [ACCESS_WALLET]({ dispatch, commit }, { passcode }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post("/wallet/access", {
        passcode: passcode
      }).then(
        (response) => {
          let res_data = response.data;
          localStorage.setItem("wallet_user", JSON.stringify(res_data));
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [WALLET_REVOKE]({ dispatch, commit }, {  }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post("/wallet/revoke").then(
        (response) => {
          deleteCurrentWalletUser();
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          deleteCurrentWalletUser();
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_ALL_WALLET_ACCOUNT]({ dispatch, commit }, {  }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`/wallet/accounts`).then(
        (response) => {
          commit(SET_ALL_WALLET_ACCOUNT, response.data.accounts);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_ALL_WALLET_TRANSACTION]({ dispatch, commit }, { start_date, end_date, type, status, per_page, page, wallet_account }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`/wallet/transactions?${start_date ? `start_date=${start_date}`: ''}${end_date ? `&end_date=${end_date}` : ''}${page ? `&page=${page}` : ''}&per_page=${per_page ? per_page : 100}${status ? `&status=${status}` : ''}${type ? `&type=${type}` : ''}${wallet_account ? `&wallet_account=${wallet_account}` : ''}`).then(
        (response) => {
          commit(SET_ALL_WALLET_TRANSACTION, response.data.transactions);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [ADD_WALLET_ACCOUNT]({ dispatch, commit }, { body_json }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post("/wallet/accounts/add", body_json).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [WALLET_CREATE_ACCOUNT_FROM_SOURCE]({ dispatch, commit }, { body_json }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post("/wallet/accounts/create_from_source", body_json).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [WALLET_CREATE_ACCOUNT_WITHOUT_SOURCE]({ dispatch, commit }, { body_json }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post("/wallet/accounts/create", body_json).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [CHECK_BALANCE]({ commit }, {public_key}) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`/wallet/accounts/balance`, {
        public_key
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [WALLET_CHECK_QUOTE]({ commit }, {body_json}) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`/wallet/quote`, body_json).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [WALLET_SWAP_ASSET]({ commit }, {body_json, public_key}) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`/wallet/accounts/${public_key}/swap`, body_json).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [WALLET_SEND_ASSET]({ commit }, {body_json, public_key}) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`/wallet/accounts/${public_key}/send`, body_json).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [REGISTER_WALLET_PASSCODE](
    { commit, dispatch },
    { passcode }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post("/wallet/passcode/register", {
        passcode: passcode,
      })
        .then((response) => {
          let res_data = response.data;
          localStorage.setItem("wallet_user", JSON.stringify(res_data));
          updateLoaderTo(false);
          dispatch(
            `alert/${ADD_ALERT}`,
            {
              message: res_data.message,
              color: "success"
            },
            { root: true }
          );
          resolve(response);
        },(error) => {
          updateLoaderTo(false);
          reject(error);
        })
    });
  },
  [WALLET_BUY_ASSET]({ dispatch, commit }, { public_key, source_currency, buy_amount }) {
    updateLoaderTo(true)
    return new Promise((resolve, reject) => {
      Api.post(`/wallet/accounts/${public_key}/buy/wyre-reserve`, {
        source_currency: source_currency,
        buy_amount: buy_amount
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response)
        },
        (error) => {
          updateLoaderTo(false)
          reject(error)
        }
      )
    })
  },
  [BUY_AUTHORIZE]({ dispatch, commit }, { txn_id, sms, card2fa, passcode }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`/wallet/transactions/${txn_id}/authorize`, {
        sms: sms,
        card2fa: card2fa,
        passcode: passcode
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_WALLET_TRANSACTION_DETAILS]({ dispatch, commit }, { txn_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`/wallet/transactions/${txn_id}`)
      .then(
        (response) => {
          updateLoaderTo(false)
          resolve(response)
        },
        (error) => {
          updateLoaderTo(false)
          reject(error)
        }
      )
    })
  },
  [GET_WALLET_ACCOUNT_DETAILS]({ dispatch, commit }, { public_key }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`/wallet/accounts/${public_key}`)
      .then(
        (response) => {
          commit(SET_WALLET_ACCOUNT_DETAILS, response?.data?.details);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    })
  },
  [GET_WALLET_SWAP_RATE]({ dispatch, commit}, { body_json}) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`/wallet/swap-rate`, body_json)
      .then((response) => {
        updateLoaderTo(false);
        resolve(response)
      },
      (error) => {
        updateLoaderTo(false)
        reject(error)
      }
      )
    })
  },
  [EDIT_WALLET_ACCOUNT]({ dispatch, commit }, { public_key, account_name, passcode }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.put(`/wallet/accounts/${public_key}/edit`, { account_name, passcode })
      .then(
        (response) => {
          updateLoaderTo(false);
          dispatch(
            `alert/${ADD_ALERT}`,
            { message: "Wallet account has been updated successfully!", color: "success" },
            { root: true }
          );
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      )
    })
  },
  [REMOVE_WALLET_ACCOUNT]({ dispatch, commit }, { public_key, passcode }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`/wallet/accounts/${public_key}/delete`, { passcode })
      .then(
        (response) => {
        updateLoaderTo(false);
        dispatch(
          `alert/${ADD_ALERT}`,
          { message: "Wallet account has been removed successfully!", color: "success" },
          { root: true }
        );
        resolve(response);
      },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      )
    });
  },

  [GET_WALLET_TRANSACTION] ({ dispatch, commit }, { public_key, limit, cursor, commit_data=true }) {
    updateLoaderTo(true)
    return new Promise((resolve, reject) => {
      Api.get(`/wallet/accounts/${public_key}/transactions?limit=${limit}&cursor=${cursor}`)
        .then(
          (response) => {
            updateLoaderTo(false)
            commit_data && commit(SET_WALLET_STELLAR_TRANSACTION, response.data.stellar_response)
            resolve(response)
        },
          (error) => {
            updateLoaderTo(false)
            commit(SET_WALLET_STELLAR_TRANSACTION, [])
            reject(error)
          }
        )
    })
  },
  [GET_WALLET_CLAIMABLE_BALANCE] ({ dispatch, commit }, { asset_code_id, public_key, limit, cursor, commit_data=true }) {
    updateLoaderTo(true)
    return new Promise((resolve, reject) => {
      Api.get(`/wallet/accounts/${public_key}/claimable-balance?limit=${limit}&cursor=${cursor}&asset_code_id=${asset_code_id ? asset_code_id : ""}`)
        .then(
          (response) => {
            updateLoaderTo(false)
            commit_data && commit(SET_ALL_WALLET_CLAIMABLE_BALANCE, response.data.claimable_balances)
            resolve(response)
          },
          (error) => {
            updateLoaderTo(false)
            commit(SET_ALL_WALLET_CLAIMABLE_BALANCE, [])
            reject(error)
          }
        )
    })
  },
  [WALLET_CLAIM_CLAIMABLE_BALANCE]({ dispatch, commit }, { body_json, public_key }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(
        `/wallet/accounts/${public_key}/claim-claimable-balance`,
        body_json
      ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_WALLET_CLAIMABLE_BALANCE_RECIEVED_HISTORY]({ dispatch, commit }, { public_key, asset_code_id, cursor, commit_data=true }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`/wallet/accounts/${public_key}/claimed-balance?asset_code_id=${asset_code_id ? asset_code_id : ""}&cursor=${cursor}`)
      .then(
        (response) => {
          updateLoaderTo(false);
          commit_data && commit(SET_ALL_WALLET_RECIEVED_CLAIMABLE_BALANCE, response.data.stellar_response)
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          commit(SET_ALL_WALLET_RECIEVED_CLAIMABLE_BALANCE, [])
          reject(error);
        }
      )
    })
  },
  [WALLET_SECRET_KEY_SAVED_CONSENT]({ dispatch, commit }, { public_key }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(
        `/wallet/accounts/${public_key}/secret-saved-consent`
        ).then(
        (response) => {
          updateLoaderTo(false)
          dispatch(
            `alert/${ADD_ALERT}`,
            { message: response.data.message, color: "success" },
            { root: true }
          );
          dispatch(LOAD_ALL_WALLET_ACCOUNT, {}).then((res) => {
            resolve(response)
          })
        },
        (error) => {
          updateLoaderTo(false)
          reject(error)
        }
      )
    })
  },
  [LOAD_WALLET_BUY_HISTORY_BY_ACCOUNT]({ commit }, {public_key, per_page, page}) {
    updateLoaderTo(true);
    commit(SET_WALLET_BUY_HISTORY_BY_ACCOUNT, {});
    return new Promise((resolve, reject) => {
      Api.get(`/wallet/accounts/${public_key}/buy-history?${page ? `&page=${page}` : ''}&per_page=${per_page ? per_page : 10}`).then(
        (response) => {
          let allBuyHistoryByAccount = response.data;
          commit(SET_WALLET_BUY_HISTORY_BY_ACCOUNT, allBuyHistoryByAccount);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_WALLET_REWARD_PERCENTAGES]({ dispatch, commit}, { }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`/wallet/reward-percentages`)
      .then((response) => {
        updateLoaderTo(false);
        resolve(response)
      },
      (error) => {
        updateLoaderTo(false)
        reject(error)
      }
      )
    })
  },
  [LOAD_WALLET_APPLICABLE_REWARD_INFO]({ dispatch, commit}, { public_key }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`/wallet/accounts/${public_key}/reward-eligibility`)
      .then((response) => {
        updateLoaderTo(false);
        resolve(response)
      },
      (error) => {
        updateLoaderTo(false)
        reject(error)
      }
      )
    })
  },
  [WALLET_SHOW_SECRET_KEY]({ dispatch, commit }, { public_key, body_json }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(
        `/wallet/accounts/${public_key}/show-secret`, body_json
      ).then(
        (response) => {
          updateLoaderTo(false)
          resolve(response)
        },
        (error) => {
          updateLoaderTo(false)
          reject(error)
        }
      )
    })
  },
  [LOAD_ALL_WALLET_ASSET]({ dispatch, commit }, {}) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(
        `/wallet/supported-assets`
      ).then(
        (response) => {
          commit(SET_ALL_WALLET_ASSET, response.data.assets)
          updateLoaderTo(false)
          resolve(response)
        },
        (error) => {
          commit(SET_ALL_WALLET_ASSET, [])
          updateLoaderTo(false)
          reject(error)
        }
      )
    })
  },
  [UPDATE_WALLET_WYRE_ORDER]({ dispatch, commit }, { public_key, order_id }) {
    updateLoaderTo(true)
    return new Promise((resolve, reject) => {
      Api.put(`/wallet/accounts/${public_key}/wyre-order`, { order_id }).then(
        (response) => {
          updateLoaderTo(false)
          resolve(response)
        },
        (error) => {
          updateLoaderTo(false)
          reject(error)
        }
      )
    })
  },
};

const mutations = {
  [SET_ALL_WALLET_ACCOUNT](state, allAccount) {
    state.allAccount = allAccount;
  },
  [SET_ALL_WALLET_TRANSACTION](state, allTransaction) {
    state.allTransaction = allTransaction;
  },
  [SET_WALLET_ACCOUNT_DETAILS](state, accountDetails) {
    state.accountDetails = accountDetails;
  },
  [SET_WALLET_STELLAR_TRANSACTION](state, stellarTransaction) {
    state.stellarTransaction = stellarTransaction
  },
  [SET_WALLET_SELECTED_ACC](state, selected_acc) {
    state.selected_acc = selected_acc;
    localStorage.setItem('wallet_account', JSON.stringify(selected_acc))
  },
  [SET_ALL_WALLET_CLAIMABLE_BALANCE](state, allClaimableBalance) {
    state.allClaimableBalance = allClaimableBalance
  },
  [SET_ALL_WALLET_RECIEVED_CLAIMABLE_BALANCE](state, allRecievedClaimableBalance) {
    state.allRecievedClaimableBalance = allRecievedClaimableBalance;
  },
  [SET_WALLET_BUY_HISTORY_BY_ACCOUNT](state, allBuyHistoryByAccount) {
    state.allBuyHistoryByAccount = allBuyHistoryByAccount;
  },
  [SET_ALL_WALLET_ASSET](state, allAsset) {
    state.allAsset = allAsset;
  },
  toggleModal(state, payload) {
    state.showModal = payload
  }
};

const getters = {

};


export const wallet = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
