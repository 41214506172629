import PersonalizerApi from "@/services/personalizer_api";
import {
    GET_TASK_STATUS,
    GET_TASKS_BY_PREFIX
} from "@/store/_actiontypes";
import {

} from "@/store/_mutationtypes";
import { updateLoaderTo } from "./account";

const actions = {
    [GET_TASK_STATUS]({ commit }, { project_id, task_id }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            PersonalizerApi.get(`tasks/${task_id}`,
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            ).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    async [GET_TASKS_BY_PREFIX]({ commit }, { project_id, task_prefix, start=0, size=10, task_status="all_status"  }) {
        updateLoaderTo(true);
        try {
            const url = `tasks/search/by_prefix/${task_prefix}?task_status=${task_status}&start=${start}&size=${size}`
            const config = {
                headers: {
                    "project-id": project_id
                }
            }
            return await PersonalizerApi.get(url, config)
        } catch (error){
            throw error
        } finally {
            updateLoaderTo(false)
        }
        
    },
};

const mutations = {

};

export const task = {
    namespaced: true,
    actions,
};
