<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-if="size==='small'"
            class="mx-1"
            fab
            small
            :color="color"
            :disabled="disabled"
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            @click.stop="onClick()"
        >
            <v-icon dark>{{iconName}}</v-icon>
        </v-btn>
        <v-btn
            v-else-if="size==='x-small'"
            class="mx-1"
            fab
            x-small
            :color="color"
            :disabled="disabled"
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            @click.stop="onClick()"
        >
            <v-icon dark>{{iconName}}</v-icon>
        </v-btn>
        <v-btn
            v-else-if="size==='x-large'"
            class="mx-1"
            fab
            x-large
            :color="color"
            :disabled="disabled"
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            @click.stop="onClick()"
        >
            <v-icon dark>{{iconName}}</v-icon>
        </v-btn>
        <v-btn
            v-else-if="size==='large'"
            class="mx-1"
            fab
            large
            :color="color"
            :disabled="disabled"
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            @click.stop="onClick()"
        >
            <v-icon dark>{{iconName}}</v-icon>
        </v-btn>
        <v-btn
            v-else
            class="mx-1"
            fab
            :color="color"
            :disabled="disabled"
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            @click.stop="onClick()"
        >
            <v-icon dark>{{iconName}}</v-icon>
        </v-btn>
        </template>
        <span>{{tooltipText}}</span>
    </v-tooltip>
</template>

<script>

export default {
    props: {
        tooltipText: {
            type: String,
            default: "Click"
        },
        iconName: {
            type: String,
            default: "mdi-television"
        },
        color: {
            type: String,
            default: "primary"
        },
        size: {
            type: String,
            default: "medium"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        
    },
    data() {
        return {
            
        };
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        }
    }
};
</script>
