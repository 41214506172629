import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from '@/mixins/custom-locale-en';
import ja from '@/mixins/custom-locale-ja';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#004aad',
        background: '#004aad'
      },
      dark: {
        primary: '#004aad'
      },
    },
  },
  lang: {
    locales: { en, ja },
    current:  localStorage.getItem("language")?.substring(0, localStorage.getItem("language")?.indexOf("-")) || 'en',
  },
});