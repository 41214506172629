<template>
    <v-btn
        v-if="size==='small'"
        small
        :color="color"
        :loading="loading"
        :disabled="disabled"
        @click.stop="onClick()"
    >
        {{buttonText}}
    </v-btn>
    <v-btn
        v-else-if="size==='x-small'"
        x-small
        :color="color"
        :loading="loading"
        :disabled="disabled"
        @click.stop="onClick()"
    >
        {{buttonText}}
    </v-btn>
    <v-btn
        v-else-if="size==='x-large'"
        x-large
        :color="color"
        :loading="loading"
        :disabled="disabled"
        @click.stop="onClick()"
    >
        {{buttonText}}
    </v-btn>
    <v-btn
        v-else-if="size==='large'"
        large
        :color="color"
        :loading="loading"
        :disabled="disabled"
        @click.stop="onClick()"
    >
        {{buttonText}}
    </v-btn>
    <v-btn
        v-else
        :color="color"
        :loading="loading"
        :disabled="disabled"
        @click.stop="onClick()"
    >
        {{buttonText}}
    </v-btn>
</template>

<script>

export default {
    props: {
        buttonText: {
            type: String,
            default: "Click"
        },
        color: {
            type: String,
            default: "primary"
        },
        size: {
            type: String,
            default: "medium"
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        
    },
    data() {
        return {
            
        };
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        }
    }
};
</script>

<style>
</style>