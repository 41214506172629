<template>
    <div>
      <v-container fluid>
       <GptDocuments/>
      </v-container>
  
    </div>
  </template>
  
  <script>
  import GptDocuments from "@/components/Projects/GptDocuments/GptDocuments";


  
  export default {
    props: {},
    components: {
      GptDocuments
    },
    computed: {},
    watch: {},
    mounted() {},
    data() {
      return {};
    },
    methods: {}
  };
  
  </script>
  
  <style></style>
  