<template>
    <div>
        <fieldset :class="{ 'text-editor': !isError, 'editor-is-danger': isError }">
            <legend 
                class="subheading pl-1 pr-1 ml-2"
                :style="{ paddingLeft: '2px', paddingRight: '2px' }"
            >
                {{ label }}
            </legend>
            <div :id="editorId"></div>
        </fieldset>
        <div v-if="isError" class="editor-text-danger text-caption mt-1 mb-3 ml-3">{{ errorMsg }}</div>
    </div>
</template>

<script>
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Quote from '@editorjs/quote';
import CodeTool from '@editorjs/code';
import RawTool from '@editorjs/raw';
import Underline from '@editorjs/underline';
import Marker from '@editorjs/marker';
import Table from '@editorjs/table';
import SimpleLink from './SimpleLink';
import SimpleImage from './SimpleImage';
import Delimiter from '@editorjs/delimiter';

export default {
    props: {
        editorId: {
            type: String
        },
        label: {
            type: String
        },
        placeHolder: {
            type: String
        },
        isRequired: {
            type: Boolean
        },
        isError: {
            type: Boolean
        },
        errorMsg: {
            type: String
        },
        setEditor: {
            type: Function
        },
        setIsError: {
            type: Function
        },
        editorData: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    mounted() {
        this.$emit('setEditor', this.textEditor);
    },
    data() {
        return {
            textEditor: new EditorJS({ 
                /** 
                * Id of Element that should contain the Editor 
                */ 
                holder: this.editorId, 
                placeholder: this.placeHolder ? this.placeHolder : '',
                /** 
                * Available Tools list. 
                * Pass Tool's class or Settings object for each Tool you want to use 
                */
                tools: { 
                    header: {
                        class: Header,
                        inlineToolbar : true
                    },
                    list: {
                        class: List,
                        inlineToolbar : true
                    },
                    quote: {
                        class: Quote,
                        inlineToolbar : true
                    },
                    code: {
                        class: CodeTool,
                        inlineToolbar : true
                    },
                    raw: {
                        class: RawTool,
                        inlineToolbar: true
                    },
                    underline: {
                        class: Underline,
                        inlineToolbar : true
                    },
                    marker: {
                        class: Marker,
                        inlineToolbar : true
                    },
                    table: {
                        class: Table,
                        inlineToolbar : true
                    },
                    linkTool: {
                        class: SimpleLink,
                        inlineToolbar : true,
                    },
                    image: {
                        class: SimpleImage,
                        inlineToolbar: true,
                    },
                    delimiter: {
                        class: Delimiter,
                        inlineToolbar: true
                    },
                },
                /**
                * onChange callback
                */
                onChange: (api, event) => {
                    if (this.isRequired) {
                        this.$emit('setIsError', false);
                    }
                },
                data: this.editorData,
                readOnly: false,
            }),
        };
    },
    methods: {
    },
}
</script>
<style>
.text-editor {
  padding: 5px 20px;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 4px;
  color: rgb(118, 118, 118);
}

.text-editor > legend {
    font-size: 0.75em;
}

.editor-is-danger {
  border: 2px solid rgb(255, 82, 82);
  border-radius: 4px;
  color: rgb(255, 82, 82);
  font-size: .75rem;
}

.editor-text-danger {
  color: rgb(255, 82, 82);
}

.ce-toolbar__settings-btn {
  margin-right: 0.5em;
}

.ce-block__content, .ce-toolbar__content {
  max-width: 93% !important;
  color: rgb(118,118,118);
}

.ce-paragraph {
    font-size: 16px !important;
}

.text-editor:focus-within {
  border: 2px solid rgb(23, 162, 184);
  color: rgb(23, 162, 184);
}

.codex-editor__redactor {
  padding-bottom: 80px !important;
}

.ce-paragraph {
  color: rgba(0,0,0,0.87);
}

.ce-inline-toolbar--left-oriented.ce-inline-toolbar--showed {
  color: rgba(0,0,0,0.87);
}

.ce-settings {
  right: -30px !important;
  min-width: 80px !important;
}

.link-wrapper {
    display: block;
    height: fit-content;
    padding: 1em;
    color: rgb(118,118,118);
}

.link-container {
    margin-top: 34px;
}

.link-field {
    display: block;
    border: 1px solid rgb(118,118,118);
    border-radius: 3px;
    width: 100%;
    height: 30px;
    margin: 8px 0;
    padding: 4px 8px;
    overflow: hidden;
}

.link-field:focus-visible {
    border: 1px solid rgb(118,118,118);
    outline: 0 solid rgb(118,118,118);
    color: rgb(118,118,118);
}

.text-field {
    display: block;
    border: 1px solid rgb(118,118,118);
    border-radius: 3px;
    width: 100%;
    height: 30px;
    margin: 8px 0;
    padding: 4px 8px;
    overflow: hidden;
}

.text-field:focus-visible {
    border: 1px solid rgb(118,118,118);
    outline: 0 solid rgb(118,118,118);
    color: rgb(118,118,118);
}

.add-link {
    padding: 4px 12px;
    float: right;
    border: 1px solid rgb(118,118,118);
    border-radius: 3px;
}

.link-inserted {
    font-size: 1.25em;
    color: #2196F3 !important;
    text-decoration: none !important;
    cursor: pointer !important;
}

.simple-image {
    padding: 20px 0;
    color: rgb(118,118,118);
}

.upload-btn {
    display: block;
    padding: 4px 12px;
    margin: 1em 0.25em;
    float: right;
    border: 1px solid rgb(118,118,118);
    border-radius: 3px;
}

.disabled {
    background-color: #B0BEC5;
}

.simple-image input,
.simple-image [contenteditable] {
    width: 100%;
    padding: 10px;
    border: 1px solid #e4e4e4;
    background: #fff;
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    font-size: 14px;
}

.simple-image img {
    max-width: 100%;
    margin-bottom: 15px;
}

.simple-image.withBorder img {
    border: 1px solid #e8e8eb;
}

.simple-image.withBackground {
    background: #eff2f5;
    padding: 10px;
}

.simple-image.withBackground img {
    display: block;
    max-width: 60%;
    margin: 0 auto 15px;
}

.simple-image {
    padding: 20px 0;
}

.simple-image input,
.simple-image [contenteditable] {
    width: 100%;
    padding: 10px;
    border: 1px solid #e4e4e4;
    background: #fff;
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    font-size: 14px;
}

.simple-image img {
    max-width: 100%;
    margin-bottom: 15px;
}

.simple-image.withBorder img {
    border: 1px solid #e8e8eb;
}

.simple-image.withBackground {
    background: #eff2f5;
    padding: 10px;
}

.simple-image.withBackground img {
    display: block;
    max-width: 60%;
    margin: 0 auto 15px;
}
</style>