<template>
  <v-card rounded outlined class="mx-auto " height="100%">
    <v-card-subtitle
      class="font-weight-bold d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        {{ this.$vuetify.lang.t("$vuetify.insights.repeated_users") }}
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small v-on="on" v-bind="attrs" class="ml-1">
              mdi-help-circle-outline
            </v-icon>
          </template>
          {{ this.$vuetify.lang.t("$vuetify.insights.repeated_users") }}{{ repeatedUsers }}
        </v-tooltip>
      </div>
      <v-btn
        small
        color="primary"
        @click="downloadCSV('Repeated Users', repeatedUsers)"
        :disabled="repeatedUsers.length === 0"
      >
        <v-icon left small>mdi-download</v-icon> CSV
      </v-btn>
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-data-table
      :headers="userHeaders"
      :items="repeatedUsers"
      :items-per-page="5"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    repeatedUsers: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      selectedProject: (state) => state.project.selectedProject,
    }),
  },

  data() {
    return {
      userHeaders: [
        { text: this.$vuetify.lang.t("$vuetify.gpt_history_user_id"), value: "user_id" },
      ],
    };
  },

  methods: {
    convertToCSV(data) {
      if (!data || data.length === 0) return '';
      const headerRow = this.userHeaders.map(header => header.text).join(',');
      const dataRows = data
      return [headerRow, ...dataRows].join('\n');
    },
    generateFilename(reportType) {
      const startDate = this.startDate;
      const endDate = this.endDate;
      return `${this.selectedProject.id}_${reportType}_${startDate}_${endDate}.csv`;
    },
    downloadCSV(reportType, data) {
      const filename = this.generateFilename(reportType);
      const csv = this.convertToCSV(data);
      if (!csv) {
        console.error("No data to download");
        return;
      }
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
};
</script>
<style scoped>
</style>
