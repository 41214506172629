<template>
  <v-main>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col align-self="center" sm="8" md="6" lg="4" xl="4">
          <v-card outlined class="elevation-2 mx-auto pa-4">
            <Logo />
            <v-card-title class="font-weight-bold">
              {{ $vuetify.lang.t("$vuetify.register_header_txt") }}
            </v-card-title>
            <v-card-text>
              <v-form ref="registerForm">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.account_name_label_txt')"
                  type="text"
                  v-model="registerForm.name"
                  placeholder="Gigalogy"
                  prepend-inner-icon="mdi-rename-box"
                  outlined
                  :rules="[
                    required(this.$vuetify.lang.t('$vuetify.account_name_label_txt')),
                    minLength(this.$vuetify.lang.t('$vuetify.account_name_label_txt'), 3),
                  ]"
                ></v-text-field>
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.email_label_txt')"
                  type="email"
                  v-model="registerForm.email"
                  placeholder="admin@gigalogy.com"
                  prepend-inner-icon="email"
                  outlined
                  :rules="[required(this.$vuetify.lang.t('$vuetify.email_label_txt')), email(this.$vuetify.lang.t('$vuetify.email_label_txt'))]"
                ></v-text-field>
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.password_label_txt')"
                  v-model="registerForm.password"
                  prepend-inner-icon="lock"
                  :placeholder="$vuetify.lang.t('$vuetify.password_label_txt')"
                  outlined
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="[
                    required(this.$vuetify.lang.t('$vuetify.password_label_txt')),
                    password(this.$vuetify.lang.t('$vuetify.password_label_txt')),
                    passwordWithEmail(this.$vuetify.lang.t('$vuetify.password_label_txt'), registerForm.email),
                    passwordWithName(this.$vuetify.lang.t('$vuetify.password_label_txt'), registerForm.name, this.$vuetify.lang.t('$vuetify.name_header_txt')),
                  ]"
                  :disabled="
                    registerForm.name.length > 2 && registerForm.email ? false : true
                  "
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.enter_password_label_txt')"
                  v-model="registerForm.repassword"
                  prepend-inner-icon="lock"
                  :placeholder="$vuetify.lang.t('$vuetify.enter_password_label_txt')"
                  outlined
                  :append-icon="showRePassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showRePassword ? 'text' : 'password'"
                  :rules="[required(this.$vuetify.lang.t('$vuetify.enter_password_label_txt')), match(registerForm.password)]"
                  :disabled="registerForm.password ? false : true"
                  @click:append="showRePassword = !showRePassword"
                ></v-text-field>
                <div class="font-weight-medium pb-2">
                  <v-checkbox v-model="registerForm.terms_agree" :rules="[required('')]">
                    <template v-slot:label>
                      <div>
                        {{ $vuetify.lang.t("$vuetify.privacy_policy_txt_1") }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="https://gigalogy.com/privacy-policy/"
                              @click.stop
                              v-on="on"
                              class="text-decoration-none"
                            >
                              {{ $vuetify.lang.t("$vuetify.privacy_policy_txt_2") }}
                            </a>
                          </template>
                          {{ $vuetify.lang.t("$vuetify.tooltip.open_window") }}
                        </v-tooltip>
                        ,
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="https://www.gigalogy.com/terms-and-conditions/"
                              @click.stop
                              v-on="on"
                              class="text-decoration-none"
                            >
                              {{ $vuetify.lang.t("$vuetify.privacy_policy_txt_3") }}
                            </a>
                          </template>
                          {{ $vuetify.lang.t("$vuetify.tooltip.open_window") }}
                        </v-tooltip>
                      </div>
                    </template>
                  </v-checkbox>
                </div>
                <v-btn
                  color="primary"
                  large
                  block
                  @click="handleRegisterSubmitClick()"
                  :loading="loading"
                  >{{ $vuetify.lang.t("$vuetify.continue_btn_txt") }}</v-btn
                >
              </v-form>
              <v-divider class="my-5"></v-divider>
              <div class="font-weight-medium pb-5">
                {{ $vuetify.lang.t("$vuetify.account_exist_msg_txt") }}
                <router-link to="/login" class="text-decoration-none">
                  {{ $vuetify.lang.t("$vuetify.login_btn_txt") }}
                </router-link>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import getEnv from "@/config/env";
import { REGISTER } from "@/store/_actiontypes";
import validations from "@/helpers/validations";
import Logo from "@/components/common/logo";

export default {
  components: {
    Logo,
  },
  data() {
    return {
      showPassword: false,
      showRePassword: false,
      registerForm: {
        name: "",
        email: "",
        password: "",
        repassword: "",
        terms_agree: true,
      },
      ...validations,
      emailConfirm: false,
    };
  },
  watch: {
    "registerForm.name": function () {
      if (!this.registerForm.name) {
        this.registerForm.password = "";
      }
    },
    "registerForm.email": function () {
      if (!this.registerForm.email) {
        this.registerForm.password = "";
      }
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loader.loading,
      signup_error: (state) => state.account.signup_error,
    }),
  },
  methods: {
    ...mapActions("account", [REGISTER]),
    async handleRegisterSubmitClick() {
      if (!this.$refs.registerForm.validate()) return;

      const clientIP = await this.$ipCheck.getClientIP();
      const isAllowed = await this.$ipCheck.ipRangeCheck(clientIP);

      if (getEnv("VUE_APP_CAPTCHA_ENABLED") && !isAllowed) {
        this.$recaptcha.execute("register")
          .then((recaptcha_token) => {
            this.handleRegisterSubmit(recaptcha_token, clientIP);
          }).catch((error) => {
            console.error("reCaptcha error:", error);
          });
      } else {
        this.handleRegisterSubmit(null, clientIP);
      }
    },
    handleRegisterSubmit(recaptcha_token, clientIP) {
      const { name, email, password } = this.registerForm;
      this.REGISTER({ name, email, password, recaptcha_token, clientIP }).then(
        (res) => {
          // this.emailConfirm = true
          this.$router.push("/register-success/" + email);
        },
        (err) => {}
      );
    },
  },
};
</script>

<style></style>
