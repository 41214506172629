<template>
  <span :class="`${color}--text`">
    <span class="font-weight-bold whole">
      {{prefix && prefix}}{{ `${amount.split(".")[0]}` }}.
      <span class="decimal"></span>
    </span>
    <span class="fraction">
      {{ `${amount.split(".")[1] ? amount.split(".")[1] : '0000000' }` }}
    </span>
    <span v-if="asset_code" class="font-weight-bold">
      {{ `${asset_code}` }}
    </span>
  </span>
</template>

<script>
export default {
    props: {
        amount: String,
        asset_code: String,
        prefix: String,
        color: String
    }
};
</script>
<style scoped>
.whole {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.decimal {
  position: absolute;
  opacity: 0;
}
.fraction {
  top: -0.05em;
  font-size: 11px;
  position: relative;
  left: -0.25em;
}
</style>