<template>
    <div>
        <v-row>
            <v-col cols="12" md="2" class="pl-12">
                <v-card class="pa-2" flat>
                    <strong>{{ `${$vuetify.lang.t("$vuetify.project.ranks_train")}` }}</strong>
                </v-card>
            </v-col>

            <v-col cols="12" md="8"> </v-col>

            <v-col cols="12" md="2">
                <v-btn v-if="!this.inProgress" color="primary" @click="startTraining">
                    <v-icon>mdi-play</v-icon>
                    {{ `${$vuetify.lang.t("$vuetify.tooltip.start")}` }}
                </v-btn>
                <v-btn v-else color="primary" class="mr-4 secondary-disabled-button">
                    {{ `${$vuetify.lang.t("$vuetify.project.training")}` }}
                    <v-progress-circular indeterminate :size="15" :width="2" color="white"></v-progress-circular>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="updateResult !== ''" class="pl-11">
            <v-col cols="auto" class="">
                <v-icon v-if="updateStatus === 'success'" color="#C8E6C9">mdi-check-circle</v-icon>
                <v-icon v-else-if="updateStatus === 'failed'" color="error">mdi-alert-circle</v-icon>
            </v-col>
            <v-col>
                <p>
                    <span :class="{ 'error--text': updateStatus === 'failed' }">
                        {{ updateResult }}
                    </span>
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import { RANKS_TRAIN, GET_TASK_STATUS, ADD_ALERT } from "@/store/_actiontypes";

import getEnv from "@/config/env";

export default {
    props: {},
    components: {},
    data() {
        return {
            selectedFile: null,
            polling: null,
            inProgress: false,
            updateResult: "",
            updateStatus: "",
        };
    },
    computed: {
        ...mapState({
            selectedProject: (state) => state.project.selectedProject,
        }),
    },
    watch: {},
    beforeDestroy() {
        clearInterval(this.polling);
        this.polling = null;
    },
    methods: {
        ...mapActions("project", [RANKS_TRAIN]),
        ...mapActions("alert", [ADD_ALERT]),
        ...mapActions("task", [GET_TASK_STATUS]),
        async startPolling(task_id) {
            this.polling = setInterval(() => {
                this.getTaskStatus(task_id);
            }, getEnv("VUE_APP_POLLING_INTERVAL"));
        },
        stopPolling() {
            clearInterval(this.polling);
            this.polling = null;
        },
        async getTaskStatus(task_id) {
            let project_id = this.selectedProject.id;
            this.GET_TASK_STATUS({
                project_id: project_id,
                task_id: task_id
            }).then(
                (response) => {
                    if (response.status === 200) {
                        if (response.data.detail.response.status !== "pending") {
                            this.stopPolling();
                            this.inProgress = false;
                            this.isLoading = false;
                        }
                        this.updateResult = response.data.detail.response.message;
                        this.updateStatus = response.data.detail.response.status;
                    }
                },
                (error) => {
                    console.error(error.response);
                }
            );
        },
        async startTraining() {
            let project_id = this.selectedProject.id;
            this.inProgress = true;
            this.RANKS_TRAIN({
                project_id: project_id,
            }).then(
                async (response) => {
                    if (response.status === 202) {
                        this.startPolling(response.data.detail.response);
                    } else {
                        this.inProgress = false;
                        console.error(response);
                    }
                },
                (error) => {
                    if (error.response.status === 404) {
                        this.$store.dispatch(
                            `alert/${ADD_ALERT}`,
                            {
                                message: error.response.data.detail.response,
                                color: "error",
                            },
                            { root: true }
                        );
                        this.updateResult = error.response.data.detail.response;
                        this.updateStatus = "failed";
                    }
                    this.inProgress = false;
                    console.log(error.response);
                }
            );
        },
    },
};
</script>

<style scoped>
.secondary-disabled-button {
    cursor: not-allowed;
    pointer-events: none;
}
</style>
