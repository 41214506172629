<template>
  <v-dialog v-model="dialog" width="500" :persistent="persistent">
    <v-card>
      <v-card-title :class="headlineClass" primary-title>
        {{ title }}
      </v-card-title>
      <v-layout class="px-5 py-5">
        <v-row class="mx-2 my-2">
          <span>{{ message }}</span>
          <slot></slot>
        </v-row>
      </v-layout>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <Button
          v-if="cancelButton"
          :buttonType="TEXT_BUTTON"
          :buttonText="cancelButtonText"
          color="error"
          @onClick="onCancel()"
        />
        <Button
          v-if="confirmButton"
          :buttonType="RAISED_BUTTON"
          :buttonText="confirmButtonText"
          color="primary"
          @onClick="onConfirmation()"
          :loading="confirmBtnLoading"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { TEXT_BUTTON, RAISED_BUTTON } from "@/components/common/button/_buttontypes";

import Button from "@/components/common/button/Button";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Confirmation",
    },
    message: {
      type: String,
      default: "Are you sure?",
    },
    confirmButtonText: {
      type: String,
      default: "Confirm",
    },
    cancelButtonText: {
      type: String,
      default: "Cancel",
    },
    cancelButton: {
      type: Boolean,
      default: true,
    },
    confirmButton: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    headlineClass: {
      type: String,
      default: "headline primary white--text",
    },
    confirmBtnLoading: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Button,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      TEXT_BUTTON: TEXT_BUTTON,
      RAISED_BUTTON: RAISED_BUTTON,
    };
  },
  methods: {
    onConfirmation() {
      this.$emit("onConfirmation");
    },
    onCancel() {
      this.$emit("onCancel");
    },
  },
};
</script>
