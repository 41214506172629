<template>
  <v-main>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col align-self="center" sm="8" md="6" lg="4" xl="4">
          <v-card outlined class="elevation-2 mx-auto pa-4">
            <v-card-text class="text-center">
              <v-icon color="success" size="100">task_alt</v-icon>
            </v-card-text>
            <v-card-text class="font-weight-bold success--text headline">
              {{ $vuetify.lang.t('$vuetify.registration_success_msg_txt_1') }}
            </v-card-text>
            <v-card-text class="text-subtitle-1">
              {{ $vuetify.lang.t('$vuetify.registration_success_msg_txt_2') }}
            </v-card-text>
            <v-divider class="ml-4 mr-4"></v-divider>
            <v-card-text>
              <p class="body-2">
                <v-icon class="mx-0" slot="icon" size="20">
                  info
                </v-icon>
                {{ $vuetify.lang.t('$vuetify.resend_verification_email_msg_txt_1') }}
              </p>
              <v-btn
                color="primary"
                large
                block
                @click="handleSubmitClick()"
                :loading="loading"
              >{{ $vuetify.lang.t('$vuetify.resend_verification_email_btn_txt') }}</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import getEnv from "@/config/env";
import { RESEND_VERIFICATION_EMAIL } from "@/store/_actiontypes";

export default {
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading: (state) => state.loader.loading,
    }),
  },
  methods: {
    ...mapActions("account", [RESEND_VERIFICATION_EMAIL]),
    async handleSubmitClick() {
      const clientIP = await this.$ipCheck.getClientIP();
      const isAllowed = await this.$ipCheck.ipRangeCheck(clientIP);
      if (getEnv("VUE_APP_CAPTCHA_ENABLED") && !isAllowed) {
        this.$recaptcha.execute("resend_verify_email")
          .then((recaptcha_token) => {
            this.handleSubmit(recaptcha_token, clientIP);
          }).catch((error) => {
            // handle error
            console.error("reCaptcha error:", error);
          });
      } else {
        this.handleSubmit(null, clientIP);
      }
    },
    handleSubmit(recaptcha_token, clientIP) {
      this.RESEND_VERIFICATION_EMAIL({
        email: this.$route.params.email,
        recaptcha_token,
        clientIP
      }).then((res)=>{

      }).catch(err=>{

      });
    },
    waitThreeSec() {
      setTimeout(() => this.$router.replace("/login"), 3000);
    },
  },
};
</script>
