export function formatDateTime(datetime) {
  if (!datetime) {
    return "N/A";
  }

  try {
    const date = new Date(datetime);
    if (isNaN(date)) {
      return "Invalid Date";
    }
    return date.toLocaleString();
  } catch (error) {
    return "Invalid Date";
  }
}
