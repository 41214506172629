export const TEXT = 'TEXT';
export const TEXT_AREA = 'TEXT_AREA';
export const PASSWORD = 'PASSWORD';
export const NUMBER = 'NUMBER';
export const AUTO_COMPLETE = 'AUTO_COMPLETE';
export const MULT_AUTO_COMPLETE = 'MULT_AUTO_COMPLETE';
export const DATE_PICKER = 'DATE_PICKER';
export const DATETIME_PICKER = 'DATETIME_PICKER';
export const RADIO_GROUP = 'RADIO_GROUP';
export const COMBOBOX = 'COMBOBOX';
export const MULT_COMBOBOX = 'MULT_COMBOBOX';
export const SELECT = 'SELECT';
