<template>
  <span
    >{{ label }}
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          x-small
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            mdi-help-circle
          </v-icon>
        </v-btn>
      </template>
      <span>{{ message }}</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    message: {
      type: String,
    },
  },
};
</script>