<template>
  <v-container fill-height fluid>
    <!-- Date Filtering  -->
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      top
      :timeout="timeout"
    >
      {{ snackbarMessage }}
    </v-snackbar>
    <v-row>
      <v-col cols="12" xs="12" md="4">
        <v-menu
          ref="menuDates"
          v-model="menuDates"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              :placeholder="
                $vuetify.lang.t('$vuetify.daterange_placeholder_txt')
              "
              prepend-icon="mdi-calendar"
              readonly
              outlined
              dense
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            color="primary"
            no-title
            scrollable
            range
            :max="maxDate"
          >
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="menuDates = false">
              {{ $vuetify.lang.t("$vuetify.cancel_btn_txt") }}
            </v-btn>
            <v-btn text color="primary" @click="setDates">
              {{ $vuetify.lang.t("$vuetify.OK_btn_txt") }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col v-if="last_insights_updated_at !== ''" cols="12" xs="12" md="8">
        <div class="float-end">
          <p class="text-end text-caption ma-0 font-weight-bold">
            {{ this.$vuetify.lang.t("$vuetify.insights.last_updated_at") }}
          </p>
          <p class="text-end text-caption ma-0">
            {{ last_insights_updated_at }}
          </p>
        </div>
      </v-col>
    </v-row>
    <template v-if="selectedProject.segment.name === 'Personalizer'">
      <Loader v-if="isLoading" />
      <v-row v-else class="d-flex justify-space-between">
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{ this.$vuetify.lang.t("$vuetify.insights.search") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ this.$vuetify.lang.t("$vuetify.insights.search_txt") }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{ totalNumberOfSearches.toLocaleString() }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageSearch }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageSearch')"
                :color="percentageColor('percentageSearch')"
              >
                {{ percentageIcon("percentageSearch") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{ this.$vuetify.lang.t("$vuetify.insights.recommend") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ this.$vuetify.lang.t("$vuetify.insights.recommend_txt") }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{ totalNumberOfRecommendations.toLocaleString() }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageRecommend }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageRecommend')"
                :color="percentageColor('percentageRecommend')"
              >
                {{ percentageIcon("percentageRecommend") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{ this.$vuetify.lang.t("$vuetify.insights.orders") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ this.$vuetify.lang.t("$vuetify.insights.orders_txt") }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{
                kpiSummary &&
                kpiSummary.purchase &&
                kpiSummary.purchase.number_of_orders
                  ? kpiSummary.purchase.number_of_orders.toLocaleString()
                  : "0"
              }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageOrders }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageOrders')"
                :color="percentageColor('percentageOrders')"
              >
                {{ percentageIcon("percentageOrders") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{ this.$vuetify.lang.t("$vuetify.insights.revenue") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ this.$vuetify.lang.t("$vuetify.insights.revenue_txt") }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{
                kpiSummary &&
                kpiSummary.purchase &&
                kpiSummary.purchase.total_purchased_amounts
                  ? kpiSummary.purchase.total_purchased_amounts.toLocaleString()
                  : "0"
              }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageRevenue }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageRevenue')"
                :color="percentageColor('percentageRevenue')"
              >
                {{ percentageIcon("percentageRevenue") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{ this.$vuetify.lang.t("$vuetify.insights.average_cart_size") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{
                    this.$vuetify.lang.t(
                      "$vuetify.insights.average_cart_size_txt",
                    )
                  }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{ averageCartSize.toLocaleString() }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageAvgCartSize }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageAvgCartSize')"
                :color="percentageColor('percentageAvgCartSize')"
              >
                {{ percentageIcon("percentageAvgCartSize") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{
                this.$vuetify.lang.t("$vuetify.insights.average_order_value")
              }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{
                    this.$vuetify.lang.t(
                      "$vuetify.insights.average_sales_orders_txt",
                    )
                  }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{
                kpiSummary &&
                kpiSummary.purchase &&
                kpiSummary.purchase.avg_purchase_value_by_orders
                  ? kpiSummary.purchase.avg_purchase_value_by_orders.toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2 },
                    )
                  : "0"
              }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageAvgSaleOrder }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageAvgSaleOrder')"
                :color="percentageColor('percentageAvgSaleOrder')"
              >
                {{ percentageIcon("percentageAvgSaleOrder") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{
                this.$vuetify.lang.t("$vuetify.insights.average_sales_users")
              }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{
                    this.$vuetify.lang.t(
                      "$vuetify.insights.average_sales_users_txt",
                    )
                  }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{
                kpiSummary &&
                kpiSummary.purchase &&
                kpiSummary.purchase.avg_purchase_value_by_users
                  ? kpiSummary.purchase.avg_purchase_value_by_users.toLocaleString()
                  : "0"
              }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageAvgSaleUser }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageAvgSaleUser')"
                :color="percentageColor('percentageAvgSaleUser')"
              >
                {{ percentageIcon("percentageAvgSaleUser") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{
                this.$vuetify.lang.t("$vuetify.insights.average_sales_items")
              }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{
                    this.$vuetify.lang.t(
                      "$vuetify.insights.average_sales_items_txt",
                    )
                  }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{
                kpiSummary &&
                kpiSummary.purchase &&
                typeof kpiSummary.purchase.avg_purchase_value_by_items
                  ? kpiSummary.purchase.avg_purchase_value_by_items
                      .toFixed(2)
                      .toLocaleString()
                  : "0"
              }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageAvgSaleItems }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageAvgSaleItems')"
                :color="percentageColor('percentageAvgSaleItems')"
              >
                {{ percentageIcon("percentageAvgSaleItems") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <v-row
      align="center"
      justify="center"
      v-if="selectedProject.segment.name === 'Personalizer'"
    >
      <v-col cols="12">
        <h3 class="text-center">Behavioral Insights</h3>
      </v-col>

      <v-btn-toggle v-model="activeTab" active-class="white">
        <v-btn :disabled="activeTab === 'item'" value="item">
          <span class="tab-card-title">
            {{ this.$vuetify.lang.t("$vuetify.insights.item") }}
          </span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="activeTab === 'user'" value="user">
          <span class="tab-card-title">
            {{ this.$vuetify.lang.t("$vuetify.insights.user") }}
          </span>
        </v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row
      v-if="
        activeTab === 'item' && selectedProject.segment.name === 'Personalizer'
      "
      class="d-flex justify-space-between"
    >
      <v-col cols="12" md="6">
        <v-card outlined class="mx-auto">
          <v-card-subtitle class="d-flex justify-space-between align-center">
            <div class="font-weight-bold d-flex align-center">
              {{ $vuetify.lang.t("$vuetify.insights.item") }}
              Count
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ $vuetify.lang.t("$vuetify.insights.item") }}
                </span>
              </v-tooltip>
            </div>
            <div class="float-right">
              <v-switch
                class="mt-0"
                dense
                hide-details="true"
                v-model="isLineChart"
                :label="
                  isLineChart
                    ? this.$vuetify.lang.t('$vuetify.insights.line_chart')
                    : 'Bar Chart'
                "
              ></v-switch>
            </div>
          </v-card-subtitle>
          <v-divider></v-divider>
          <Loader v-if="isLoading" class="mx-auto my-6" />
          <div v-else class="d-flex flex-column justify-space-between">
            <div id="chartContainer" style="flex: 1">
              <apexchart
                v-if="isLineChart"
                ref="lineChart"
                type="line"
                :options="itemlineChart"
                :series="[{ data: itemChartData }]"
              ></apexchart>
              <apexchart
                v-else
                ref="barChart"
                width="100%"
                type="bar"
                :options="itemBarChart"
                :series="[{ data: itemChartData }]"
              ></apexchart>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <TopPurchasedItems
          :itemList="
            (kpiSummary &&
              kpiSummary.purchase &&
              kpiSummary.purchase.top_items) ||
            []
          "
          :isLoading="isLoading"
          :start-date="dates[0] || ''"
          :end-date="dates[1] || ''"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TopBrowsedItems
          :itemList="
            (kpiSummary && kpiSummary.browse && kpiSummary.browse.top_items) ||
            []
          "
          :isLoading="isLoading"
          :start-date="dates[0] || ''"
          :end-date="dates[1] || ''"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TopRatedItems
          :itemList="
            (kpiSummary &&
              kpiSummary.ratings &&
              kpiSummary.ratings.top_positive_rated_items) ||
            []
          "
          :isLoading="isLoading"
          :start-date="dates[0] || ''"
          :end-date="dates[1] || ''"
        />
      </v-col>
    </v-row>
    <v-row v-if="activeTab === 'user'">
      <v-col cols="12" md="6">
        <v-card outlined class="mx-auto">
          <v-card-subtitle class="d-flex justify-space-between align-center">
            <div class="font-weight-bold d-flex align-center">
              {{ $vuetify.lang.t("$vuetify.insights.user") }}
              Count
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ $vuetify.lang.t("$vuetify.insights.user") }}
                </span>
              </v-tooltip>
            </div>
            <div class="float-right">
              <v-switch
                class="mt-0"
                dense
                hide-details="true"
                v-model="isLineChart"
                :label="
                  isLineChart
                    ? this.$vuetify.lang.t('$vuetify.insights.line_chart')
                    : 'Bar Chart'
                "
              ></v-switch>
            </div>
          </v-card-subtitle>
          <v-divider></v-divider>
          <Loader v-if="isLoading" class="mx-auto my-6" />
          <div v-else class="d-flex flex-column justify-space-between">
            <div id="chartContainer" style="flex: 1">
              <apexchart
                v-if="isLineChart"
                ref="lineChart"
                type="line"
                :options="userlineChart"
                :series="[{ data: userChartData }]"
              ></apexchart>
              <apexchart
                :key="isLineChart"
                v-else
                ref="barChart"
                width="100%"
                type="bar"
                :options="userBarChart"
                :series="[{ data: userChartData }]"
              ></apexchart>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card rounded outlined class="mx-auto" height="100%">
          <v-card-subtitle
            class="font-weight-bold d-flex justify-space-between align-center"
          >
            <div class="d-flex align-center">
              {{ this.$vuetify.lang.t("$vuetify.insights.top_users") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs" class="ml-1">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                {{ this.$vuetify.lang.t("$vuetify.insights.top_users") }}
              </v-tooltip>
            </div>
            <v-btn
              small
              color="primary"
              @click="downloadAsCSV('top_users', userHeaders)"
              :loading="downloading"
              :disabled="downloading || topUsers.length === 0"
            >
              <v-icon left small>mdi-download</v-icon> CSV
            </v-btn>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-data-table
            :headers="userHeaders"
            :items="topUsers"
            :items-per-page="itemsPerPage"
            :sort-by="['total_purchased_amount']"
            :sort-desc="[true]"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.user_id }}</td>
                <td>{{ item.member_id || "N/A" }}</td>
                <td>{{ item.total_purchased_amount.toLocaleString() }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <RepeatedUsers
          :repeatedUsers="repeatedUsers"
          :isLoading="isLoading"
          :start-date="dates[0] || ''"
          :end-date="dates[1] || ''"
        />
      </v-col>
    </v-row>

    <!--  Conversation history  -->
    <v-row>
      <v-col
        class="pa-0 px-3"
        xs="12"
        md="12"
        v-if="
          selectedProject.segment.name === PROJECT_SEGMENTS.Maira ||
          selectedProject.segment.name === PROJECT_SEGMENTS.PERSONALIZER
        "
      >
        <v-card outlined class="mx-auto">
          <template>
            <!-- integrate after get insights data  -->
            <v-card-subtitle
              class="font-weight-bold d-flex justify-space-between align-center"
            >
              <div class="d-flex align-center">
                {{ $vuetify.lang.t("$vuetify.gpt_conversation_history_title") }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-on="on" v-bind="attrs">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    {{
                      this.$vuetify.lang.t(
                        "$vuetify.gpt_conversation_history_title",
                      )
                    }}
                  </span>
                </v-tooltip>
              </div>
              <div class="float-right">
                <v-btn
                  class="mr-1"
                  small
                  color="primary"
                  @click="gptConversationFileGenerationModal = true"
                >
                  <v-icon left>mdi-download</v-icon>
                  <span class="hidden-sm-and-down"> CSV </span>
                </v-btn>
                <v-btn
                  class="ml-1"
                  small
                  color="primary"
                  @click="gptConversationHistoryModal = true"
                >
                  <v-icon left>mdi-history</v-icon>
                  <span class="hidden-sm-and-down">
                    {{ $vuetify.lang.t("$vuetify.history") }}
                  </span>
                </v-btn>
              </div>
            </v-card-subtitle>
            <v-divider></v-divider>

            <v-row class="d-flex flex-column flex-md-row py-4 mb-n6 px-6">
              <v-col width class="pa-0 pr-0 pr-md-3">
                <v-text-field
                  v-model="conversationSearch"
                  :label="$vuetify.lang.t('$vuetify.search_txt')"
                  outlined
                  dense
                  clearable
                ></v-text-field>
              </v-col>
              <!-- <v-col width class="pa-0 pr-0 pr-md-3">
                <v-text-field
                  v-model="userFilter"
                  :label="
                    $vuetify.lang.t('$vuetify.gpt_history_search_user_id')
                  "
                  outlined
                  dense
                  clearable
                ></v-text-field>
              </v-col> -->
              <!-- <v-col xs="12" md="3">
              <v-text-field
                v-model="conversationFilter"
                label="Search Conversation ID"
                outlined
                dense
                clearable
              ></v-text-field>
            </v-col> -->
              <!-- <v-col class="pa-0 pr-0 pr-md-3">
                <v-text-field
                  v-model="promptFilter"
                  :label="$vuetify.lang.t('$vuetify.gpt_history_search_query')"
                  outlined
                  dense
                  clearable
                ></v-text-field>
              </v-col> -->
              <v-col class="pa-0 pr-0 pr-md-3">
                <v-select
                  v-model="feedbackFilter"
                  :items="feedbackOptions"
                  :label="
                    $vuetify.lang.t('$vuetify.gpt_history_filter_feedback')
                  "
                  outlined
                  dense
                  clearable
                ></v-select>
              </v-col>
              <v-col class="pa-0 pr-0">
                <v-select
                  v-model="endpointFilter"
                  :items="endpointOptions"
                  :label="
                    $vuetify.lang.t('$vuetify.gpt_history_filter_endpoints')
                  "
                  outlined
                  dense
                  clearable
                ></v-select>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <Loader v-if="isLoading" class="mr-2 mt-6" />
            <v-data-table
              v-else
              :headers="gptHeaders"
              :items="gptConversations"
              :options.sync="gptConversationHistoryOptions"
              :server-items-length="gptTotalConversations"
              sort-by="createdAt"
              :sort-desc="true"
            >
              <template v-slot:[`item.requestBody.userId`]="{ item }">
                <span>
                  {{
                    item.requestBody.userId
                      ? item.requestBody.userId.split("-")[0]
                      : ""
                  }}
                </span>
              </template>
              <template v-slot:[`item.requestBody.query`]="{ item }">
                <span>
                  {{
                    item.requestBody.query > 50
                      ? item.requestBody.query.slice(0, 50) + "..."
                      : item.requestBody.query
                  }}
                </span>
              </template>
              <template v-slot:[`item.feedback`]="{ item }">
                <span>
                  <template v-if="item.feedback && item.feedback.type">
                    {{ item.feedback.type }}
                  </template>
                  <template v-else> N/A </template>
                </span>
              </template>
              <template v-slot:[`item.tokens`]="{ item }">
                <span>{{ item.tokens }}</span>
              </template>
              <template v-slot:[`item.requestUrl`]="{ item }">
                <span>
                  {{ determineEndpointType(item.requestUrl) }}
                </span>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                <div>
                  {{ formatDateTime(item.createdAt) }}
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                      @click="detail_modal(item)"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>{{ $vuetify.lang.t("$vuetify.common.details") }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.gpt_train`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                      @click="train_modal(item)"
                      >mdi-plus-circle</v-icon
                    >
                  </template>
                  <span>
                    {{ $vuetify.lang.t("$vuetify.add_to_training_data") }}
                  </span>
                </v-tooltip>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <!--  Questionnaire log  -->
    <v-row>
      <v-col
        class="pa-0 px-3"
        xs="12"
        md="12"
        v-if="selectedProject.segment.name === PROJECT_SEGMENTS.PERSONALIZER"
      >
        <QuestionnaireLog :startDate="start_date" :endDate="end_date" />
      </v-col>
    </v-row>
    <!-- API call response summary -->
    <v-row v-if="selectedProject.segment.name === 'Personalizer'">
      <v-col xs="12" md="12">
        <v-card rounded outlined class="mx-auto px-2">
          <v-col md="6">
            <div class="primary--text font-weight-bold ml-n1">
              {{ $vuetify.lang.t("$vuetify.api_call_response_summary") }}
            </div>
          </v-col>
          <v-divider></v-divider>
          <Loader v-if="isLoading" class="mr-2 mt-6" />
          <v-data-table
            v-else
            :headers="headers"
            :items="requestDetails"
            :items-per-page="itemsPerPage"
          >
            <template v-slot:[`item.request_path`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.request_path }}</span>
                </template>
                <span>{{ item.request_path }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.number_of_request`]="{ item }">
              <div>
                {{ item.number_of_request }}
              </div>
            </template>

            <template v-slot:[`item.avg_processing_time`]="{ item }">
              <div>
                {{ item.avg_processing_time.toFixed(5) }}
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- Device, Browser & OS ratio -->
    <template v-if="selectedProject.segment.name === 'Personalizer'">
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="12" sm="6" md="4">
            <v-card outlined>
              <v-card-subtitle class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.device_ratio_txt") }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-on="on" v-bind="attrs">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    {{ $vuetify.lang.t("$vuetify.show_device_ratio_txt") }}
                  </span>
                </v-tooltip>
              </v-card-subtitle>
              <v-card-text>
                <Loader v-if="isLoading" />
                <v-container
                  v-else-if="
                    deviceRatio &&
                    Object.keys(deviceRatio).length > 0 &&
                    Object.values(deviceRatio).some(value => value !== 0)
                  "
                >
                  <v-row>
                    <v-col cols="12">
                      <div id="chart">
                        <apexchart
                          type="pie"
                          width="100%"
                          :options="deviceRatioOptions"
                          :series="deviceRatioValue"
                        >
                        </apexchart>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card v-else flat height="250">
                  <v-row class="fill-height" align="center" justify="center">
                    <span class="grey--text">
                      {{ $vuetify.lang.t("$vuetify.no_data_available") }}
                    </span>
                  </v-row>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card outlined>
              <v-card-subtitle class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.browser_ratio_txt") }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-on="on" v-bind="attrs">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    {{ $vuetify.lang.t("$vuetify.show_browser_ratio_txt") }}
                  </span>
                </v-tooltip>
              </v-card-subtitle>
              <v-card-text>
                <Loader v-if="isLoading" />
                <v-container
                  v-else-if="
                    browserRatio &&
                    Object.keys(browserRatio).length > 0 &&
                    Object.values(browserRatio).some(value => value !== 0)
                  "
                >
                  <v-row>
                    <v-col cols="12">
                      <div id="chart">
                        <apexchart
                          type="pie"
                          width="100%"
                          :options="browserRatioOptions"
                          :series="browserRatioValue"
                        >
                        </apexchart>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card v-else flat height="250">
                  <v-row class="fill-height" align="center" justify="center">
                    <span class="grey--text">
                      {{ $vuetify.lang.t("$vuetify.no_data_available") }}
                    </span>
                  </v-row>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card outlined>
              <v-card-subtitle class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.os_ratio_txt") }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-on="on" v-bind="attrs">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    {{ $vuetify.lang.t("$vuetify.show_os_ratio_txt") }}
                  </span>
                </v-tooltip>
              </v-card-subtitle>
              <v-card-text>
                <Loader v-if="isLoading" />
                <v-container
                  v-else-if="
                    osRatio &&
                    Object.keys(osRatio).length > 0 &&
                    Object.values(osRatio).some(value => value !== 0)
                  "
                >
                  <v-row>
                    <v-col cols="12">
                      <div id="Chart">
                        <apexchart
                          type="pie"
                          width="102%"
                          :options="osRatioOptions"
                          :series="osRatioValue"
                        ></apexchart>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card v-else flat height="250">
                  <v-row class="fill-height" align="center" justify="center">
                    <span class="grey--text">
                      {{ $vuetify.lang.t("$vuetify.no_data_available") }}
                    </span>
                  </v-row>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <!-- GPT-Flow History Details Modal -->
    <template v-if="openModal">
      <v-dialog v-model="openModal" max-width="100%">
        <v-card
          class="d-flex flex-column"
          style="overflow-y: scroll; overflow-x: hidden"
        >
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="response-message-header">
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_query",
                                  )
                                }}:
                              </strong>
                              <span class="response-message">
                                {{ modalContent.requestBody.query }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_user_id",
                                  )
                                }}:
                              </strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.userId }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_response",
                                  )
                                }}:
                              </strong>
                              <v-card flat class="mt-1">
                                <v-card-text
                                  class="pa-1"
                                  style="
                                    max-height: 300px;
                                    overflow-y: auto;
                                    word-break: break-word;
                                  "
                                >
                                  <VueMarkdown>
                                    {{
                                      normalizeTextContent(
                                        modalContent.response,
                                      )
                                    }}
                                  </VueMarkdown>
                                </v-card-text>
                              </v-card>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_response",
                                  )
                                }}:
                              </strong>
                              <v-card flat class="mt-1">
                                <v-card-text
                                  class="pa-1"
                                  style="
                                    max-height: 300px;
                                    overflow-y: auto;
                                    word-break: break-word;
                                  "
                                >
                                  <VueMarkdown>
                                    {{
                                      normalizeTextContent(
                                        modalContent.response,
                                      )
                                    }}
                                  </VueMarkdown>
                                </v-card-text>
                              </v-card>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="mt-n6">
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_conversation_id",
                                  )
                                }}:
                              </strong>
                              <span class="response-details-text">
                                {{ modalContent.id }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_date",
                                  )
                                }}:
                              </strong>
                              <span class="response-details-text">
                                {{ formatDateTime(modalContent.createdAt) }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_processing_time",
                                  )
                                }}:
                              </strong>
                              <span class="response-details-text">
                                {{ modalContent.processTime }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_model",
                                  )
                                }}:
                              </strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.model }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>Chat History Length:</strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.chatHistoryLength }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_temperature",
                                  )
                                }}:
                              </strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.temperature }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>Top P:</strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.topP }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>Top K:</strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.topK }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_search_max_token",
                                  )
                                }}:
                              </strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.searchMaxToken }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_frequency_penalty",
                                  )
                                }}:
                              </strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.frequencyPenalty }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_presence_penalty",
                                  )
                                }}:
                              </strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.presencePenalty }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>Stop:</strong>
                              <span class="response-details-text">
                                {{
                                  modalContent.requestBody.stop &&
                                  modalContent.requestBody.stop.join(", ")
                                }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_result_includes",
                                  )
                                }}:
                              </strong>
                              <span class="response-details-text">
                                {{
                                  modalContent.requestBody.resultIncludes &&
                                  modalContent.requestBody.resultIncludes.join(
                                    ", ",
                                  )
                                }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>Session ID:</strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.sessionId }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>GPT Profile Id:</strong>
                              <span class="response-details-text">
                                {{ modalContent.gptProfileId }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_profile_name",
                                  )
                                }}:
                              </strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.name }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>Intro:</strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.intro }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_system",
                                  )
                                }}:
                              </strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.system }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_conversation_type",
                                  )
                                }}:
                              </strong>
                              <span class="response-details-text">
                                {{ modalContent.requestBody.conversationType }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row class="mt-0">
                                <v-col class="py-0 pr-0" cols="12" md="auto">
                                  <div class="font-weight-bold pt-2">
                                    {{
                                      this.$vuetify.lang.t(
                                        "$vuetify.gpt_history_feedback_message",
                                      )
                                    }}:
                                  </div>
                                </v-col>
                                <template v-if="!isEditing">
                                  <v-col class="py-0 w-100">
                                    <div
                                      class="response-details-text pt-2 d-inline"
                                    >
                                      {{
                                        modalContent.feedback &&
                                        modalContent.feedback.message !==
                                          undefined &&
                                        modalContent.feedback.message !==
                                          null &&
                                        modalContent.feedback.message !== ""
                                          ? modalContent.feedback.message
                                          : updatedData.message !== undefined &&
                                              updatedData.message !== null &&
                                              updatedData.message !== ""
                                            ? updatedData.message
                                            : "N/A"
                                      }}
                                    </div>
                                    <v-btn @click="toggleEditing" icon>
                                      <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                  </v-col>
                                </template>
                                <template v-else>
                                  <v-col class="py-2 w-100">
                                    <v-textarea
                                      v-if="isEditing"
                                      v-model="editedFeedback"
                                      label=""
                                      placeholder="Write your feedback here..."
                                      auto-grow
                                      :rows="dynamicRowCount"
                                      max-rows="8"
                                      outlined
                                      dense
                                    ></v-textarea>
                                    <v-form ref="feedbackTextarea">
                                      <div
                                        class="d-flex flex-column flex-sm-row justify-space-between align-center"
                                      >
                                        <div>
                                          <v-radio-group
                                            v-if="isEditing"
                                            v-model="editedFeedbackType"
                                            row
                                            class="response-details-text mt-0 flex-column flex-sm-row"
                                            :rules="[
                                              v =>
                                                !!v ||
                                                $vuetify.lang.t(
                                                  '$vuetify.insights.feedback_type_required',
                                                ),
                                            ]"
                                          >
                                            <v-radio
                                              :label="
                                                $vuetify.lang.t(
                                                  '$vuetify.insights.positive',
                                                )
                                              "
                                              value="positive"
                                            ></v-radio>
                                            <v-radio
                                              :label="
                                                $vuetify.lang.t(
                                                  '$vuetify.insights.negative',
                                                )
                                              "
                                              value="negative"
                                            ></v-radio>
                                          </v-radio-group>
                                        </div>
                                        <div>
                                          <v-row
                                            align="center"
                                            v-if="isEditing"
                                          >
                                            <v-col>
                                              <v-btn
                                                outlined
                                                @click="cancelEditing"
                                                class="mr-2"
                                                color="red"
                                              >
                                                {{
                                                  $vuetify.lang.t(
                                                    "$vuetify.common.cancel",
                                                  )
                                                }}
                                              </v-btn>
                                              <v-btn
                                                @click.prevent="
                                                  saveEditedFeedback
                                                "
                                                color="primary"
                                                outlined
                                              >
                                                {{
                                                  $vuetify.lang.t(
                                                    "$vuetify.common.save",
                                                  )
                                                }}
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </div>
                                      </div>
                                    </v-form>
                                  </v-col>
                                </template>
                              </v-row>
                              <template>
                                <v-snackbar
                                  v-model="snackbar"
                                  color="success"
                                  top
                                  timeout="1000"
                                >
                                  {{ snackbarMessage }}
                                </v-snackbar>
                              </template>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>Sections:</strong>
                              <span class="response-details-text">
                                {{
                                  modalContent.sections &&
                                  modalContent.sections.join(", ")
                                }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_context_preference",
                                  )
                                }}:
                              </strong>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <p style="margin-left: 20px; font-weight: 500">
                                Preferred:
                              </p>
                              <span style="margin-left: 40px">Dataset ID:</span>
                              <span class="response-details-text">
                                {{
                                  modalContent.requestBody.contextPreference &&
                                  modalContent.requestBody.contextPreference
                                    .preferred &&
                                  modalContent.requestBody.contextPreference
                                    .preferred.datasetId
                                }}
                              </span>
                              <p></p>
                              <span style="margin-left: 40px">Project ID:</span>
                              <span class="response-details-text">
                                {{
                                  modalContent.requestBody.contextPreference &&
                                  modalContent.requestBody.contextPreference
                                    .preferred &&
                                  modalContent.requestBody.contextPreference
                                    .preferred.projectId
                                }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <p style="margin-left: 20px; font-weight: 500">
                                Excludes:
                              </p>
                              <span style="margin-left: 40px">Client ID:</span>
                              <span class="response-details-text">
                                {{
                                  modalContent.requestBody.contextPreference &&
                                  modalContent.requestBody.contextPreference
                                    .excludes &&
                                  modalContent.requestBody.contextPreference
                                    .excludes.clientId
                                }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_conversation_metadata",
                                  )
                                }}:
                              </strong>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span style="margin-left: 20px; font-weight: 500">
                                Client ID:
                              </span>
                              <span>
                                {{
                                  modalContent.requestBody
                                    .conversationMetadata &&
                                  modalContent.requestBody.conversationMetadata
                                    .clientId
                                }}
                              </span>
                              <p></p>
                              <span style="margin-left: 20px; font-weight: 500"
                                >Project ID:</span
                              >
                              <span>
                                {{
                                  modalContent.requestBody
                                    .conversationMetadata &&
                                  modalContent.requestBody.conversationMetadata
                                    .projectId
                                }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>Usage:</strong>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span style="margin-left: 20px; font-weight: 500">
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_completion_tokens",
                                  )
                                }}:
                              </span>
                              <span>
                                {{
                                  modalContent.usage &&
                                  modalContent.usage.completionTokens
                                }}
                              </span>
                              <p></p>
                              <span style="margin-left: 20px; font-weight: 500">
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_prompt_tokens",
                                  )
                                }}:
                              </span>
                              <span>
                                {{
                                  modalContent.usage &&
                                  modalContent.usage.promptTokens
                                }}
                              </span>
                              <p></p>
                              <span style="margin-left: 20px; font-weight: 500">
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_query_tokens",
                                  )
                                }}:
                              </span>
                              <span>
                                {{
                                  modalContent.usage &&
                                  modalContent.usage.queryTokens
                                }}
                              </span>
                              <p></p>
                              <span style="margin-left: 20px; font-weight: 500">
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_total_tokens",
                                  )
                                }}:
                              </span>
                              <span>
                                {{
                                  modalContent.usage &&
                                  modalContent.usage.totalTokens
                                }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_vision_settings",
                                  )
                                }}:
                              </strong>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span style="margin-left: 20px; font-weight: 500">
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_image_context_enabled",
                                  )
                                }}:
                              </span>
                              <span>
                                {{
                                  modalContent.requestBody.visionSettings &&
                                  modalContent.requestBody.visionSettings
                                    .is_image_context_enabled
                                }}
                              </span>
                              <p></p>
                              <span style="margin-left: 20px; font-weight: 500">
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_resolution",
                                  )
                                }}:
                              </span>
                              <span>
                                {{
                                  modalContent.requestBody.visionSettings &&
                                  modalContent.requestBody.visionSettings
                                    .resolution
                                }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_references",
                                  )
                                }}:
                              </strong>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <template v-if="modalContent.references">
                          <v-data-table
                            :headers="gptReferenceHeaders"
                            :items="
                              getUniqueReferences(modalContent.references)
                            "
                            :items-per-page="5"
                            :single-expand="singleExpand"
                            :expanded.sync="expanded"
                            show-expand
                            item-key="section_id"
                          >
                            <template v-slot:[`item.content`]="{ item }">
                              <span>
                                {{
                                  item.content && item.content.substring(0, 60)
                                }}
                                ...
                              </span>
                            </template>
                            <template v-slot:expanded-item="{ headers, item }">
                              <td class="word-break" :colspan="headers.length">
                                <VueMarkdown>
                                  {{
                                    item.content.replace(
                                      /(^|\s)(\b(?!http|https)\w+):/g,
                                      "$1**$2**:",
                                    )
                                  }}
                                </VueMarkdown>
                              </td>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                              <v-icon small @click="redirectDocumentPage(item)">
                                mdi-pencil
                              </v-icon>
                            </template>
                          </v-data-table>
                        </template>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_conversation_context",
                                  )
                                }}:
                              </strong>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-card
                              flat
                              class="ml-4"
                              style="max-height: 400px; overflow-y: auto"
                            >
                              <v-expansion-panels flat>
                                <v-expansion-panel
                                  v-for="(
                                    context, index
                                  ) in modalContent.conversationContext"
                                  :key="index"
                                >
                                  <v-expansion-panel-header>
                                    <v-container fluid class="pa-0">
                                      <v-row no-gutters align="center">
                                        <v-col
                                          cols="12"
                                          sm="4"
                                          md="2"
                                          class="d-flex align-center mb-1 mb-sm-0"
                                        >
                                          <span
                                            class="text-subtitle-2 font-weight-medium"
                                          >
                                            Conversation-{{ index + 1 }}
                                          </span>
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          sm="8"
                                          md="10"
                                          class="d-flex flex-wrap align-center"
                                        >
                                          <v-chip
                                            x-small
                                            label
                                            outlined
                                            class="mr-2 mb-1"
                                          >
                                            <span class="text-subtitle-2">{{
                                              context.user
                                            }}</span>
                                          </v-chip>
                                          <span class="text-caption grey--text">
                                            {{
                                              formatDateTime(context.timestamp)
                                            }}
                                          </span>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-card-text>
                                      <strong class="primary--text"
                                        >Assistant:</strong
                                      >
                                      <VueMarkdown>
                                        {{
                                          normalizeTextContent(
                                            context.assistant,
                                          )
                                        }}
                                      </VueMarkdown>
                                    </v-card-text>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-card>
                          </v-list-item-content>
                        </v-list-item>
                        <template v-if="modalContent.evaluation">
                          <v-card flat>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <strong
                                    >{{
                                      this.$vuetify.lang.t(
                                        "$vuetify.gpt_history_is_evaluation",
                                      )
                                    }}:</strong
                                  >
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-card-subtitle>
                              <v-chip color="primary" label small>
                                <v-icon left small>mdi-star</v-icon>
                                {{
                                  this.$vuetify.lang.t(
                                    "$vuetify.gpt_history_score",
                                  )
                                }}:
                                {{
                                  modalContent.evaluation.response_score || 0
                                }}
                              </v-chip>
                            </v-card-subtitle>
                          </v-card>
                          <v-data-table
                            v-if="
                              modalContent.evaluation.top_references &&
                              modalContent.evaluation.top_references.length
                            "
                            :headers="evaluationHeaders"
                            :items="modalContent.evaluation.top_references"
                            :items-per-page="5"
                            :expand-on-click="false"
                            :single-expand="true"
                            :mobile-breakpoint="0"
                            flat
                          >
                            <template v-slot:top>
                              <v-toolbar flat>
                                <v-toolbar-title
                                  class="text-subtitle-1 text-sm-h6"
                                >
                                  Top References
                                </v-toolbar-title>
                              </v-toolbar>
                            </template>
                            <template
                              v-slot:item="{ item, expand, isExpanded }"
                            >
                              <tr>
                                <td
                                  v-for="header in evaluationHeaders"
                                  :key="header.value"
                                  :class="{
                                    'text-truncate':
                                      $vuetify.breakpoint.smAndDown,
                                  }"
                                >
                                  <template v-if="header.value === 'score'">
                                    <v-chip
                                      :color="getScoreColor(item.score)"
                                      dark
                                      small
                                    >
                                      <span class="subtitle-2">{{
                                        item.score
                                      }}</span>
                                    </v-chip>
                                  </template>
                                  <template
                                    v-else-if="
                                      header.value === 'source' &&
                                      item.source === 'conversation_context'
                                    "
                                  >
                                    <v-btn
                                      text
                                      x-small
                                      @click="expand(!isExpanded)"
                                    >
                                      {{ item.source }}
                                      <v-icon small>{{
                                        isExpanded
                                          ? "mdi-chevron-up"
                                          : "mdi-chevron-down"
                                      }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <template
                                    v-else-if="
                                      item.source === 'conversation_context' &&
                                      (header.value === 'dataset_id' ||
                                        header.value === 'index')
                                    "
                                  >
                                    N/A
                                  </template>
                                  <template
                                    v-else-if="header.value === 'dataset_id'"
                                  >
                                    {{ item.dataset_id || "N/A" }}
                                  </template>
                                  <template
                                    v-else-if="header.value === 'index'"
                                  >
                                    {{ formatIndexValues(item) }}
                                  </template>
                                  <template v-else>
                                    {{ item[header.value] }}
                                  </template>
                                </td>
                              </tr>
                            </template>
                            <template v-slot:expanded-item="{ item, headers }">
                              <td :colspan="headers.length">
                                <template
                                  v-if="item.source === 'conversation_context'"
                                >
                                  <v-sheet
                                    v-if="
                                      modalContent.conversationContext &&
                                      modalContent.conversationContext.length
                                    "
                                    max-height="300px"
                                    class="overflow-y-auto"
                                  >
                                    <v-list dense class="py-0">
                                      <v-list-item
                                        v-for="(
                                          context, index
                                        ) in modalContent.conversationContext"
                                        :key="index"
                                      >
                                        <v-list-item-content>
                                          <v-list-item-subtitle
                                            class="text--primary text-caption text-sm-body-2"
                                          >
                                            <strong>User:</strong>
                                            {{ context.user }}
                                          </v-list-item-subtitle>
                                          <v-list-item-subtitle
                                            class="text-wrap text-caption text-sm-body-2"
                                          >
                                            <strong>Assistant:</strong>
                                            {{ context.assistant }}
                                          </v-list-item-subtitle>
                                          <v-list-item-subtitle
                                            class="text-caption"
                                          >
                                            <strong>Timestamp:</strong>
                                            {{
                                              formatDateTime(context.timestamp)
                                            }}
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-sheet>
                                </template>
                                <template v-else>
                                  <v-card flat>
                                    <v-card-text
                                      class="text-caption text-sm-body-2"
                                    >
                                      {{ item.content }}
                                    </v-card-text>
                                  </v-card>
                                </template>
                              </td>
                            </template>
                          </v-data-table>
                        </template>
                        <!-- end Evaluation -->
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="primary" text @click="close">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <!-- Add Gpt Response to Training Data Modal -->
    <template>
      <v-dialog persistent v-model="openTrainModal" max-width="60%">
        <v-card style="overflow-y: scroll; overflow-x: hidden">
          <div class="d-flex flex-column modalWrapper">
            <p class="training-header">
              {{
                $vuetify.lang.t("$vuetify.add_gpt_response_to_training_data")
              }}
            </p>
            <label class="text-subtitle-1 text-medium-emphasis">
              {{ $vuetify.lang.t("$vuetify.gpt_history_conversation_id") }}:
              <span class="conversation_id">
                {{ trainingData.conversation_id }}
              </span>
            </label>

            <v-form ref="documentForm">
              <div class="inputWrapper">
                <v-select
                  @change="handleDataset"
                  v-model="selectedDataset"
                  :items="datasets"
                  item-text="name"
                  :label="$vuetify.lang.t('$vuetify.select_dataset')"
                  outlined
                  item-value="dataset_id"
                  :rules="getRules($vuetify.lang.t('$vuetify.select_dataset'))"
                >
                </v-select>
                <div v-if="!newDataSet" class="dummy-width"></div>
              </div>
            </v-form>
            <template v-if="newDataSet">
              <div>
                <v-form ref="datasetForm">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        type="text"
                        v-model="newDatasetName"
                        :label="$vuetify.lang.t('$vuetify.dataset_name')"
                        :rules="[
                          required($vuetify.lang.t('$vuetify.dataset_name')),
                        ]"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        type="text"
                        v-model="indexColumnName"
                        :label="$vuetify.lang.t('$vuetify.index_column_name')"
                        :rules="[
                          required(
                            $vuetify.lang.t('$vuetify.index_column_name'),
                          ),
                        ]"
                        outlined
                      />
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        type="text"
                        v-model="secondaryIndex"
                        :label="
                          $vuetify.lang.t('$vuetify.secondary_index_column')
                        "
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        type="text"
                        v-model="imageColumn"
                        :label="$vuetify.lang.t('$vuetify.image_url_column')"
                        outlined
                      />
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        type="text"
                        v-model="description"
                        :label="
                          $vuetify.lang.t('$vuetify.description_label_txt')
                        "
                        outlined
                      />
                    </v-col>
                    <v-checkbox
                      style="margin-left: 10px"
                      v-model="fillUpEmptyIndex"
                      :label="
                        $vuetify.lang.t('$vuetify.fill_up_index_if_empty')
                      "
                    ></v-checkbox>
                    <v-col cols="12" md="12">
                      <v-file-input
                        outlined
                        accept=".json, .csv, application/json, text/csv"
                        @change="handleFileChange"
                        :label="$vuetify.lang.t('$vuetify.dataset_file')"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-form>
                <div class="btn-wrapper" style="margin-top: 20px">
                  <v-btn
                    type="reset"
                    class="red--text font-weight-bold"
                    text
                    outlined
                    @click="closeTrainModal"
                    style="margin-right: 14px"
                  >
                    {{ this.$vuetify.lang.t("$vuetify.cancel_btn_txt") }}
                  </v-btn>
                  <v-btn
                    @click="createDataset()"
                    color="primary"
                    style=""
                    class="add-btn"
                  >
                    {{ $vuetify.lang.t("$vuetify.create_dataset") }}
                  </v-btn>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="inputWrapper">
                <v-textarea
                  type="text"
                  auto-grow
                  v-model="trainingData.query"
                  :label="$vuetify.lang.t('$vuetify.gpt_history_query')"
                  outlined
                />
                <input
                  class="checkbox"
                  type="checkbox"
                  name="myCheckbox"
                  v-model="selectedResponseFields.query"
                />
              </div>
              <div class="inputWrapper">
                <v-textarea
                  type="text"
                  auto-grow
                  v-model="trainingData.response"
                  :label="$vuetify.lang.t('$vuetify.gpt_history_response')"
                  outlined
                />
                <input
                  class="checkbox"
                  type="checkbox"
                  name="myCheckbox"
                  v-model="selectedResponseFields.response"
                />
              </div>
              <div class="inputWrapper">
                <v-textarea
                  type="text"
                  auto-grow
                  v-model="trainingData.feedback"
                  :label="$vuetify.lang.t('$vuetify.gpt_history_feedback')"
                  outlined
                />
                <input
                  class="checkbox"
                  type="checkbox"
                  name="myCheckbox"
                  v-model="selectedResponseFields.feedback"
                />
              </div>
              <v-form ref="conversationMetadata">
                <div class="inputWrapper">
                  <v-textarea
                    type="text"
                    auto-grow
                    v-model="trainingData.conversation_metadata"
                    :label="
                      $vuetify.lang.t(
                        '$vuetify.gpt_history_conversation_metadata',
                      )
                    "
                    outlined
                    :rules="
                      selectedResponseFields.conversation_metadata
                        ? [validateConversationMetadata]
                        : []
                    "
                  />
                  <input
                    class="checkbox"
                    type="checkbox"
                    name="myCheckbox"
                    v-model="selectedResponseFields.conversation_metadata"
                  />
                </div>
              </v-form>
              <div class="btn-wrapper">
                <v-btn
                  type="reset"
                  class="red--text font-weight-bold"
                  text
                  outlined
                  @click="closeTrainModal"
                  style="margin-right: 14px"
                >
                  {{ this.$vuetify.lang.t("$vuetify.cancel_btn_txt") }}
                </v-btn>
                <v-btn
                  @click="addTrainingData"
                  color="primary"
                  style="margin-right: 44px; min-width: 120px"
                  class="add-btn"
                >
                  {{ $vuetify.lang.t("$vuetify.common.add") }}
                </v-btn>
              </div>
            </template>
          </div>
        </v-card>
      </v-dialog>
    </template>
    <!--Conversation History File Generation and History Modal -->
    <template v-if="gptConversationFileGenerationModal">
      <FileGenerationModal
        :open="gptConversationFileGenerationModal"
        :closeModal="closeGptConversationFileGenerationModal"
        :title="
          $vuetify.lang.t(
            '$vuetify.logs_file_generation.conversation_logs_file_generation_title',
          )
        "
        :history_type="HISTORY_TYPES.CONVERSATION"
      />
    </template>
    <template v-if="gptConversationHistoryModal">
      <FileGeneratedHistoryModal
        :open="gptConversationHistoryModal"
        :closeModal="closeGptConversationHistoryModal"
        :title="
          $vuetify.lang.t(
            '$vuetify.logs_file_generation.conversation_logs_file_generated_history_title',
          )
        "
        :history_type="HISTORY_TYPES.CONVERSATION"
      />
    </template>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VueMarkdown from "vue-markdown";
import {
  ADD_CONVERSATION_TO_DATASET,
  CREATE_DATASET,
  GET_GPT_DATASETS,
  RECOMMENDER_INSIGHTS,
  RECOMMENDER_UPDATE_FEEDBACK,
  RETRIEVE_GPT_CONVERSATIONS,
  UPLOAD_DATASET,
} from "@/store/_actiontypes";
import { SEGMENTS, HISTORY_TYPES } from "@/global/_constants";
import { DATETIME_PICKER, TEXT } from "@/components/common/input/_inputTypes";
import {
  capitalizeFirstLetter,
  dateOfToday,
  getUniqueArrayOfObjects,
} from "@/helpers/helper";
import Loader from "@/components/common/loader/Loader";
import VueApexCharts from "vue-apexcharts";
import TopBrowsedItems from "./Insights/TopBrowsedItems.vue";
import TopPurchasedItems from "./Insights/TopPurchasedItems.vue";
import TopRatedItems from "./Insights/TopRatedItems.vue";
import RepeatedUsers from "./Insights/RepeatedUsers.vue";
import QuestionnaireLog from "./Insights/QuestionnaireLog.vue";
import { debounce } from "lodash";
import FileGenerationModal from "@/components/common/FileGenerationModal";
import FileGeneratedHistoryModal from "@/components/common/FileGeneratedHistoryModal";

export default {
  props: {},
  components: {
    VueMarkdown,
    Loader,
    apexchart: VueApexCharts,
    TopBrowsedItems,
    TopPurchasedItems,
    TopRatedItems,
    RepeatedUsers,
    QuestionnaireLog,
    FileGenerationModal,
    FileGeneratedHistoryModal,
  },
  computed: {
    ...mapState({
      selectedProject: state => state.project.selectedProject,
      insights: state => state.insight.recommender_insights,
      gptConversationsGlobal: state => {
        return state.gpt.gpt_conversations.conversations;
      },
      gptTotalConversations: state => state.gpt.gpt_conversations.total,
      maxDate() {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1);
        return this.formatDate(currentDate);
      },
      dateRangeText: function () {
        return this.dates.join(" ~ ");
      },
    }),
    ...mapGetters("loader", ["loading"]),
    overview: function () {
      return this.insights.overview;
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
    itemChartData() {
      const { browse, purchase, ratings } = this.insights.kpi_summary || {};
      const data = [
        { x: "Browsed", y: browse?.total_browsed_items || 0 },
        { x: "Positive Rated", y: ratings?.total_positive_rated_items || 0 },
        { x: "Negative Rated", y: ratings?.total_negative_rated_items || 0 },
        { x: "Purchased", y: purchase?.total_purchased_items || 0 },
      ];
      return data.every(({ y }) => y === 0) ? [] : data;
    },
    userChartData() {
      const { browse, purchase, ratings } = this.insights.kpi_summary || {};
      const data = [
        { x: "Browsed", y: browse?.total_unique_users_browsed || 0 },
        { x: "Rated", y: ratings?.total_unique_users_rated || 0 },
        { x: "Purchased", y: purchase?.total_unique_users_purchased || 0 },
      ];
      return data.every(({ y }) => y === 0) ? [] : data;
    },
    kpiSummary: function () {
      return this.insights.kpi_summary;
    },
    deviceRatio: function () {
      return this.insights.device_ratio ?? {};
    },
    browserRatio: function () {
      return this.insights.browser_ratio ?? {};
    },
    osRatio: function () {
      return this.insights.os_ratio ?? {};
    },
    requestDetails: function () {
      return this.insights?.request_details
        ? this.insights?.request_details
        : [];
    },
    gptDetails: function () {
      let gpt_history = this.insights.gpt_history;
      if (!gpt_history) {
        gpt_history = [];
      }
      return gpt_history;
    },
    filteredGptDetails() {
      return this.gptDetails.filter(
        item =>
          (!this.userFilter ||
            item.user_id
              ?.toLowerCase()
              .includes(this.userFilter.toLowerCase())) &&
          (!this.feedbackFilter ||
            (item.feedback && item.feedback.type === this.feedbackFilter) ||
            (item.feedback === null && this.feedbackFilter === "N/A")) &&
          (!this.promptFilter ||
            item.query
              .toLowerCase()
              .includes(this.promptFilter.toLowerCase())) &&
          (!this.endpointFilter ||
            (item.request_path &&
              this.determineEndpointType(item.request_path) ===
                this.endpointFilter) ||
            (item.request_path === null && this.endpointFilter === "N/A")),
      );
    },

    isDownloadButtonVisible() {
      return (
        this.filteredGptDetails.length > 0 || this.gptConversations.length > 0
      );
    },
    formattedPercentages() {
      return {
        percentageRecommend: this.percentageFormatted("percentageRecommend"),
        percentageSearch: this.percentageFormatted("percentageSearch"),
        percentageOrders: this.percentageFormatted("percentageOrders"),
        percentageRevenue: this.percentageFormatted("percentageRevenue"),
        percentageAvgCartSize: this.percentageFormatted(
          "percentageAvgCartSize",
        ),
        percentageAvgSaleOrder: this.percentageFormatted(
          "percentageAvgSaleOrder",
        ),
        percentageAvgSaleUser: this.percentageFormatted(
          "percentageAvgSaleUser",
        ),
        percentageAvgSaleItems: this.percentageFormatted(
          "percentageAvgSaleItems",
        ),
      };
    },
    topUsers() {
      return (
        (this.kpiSummary &&
          this.kpiSummary.purchase &&
          this.kpiSummary.purchase.top_users) ||
        []
      );
    },
    repeatedUsers() {
      return this.insights.repeat_users || [];
    },
  },
  data() {
    return {
      isLoading: false,
      PROJECT_SEGMENTS: SEGMENTS,
      HISTORY_TYPES: HISTORY_TYPES,
      gptConversationFileGenerationModal: false,
      gptConversationHistoryModal: false,
      expanded: [],
      singleExpand: true,
      capitalizeFirstLetter,
      dateOfToday,
      TEXT: TEXT,
      DATETIME_PICKER: DATETIME_PICKER,
      start_date: "",
      end_date: "",
      last_insights_updated_at: "",
      menuDates: false,
      editedFeedback: "",
      editedFeedbackType: "",
      isEditing: false,
      dynamicRowCount: 1,
      showFeedbackTypeError: false,
      updateMessage: "",
      updatedData: {
        conversation_id: null,
        message: null,
        type: null,
      },
      gptConversationHistoryLoading: true,
      gptConversationHistoryOptions: {},
      snackbarMessage: "",
      dates: [],
      on: {},
      activeTab: "item",
      selectedOption: null,
      tab: null,
      userHeaders: [
        { text: "User ID", value: "user_id", sortable: false },
        { text: "Member ID", value: "member_id", sortable: false },
        { text: "Purchased Amount", value: "purchased_amount", sortable: true },
      ],
      selectedDataset: "",
      dataNotSelectedError: false,
      datasets: [
        {
          name: this.$vuetify.lang.t("$vuetify.add_new_dataset"),
          dataset_id: "new_dataset",
        },
      ],
      selectedResponseFields: {
        query: true,
        response: true,
        feedback: true,
        conversation_metadata: false,
      },
      gptReferenceHeaders: [
        {
          text: "Content",
          align: "start",
          value: "content",
        },
        {
          text: "Dataset ID",
          align: "start",
          value: "dataset_id",
        },
        {
          text: "Index",
          align: "start",
          value: "index",
        },
        {
          text: "Similarity Score",
          align: "start",
          value: "similarity_score",
        },
        {
          text: "Section ID",
          align: "start",
          value: "section_id",
        },
        { text: "Action", value: "action" },
      ],
      evaluationHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.insights.source"),
          value: "source",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.insights.score"),
          value: "score",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.insights.dataset_id"),
          value: "dataset_id",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.insights.index"),
          value: "index",
        },
      ],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.request_path_header_txt"),
          align: "start",
          sortable: true,
          value: "request_path",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.number_of_request_header_txt"),
          align: "start",
          sortable: true,
          value: "number_of_request",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.avg_processing_time_header_txt"),
          align: "start",
          sortable: true,
          value: "avg_processing_time",
        },
      ],
      gptHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history_user_id"),
          align: "start",
          sortable: false,
          value: "requestBody.userId",
        },
        // {
        //   text: this.$vuetify.lang.t("$vuetify.gpt_history_conversation_id"),
        //   align: "start",
        //   sortable: true,
        //   value: "conversation_id",
        // },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history_query"),
          align: "start",
          sortable: false,
          value: "requestBody.query",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history_feedback"),
          align: "start",
          sortable: false,
          value: "feedback",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history_consumed_tokens"),
          align: "start",
          sortable: true,
          value: "tokens",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history_endpoint"),
          align: "start",
          sortable: false,
          value: "requestUrl",
        },
        // {
        //   text: this.$vuetify.lang.t("$vuetify.gpt_history_response"),
        //   align: "start",
        //   sortable: true,
        //   value: "response_msg",
        // },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history_date"),
          align: "start",
          sortable: true,
          value: "createdAt",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.details_header_txt"),
          align: "center",
          sortable: false,
          value: "actions",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.add_to_training_data"),
          align: "center",
          sortable: false,
          value: "gpt_train",
        },
      ],
      optionalDataMapper: {
        secondary_idx_column: "secondaryIndex",
        image_url_column: "imageColumn",
        description: "description",
        dataset_file: "datasetFile",
      },
      required: fieldName => {
        return value => !!value || `${fieldName} is required`;
      },
      getRules(fieldName) {
        const rules = [];
        if (!this.newDataSet) {
          rules.push(value => !!value || `${fieldName} is required`);
        }
        return rules;
      },
      snackbar: false,
      timeout: 6000,
      snackbarColor: "success",
      newDataSet: false,
      newDatasetName: "",
      indexColumnName: "",
      secondaryIndex: "",
      fillUpEmptyIndex: true,
      imageColumn: "",
      description: "",
      datasetFile: null,
      itemsPerPage: 5,
      currentPage: 1,
      modalContent: {},
      openModal: false,
      openTrainModal: false,
      trainingData: {},
      userFilter: "",
      conversationSearch: "",
      conversationFilter: "",
      promptFilter: "",
      feedbackFilter: "",
      endpointFilter: "",
      feedbackOptions: ["positive", "negative", "N/A"],
      percentageChange: 0,
      percentageRevenue: 0,
      percentageOrders: 0,
      percentageAvgSaleUser: 0,
      percentageAvgSaleOrder: 0,
      percentageAvgSaleItems: 0,
      percentageAvgCartSize: 0,
      percentageRecommend: 0,
      percentageSearch: 0,
      totalNumberOfSearches: 0,
      totalNumberOfRecommendations: 0,
      averageCartSize: 0,
      oldValue: 0,
      newValue: 0,
      isLineChart: false,
      endpointOptions: ["Ask", "Vision"],
      downloading: false,
      deviceRatioValue: [],
      browserRatioValue: [],
      osRatioValue: [],
      gptConversations: [],
      deviceRatioOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return (val * 100).toFixed(2) + "%";
            },
          },
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
              cursor: {
                show: true,
              },
            },
          },
        ],
      },
      browserRatioOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return (val * 100).toFixed(2) + "%";
            },
          },
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      osRatioOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return (val * 100).toFixed(2) + "%";
            },
          },
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      itemlineChart: {
        chart: {
          height: 400,
          type: "line",
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
            autoSelected: "zoom",
          },
        },
        plotOptions: {
          line: {
            curve: "smooth",
            markers: {
              size: 6,
              colors: ["#008FFB"],
              strokeColors: "#fff",
              strokeWidth: 2,
              hover: {
                size: 8,
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: [
            "Browsed",
            "Positive Rated",
            "Negative Rated",
            "Purchased",
          ],
        },
        yaxis: {
          logarithmic: true,
          min: 1,
        },
        tooltip: {
          x: {
            formatter: function () {
              return "";
            },
          },
          y: {
            formatter: function (val, { dataPointIndex, w }) {
              const categories = [
                "Browse",
                "Positive Rated",
                "Negative Rated",
                "Purchase",
              ];
              const label = categories[dataPointIndex];
              return `${label}: ${val.toLocaleString()}`;
            },
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          followCursor: true,
        },
        title: {
          text: this.$vuetify.lang.t("$vuetify.insights.items_counts"),
          align: "center",
          margin: 20,
          style: {
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            color: "#333333",
          },
        },
      },
      userlineChart: {
        chart: {
          height: 400,
          type: "line",
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
            autoSelected: "zoom",
          },
        },

        plotOptions: {
          line: {
            curve: "smooth",
            markers: {
              size: 6,
              colors: ["#008FFB"],
              strokeColors: "#fff",
              strokeWidth: 2,
              hover: {
                size: 8,
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: ["Browsed", "Rated", "Purchased"],
        },
        yaxis: {
          logarithmic: true,
          min: 2,
        },
        tooltip: {
          x: {
            formatter: function () {
              return "";
            },
          },
          y: {
            formatter: function (val, { dataPointIndex, w }) {
              const categories = ["Browse", "Rated", "Purchase"];
              const label = categories[dataPointIndex];
              return `${label}: ${val.toLocaleString()}`;
            },
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          followCursor: true,
        },
        title: {
          text: this.$vuetify.lang.t("$vuetify.insights.users_counts"),
          align: "center",
          margin: 20,
          style: {
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            color: "#333333",
          },
        },
      },
      itemBarChart: {
        chart: {
          type: "bar",
          height: 400,
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
            autoSelected: "zoom",
          },
        },
        colors: ["#008FFB", "#FF4560", "#33B2DF", "#00E396"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
            dataLabels: {
              position: "bottom",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Browse",
            "Positive Rated",
            "Negative Rated",
            "Purchase",
          ],
          labels: {
            style: {
              colors: "#000000",
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
        },
        yaxis: {
          logarithmic: true,
          min: 1,
          // tickAmount: 'dataPoints',
          // forceNiceScale: false
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          x: {
            formatter: function () {
              return "";
            },
          },
          y: {
            formatter: function (val, { dataPointIndex, w }) {
              const categories = [
                "Browse",
                "Positive Rated",
                "Negative Rated",
                "Purchase",
              ];
              const label = categories[dataPointIndex];
              return `${label}: ${val.toLocaleString()}`;
            },
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          followCursor: true,
        },
        title: {
          text: this.$vuetify.lang.t("$vuetify.insights.items_counts"),
          align: "center",
          margin: 20,
          style: {
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            color: "#333333",
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                height: 300,
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
              yaxis: {
                logarithmic: true,
                labels: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
              title: {
                style: {
                  fontSize: "16px",
                },
              },
            },
          },
        ],
      },
      userBarChart: {
        chart: {
          type: "bar",
          height: 400,
          toolbar: {
            show: false,
            tools: {
              download: false,
              // selection: true,
              // zoom: true,
              // zoomin: true,
              // zoomout: true,
              // pan: true,
              // reset: true | '<img src="/static/icons/reset.png" width="20">',
              // customIcons: [],
            },
            autoSelected: "zoom",
          },
        },
        colors: ["#008FFB", "#FF4560", "#33B2DF", "#00E396"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
            dataLabels: {
              position: "bottom",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["Browsed", "Rated", "Purchase"],
          labels: {
            style: {
              colors: "#000000",
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
        },
        yaxis: {
          logarithmic: true,
          min: 2,
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          x: {
            formatter: function () {
              return "";
            },
          },
          y: {
            formatter: function (val, { dataPointIndex, w }) {
              const categories = ["Browse", "Rated", "Purchase"];
              const label = categories[dataPointIndex];
              return `${label}: ${val.toLocaleString()}`;
            },
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          followCursor: true,
        },
        title: {
          text: this.$vuetify.lang.t("$vuetify.insights.users_counts"),
          align: "center",
          margin: 20,
          style: {
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            color: "#333333",
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                height: 300,
              },
              xaxis: {
                min: 0,
                max: 100,
                labels: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
              yaxis: {
                min: 0,
                max: 100,
                labels: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
              title: {
                style: {
                  fontSize: "16px",
                },
              },
            },
          },
        ],
      },
    };
  },
  created() {
    if (this.selectedProject.segment.name !== "Maira") {
      this.getInsights();
    }

    // Creating a debounced version of the search method
    this.debouncedSearch = debounce(this.searchConversations, 500);
  },

  watch: {
    dates() {
      this.start_date = this.dates[0];
      this.end_date = this.dates[1];
    },
    conversationSearch() {
      this.debouncedSearch();
    },
    feedbackFilter() {
      this.debouncedSearch();
    },
    endpointFilter() {
      this.debouncedSearch();
    },
    gptConversationHistoryOptions: {
      handler() {
        this.handlePageChange();
      },
      deep: true,
    },
    gptConversationsGlobal(newValue, oldVal) {
      this.gptConversations = newValue;
    },
    deviceRatio(newDeviceRatio) {
      this.updateRatioOptions(
        newDeviceRatio,
        "deviceRatioOptions",
        "deviceRatioValue",
      );
    },
    browserRatio(newBrowserRatio) {
      this.updateRatioOptions(
        newBrowserRatio,
        "browserRatioOptions",
        "browserRatioValue",
      );
    },
    osRatio(newOsRatio) {
      this.updateRatioOptions(newOsRatio, "osRatioOptions", "osRatioValue");
    },
  },
  mounted() {},
  methods: {
    ...mapActions("insight", [
      RECOMMENDER_INSIGHTS,
      RECOMMENDER_UPDATE_FEEDBACK,
    ]),
    ...mapActions("project", [GET_GPT_DATASETS]),
    ...mapActions("dataset", [
      CREATE_DATASET,
      ADD_CONVERSATION_TO_DATASET,
      UPLOAD_DATASET,
    ]),
    ...mapActions("gpt", [RETRIEVE_GPT_CONVERSATIONS]),
    capitalizeLabel(label) {
      return label.charAt(0).toUpperCase() + label.slice(1);
    },
    updateRatioOptions(ratioData, optionsKey, valueKey) {
      const labels = Object.keys(ratioData);
      const values = Object.values(ratioData);

      this[optionsKey].labels = labels.map(this.capitalizeLabel);
      this[valueKey] = values;
    },
    getAllDatasets() {
      this.GET_GPT_DATASETS({
        project_id: this.selectedProject.id,
        start: 0,
        size: 999,
      })
        .then(res => {
          this.datasets = [...this.datasets, ...res.data.datasets];
        })
        .catch(e => {
          console.error("Error on getting GPT datasets", e);
        });
    },
    sortConversations({ sortBy, sortDesc }) {
      this.gptConversations = this.gptConversations.sort((a, b) => {
        const sortA = a[sortBy[0]];
        const sortB = b[sortBy[0]];

        if (sortDesc[0]) {
          if (sortA < sortB) return 1;
          if (sortA > sortB) return -1;
          return 0;
        } else {
          if (sortA < sortB) return -1;
          if (sortA > sortB) return 1;
          return 0;
        }
      });
    },
    closeTrainModel() {
      this.openTrainModal = false;
    },
    handleDataset(value) {
      if (value === "new_dataset") {
        this.newDataSet = true;
        this.selectedDataset = "";
      } else {
        this.newDataSet = false;
      }
    },
    handleFileChange(file) {
      this.datasetFile = file;
    },
    createDataset(e) {
      if (!this.$refs.datasetForm.validate()) {
        this.showSnackBar(
          "error",
          this.$vuetify.lang.t("$vuetify.common.add") +
            " " +
            this.$vuetify.lang.t("$vuetify.common.required_field"),
        );
        return;
      } else {
        const datasetRequestBody = {};
        datasetRequestBody.name = this.newDatasetName;
        datasetRequestBody.idx_column_name = this.indexColumnName;
        datasetRequestBody.is_idx_fillup_if_empty = this.fillUpEmptyIndex;

        for (const key in this.optionalDataMapper) {
          if (
            this.optionalDataMapper.hasOwnProperty(key) &&
            this[this.optionalDataMapper[key]]
          ) {
            datasetRequestBody[key] = this[this.optionalDataMapper[key]];
          }
        }
        delete datasetRequestBody["dataset_file"];

        this.CREATE_DATASET({
          project_id: this.selectedProject.id,
          datasetRequestBody,
        })
          .then(res => {
            this.selectedDataset = res.data.detail.dataset_id;
            this.datasets.push({
              name: this.newDatasetName,
              dataset_id: res.data?.detail?.dataset_id,
              idx_column_name: this.indexColumnName,
            });
            if (this.datasetFile) {
              this.UPLOAD_DATASET({
                project_id: this.selectedProject.id,
                dataset_id: res.data?.detail?.dataset_id,
                dataset_file: this.datasetFile,
              })
                .then(res => {
                  this.showSnackBar("success", "Dataset created successfully");
                })
                .catch(err => {
                  this.showSnackBar("error", "Failed to upload dataset");
                });
            }
            this.newDataSet = false;
            this.showSnackBar(
              "success",
              this.$vuetify.lang.t("$vuetify.project.dataset_create_success"),
            );
          })
          .catch(err => {
            console.error("Error on creating dataset", err);
            this.showSnackBar(
              "error",
              this.$vuetify.lang.t("$vuetify.project.dataset_create_failed"),
            );
          });
      }
    },
    showSnackBar(type = "success", message = "Success") {
      this.snackbar = true;
      switch (type) {
        case "success":
          this.snackbarMessage = message;
          this.snackbarColor = "success";
          break;
        case "error":
          this.snackbarMessage = message;
          this.snackbarColor = "error";
          break;
      }
    },
    validateConversationMetadata(value) {
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return this.$vuetify.lang.t(
          "$vuetify.gpt_history_conversation_metadata_invalid",
        );
      }
    },
    addTrainingData(e) {
      const isConversationMetadataValid = this.selectedResponseFields
        ?.conversation_metadata
        ? this.$refs.conversationMetadata.validate()
        : true;
      const isDocumentFormValid = this.$refs.documentForm.validate();
      if (!isConversationMetadataValid || !isDocumentFormValid) {
        this.showSnackBar(
          "error",
          this.$vuetify.lang.t("$vuetify.common.add") +
            " " +
            this.$vuetify.lang.t("$vuetify.common.required_field"),
        );
        return;
      }
      const document = {};
      for (const key in this.selectedResponseFields) {
        if (
          this.selectedResponseFields.hasOwnProperty(key) &&
          this.selectedResponseFields[key]
        ) {
          if (key === "conversation_metadata") {
            try {
              const jsonData = JSON.parse(this.trainingData[key]);
              document[key] = jsonData;
            } catch (error) {
              return;
            }
          } else {
            document[key] = this.trainingData[key];
          }
        }
      }
      document["conversation_id"] = this.trainingData?.conversation_id;
      const is_background_task = false;
      const documents = [document];
      this.ADD_CONVERSATION_TO_DATASET({
        project_id: this.selectedProject?.id,
        dataset_id: this.selectedDataset,
        documents,
        is_background_task,
      })
        .then(res => {
          this.openTrainModal = false;
          this.showSnackBar(
            "success",
            this.$vuetify.lang.t("$vuetify.gpt_history_add_document_success"),
          );
        })
        .catch(err => {
          this.showSnackBar(
            "error",
            this.$vuetify.lang.t("$vuetify.gpt_history_add_document_failed"),
          );
          console.error("Error on adding conversation to dataset", err);
        });
    },

    determineEndpointType(requestPath) {
      const path = requestPath && (requestPath.type || requestPath);
      return path.includes("/v1/gpt/ask/vision")
        ? "Vision"
        : path.includes("/v1/gpt/ask")
          ? "Ask"
          : "N/A";
    },
    getUniqueReferences(references) {
      return getUniqueArrayOfObjects(references, "section_id");
    },

    formatDate(date) {
      return date.toISOString().split("T")[0];
    },

    handlePageChange() {
      let { sortBy, sortDesc, page, itemsPerPage } =
        this.gptConversationHistoryOptions;
      let sortName = "created_at";
      let sortType = "desc";
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sortName = sortBy[0] === "createdAt" ? sortName : sortBy[0];
        sortType = sortDesc[0] ? sortType : "asc";
      }

      if (this.dates.length === 1) {
        this.dates[1] = dateOfToday();
        this.end_date = dateOfToday();
      }
      let start = 0;
      if (itemsPerPage === -1) {
        itemsPerPage = this.gptTotalConversations;
      } else {
        start = (page - 1) * itemsPerPage;
      }
      const start_date = this.dates[0];
      const end_date = this.dates[1];
      this.getGptConversationHistory(
        start_date,
        end_date,
        start,
        itemsPerPage,
        sortName,
        sortType,
      );
    },
    searchConversations() {
      if (this.gptConversationHistoryOptions.page === 1) {
        this.handlePageChange();
        return;
      }
      this.gptConversationHistoryOptions.page = 1;
    },
    getGptConversationHistory(
      start_date,
      end_date,
      start = 0,
      size = 10,
      sortName = "created_at",
      sortType = "desc",
    ) {
      let project_id = this.selectedProject.id;
      this.RETRIEVE_GPT_CONVERSATIONS({
        project_id: project_id,
        start_date: start_date,
        end_date: end_date,
        start: start,
        size: size,
        query: this.promptFilter,
        feedback: this.feedbackFilter,
        endpoint: this.endpointFilter,
        userId: this.userFilter,
        sortName: sortName,
        sortType: sortType,
        search_text: this.conversationSearch,
      }).then(
        res => res,
        err => {
          console.error("Error on retrieving conversations", err);
        },
      );
    },
    redirectDocumentPage(item) {
      if (!item.dataset_id) {
        this.snackbarMessage = "Dataset ID is not available";
        this.snackbarColor = "error";
        this.snackbar = true;
        return;
      }
      this.$router.push(
        "/projects/" +
          this.selectedProject.id +
          "/datasets/" +
          item.dataset_id +
          "/documents?section_id=" +
          item.section_id,
      );
    },
    getInsights() {
      this.isLoading = true;
      let project_id = this.selectedProject.id;
      if (this.dates.length === 1) {
        this.dates[1] = dateOfToday();
        this.end_date = dateOfToday();
      }
      this.RECOMMENDER_INSIGHTS({
        project_id: project_id,
        start_date: this.start_date ? this.start_date : "",
        end_date: this.end_date ? this.end_date : "",
      }).then(
        _response => {
          this.dates = [this.insights.start_date, this.insights.end_date];
          this.last_insights_updated_at = this.insights
            .last_insight_processed_time
            ? new Date(
                this.insights.last_insight_processed_time,
              ).toLocaleString(`${this.$vuetify.lang.current}-CA`, {
                timeZoneName: "short",
              })
            : "N/A";
          this.percentageAvgCartSize = this.insights.percentage_avg_cart_size;
          this.percentageAvgSaleItems = this.insights.percentage_avg_sale_items;
          this.percentageAvgSaleOrder = this.insights.percentage_avg_sale_order;
          this.percentageAvgSaleUser = this.insights.percentage_avg_sale_user;
          this.percentageOrders = this.insights.percentage_orders;
          this.percentageRecommend = this.insights.percentage_recommendations;
          this.percentageRevenue = this.insights.percentage_revenue;
          this.percentageSearch = this.insights.percentage_searches;
          this.totalNumberOfSearches = this.insights.total_number_of_searches;
          this.totalNumberOfRecommendations =
            this.insights.total_number_of_recommendations;
          this.averageCartSize = this.insights.average_cart_size;
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
        },
      );
    },
    saveEditedFeedback() {
      if (!this.$refs.feedbackTextarea.validate()) {
        return;
      }
      this.updateFeedback();
    },
    updateFeedback() {
      this.updateFeedbackFunction(this.selectedProject.id);
    },
    updateFeedbackFunction(project_id) {
      const updateFeedback = {
        conversation_id: this.modalContent.id,
        type: this.editedFeedbackType,
      };
      if (
        this.editedFeedback !== undefined &&
        this.editedFeedback.trim() !== ""
      ) {
        updateFeedback.message = this.editedFeedback;
      }
      this.RECOMMENDER_UPDATE_FEEDBACK({
        project_id: project_id,
        feedback: updateFeedback,
      }).then(
        _response => {
          this.cancelEditing();
          const updatedData = {
            conversation_id: updateFeedback.conversation_id,
            message: updateFeedback.message,
            type: updateFeedback.type,
          };
          this.modalContent.feedback = {
            message: updatedData.message,
            type: updatedData.type,
          };
          this.showAlert("Feedback updated successfully!");
          this.isEditing = false;
        },
        error => {
          console.error("Error updating insights:", error);
        },
      );
    },
    toggleEditing() {
      this.isEditing = !this.isEditing;
      if (this.isEditing && this.modalContent.feedback) {
        if (this.modalContent.feedback.message) {
          this.editedFeedback = this.modalContent.feedback.message;
        } else {
          this.editedFeedback = "";
        }
        if (this.modalContent.feedback.type) {
          this.editedFeedbackType = this.modalContent.feedback.type;
        } else {
          this.editedFeedbackType = "";
        }
      } else {
        this.editedFeedback = "";
        this.editedFeedbackType = "";
      }
    },
    showAlert(message) {
      this.snackbarMessage = message;
      this.snackbar = true;
    },
    cancelEditing() {
      this.isEditing = false;
      this.editedFeedback = "";
      this.editedFeedbackType = "";
      this.showFeedbackTypeError = false;
      this.updateMessage = "";
    },
    percentageFormatted(propertyName) {
      if (this[propertyName] === "N/A" || this[propertyName] === "---") {
        return this[propertyName];
      } else {
        return this[propertyName].toFixed(2) + "%";
      }
    },
    percentageColor(propertyName) {
      const percentage = this[propertyName];
      return percentage > 0 ? "green" : percentage < 0 ? "red" : "";
    },
    percentageIcon(propertyName) {
      const percentage = this[propertyName];
      if (isNaN(percentage) || !isFinite(percentage)) {
        return "";
      }
      return percentage > 0 ? "mdi-arrow-up" : "mdi-arrow-down";
    },
    detail_modal(data) {
      this.modalContent = data;
      this.openModal = true;
    },
    train_modal(data) {
      this.selectedResponseFields = {
        query: true,
        response: true,
        feedback: true,
        conversation_metadata: false,
      };
      this.openTrainModal = true;
      this.trainingData = {
        conversation_id: data.id,
        query: data.requestBody.query,
        response: data.response,
        feedback: data.feedback?.message ? data.feedback?.message : "",
        conversation_metadata: data.requestBody?.conversationMetadata
          ? JSON.stringify(data.requestBody?.conversationMetadata) === "{}"
            ? ""
            : JSON.stringify(data.requestBody?.conversationMetadata)
          : "",
      };
      this.getAllDatasets();
    },
    closeTrainModal() {
      this.openTrainModal = false;
    },
    close() {
      this.openModal = false;
      this.isEditing = false;
      this.showFeedbackTypeError = false;
    },
    setDates() {
      this.$refs.menuDates.save(this.dates);
      this.getInsights();
      if (this.dates.length === 1) {
        this.dates[1] = dateOfToday();
        this.end_date = dateOfToday();
      }
      const start_date = this.dates[0];
      const end_date = this.dates[1];
      this.getGptConversationHistory(start_date, end_date);
    },
    getScoreColor(score) {
      return score > 80
        ? "green"
        : score > 60
          ? "light-green"
          : score > 40
            ? "yellow"
            : score > 20
              ? "orange"
              : "red";
    },
    convertToCSV(headers, data) {
      const additionalFields = [
        this.$vuetify.lang.t("$vuetify.gpt_history_user_id"),
        this.$vuetify.lang.t("$vuetify.gpt_history_query"),
        this.$vuetify.lang.t("$vuetify.gpt_history_consumed_tokens"),
        this.$vuetify.lang.t("$vuetify.gpt_history_response"),
        this.$vuetify.lang.t("$vuetify.gpt_history_conversation_id"),
        this.$vuetify.lang.t("$vuetify.gpt_history_endpoint"),
        this.$vuetify.lang.t("$vuetify.gpt_history_date"),
        this.$vuetify.lang.t("$vuetify.gpt_history_processing_time"),
        this.$vuetify.lang.t("$vuetify.gpt_history_model"),
        "Chat History Length",
        this.$vuetify.lang.t("$vuetify.gpt_history_temperature"),
        "Top P",
        "Top K",
        this.$vuetify.lang.t("$vuetify.gpt_history_search_max_token"),
        this.$vuetify.lang.t("$vuetify.gpt_history_frequency_penalty"),
        this.$vuetify.lang.t("$vuetify.gpt_history_presence_penalty"),
        "Stop",
        this.$vuetify.lang.t("$vuetify.gpt_history_result_includes"),
        "Session ID",
        "GPT Profile Id",
        this.$vuetify.lang.t("$vuetify.gpt_history_profile_name"),
        "Intro",
        this.$vuetify.lang.t("$vuetify.gpt_history_system"),
        this.$vuetify.lang.t("$vuetify.gpt_history_conversation_type"),
        "Sections",
        this.$vuetify.lang.t("$vuetify.gpt_history_context_preference"),
        this.$vuetify.lang.t("$vuetify.gpt_history_conversation_metadata"),
        "Usage",
        this.$vuetify.lang.t("$vuetify.gpt_history_vision_settings"),
        this.$vuetify.lang.t("$vuetify.gpt_history_references"),
        this.$vuetify.lang.t("$vuetify.gpt_history_feedback_message"),
        this.$vuetify.lang.t("$vuetify.gpt_history_feedback"),
        this.$vuetify.lang.t("$vuetify.gpt_history_is_evaluation"),
        this.$vuetify.lang.t("$vuetify.gpt_conversation_context"),
      ];

      const headerRow = additionalFields.map(field => `"${field}"`).join(",");

      const escapeCSVValue = value => {
        if (value === undefined || value === null || value === "") {
          return "N/A";
        }
        return `"${String(value).replace(/"/g, '""')}"`;
      };

      const dataRows = data.map(row =>
        [
          escapeCSVValue(row.requestBody.userId),
          escapeCSVValue(row.requestBody.query),
          escapeCSVValue(row.tokens),
          escapeCSVValue(
            row.response
              ? row.response
                  .replace(/(\r\n|\n|\r)/gm, " ")
                  .match(/.{1,200}/g)
                  .join("\n")
              : null,
          ),
          escapeCSVValue(row.id),
          escapeCSVValue(this.determineEndpointType(row.requestUrl)),
          escapeCSVValue(this.formatDateTime(row.createdAt)),
          escapeCSVValue(row.processTime),
          escapeCSVValue(row.requestBody.model),
          escapeCSVValue(row.requestBody.chatHistoryLength),
          escapeCSVValue(row.requestBody.temperature),
          escapeCSVValue(row.requestBody.topP),
          escapeCSVValue(row.requestBody.topK),
          escapeCSVValue(row.requestBody.searchMaxToken),
          escapeCSVValue(row.requestBody.frequencyPenalty),
          escapeCSVValue(row.requestBody.presencePenalty),
          escapeCSVValue(
            row.requestBody.stop ? JSON.stringify(row.requestBody.stop) : null,
          ),
          escapeCSVValue(
            row.requestBody.resultIncludes
              ? row.requestBody.resultIncludes.join(", ")
              : null,
          ),
          escapeCSVValue(row.requestBody.sessionId),
          escapeCSVValue(row.requestBody.gptProfileId),
          escapeCSVValue(row.requestBody.name),
          escapeCSVValue(row.requestBody.intro),
          escapeCSVValue(row.requestBody.system),
          escapeCSVValue(row.requestBody.conversationType),
          escapeCSVValue(
            row.requestBody.sections
              ? row.requestBody.sections.join(", ")
              : null,
          ),
          escapeCSVValue(
            `Dataset ID: ${
              row.requestBody.contextPreference &&
              row.requestBody.contextPreference.preferred &&
              row.requestBody.contextPreference.preferred.datasetId
                ? row.requestBody.contextPreference.preferred.datasetId
                : "N/A"
            }, Project ID: ${
              row.requestBody.contextPreference &&
              row.requestBody.contextPreference.preferred &&
              row.requestBody.contextPreference.preferred.projectId
                ? row.requestBody.contextPreference.preferred.projectId
                : "N/A"
            }, Client ID: ${
              row.requestBody.contextPreference &&
              row.requestBody.contextPreference.excludes &&
              row.requestBody.contextPreference.excludes.clientId
                ? row.requestBody.contextPreference.excludes.clientId
                : "N/A"
            }`,
          ),
          escapeCSVValue(
            `Client ID: ${
              row.requestBody.conversationMetadata &&
              row.requestBody.conversationMetadata.clientId
                ? row.requestBody.conversationMetadata.clientId
                : "N/A"
            }, Project ID: ${
              row.requestBody.conversationMetadata &&
              row.requestBody.conversationMetadata.projectId
                ? row.requestBody.conversationMetadata.projectId
                : "N/A"
            }`,
          ),
          escapeCSVValue(
            `Completion Tokens: ${
              row.usage && row.usage.completionTokens
                ? row.usage.completionTokens
                : "N/A"
            }, Prompt Tokens: ${
              row.usage && row.usage.promptTokens
                ? row.usage.promptTokens
                : "N/A"
            }, Query Tokens: ${
              row.usage && row.usage.queryTokens ? row.usage.queryTokens : "N/A"
            }, Total Tokens: ${
              row.usage && row.usage.totalTokens ? row.usage.totalTokens : "N/A"
            }`,
          ),
          escapeCSVValue(
            `Image Context Enabled: ${
              row.requestBody.visionSettings &&
              row.requestBody.visionSettings.is_image_context_enabled
                ? row.requestBody.visionSettings.is_image_context_enabled
                : "N/A"
            }, Resolution: ${
              row.requestBody.visionSettings &&
              row.requestBody.visionSettings.resolution
                ? row.requestBody.visionSettings.resolution
                : "N/A"
            }`,
          ),
          escapeCSVValue(
            row.references
              ? JSON.stringify(row.references)
                  .match(/.{1,700}/g)
                  .join("\n")
              : null,
          ),
          escapeCSVValue(row.feedback ? row.feedback.message : null),
          escapeCSVValue(row.feedback ? row.feedback.type : null),
          escapeCSVValue(
            row.evaluation
              ? `"${
                  row.evaluation &&
                  row.evaluation.top_references &&
                  Array.isArray(row.evaluation.top_references)
                    ? row.evaluation.top_references
                        .map(
                          (item, index) =>
                            `Reference-${index + 1}:\n` +
                            `Source: ${item.source || "N/A"}\n` +
                            `Score: ${item.score || "N/A"}\n` +
                            `Dataset ID: ${item.dataset_id || "N/A"}\n` +
                            `Index: ${item.idx || "N/A"}`,
                        )
                        .join("\n\n")
                        .replace(/\n/g, "\r\n")
                        .replace(/"/g, '""')
                    : "N/A"
                }"`
              : null,
          ),
          escapeCSVValue(
            row.conversationContext
              ? `"${
                  row.conversationContext && row.conversationContext.length > 0
                    ? row.conversationContext
                        .map(
                          (context, index) =>
                            `Conversation-${index + 1}:\n` +
                            `User: ${context.user.replace(/"/g, '""')}\n` +
                            `Assistant: ${context.assistant.replace(/"/g, '""')}\n` +
                            `Timestamp: ${this.formatDateTime(context.timestamp)}`,
                        )
                        .join("\n\n")
                        .replace(/\n/g, "\r\n")
                        .replace(/"/g, '""')
                    : "N/A"
                }"`
              : null,
          ),
        ].join(","),
      );
      const csvRows = [headerRow, ...dataRows];
      return csvRows.join("\n");
    },
    downloadCSV(reportType, headers) {
      this.downloading = true;
      // const filteredData = this.filteredGptDetails;
      const filteredData = this.gptConversations;
      const filename = `${reportType}.csv`;
      const csv = this.convertToCSV(headers, filteredData);
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.downloading = false;
    },
    formatDateTime(dateTimeString) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const date = new Date(dateTimeString);
      const formattedDate = date
        .toLocaleString("en-US", options)
        .replace(",", "");
      // Manual formatting to get the desired format: YYYY-MM-DD HH-MM-SS
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");
      const hh = String(date.getHours()).padStart(2, "0");
      const min = String(date.getMinutes()).padStart(2, "0");
      const ss = String(date.getSeconds()).padStart(2, "0");
      return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
    },
    //Download button for top users
    generateFilename(reportType) {
      const startDate = this.start_date;
      const endDate = this.end_date;
      return `${this.selectedProject.id}_${reportType}_${startDate}_${endDate}.csv`;
    },
    downloadAsCSV(reportType, headers) {
      this.downloading = true;
      const filteredData =
        this.kpiSummary && this.kpiSummary.purchase
          ? this.kpiSummary.purchase.top_users
          : [];
      const filename = this.generateFilename(reportType);
      const csv = this.convertAsCSV(headers, filteredData);
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.downloading = false;
    },
    convertAsCSV(headers, data) {
      const headerValues = headers.map(header => header.value);
      const headerRow = headers.map(header => `"${header.text}"`).join(",");
      const dataRows = data.map(item =>
        headerValues
          .map(value => {
            let cellValue = item[value];
            if (
              value === "member_id" &&
              (cellValue === undefined || cellValue === "")
            ) {
              cellValue = "N/A";
            }
            return `"${(cellValue + "").replace(/"/g, '""')}"`;
          })
          .join(","),
      );
      const csvRows = [headerRow, ...dataRows];
      return csvRows.join("\n");
    },
    formatIndexValues(item) {
      const excludedFields = ["source", "score", "dataset_id"];
      const indexFields = Object.keys(item).filter(
        key => !excludedFields.includes(key),
      );
      const values = indexFields
        .map(field => {
          const value = item[field];
          // if (value === undefined || value === null) return null;
          // if (Array.isArray(value)) {
          //   return `${field}: [${value.join(', ')}]`;
          // } else if (typeof value === 'object') {
          //   return `${field}: ${JSON.stringify(value)}`;
          // }
          return `${field}: ${value}`;
        })
        .filter(Boolean);
      return values.length ? values.join(", ") : "N/A";
    },
    closeGptConversationFileGenerationModal() {
      this.gptConversationFileGenerationModal = false;
    },
    closeGptConversationHistoryModal() {
      this.gptConversationHistoryModal = false;
    },
    // Normalizes response text content, extracting and formatting JSON if present.
    normalizeTextContent(response) {
      if (this.isJsonString(response)) {
        try {
          const jsonStart = response.indexOf("```json");
          const jsonContent = response.substring(jsonStart);
          const cleanJson = jsonContent
            .replace(/```json\n?/, "")
            .replace(/```$/, "")
            .trim();
          const parsed = JSON.parse(cleanJson);
          return this.formatJsonAsPlainText(parsed);
        } catch (e) {
          console.error("JSON parsing failed:", e);
        }
      }
      return this.normalizeBasicText(response);
    },
    //Normalize text formatting by removing unnecessary characters
    normalizeBasicText(text) {
      return text
        .replace(/```json\n/, "")
        .replace(/```/g, "")
        .replace(/"(\w+)":/g, "$1:")
        .replace(/['"]([^'"]+)['"]/g, "$1")
        .replace(/[[\]{}]/g, "")
        .replace(/\\n/g, "\n")
        .replace(/\r?\n/g, "\n")
        .replace(/,\s*$/gm, "")
        .replace(/^\s*,/gm, "")
        .trim();
    },
    // Checks if a string contains a valid JSON object wrapped in Markdown code blocks.
    isJsonString(str) {
      try {
        if (!str) return false;
        const jsonStart = str.indexOf("```json");
        if (jsonStart !== -1) {
          const jsonContent = str.substring(jsonStart);
          const cleanJson = jsonContent
            .replace(/```json\n?/, "")
            .replace(/```$/, "")
            .trim();
          JSON.parse(cleanJson);
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    //Formats a JSON object into a human-readable string with indentation and formatting.
    formatJsonAsPlainText(obj, level = 0) {
      const indent = "  ".repeat(level);
      return (
        Object.entries(obj)
          .map(([key, value]) => {
            const formattedKey = key.replace(/_/g, " ");
            if (Array.isArray(value)) {
              const items = value
                .map((item, index) =>
                  typeof item === "object" && item !== null
                    ? `${indent}**Product ${index + 1}:**\n${this.formatJsonAsPlainText(item, level + 1)}`
                    : `${indent}• ${item}`,
                )
                .join("\n");
              return `${indent}**${formattedKey}:**\n${items}`;
            } else if (typeof value === "object" && value !== null) {
              return `${indent}**${formattedKey}:**\n${this.formatJsonAsPlainText(value, level + 1)}`;
            } else {
              return `${indent}**${formattedKey}:** ${value}`;
            }
          })
          .join("\n") + "\n"
      );
    },
  },
};
</script>
<style scoped>
.response-message {
  white-space: pre-line;
}

.response-details-text {
  color: gray;
  white-space: pre-line;
}

.response-message-header {
  font-size: 20px;
}

.add-btn {
  min-width: 140px;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

.training-header {
  display: flex;
  justify-content: center;
  font-weight: 600;
}

.modalWrapper {
  padding: 50px 25px;
  gap: 20px;
}

.input-field {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.textarea {
  height: 200px;
  padding: 10px;
  box-sizing: border-box;
}

.flex-grow {
  flex-grow: 1;
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 30px;
}

.dummy-width {
  width: 17px;
}

.checkbox {
  cursor: pointer;
  transform: scale(1.5);
  margin-right: 5px;
}

.relative-pos {
  position: relative;
}

.absolute-pos {
  position: absolute;
}

.arrow-icon {
  right: 10px;
  top: 10px;
}

.text-subtitle-1 {
  font-weight: 600;
}

.conversation_id {
  font-weight: 400;
}

.required {
  color: red;
}

.response-message {
  white-space: pre-line;
}

.response-details-text {
  color: gray;
  white-space: pre-line;
}

.response-message-header {
  font-size: 20px;
}

.word-break {
  word-break: break-all;
}
</style>
<style>
.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
