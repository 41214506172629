// TODO: need code improvement in this page
<template>
  <div class="mt-4">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <template v-for="n in steps">
          <v-stepper-step :key="`${n}-step`" :complete="e1 > n" :step="n">
            <!-- Step {{ n }} -->
            <div v-if="n === 1">{{ $vuetify.lang.t("$vuetify.details_step_txt") }}</div>
            <div v-if="n === 2">{{ $vuetify.lang.t("$vuetify.pricing_step_txt") }}</div>
            <div v-if="n === 3">{{ $vuetify.lang.t("$vuetify.metadata_step_txt") }}</div>
            <div v-if="n === 4">{{ $vuetify.lang.t("$vuetify.review_step_txt") }}</div>
          </v-stepper-step>

          <v-divider v-if="n !== steps" :key="n"></v-divider>
        </template>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content v-for="n in steps" :key="`${n}-content`" :step="n">
          <ValidationObserver ref="firstStep">
            <div v-if="n === 1">
              <v-form ref="form1">
                <v-row>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      name="Content Language"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        v-model="contentLanguage"
                        :items="contentLanguages"
                        item-text="text"
                        item-value="value"
                        :label="$vuetify.lang.t('$vuetify.content_lang_header_txt')"
                        :error-messages="localizeErrorMsg(errors, 'content_lang')"
                        outlined
                      >
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" md="6">
                    <ValidationProvider
                      name="Solution Name"
                      :rules="(contentLanguage=='en' ? 'required|is_numeric' : 'is_numeric')"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-if="contentLanguage=='en'"
                        v-model="solutions.name"
                        :label="$vuetify.lang.t('$vuetify.solution_name_label_txt')"
                        outlined
                        :error-messages="localizeErrorMsg(errors, 'solution_name', solutions.name)"
                        :disabled="solutionHasProject"
                      ></v-text-field>
                      <v-text-field
                        v-if="contentLanguage=='ja'"
                        v-model="solutions.name_ja"
                        :label="$vuetify.lang.t('$vuetify.solution_name_label_txt')"
                        :error-messages="localizeErrorMsg(errors, 'solution_name', solutions.name_ja)"
                        :disabled="solutionHasProject"
                        outlined
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <ValidationProvider
                      name="Solution Type"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        v-model="solutions.type"
                        :items="allSegments"
                        item-text="name"
                        item-value="id"
                        :label="$vuetify.lang.t('$vuetify.solution_header_txt')"
                        :error-messages="localizeErrorMsg(errors, 'solution_type')"
                        :disabled="solutionHasProject"
                        return-object
                        outlined
                      >
                        <template v-slot:selection="data">
                          <div>{{ data.item.name }}</div>
                        </template>
                        <template v-slot:item="data">
                          <div>{{ data.item.name }}</div>
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <text-editor
                      v-if="!isLoading"
                      :class="contentLanguage=='ja' ? 'd-none' : ''"
                      :editorId="'overview-editor'"
                      @setEditor="setSolutionOverviewEditor"
                      :isRequired="true"
                      :label="$vuetify.lang.t('$vuetify.solution_overview_label_txt')"
                      :placeHolder="$vuetify.lang.t('$vuetify.solution_overview_label_txt')"
                      :isError="overviewErr"
                      @setIsError="setOverviewErr"
                      :errorMsg="overviewErrMsg"
                      :editorData="solutions.overview"
                    />
                    <text-editor
                      v-if="!isLoading"
                      :class="contentLanguage=='en' ? 'd-none' : ''"
                      :editorId="'overview-ja-editor'"
                      @setEditor="setSolutionOverviewEditorJa"
                      :isRequired="false"
                      :label="$vuetify.lang.t('$vuetify.solution_overview_label_txt')"
                      :placeHolder="$vuetify.lang.t('$vuetify.solution_overview_label_txt')"
                      :isError="false"
                      :editorData="solutions.overview_ja"
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <text-editor
                      v-if="!isLoading"
                      :class="contentLanguage=='ja' ? 'd-none' : ''"
                      :editorId="'description-editor'"
                      @setEditor="setSolutionDescEditor"
                      :isRequired="false"
                      :label="$vuetify.lang.t('$vuetify.solution_description_label_txt')"
                      :placeHolder="
                        $vuetify.lang.t('$vuetify.solution_description_placeholder_txt')
                      "
                      :isError="false"
                      :editorData="solutions.description"
                    />
                    <text-editor
                      v-if="!isLoading"
                      :class="contentLanguage=='en' ? 'd-none' : ''"
                      :editorId="'description-ja-editor'"
                      @setEditor="setSolutionDescEditorJa"
                      :isRequired="false"
                      :label="$vuetify.lang.t('$vuetify.solution_description_label_txt')"
                      :placeHolder="$vuetify.lang.t('$vuetify.solution_description_placeholder_txt')"
                      :isError="false"
                      :editorData="solutions.description_ja"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="12">
                    <ValidationProvider
                      ref="industryRef"
                      name="Industries"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        v-model="solutions.industry"
                        :items="allCategory"
                        item-text="name"
                        :label="$vuetify.lang.t('$vuetify.industry_label_txt')"
                        :search-input.sync="searchIndustry"
                        :error-messages="localizeErrorMsg(errors, 'solution_industry')"
                        multiple
                        small-chips
                        return-object
                        outlined
                      >
                        <template v-slot:append-outer>
                          <v-slide-x-reverse-transition mode="out-in">
                            <v-icon
                              color="primary"
                              @click="createIndustry()"
                              v-text="'mdi-plus-circle-outline'"
                            >
                            </v-icon>
                          </v-slide-x-reverse-transition>
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <text-editor
                      v-if="!isLoading"
                      :class="contentLanguage=='ja' ? 'd-none' : ''"
                      :editorId="'use-case-editor'"
                      @setEditor="setSolutionUseCaseEditor"
                      :isRequired="true"
                      :label="$vuetify.lang.t('$vuetify.use_case_label_txt')"
                      :placeHolder="$vuetify.lang.t('$vuetify.use_case_placeholder_txt')"
                      :isError="useCaseErr"
                      @setIsError="setUseCaseErr"
                      :errorMsg="useCaseErrMsg"
                      :editorData="solutions.use_cases"
                    />
                    <text-editor
                      v-if="!isLoading"
                      :class="contentLanguage=='en' ? 'd-none' : ''"
                      :editorId="'use-case-ja-editor'"
                      @setEditor="setSolutionUseCaseEditorJa"
                      :isRequired="false"
                      :label="$vuetify.lang.t('$vuetify.use_case_label_txt')"
                      :placeHolder="$vuetify.lang.t('$vuetify.use_case_placeholder_txt')"
                      :isError="false"
                      :editorData="solutions.use_cases_ja"
                    />
                  </v-col>
                  <!-- show all solution  -->
                  <!-- <v-col cols="12" xs="12" md="12">
                    <ValidationProvider
                      name="Solutions which can be used together"
                      rules=""
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        v-model="solutions.linked_solution"
                        :items="devSolByType"
                        :disabled="solutionHasProject"
                        :error-messages="errors"
                        :search-input.sync="searchSolutions"
                        item-text="name"
                        :label="$vuetify.lang.t('$vuetify.linked_solution_label_txt_1')"
                        :no-data-text="
                          solutions.type
                            ? $vuetify.lang.t('$vuetify.no_data_msg_txt')
                            : $vuetify.lang.t('$vuetify.select_solution_type_msg_txt')
                        "
                        multiple
                        small-chips
                        return-object
                        outlined
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col> -->
                  <!-- show all language  -->
                  <v-col cols="12" xs="12" md="12">
                    <ValidationProvider
                      name="Supported languages"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        v-model="solutions.language"
                        :items="AllLanguage"
                        :error-messages="localizeErrorMsg(errors, 'solution_language')"
                        :disabled="solutionHasProject"
                        :search-input.sync="searchLanguage"
                        item-text="name"
                        :label="$vuetify.lang.t('$vuetify.language_label_txt')"
                        multiple
                        small-chips
                        return-object
                        outlined
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </ValidationObserver>
          <!-- second step  -->
          <div v-if="n === 2">
            <ValidationObserver ref="stepTwo">
              <v-form ref="form2">
                <!-- price  -->
                <div class="primary--text text-h6">
                  {{ $vuetify.lang.t("$vuetify.resource_header_txt") }}
                </div>
                <v-divider class="mb-3"></v-divider>
                <v-row>
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      name="Minimum CPU"
                      rules="required|min_value:0"
                      v-slot="{ errors }"
                    >
                      <vuetify-money
                        v-model="solutions.min_cpu"
                        :label="$vuetify.lang.t('$vuetify.cpu_label_txt')"
                        placeholder="2"
                        :errorMessages="localizeErrorMsg(errors, 'solution_cpu')"
                        :disabled="solutionHasProject"
                        outlined
                        clearable
                        value-when-is-empty=""
                        :options="numberOptions"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      name="Min Memory"
                      rules="required|min_value:0"
                      v-slot="{ errors }"
                    >
                      <vuetify-money
                        v-model="solutions.min_memory"
                        :label="$vuetify.lang.t('$vuetify.memory_label_txt')"
                        placeholder="4"
                        :errorMessages="localizeErrorMsg(errors, 'solution_memory')"
                        :disabled="solutionHasProject"
                        outlined
                        clearable
                        value-when-is-empty=""
                        :options="numberOptions"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      name="Min GPU"
                      rules="min_value:0"
                      v-slot="{ errors }"
                    >
                      <vuetify-money
                        v-model="solutions.min_gpu"
                        :label="$vuetify.lang.t('$vuetify.gpu_label_txt')"
                        placeholder="8"
                        :disabled="solutionHasProject"
                        :errorMessages="errors"
                        outlined
                        clearable
                        value-when-is-empty=""
                        :options="numberOptions"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <div class="primary--text text-h6">
                  {{ $vuetify.lang.t("$vuetify.performance_header_txt") }}
                </div>
                <v-divider class="mb-3"></v-divider>
                <v-row>
                  <v-col cols="12" xs="12" md="6">
                    <ValidationProvider
                      name="Benchmark request / month"
                      rules="required|min_value:1|max_value:2147483647"
                      v-slot="{ errors }"
                    >
                      <vuetify-money
                        v-model="solutions.page_view"
                        :label="$vuetify.lang.t('$vuetify.benchmark_request_label_txt')"
                        placeholder="25,000,000"
                        outlined
                        clearable
                        value-when-is-empty=""
                        :options="numberOptions"
                        :errorMessages="localizeErrorMsg(errors, 'solution_request')"
                        :disabled="solutionHasProject"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <ValidationProvider
                      name="Benchmark RPS"
                      rules="min_value:0"
                      v-slot="{ errors }"
                    >
                      <vuetify-money
                        v-model="solutions.rps"
                        :label="$vuetify.lang.t('$vuetify.benchmark_rps_label_txt')"
                        placeholder="10"
                        outlined
                        clearable
                        value-when-is-empty=""
                        :options="numberOptions"
                        :disabled="solutionHasProject"
                        :errorMessages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <div class="primary--text text-h6">
                  {{ $vuetify.lang.t("$vuetify.price_header_txt") }}
                </div>
                <v-divider class="mb-3"></v-divider>
                <v-row>
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      name="Solution Base Price"
                      rules="required|min_value:1"
                      v-slot="{ errors }"
                    >
                      <vuetify-money
                        v-model="solutions.price"
                        :label="$vuetify.lang.t('$vuetify.base_price_label_txt_1')"
                        placeholder="3,000"
                        outlined
                        clearable
                        value-when-is-empty=""
                        :options="numberOptions"
                        :errorMessages="localizeErrorMsg(errors, 'solution_base_price')"
                        :disabled="solutionHasProject"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      name="Additional Solution Price"
                      :rules="{
                        required: solutions.linked_solution.length !== 0,
                        min_value: solutions.linked_solution.length !== 0 ? 1 : 0,
                      }"
                      v-slot="{ errors }"
                    >
                      <vuetify-money
                        v-model="solutions.additional_price"
                        :label="$vuetify.lang.t('$vuetify.additional_price_label_txt')"
                        placeholder="500"
                        :errorMessages="
                          localizeErrorMsg(errors, 'solution_additional_price')
                        "
                        :disabled="solutionHasProject"
                        outlined
                        clearable
                        value-when-is-empty=""
                        :options="numberOptions"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </div>
          <div v-if="n === 3">
            <ValidationObserver ref="stepThree">
              <v-form ref="form3">
                <v-row>
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      name="Version Number"
                      rules="required|is_not:-1"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="solutions.version"
                        :label="$vuetify.lang.t('$vuetify.version_label_txt')"
                        placeholder="1.0.0"
                        :error-messages="
                          localizeErrorMsg(errors, 'solution_version', solutions.version)
                        "
                        outlined
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      name="Source Code URL"
                      rules="required|url"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="solutions.source_url"
                        :label="$vuetify.lang.t('$vuetify.source_code_label_txt')"
                        placeholder="https://github.com/gigalogy/recommender.git"
                        :error-messages="
                          localizeErrorMsg(
                            errors,
                            'solution_sourcecode_url',
                            solutions.source_url
                          )
                        "
                        outlined
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      name="Sandbox URL"
                      rules="url"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="solutions.sanbdbox_url"
                        :label="$vuetify.lang.t('$vuetify.sandbox_label_txt')"
                        :error-messages="
                          localizeErrorMsg(
                            errors,
                            'solution_sourcecode_url',
                            solutions.sanbdbox_url
                          )
                        "
                        outlined
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      name="API Docs URL"
                      rules="url"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="solutions.document_url"
                        :label="$vuetify.lang.t('$vuetify.api_docs_label_txt')"
                        :error-messages="
                          localizeErrorMsg(
                            errors,
                            'solution_sourcecode_url',
                            solutions.document_url
                          )
                        "
                        outlined
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      name="Tutorial URL"
                      rules="url"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="solutions.tutorial_location"
                        :label="$vuetify.lang.t('$vuetify.tutorial_label_txt')"
                        :error-messages="
                          localizeErrorMsg(
                            errors,
                            'solution_sourcecode_url',
                            solutions.tutorial_location
                          )
                        "
                        outlined
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-divider :vertical="$vuetify.breakpoint.mdAndUp"></v-divider>
                  <v-col cols="12" md="6">
                    <!-- select image for your solution  -->
                    <v-row no-gutters>
                      <v-col cols="12" md="12">
                        <v-file-input
                          show-size
                          :label="$vuetify.lang.t('$vuetify.select_img_label_txt')"
                          accept="image/*"
                          @change="selectImage"
                          outlined
                        >
                        </v-file-input>
                      </v-col>
                    </v-row>
                    <!-- Image Preview -->
                    <v-row>
                      <v-col cols="12" v-if="previewImage">
                        <div v-if="progress">
                          <div>
                            <v-progress-linear
                              v-model="progress"
                              color="primary"
                              height="25"
                              reactive
                            >
                              <strong>{{ progress }} %</strong>
                            </v-progress-linear>
                          </div>
                        </div>
                        <div>
                          <v-img
                            class="preview my-3"
                            aspect-ratio="2"
                            contain
                            :src="previewImage"
                            alt=""
                          ></v-img>
                        </div>
                      </v-col>
                      <v-col cols="12" v-if="!previewImage">
                        <div class="mx-auto">
                          <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                              <v-img
                                class="d-block lang-menu-activator ma-auto"
                                aspect-ratio="2"
                                contain
                                :src="getIcon()"
                                v-bind="attrs"
                                v-on="on"
                              ></v-img>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(img, index) in imageParams"
                                :key="index"
                                class="img-menu-item"
                                @click="setImg(img.value)"
                              >
                                <div class="d-flex align-center">
                                  <v-img
                                    aspect-ratio="2"
                                    contain
                                    :src="img.img"
                                    class="mr-2 d-block"
                                  />
                                  <span>{{ img.text }}</span>
                                </div>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <div class="text-center mt-4 mr-4">Select Cover Image</div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </div>
          <div v-if="n === 4">
            <Review
              :solutions="solutions"
              :solutionImg="previewImage ? previewImage : getIcon()"
            />
          </div>
          <br />
          <v-row align="center" justify="start">
            <v-col cols="12">
              <v-btn
                v-if="n !== 1"
                color="primary"
                class="mr-4"
                large
                outlined
                :loading="false"
                @click="previousStep(n)"
                >{{ $vuetify.lang.t("$vuetify.prev_btn_txt") }}</v-btn
              >
              <v-btn
                v-if="n === 1"
                color="error"
                class="mr-4"
                large
                outlined
                :loading="false"
                @click="goBack()"
                >{{ $vuetify.lang.t("$vuetify.cancel_btn_txt") }}</v-btn
              >
              <v-btn
                v-if="n === 4 && solutions.status && solutions.status === 'published'"
                color="primary"
                class="mr-2"
                large
                :loading="isSaving"
                @click="compareImpactData(solutions.status)"
                >{{ $vuetify.lang.t("$vuetify.save_btn_txt") }}</v-btn
              >
              <v-overflow-btn
                v-if="n === 4 && solutions.status !== 'published'"
                class="mr-4 d-inline-block"
                background-color="primary"
                color="primary"
                :items="saveOptions"
                :label="
                  isLoading ? '' : $vuetify.lang.t('$vuetify.submit_review_btn_txt')
                "
                :placeholder="
                  isLoading ? '' : $vuetify.lang.t('$vuetify.submit_review_btn_txt')
                "
                :value="isLoading ? '' : 'pending'"
                :style="isLoading ? 'top: -10px; width: 232px;' : ''"
                segmented
              >
                <template v-slot:prepend-inner>
                  <v-progress-circular
                    v-if="isLoading"
                    style="transform: translate(36px, 4px)"
                    color="#FFFFFF"
                    size="26"
                    indeterminate
                  ></v-progress-circular>
                </template>
              </v-overflow-btn>
              <v-btn
                v-if="n < 4"
                color="primary"
                class="mr-4"
                large
                :loading="false"
                @click="nextStep(n)"
                >{{ $vuetify.lang.t("$vuetify.continue_btn_txt") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <!-- industry category dialog  -->
    <v-dialog v-model="createIndustryDialog" width="600">
      <v-card>
        <v-card-title class="headline primary white--text" primary-title>
          {{ $vuetify.lang.t("$vuetify.create_industry_header_txt") }}
        </v-card-title>
        <div class="pa-5">
          <v-form ref="categoryForm">
            <v-text-field
              type="text"
              v-model="industryName"
              :label="$vuetify.lang.t('$vuetify.industry_name_label_txt')"
              outlined
              counter="80"
              maxlength="80"
              :rules="[
                alphaSpaceHyphen($vuetify.lang.t('$vuetify.industry_name_label_txt')),
                required($vuetify.lang.t('$vuetify.industry_name_label_txt'))
              ]"
            />
            <v-text-field
              type="text"
              v-model="industryCode"
              :label="$vuetify.lang.t('$vuetify.industry_code_label_txt')"
              :rules="[
                lowercase($vuetify.lang.t('$vuetify.industry_code_label_txt')),
                alphaSpace($vuetify.lang.t('$vuetify.industry_code_label_txt')),
                required($vuetify.lang.t('$vuetify.industry_code_label_txt'))
              ]"
              counter="80"
              maxlength="80"
              outlined
            />
            <v-textarea
              :label="$vuetify.lang.t('$vuetify.description_label_txt')"
              v-model="industryDescription"
              outlined
              :rules="[required($vuetify.lang.t('$vuetify.description_label_txt'))]"
            >
            </v-textarea>
          </v-form>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button
            class="mr-2"
            :buttonType="TEXT_BUTTON"
            :buttonText="$vuetify.lang.t('$vuetify.close_btn_txt')"
            color="error"
            @onClick="createIndustryDialog = false"
          />
          <Button
            :buttonType="RAISED_BUTTON"
            :buttonText="$vuetify.lang.t('$vuetify.save_btn_txt')"
            color="primary"
            :loading="isCreatingInd"
            @onClick="saveIndustry()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogModal
      v-model="alertDialog"
      :title="$vuetify.lang.t('$vuetify.error_title_txt')"
      :message="alertMessage"
      :confirmButtonText="$vuetify.lang.t('$vuetify.ok_btn_txt')"
      :cancelButton="false"
      @onConfirmation="alertDialog = false"
    />
    <DialogModal
      v-model="warningDialog"
      :title="$vuetify.lang.t('$vuetify.warning_title_txt')"
      :message="alertMessage"
      :confirmButtonText="$vuetify.lang.t('$vuetify.yes_btn_txt')"
      :cancelButtonText="$vuetify.lang.t('$vuetify.no_btn_txt')"
      headlineClass="headline warning white--text"
      @onCancel="cancelDialog()"
      :persistent="true"
      @onConfirmation="resetMonthlyPV()"
    />
    <DialogModal
      v-model="confirmDialog"
      :title="$vuetify.lang.t('$vuetify.confirm_alert_title_txt')"
      :message="$vuetify.lang.t('$vuetify.confirm_alert_msg_txt_2')"
      :confirmButtonText="$vuetify.lang.t('$vuetify.submit_review_btn_txt')"
      headlineClass="headline red white--text"
      @onCancel="onCancel()"
      :persistent="true"
      @onConfirmation="saveSolutionWithImg(solutions.status)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import {
  LOAD_ALL_CATEGORY,
  SAVE_CATEGORY,
  SAVE_SOLUTION,
  UPDATE_SOLUTION_BY_ID,
  LOAD_ALL_SEGMENT,
  ADD_ALERT,
  SAVE_CONTENT,
  LOAD_SOLUTION_BY_ID,
  LOAD_ALL_SOLUTION_BY_DEV,
  SOLUTION_HAS_PROJECT,
  RESET_SOLUTION_HAS_PROJECT,
  RESET_SELECTED_SOLUTION,
} from "@/store/_actiontypes";

import {
  AUTO_COMPLETE,
  MULT_AUTO_COMPLETE,
  TEXT,
  NUMBER,
} from "@/components/common/input/_inputTypes";

import { RAISED_BUTTON, TEXT_BUTTON } from "@/components/common/button/_buttontypes";

import validations from "@/helpers/validations";

import Button from "@/components/common/button/Button";
import DialogModal from "@/components/common/DialogModal";
import AllLanguage from "@/helpers/language";
import Review from "@/components/SolutionRegister/Review";
import TextEditor from "@/components/SolutionRegister/TextEditor";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { NUMBER_FORMAT_LOCALE } from "@/global/_constants";

export default {
  props: {
    currencyParams: {
      type: Array,
      default() {
        return ["JPY", "USD"];
      },
    },
    imageParams: {
      type: Array,
      default() {
        return [
          {
            value: "recommender",
            text: "Personalizer",
            img: "https://i.imgur.com/IZ26WtD.png",
          },
          {
            value: "nlu",
            text: "NLU",
            img: "https://i.imgur.com/D7pEWBg.png",
          },
        ];
      },
    },
  },
  components: {
    Button,
    DialogModal,
    Review,
    TextEditor,
    ValidationObserver,
    ValidationProvider,
  },
  computed: {
    ...mapState({
      allCategory: (state) => state.category.allCategory,
      organization: (state) => state.account.organization,
      allSolution: (state) => state.solution.allSolution,
      allSegments: (state) => state.segment.allSegments,
      selectedSolution: (state) => state.solution.selectedSolution,
      allSolutionByDev: (state) => state.solution.allSolutionByDev,
      // solutionHasProject: (state) => state.solution.solutionHasProject,
      solutionHasProject: (state) => false,
    }),
    ...mapGetters("loader", ["loading"]),
    getIcon() {
      return (item) => {
        let image = item || this.selectedSolutionforImg;
        let iconSrc = null;
        Object.keys(this.imageParams).forEach((index) => {
          if (this.imageParams[index].value === image) {
            iconSrc = this.imageParams[index].img;
          }
        });
        return iconSrc;
      };
    },
    size() {
      const size = { xs: "large", sm: "large", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },
  created() {
    this.getAll();
    if (this.$route.params.solution_id) {
      this.fetchSolution();
    }
    this.RESET_SOLUTION_HAS_PROJECT();
    this.RESET_SELECTED_SOLUTION();
  },
  data() {
    return {
      e1: 1,
      steps: 4,
      TEXT: TEXT,
      NUMBER: NUMBER,
      AUTO_COMPLETE: AUTO_COMPLETE,
      MULT_AUTO_COMPLETE: MULT_AUTO_COMPLETE,
      RAISED_BUTTON: RAISED_BUTTON,
      TEXT_BUTTON: TEXT_BUTTON,
      ...validations,
      AllLanguage,
      contentLanguage: "en",
      contentLanguages: [{ text: "English", value: "en" }, { text: "日本語", value: "ja" }],
      solutions: {
        name: "",
        name_ja: "",
        overview: null,
        overview_ja: null,
        description: null,
        description_ja: null,
        type: "",
        industry: [],
        use_cases: null,
        use_cases_ja: null,
        linked_solution: [],
        language: [],
        page_view: "",
        rps: "",
        price: "",
        additional_price: "",
        min_cpu: "",
        min_memory: "",
        min_gpu: "",
        version: "",
        source_url: "",
        sanbdbox_url: "",
        document_url: "",
        tutorial_location: "",
        status: "",
      },
      devSolByType: [],
      searchSolutions: "",
      searchLanguage: "",
      alertDialog: false,
      warningDialog: false,
      confirmDialog: false,
      alertMessage: this.$vuetify.lang.t("$vuetify.unknown_err_msg_txt"),
      isUpdate: false,
      createIndustryDialog: false,
      industryCode: "",
      industryName: "",
      industryDescription: "",
      searchIndustry: "",
      selectedSolutionforImg: "recommender",
      currentImage: undefined,
      previewImage: undefined,
      cover_img_key: "",
      progress: 0,
      disableUpload: false,
      numberOptions: {
        locale: NUMBER_FORMAT_LOCALE,
        prefix: "",
        suffix: "",
        length: 15,
        precision: 0,
      },
      saveOptions: [
        {
          text: this.$vuetify.lang.t("$vuetify.submit_review_btn_txt"),
          value: "pending",
          callback: () => this.saveSolutionWithImg("pending"),
        },
        {
          text: this.$vuetify.lang.t("$vuetify.draft_btn_txt"),
          value: "drafted",
          callback: () => this.saveSolutionWithImg("drafted"),
        },
      ],
      isLoading: false,
      solutionOverviewJa: null,
      solutionOverview: null,
      overviewErr: false,
      overviewErrMsg: "",
      isOverviewEmpty: true,
      solutionDescJa: null,
      solutionDesc: null,
      descErr: false,
      isDescEmpty: true,
      solutionUseCaseJa: null,
      solutionUseCase: null,
      useCaseErr: false,
      useCaseErrMsg: "",
      isUseCaseEmpty: true,
      isCreatingInd: false,
      isCreatingSolution: false,
      selectedSolCovImg: "",
      isSaving: false,
    };
  },
  watch: {
    "solutions.type": {
      handler: function () {
        this.solutions.type.name === "recommender"
          ? (this.selectedSolutionforImg = "recommender")
          : this.solutions.type.name === "nlu"
          ? (this.selectedSolutionforImg = "nlu")
          : this.solutions.type.name === "cv"
          ? (this.selectedSolutionforImg = "nlu")
          : "recommender";
        if (
          this.solutions.type &&
          this.solutions.type.id &&
          Array.isArray(this.allSolutionByDev) &&
          this.allSolutionByDev.length !== 0
        ) {
          let linkableSol = [];
          this.allSolutionByDev.forEach((element) => {
            if (
              element.segment &&
              element.segment.id &&
              element.segment.id === this.solutions.type.id &&
              element.status &&
              element.status === "published" &&
              this.$route?.params?.solution_id !== element.id
            ) {
              linkableSol.push(element);
            }
          });
          this.devSolByType = linkableSol;
          if (
            this.selectedSolution &&
            this.selectedSolution.links &&
            this.solutions?.type?.id === this.selectedSolution?.segment?.id
          ) {
            this.solutions.linked_solution = this.selectedSolution.links;
          } else {
            this.solutions.linked_solution = [];
          }
        }
      },
      deep: true,
    },
    "solutions.industry": {
      handler: function () {
        this.searchIndustry = "";
      },
      deep: true,
    },
    "solutions.linked_solution": {
      handler: function () {
        this.searchSolutions = "";
      },
      deep: true,
    },
    "solutions.language": {
      handler: function () {
        this.searchLanguage = "";
      },
      deep: true,
    },
    selectedSolution: {
      handler: function () {
        if (this.selectedSolution && this.selectedSolution.id) {
          this.SOLUTION_HAS_PROJECT({ solutionId: this.selectedSolution.id });
        }
      },
      deep: true,
    },
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  methods: {
    ...mapActions("category", [LOAD_ALL_CATEGORY, SAVE_CATEGORY]),
    ...mapActions("solution", [
      SAVE_SOLUTION,
      UPDATE_SOLUTION_BY_ID,
      LOAD_ALL_SOLUTION_BY_DEV,
      LOAD_SOLUTION_BY_ID,
      SOLUTION_HAS_PROJECT,
      RESET_SOLUTION_HAS_PROJECT,
      RESET_SELECTED_SOLUTION,
    ]),
    ...mapActions("segment", [LOAD_ALL_SEGMENT]),
    ...mapActions("alert", [ADD_ALERT]),
    ...mapActions("upload", [SAVE_CONTENT]),
    localizeErrorMsg() {
      if (arguments[0] && arguments[0].length && arguments[1] && !arguments[2]) {
        return this.$vuetify.lang.t(`$vuetify.${arguments[1]}_error_msg_txt`);
      }
      if (arguments[0] && arguments[0].length && arguments[1] && arguments[2]) {
        return this.$vuetify.lang.t(`$vuetify.${arguments[1]}_error_msg_txt_2`);
      }
    },
    getAll() {
      this.getAllCategory();
      this.getDevSolution();
      this.getAllSegment();
    },
    getAllCategory() {
      this.LOAD_ALL_CATEGORY();
    },
    getDevSolution() {
      this.LOAD_ALL_SOLUTION_BY_DEV({ searchText: "" });
    },
    getAllSegment() {
      this.LOAD_ALL_SEGMENT().then((res) => res);
    },
    goBack() {
      history.back();
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        if (n === 1) {
          this.saveSolOverview();
          this.saveSolDesc();
          this.saveSolUseCases();
          this.$refs[`firstStep`][0].validate().then((success) => {
            if (!success || this.isOverviewEmpty || this.isUseCaseEmpty) {
              return;
            }
            this.e1 = n + 1;
            this.$vuetify.goTo(0);
          });
        } else if (n === 2) {
          this.setSolImg();
          this.$refs[`stepTwo`][0].validate().then((success) => {
            if (!success) {
              return;
            }
            this.e1 = n + 1;
            this.$vuetify.goTo(0);
          });
        } else if (n === 3) {
          this.$refs[`stepThree`][0].validate().then((success) => {
            if (!success) {
              return;
            }
            this.e1 = n + 1;
            this.$vuetify.goTo(0);
          });
        }
      }
    },
    saveSolOverview() {
      if (this.solutionOverview) {
        this.solutionOverview
          .save()
          .then((data) => {
            if (data && data.blocks.length === 0) {
              this.isOverviewEmpty = true;
              this.overviewErr = true;
              this.overviewErrMsg = this.$vuetify.lang.t(
                `$vuetify.solution_overview_error_msg_txt`
              );
            } else {
              this.solutions.overview = data;
              this.isOverviewEmpty = false;
              this.overviewErr = false;
              this.overviewErrMsg = "";
            }
          })
          .catch((error) => error);
      }

      if (this.solutionOverviewJa) {
        this.solutionOverviewJa.save().then((data) => {
          if (data && data.blocks.length !== 0) {
            this.solutions.overview_ja = data;
          }
        }).catch((error) => error);
      }
    },
    saveSolDesc() {
      if (this.solutionDesc) {
        this.solutionDesc
          .save()
          .then((data) => {
            if (data && data.blocks.length !== 0) {
              this.solutions.description = data;
              this.isDescEmpty = false;
            } else {
              this.isDescEmpty = true;
            }
          })
          .catch((error) => error);
      }
      if (this.solutionDescJa) {
        this.solutionDescJa.save().then((data) => {
          if (data && data.blocks.length !== 0) {
            this.solutions.description_ja = data;
          }
        }).catch((error) => error);
      }
    },
    saveSolUseCases() {
      if (this.solutionUseCase) {
        this.solutionUseCase
          .save()
          .then((data) => {
            if (data && data.blocks.length === 0) {
              this.isUseCaseEmpty = true;
              this.useCaseErr = true;
              this.useCaseErrMsg = this.$vuetify.lang.t(
                `$vuetify.solution_usecase_error_msg_txt`
              );
            } else {
              this.solutions.use_cases = data;
              this.isUseCaseEmpty = false;
              this.useCaseErr = false;
              this.useCaseErrMsg = "";
            }
          })
          .catch((error) => error);
      }
      if (this.solutionUseCaseJa) {
        this.solutionUseCaseJa.save().then((data) => {
          if (data && data.blocks.length !== 0) {
            this.solutions.use_cases_ja = data;
          }
        }).catch((error) => error);
      }
    },
    setSolImg() {
      if (this.currentImage) {
        this.previewImage = URL.createObjectURL(this.currentImage);
      } else if (this.selectedSolCovImg) {
        this.previewImage = this.selectedSolCovImg;
      } else {
        if (Array.isArray(this.allSegments) && this.allSegments.length !== 0) {
          this.allSegments.forEach((element) => {
            if (element?.id === this.solutions.type?.id) {
              this.previewImage = element?.cover_image_url;
            }
          });
        }
      }
    },
    previousStep(n) {
      this.e1 = n - 1;
    },
    saveIndustry() {
      if (!this.$refs.categoryForm.validate()) return;
      if (this.industryCode && this.industryName && this.industryDescription) {
        this.isCreatingInd = true;
        this.SAVE_CATEGORY({
          code: this.industryCode,
          name: this.industryName,
          description: this.industryDescription,
        }).then(
          (response) => {
            this.getAllCategory();
            this.solutions.industry.push(response.data);
            this.$refs[`industryRef`][0].validate();
            this.isCreatingInd = false;
            this.createIndustryDialog = false;
          },
          (error) => {
            this.alertMessage = error.response.data.message;
            this.alertDialog = true;
            this.isCreatingInd = false;
          }
        );
      }
    },
    createIndustry() {
      this.createIndustryDialog = !this.createIndustryDialog;
      this.industryName = this.searchIndustry;
    },
    setImg(val) {
      this.selectedSolutionforImg = val;
    },
    compareImpactData(option) {
      if (
        this.solutions.name !== this.selectedSolution?.name ||
        this.solutions.type?.id !== this.selectedSolution?.segment?.id ||
        this.solutions.language?.some(
          (element) => !this.selectedSolution.languages?.includes(element.name)
        ) ||
        this.compareLinkedSolutions() ||
        this.solutions.min_cpu != this.selectedSolution?.min_cpu ||
        this.solutions.min_memory != this.selectedSolution?.min_memory ||
        this.solutions.min_gpu != this.selectedSolution?.min_gpu ||
        this.solutions.page_view != this.selectedSolution?.max_pv ||
        this.solutions.rps != this.selectedSolution?.max_rps ||
        this.solutions.price != this.selectedSolution?.base_price ||
        this.solutions.additional_price != this.selectedSolution?.additional_price
      ) {
        this.confirmDialog = true;
      } else {
        this.saveSolutionWithImg(option);
      }
    },
    compareLinkedSolutions() {
      if (
        this.solutions.linked_solution &&
        Array.isArray(this.solutions.linked_solution) &&
        this.solutions.linked_solution.length !== 0 &&
        this.selectedSolution &&
        this.selectedSolution.links &&
        Array.isArray(this.selectedSolution.links) &&
        this.selectedSolution.links.length !== 0
      ) {
        let linkedSolArr = this.solutions.linked_solution.map((element) => element.id);
        let selectedLinkSolArr = this.selectedSolution.links.map((element) => element.id);
        if (linkedSolArr.some((solutionId) => !selectedLinkSolArr.includes(solutionId))) {
          return true;
        }
      }
      return false;
    },
    saveSolutionWithImg(option) {
      this.isLoading = true;
      this.isSaving = true;
      this.confirmDialog = false;
      this.saveOptions = [];
      if (this.currentImage) {
        this.SAVE_CONTENT({
          formData: this.currentImage,
          onUploadProgress: (event) => {
            this.progress = Math.round((100 * event.loaded) / event.total);
          },
          content_type: "solution",
        })
          .then((res) => {
            this.cover_img_key = res.data.details.key;
            this.saveSolution(option);
          })
          .catch((err) => err);
      } else {
        this.saveSolution(option);
      }
    },
    saveSolution(option) {
      if (option) {
        if (this.$route.params.solution_id) {
          this.UPDATE_SOLUTION_BY_ID({
            solutionId: this.$route.params.solution_id,
            name: this.solutions.name,
            name_ja: this.solutions.name_ja === null ? "" : this.solutions.name_ja,
            description: this.formatJson(this.solutions.description),
            description_ja: this.formatJson(this.solutions.description_ja),
            overview: this.formatJson(this.solutions.overview),
            overview_ja: this.formatJson(this.solutions.overview_ja),
            languages: this.formatLanguage(this.solutions.language),
            cover_image: this.cover_img_key,
            source_version: this.solutions.version,
            source_location: this.solutions.source_url,
            sandbox_location: this.solutions.sanbdbox_url,
            documentation_location: this.solutions.document_url,
            tutorial_location: this.solutions.tutorial_location,
            max_pv:
              this.solutions.page_view === "" ? 0 : Number(this.solutions.page_view),
            max_rps: this.solutions.rps === "" ? 0 : Number(this.solutions.rps),
            base_price: this.solutions.price === "" ? 0 : Number(this.solutions.price),
            additional_price:
              this.solutions.additional_price === ""
                ? 0
                : Number(this.solutions.additional_price),
            min_cpu: this.solutions.min_cpu === "" ? 0 : Number(this.solutions.min_cpu),
            min_memory:
              this.solutions.min_memory === "" ? 0 : Number(this.solutions.min_memory),
            min_gpu: this.solutions.min_gpu === "" ? 0 : Number(this.solutions.min_gpu),
            links: this.formatLinks(this.solutions.linked_solution),
            use_case: this.formatJson(this.solutions.use_cases),
            use_case_ja: this.formatJson(this.solutions.use_cases_ja),
            status: option,
            segment_id: this.solutions.type.id,
            category_ids: this.formatIndustry(this.solutions.industry),
          }).then(
            (response) => {
              this.$store.dispatch(
                `alert/${ADD_ALERT}`,
                {
                  message: this.$vuetify.lang.t("$vuetify.solution_update_msg_txt"),
                  color: "success",
                },
                { root: true }
              );
              this.isLoading = false;
              this.isSaving = false;
              const solution_type = this.solutions.type.name;
              // this.getDevSolution();
              this.solutions.type = "";
              this.$router.push({
                path: `/solutions?type=${solution_type}&role=dev`,
                props: {
                  'role': 'dev'
                },
              });
            },
            (error) => {
              this.alertMessage = error.message;
              this.alertDialog = true;
              this.isLoading = false;
              this.isSaving = false;
              this.saveOptions = [
                {
                  text: this.$vuetify.lang.t("$vuetify.submit_review_btn_txt"),
                  value: "pending",
                  callback: () => this.saveSolutionWithImg("pending"),
                },
                {
                  text: this.$vuetify.lang.t("$vuetify.draft_btn_txt"),
                  value: "drafted",
                  callback: () => this.saveSolutionWithImg("drafted"),
                },
              ];
            }
          );
        } else {
          this.SAVE_SOLUTION({
            name: this.solutions.name,
            name_ja: this.solutions.name_ja,
            description: this.formatJson(this.solutions.description),
            description_ja: this.formatJson(this.solutions.description_ja),
            overview: this.formatJson(this.solutions.overview),
            overview_ja: this.formatJson(this.solutions.overview_ja),
            languages: this.formatLanguage(this.solutions.language),
            cover_image: this.cover_img_key,
            source_version: this.solutions.version,
            source_location: this.solutions.source_url,
            sandbox_location: this.solutions.sanbdbox_url,
            documentation_location: this.solutions.document_url,
            tutorial_location: this.solutions.tutorial_location,
            max_pv:
              this.solutions.page_view === "" ? 0 : Number(this.solutions.page_view),
            max_rps: this.solutions.rps === "" ? 0 : Number(this.solutions.rps),
            base_price: this.solutions.price === "" ? 0 : Number(this.solutions.price),
            additional_price:
              this.solutions.additional_price === ""
                ? 0
                : Number(this.solutions.additional_price),
            min_cpu: this.solutions.min_cpu === "" ? 0 : Number(this.solutions.min_cpu),
            min_memory:
              this.solutions.min_memory === "" ? 0 : Number(this.solutions.min_memory),
            min_gpu: this.solutions.min_gpu === "" ? 0 : Number(this.solutions.min_gpu),
            links: this.formatLinks(this.solutions.linked_solution),
            use_case: this.formatJson(this.solutions.use_cases),
            use_case_ja: this.formatJson(this.solutions.use_cases_ja),
            status: option,
            segment_id: this.solutions.type.id,
            category_ids: this.formatIndustry(this.solutions.industry),
          }).then(
            (response) => {
              this.$store.dispatch(
                `alert/${ADD_ALERT}`,
                {
                  message: this.$vuetify.lang.t("$vuetify.solution_register_msg_txt"),
                  color: "success",
                },
                { root: true }
              );
              this.isLoading = false;
              this.isSaving = false;
              const solution_type = this.solutions.type.name;
              this.solutions.type = "";
              this.$router.push({
                path: `/solutions?type=${solution_type}&role=dev`,
                props: {
                  'role': 'dev'
                },
              });
            },
            (error) => {
              this.alertMessage = error.message;
              this.alertDialog = true;
              this.isLoading = false;
              this.isSaving = false;
              this.saveOptions = [
                {
                  text: this.$vuetify.lang.t("$vuetify.submit_review_btn_txt"),
                  value: "pending",
                  callback: () => this.saveSolutionWithImg("pending"),
                },
                {
                  text: this.$vuetify.lang.t("$vuetify.draft_btn_txt"),
                  value: "drafted",
                  callback: () => this.saveSolutionWithImg("drafted"),
                },
              ];
            }
          );
        }
      }
    },
    formatJson(val) {
      const emptyJson = {
        time: Date.now(),
        blocks: [
          {
            id: Math.random().toString(),
            type: "paragraph",
            data: {
              text: "",
            },
          },
        ],
        version: "2.22.2",
      };
      if (val) {
        return JSON.stringify(val);
      }
      return JSON.stringify(emptyJson);
    },
    formatIndustry(arr) {
      let newArr = [];
      if (Array.isArray(arr)) {
        arr.forEach((object) => {
          newArr.push(object.id);
        });
      }
      return newArr;
    },
    formatLanguage(arr) {
      let newArr = [];
      if (Array.isArray(arr)) {
        arr.forEach((object) => {
          newArr.push(object.name);
        });
      }
      return newArr.join(",");
    },
    formatLinks(arr) {
      let newArr = [];
      if (Array.isArray(arr)) {
        arr.forEach((object) => {
          newArr.push(object.id);
        });
      }
      return newArr.join(",");
    },
    isText(str) {
      let regex = /^[a-zA-Z]+$/;
      if (str.match(regex)) {
        return false;
      } else if (str.includes("-")) {
        return false;
      }
      return true;
    },
    // image select
    selectImage(image) {
      this.currentImage = image;
      this.setSolImg();
      this.progress = 0;
    },
    fetchSolution() {
      this.solutions.type = "";
      this.isLoading = true;
      this.LOAD_SOLUTION_BY_ID({
        solution_id: this.$route.params.solution_id,
        localize: false
      }).then(
        (response) => {
          this.solutions.name = this.selectedSolution.name;
          this.solutions.name_ja = this.selectedSolution.name_ja;
          this.solutions.type = this.selectedSolution.segment;
          this.solutions.overview = this.parseJson(this.selectedSolution.overview);
          this.solutions.overview_ja = this.parseJson(this.selectedSolution.overview_ja);
          this.solutions.description = this.parseJson(this.selectedSolution.description);
          this.solutions.description_ja = this.parseJson(this.selectedSolution.description_ja);
          this.solutions.industry = this.selectedSolution.categories;
          this.solutions.use_cases = this.parseJson(this.selectedSolution.use_case);
          this.solutions.use_case_ja = this.parseJson(this.selectedSolution.use_case_ja);
          this.solutions.linked_solution = this.selectedSolution.links;
          this.selectedSolution?.languages.forEach((element) =>
            this.solutions.language.push({ name: element })
          );
          this.solutions.min_cpu = this.selectedSolution.min_cpu;
          this.solutions.min_memory = this.selectedSolution.min_memory;
          this.solutions.min_gpu = this.selectedSolution.min_gpu;
          this.solutions.page_view = this.selectedSolution.max_pv;
          this.solutions.rps = this.selectedSolution.max_rps;
          this.solutions.price = this.selectedSolution.base_price;
          this.solutions.additional_price = this.selectedSolution.additional_price;
          this.solutions.version = this.selectedSolution.source_version;
          this.solutions.source_url = this.selectedSolution.source_location;
          this.solutions.sanbdbox_url = this.selectedSolution.sandbox_location;
          this.solutions.document_url = this.selectedSolution.documentation_location;
          this.solutions.tutorial_location = this.selectedSolution.tutorial_location;
          this.cover_img_key = this.selectedSolution.cover_image_key;
          this.selectedSolCovImg = this.selectedSolution.cover_image
            ? this.selectedSolution.cover_image
            : undefined;
          this.solutions.status = this.selectedSolution.status;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    parseJson(jsonString) {
      const parsedJson = {};
      if (jsonString) {
        Object.assign(parsedJson, JSON.parse(jsonString));
      }
      return parsedJson;
    },
    setSolutionOverviewEditorJa($event) {
      if ($event) {
        this.solutionOverviewJa = $event;
      }
    },
    setSolutionOverviewEditor($event) {
      if ($event) {
        this.solutionOverview = $event;
      }
      this.solutionOverviewJa = $event;
    },
    setOverviewErr($event) {
      this.overviewErr = $event;
    },
    setSolutionDescEditorJa($event) {
      if ($event) {
        this.solutionDescJa = $event;
      }
    },
    setSolutionDescEditor($event) {
      if ($event) {
        this.solutionDesc = $event;
      }
    },
    setSolutionUseCaseEditorJa($event) {
      if ($event) {
        this.solutionUseCaseJa = $event;
      }
    },
    setSolutionUseCaseEditor($event) {
      if ($event) {
        this.solutionUseCase = $event;
      }
    },
    setUseCaseErr($event) {
      this.useCaseErr = $event;
    },
    onCancel() {
      this.confirmDialog = false;
    },
  },
};
</script>
