<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="size==='small'"
                icon
                :color="color"
                :loading="loading"
                :disabled="disabled"
                v-bind="attrs"
                v-on="on"
                @click="onClick()"
            >
                <v-icon
                    small
                >
                    {{iconName}}
                </v-icon>
            </v-btn>
            <v-btn
                v-else-if="size==='x-small'"
                icon
                :color="color"
                :loading="loading"
                :disabled="disabled"
                v-bind="attrs"
                v-on="on"
                @click="onClick()"
            >
                <v-icon
                    x-small
                >
                    {{iconName}}
                </v-icon>
            </v-btn>
            <v-btn
                v-else-if="size==='x-large'"
                icon
                :color="color"
                :loading="loading"
                :disabled="disabled"
                v-bind="attrs"
                v-on="on"
                @click="onClick()"
            >
                <v-icon
                    x-large
                >
                    {{iconName}}
                </v-icon>
            </v-btn>
            <v-btn
                v-else-if="size==='large'"
                icon
                :color="color"
                :loading="loading"
                :disabled="disabled"
                v-bind="attrs"
                v-on="on"
                @click="onClick()"
            >
                <v-icon
                    large
                >
                    {{iconName}}
                </v-icon>
            </v-btn>
            <v-btn
                v-else
                icon
                :color="color"
                :loading="loading"
                :disabled="disabled"
                v-bind="attrs"
                v-on="on"
                @click="onClick()"
            >
                <v-icon>
                    {{iconName}}
                </v-icon>
            </v-btn>
        </template>
        <span>{{tooltipText}}</span>
    </v-tooltip>
</template>

<script>

export default {
    props: {
        tooltipText: {
            type: String,
            default: "Click"
        },
        iconName: {
            type: String,
            default: "mdi-television"
        },
        color: {
            type: String,
            default: "primary"
        },
        size: {
            type: String,
            default: "medium"
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        
    },
    data() {
        return {
            
        };
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        }
    }
};
</script>

<style>
</style>