<template>
  <v-dialog v-model="showModal" max-width="400" persistent>
    <v-card>
      <v-card-title class="font-weight-bold"> {{ $vuetify.lang.t("$vuetify.wallet.enter_passcode") }} </v-card-title>
      <v-card-subtitle v-if="message">{{message}}</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col>
            <ValidationObserver ref="passcodeConfirm">
              <v-form ref="passcodeForm" @submit.prevent="confirmClick()">
                <v-col cols="12" md="12">
                  <ValidationProvider
                    :name="$vuetify.lang.t('$vuetify.wallet.passcode')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :class="{ 'mask-password': !showPasscode }"
                      :label="$vuetify.lang.t('$vuetify.wallet.passcode')"
                      :value="value"
                      :placeholder="$vuetify.lang.t('$vuetify.wallet.passcode')"
                      autocomplete="off"
                      prepend-inner-icon="lock"
                      outlined
                      :append-icon="showPasscode ? 'mdi-eye' : 'mdi-eye-off'"
                      type="text"
                      :error-messages="errors"
                      @click:append="showPasscode = !showPasscode"
                      @input="$emit('input', $event)"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-form>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="isCancelBtn" :disabled="isLoading" color="error" @click="clickCancel()">{{ $vuetify.lang.t('$vuetify.common.cancel') }}</v-btn>
        <v-btn color="primary" :loading="isLoading" @click="confirmClick()">{{ $vuetify.lang.t('$vuetify.common.confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { ACCESS_WALLET } from "@/store/_actiontypes";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  props: {
    isCancelBtn: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    message: String,
    value: String,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      showModal: (state) => state.wallet.showModal,
    }),
  },
  data() {
    return {
      passcode: null,
      showPasscode: false,
    };
  },
  methods: {
    ...mapActions("wallet", [ACCESS_WALLET]),
    confirmClick() {
      this.$refs.passcodeConfirm.validate().then((success) => {
        if (!success) {
          return;
        }
        this.ACCESS_WALLET({
          passcode: this.value,
        })
          .then((res) => {
            !this.isCancelBtn && this.$store.commit("wallet/toggleModal", false);
            this.isCancelBtn && this.$emit('confirm')
          })
          .catch(() => {});
      });
    },
    clickCancel() {
      this.$emit('cancel')
    }
  },
};
</script>