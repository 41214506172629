<template>
  <div>
    <v-text-field
      v-if="inputType==TEXT"
      v-model="inputVal"
      :type="textInputType"
      :label="label"
      :placeholder="placeholder"
      :hint="hint"
      :prepend-icon="prependIcon"
      :rules="rules"
      :disabled="disabled"
      :dense="dense"
      v-on:change="onChange"
    ></v-text-field>
    <v-textarea
      v-if="inputType==TEXT_AREA"
      outlined
      v-model="inputVal"
      :type="textInputType"
      :label="label"
      :placeholder="placeholder"
      :hint="hint"
      :prepend-icon="prependIcon"
      :rules="rules"
      :disabled="disabled"
      :dense="dense"
      v-on:change="onChange"
    ></v-textarea>
    <vuetify-money
      v-if="inputType==NUMBER"
      v-model="inputVal"
      :label="label"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      :outlined="outlined"
      :clearable="clearable"
      :valueWhenIsEmpty="valueWhenIsEmpty"
      :options="options"
      :hint="hint"
      :prepend-icon="prependIcon"
      :rules="rules"
      :dense="dense"
      v-on:change="onChange"
    />
    <PasswordInput
      v-if="inputType==PASSWORD"
      v-model="inputVal"
      :label="label"
      :placeholder="placeholder"
      :hint="hint"
      :prependIcon="prependIcon"
      :rules="rules"
      :disabled="disabled"
      :dense="dense"
      @onChange="onChange"
    ></PasswordInput>
    <v-select
      v-if="inputType==SELECT"
      v-model="inputVal"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :label="label"
      :placeholder="placeholder"
      :hint="hint"
      :disabled="disabled"
      class="ma-0 pa-0 form-label"
      dense
      offset-x
      :rules="rules"
      :multiple="multiple"
      v-on:change="onChange"
    ></v-select>
    <v-autocomplete
      v-if="inputType==AUTO_COMPLETE"
      v-model="inputVal"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :label="label"
      :placeholder="placeholder"
      :hint="hint"
      :disabled="disabled"
      class="ma-0 pa-0 form-label"
      dense
      offset-x
      :rules="rules"
      :multiple="multiple"
      :search-input.sync="search"
      v-on:change="onChange"
    ></v-autocomplete>
    <v-autocomplete
      v-if="inputType==MULT_AUTO_COMPLETE"
      v-model="inputVal"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :label="label"
      :placeholder="placeholder"
      :hint="hint"
      :disabled="disabled"
      chips
      class="ma-0 pa-0 form-label"
      dense
      offset-x
      multiple
      hide-selected
      clearable
      deletable-chips
      :rules="rules"
      :search-input.sync="search"
      v-on:change="onChange"
    ></v-autocomplete>
    <v-combobox
      v-if="inputType==COMBOBOX"
      v-model="inputVal"
      :items="items"
      :search-input.sync="search"
      :label="label"
      hide-selected
      multiple
      persistent-hint
      chips
      clearable
      deletable-chips
    ></v-combobox>
    <DatePicker
      v-if="inputType==DATE_PICKER"
      v-model="inputVal"
      :label="label"
      :placeholder="placeholder"
      :hint="hint"
      :disabled="disabled"
      :type="pickerType"
      @onInput="onChange"
    />
    <DateTimePicker
      v-if="inputType==DATETIME_PICKER"
      v-model="inputVal"
      :label="label"
      :placeholder="placeholder"
      :hint="hint"
      :disabled="disabled"
      @onInput="onChange"
    />
    <RadioGroup
      v-if="inputType==RADIO_GROUP"
      v-model="inputVal"
      :label="label"
      :radioOption="items"
    />
  </div>
</template>

<script>

import Vue from "vue";
import VuetifyMoney from "vuetify-money";
Vue.use(VuetifyMoney);

import {
  TEXT,
  TEXT_AREA,
  PASSWORD,
  NUMBER,
  AUTO_COMPLETE,
  DATE_PICKER,
  DATETIME_PICKER,
  RADIO_GROUP,
  MULT_AUTO_COMPLETE,
  COMBOBOX,
  SELECT,
} from '@/components/common/input/_inputTypes';

import {
  NUMBER_FORMAT_LOCALE
} from '@/global/_constants';

import PasswordInput from "@/components/common/input/PasswordInput";
import DatePicker from "@/components/common/input/DatePicker";
import DateTimePicker from "@/components/common/input/DateTimePicker";
import RadioGroup from "@/components/common/input/RadioGroup";

export default {
  props: {
    inputType: {
      type: String,
      default: TEXT
    },
    value : {
      
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    prependIcon: {
      type: String,
      default: ""
    },
    dense: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    itemText: {
      type: String,
      default: ""
    },
    itemValue: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    textInputType: {
      type: String,
      default: "text"
    },
    pickerType: {
      type: String,
      default: "date"
    },
  },
  components: {
    PasswordInput,
    DatePicker,
    DateTimePicker,
    RadioGroup
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  data() {
    return {
      TEXT: TEXT,
      TEXT_AREA: TEXT_AREA,
      PASSWORD: PASSWORD,
      NUMBER: NUMBER,
      AUTO_COMPLETE: AUTO_COMPLETE,
      DATE_PICKER: DATE_PICKER,
      DATETIME_PICKER,
      RADIO_GROUP: RADIO_GROUP,
      MULT_AUTO_COMPLETE: MULT_AUTO_COMPLETE,
      COMBOBOX: COMBOBOX,
      SELECT: SELECT,
      search: null,
      readonly: false,
      outlined: false,
      clearable: true,
      valueWhenIsEmpty: "",
      options: {
        locale: NUMBER_FORMAT_LOCALE,
        prefix: "",
        suffix: "",
        length: 15,
        precision: 0
      }
    };
  },
  watch: {
    inputVal() {
      this.search = ''
    }
  },
  methods: {
    onChange() {
      this.$emit('onChange');
    },
  }
};
</script>
