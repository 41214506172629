<template>
  <v-menu
    v-model="showPicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="inputVal"
        :label="label"
        :hint="hint"
        persistent-hint
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="inputVal"
      no-title
      :type="type"
      @input="onInput"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value : {
      
    },
    label: {
      type: String,
      default: "Select Date"
    },
    hint: {
      type: String,
      default: "YYYY/MM/DD"
    },
    type: {
      type: String,
      default: "date"
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  data() {
    return {
      showPicker: false,
    };
  },
  methods: {
    onInput() {
      this.showPicker = false;
      this.$emit('onInput');
    },
  }
};
</script>
