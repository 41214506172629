<template>
    <v-radio-group v-model="inputVal">
      <template v-slot:label>
        <div>{{label}}</div>
      </template>
      <div v-for="item in this.radioOption" :key="item.value">
        <v-radio :value="item.value">
            <template v-slot:label>
                <div>{{item.text}}</div>
            </template>
        </v-radio>
      </div>
    </v-radio-group>
</template>

<script>
export default {
  props: {
    value : {
      type: String,
      default: "0"
    },
    label: {
      type: String,
      default: "Select One"
    },
    radioOption: {
      type: Array,
      default() {
        return [
            {
                text: "Yes",
                value: "1"
            },
            {
                text: "No",
                value: "0"
            }
        ];
      }
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  data() {
    return {

    };
  },
  methods: {
    
  }
};
</script>
