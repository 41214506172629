<template>
  <div>
    <v-app-bar app clipped-left dark color="primary">
      <v-row>
        <v-col cols="6" xs="6" md="6">
          <v-toolbar-title>
            <router-link
              to="/public/solutions"
              tag="span"
              style="cursor: pointer"
              class="headline"
            >
              <img src="@/assets/images/logo_white_horizontal.png" class="logo" />
            </router-link>
          </v-toolbar-title>
        </v-col>
        <v-col class="d-flex justify-end" cols="3" xs="3" md="3">
          <switch-language />
        </v-col>
        <v-col cols="3" xs="3" md="3" class="get-started-icon">
          <v-btn
            class="text-uppercase font-weight-bold"
            color=""
            large
            outlined
            block
            @click="goToLogin()"
          >
            <span class="d-none d-sm-flex">{{
              $vuetify.lang.t("$vuetify.get_started_btn_txt")
            }}</span>
            <v-icon class="d-flex d-sm-none">account_circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-spacer></v-spacer>
    <div class="pa-4 mt-12">
      <Loader
        v-if="isLoading"
        class="mr-2 mt-6"
        loaderType="card-avatar, article, actions"
      />
      <v-container v-else fill-height>
        <v-row>
          <v-col cols="12" xs="12" md="7">
            <v-sheet class="pb-3">
              <h1>{{ solutionDetails.name }}</h1>
            </v-sheet>
            <v-chip class="ma2" color="primary" label outlined pill>
              <v-icon left> mdi-account-outline </v-icon>
              {{
                solutionDetails.account ? solutionDetails.account.account_name : "None"
              }}
            </v-chip>
            <v-chip
              class="ma-2"
              v-bind:color="solutionDetails.segment && solutionDetails.segment.color"
              label
              outlined
              pill
            >
              {{ solutionDetails.segment && solutionDetails.segment.name }}
            </v-chip>
            <v-sheet class="pt-3">
              <h2>{{ $vuetify.lang.t("$vuetify.overview_label_txt") }}</h2>
            </v-sheet>
            <p v-if="solutionDetails && solutionDetails.overview">
              <show-rich-text-data
                :editorId="'selectedSolutionOverview'"
                :editorData="solutionDetails.overview"
              />
            </p>
            <v-sheet class="pt-3">
              <h2>{{ $vuetify.lang.t("$vuetify.description_label_txt") }}</h2>
            </v-sheet>
            <p v-if="solutionDetails && solutionDetails.description">
              <show-rich-text-data
                :editorId="'selectedSolutionDesc'"
                :editorData="solutionDetails.description"
              />
            </p>
            <v-sheet class="pt-3">
              <h2>{{ $vuetify.lang.t("$vuetify.use_case_label_txt") }}</h2>
            </v-sheet>
            <p v-if="solutionDetails && solutionDetails.use_case">
              <show-rich-text-data
                :editorId="'selectedSolutionUseCases'"
                :editorData="solutionDetails.use_case"
              />
            </p>
            <v-sheet class="pb-3">
              <h2>{{ $vuetify.lang.t("$vuetify.language_label_txt") }}</h2>
            </v-sheet>
            <v-chip
              v-for="element in solutionDetails.languages"
              :key="element"
              class="ma-2"
              color="success"
              label
              outlined
              pill
            >
              {{ element }}
            </v-chip>
          </v-col>
          <v-col cols="12" xs="12" md="5">
            <v-card class="elevation-0">
              <v-img
                class="rounded my-3"
                :src="
                  solutionDetails.cover_image
                    ? solutionDetails.cover_image
                    : segmentImg(solutionDetails)
                "
                aspect-ratio="2"
              ></v-img>

              <v-sheet class="py-3">
                <h2>{{ $vuetify.lang.t("$vuetify.industry_label_txt") }}</h2>
              </v-sheet>
              <v-chip
                class="ma-2"
                v-bind:color="solutionDetails.segment && solutionDetails.segment.color"
                outlined
                label
                pill
                v-for="element in solutionDetails.categories"
                :key="element.id"
              >
                {{ element.name }}
              </v-chip>
              <v-card-actions class="py-5">
                <v-btn
                  class="text-capitalize font-weight-bold"
                  color="primary"
                  large
                  outlined
                  block
                  :disabled="
                    solutionDetails.status && solutionDetails.status === 'published'
                      ? false
                      : true
                  "
                  @click="goToLogin()"
                >
                  {{ $vuetify.lang.t("$vuetify.create_project_btn_txt") }}
                  <v-icon right class="ml-2">add_circle_outline</v-icon>
                </v-btn>
              </v-card-actions>

              <v-card-actions class="py-5">
              <v-btn
                class="text-capitalize font-weight-bold"
                :color="solutionDetails.segment && solutionDetails.segment.color"
                large
                outlined
                block
                href="https://gigalogy.com/request-demo/"
              >
                {{ $vuetify.lang.t("$vuetify.contact_sales_btn_txt") }}
                <v-icon right class="ml-2">chat_bubble_outline</v-icon>
                </v-btn>
            </v-card-actions>

            </v-card>
          </v-col>
        </v-row>
        <br />
        <v-row v-if="hasLinks()" no-gutters>
          <v-col>
            <h2>{{ $vuetify.lang.t("$vuetify.linked_solution_label_txt_3") }}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="4"
            xl="4"
            v-for="element in solutionDetails.links"
            :key="element.id"
          >
            <LinkSolutionCard
              v-if="element"
              :itemData="element"
              :color="solutionDetails.segment && solutionDetails.segment.color"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { LOAD_SOLUTION_DETAILS } from "@/store/_actiontypes";

import { RAISED_BUTTON } from "@/components/common/button/_buttontypes";

import Loader from "@/components/common/loader/Loader";
import ShowRichTextData from "../components/SolutionRegister/ShowRichTextData";
import LinkSolutionCard from "@/components/Solutions/LinkSolutionCard";
import SwitchLanguage from "@/components/common/SwitchLanguage";

export default {
  components: {
    Loader,
    ShowRichTextData,
    LinkSolutionCard,
    SwitchLanguage,
  },
  computed: {
    ...mapState({
      solutionDetails: (state) => state.solution.solutionDetails,
    }),
  },
  mounted() {
    this.getSolutionDetails();
  },
  data() {
    return {
      isLoading: false,
      RAISED_BUTTON: RAISED_BUTTON,
    };
  },
  methods: {
    ...mapActions("solution", [LOAD_SOLUTION_DETAILS]),
    getSolutionDetails() {
      this.isLoading = true;
      this.LOAD_SOLUTION_DETAILS({
        solution_id: this.$route.params.solution_id,
      }).then(
        (response) => {
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    goToLogin() {
      this.$router.push("/login");
    },
    hasLinks() {
      return this.solutionDetails.links && this.solutionDetails.links.length > 0
        ? true
        : false;
    },
    segmentImg(data) {
      if (data && data.segment && data.segment.cover_image_url) {
        return data.segment.cover_image_url;
      }
    },
  },
};
</script>

<style scoped>
.v-app-bar {
  height: 64px !important;
}
.text-4xl {
  color: black;
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-2xl {
  color: black;
  font-size: 1.875rem;
  line-height: 1.5;
  letter-spacing: 0.025em;
}
.font-semibold {
  font-weight: 600;
}
.whitespace-pre-line {
  white-space: pre-line;
}
.text-base {
  color: black;
  font-size: 1rem;
  line-height: 1.5rem;
}
.font-normal {
  font-weight: 400;
}
.mb-14 {
  margin-bottom: 3.5rem;
}

p,
ul {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-card {
  margin: 10px;
}
.v-card__title {
  word-break: break-word;
  height: 100px;
  vertical-align: middle;
}
.v-card__subtitle,
.v-card__text {
  font-size: 1em;
}
.ml-auto {
  margin-left: auto;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.logo {
  max-height: 48px;
  vertical-align: middle;
}
@media screen and (max-width: 950px) {
  .get-started-icon {
    margin-top: 6px;
  }
}
</style>
