import { ADD_ALERT, CHANGE_LOCALE } from "@/store/_actiontypes";
import { CREATE_ALERT, SET_LOCALE } from "@/store/_mutationtypes";

const state = {
  alert: {},
  locale: "en",
};

const actions = {
  [ADD_ALERT]({ commit }, alert) {
    alert.show = true;
    alert.color = alert.color;
    alert.message = alert.message;
    commit(CREATE_ALERT, alert);
  },
  [CHANGE_LOCALE]({ commit }, locale) {
    commit(SET_LOCALE, locale);
  },
};

const mutations = {
  [CREATE_ALERT](state, alert) {
    state.alert = alert;
  },
  [SET_LOCALE](state, locale) {
    state.locale = locale;
  },
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations,
};
