<template>
  <v-text-field
    v-model="inputVal"
    :label="label"
    :placeholder="placeholder"
    :hint="hint"
    :prepend-icon="prependIcon"
    :type="showPassword ? 'text' : 'password'"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    @click:append="showPassword = !showPassword"
    :rules="rules"
    :disabled="disabled"
    v-on:change="onChange"
    :dense="dense"
  >
    <!-- to change append icon size -->
    <!-- <v-icon
      slot="append"
      small
      v-if="showPassword"
      @click="showPassword = !showPassword"
    >mdi-eye</v-icon>
    <v-icon
      slot="append"
      small
      v-else
      @click="showPassword = !showPassword"
    >mdi-eye-off</v-icon> -->
  </v-text-field>
</template>

<script>
export default {
  props: {
    value : {
      
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    rules: {
      type: Array,
      default() {
        return [];
      }
    },
    prependIcon: {
      type: String,
      default: ""
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  data() {
    return {
      showPassword: false,
    };
  },
  methods: {
    onChange() {
      this.$emit('onChange');
    },
  }
};
</script>
