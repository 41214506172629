import Api from "@/services/api";
import {
  ADD_ALERT,
  LOAD_ALL_JOB,
  RERUN_JOB_BY_ID
} from "@/store/_actiontypes";
import {
  SET_ALL_JOB
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
  allJob: {}
};

const actions = {
  [LOAD_ALL_JOB]({ commit }, {start_date, end_date, job_status, per_page, page}){
    updateLoaderTo(true);
    commit(SET_ALL_JOB, {});
    return new Promise((resolve, reject) => {
      Api.get(`jobs?${start_date ? `start_date=${start_date}`: ""}${end_date ? `&end_date=${end_date}` : ""}${page ? `&page=${page}` : ""}&per_page=${per_page ? per_page : 100}${job_status ? `&status=${job_status}` : ""}`).then(
        (response) => {
          let allJob = response.data;
          commit(SET_ALL_JOB, allJob);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [RERUN_JOB_BY_ID]({ commit, dispatch }, { job_id, name, year_month }){
    let jobs_name = {
      "invoice_overdue": "update-overdue",
      "bill_consolidator": "consolidate-bills",
      "generate_monthly_invoice": "generate-invoices",
      "invoice_due_notification": "notify-due-invoices"
    }
    
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`invoices/${jobs_name[name]}?job-id=${job_id}${year_month ? `&year-month=${year_month}` : ""}`).then(
        (response) => {
          let jsonResponse = response.data;
          dispatch(
            `alert/${ADD_ALERT}`,
            { message: jsonResponse.message, color: "success" },
            { root: true }
          );
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  }
};

const mutations = {
  [SET_ALL_JOB](state, allJob) {
    state.allJob = allJob;
  }
};

export const job = {
  namespaced: true,
  state,
  actions,
  mutations,
};
